import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
// import ProductsPage from "./Products/Products";
import { images } from "../../../../assets/index";
import { colorTags } from "../../../../constants/variants";

import InstitutionsPageNGO from "./ngo";
import InstitutionsPagePRV from "./prv";
import InstitutionsPageGOV from "./gov";
import { useGetInstitutionsQuery } from "../../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#F1F1F1",
    // padding: theme.spacing(12),
    // paddingTop: theme.spacing(2),
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    // marginTop: theme.spacing(2),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
}));

const ProductsPage = ({ searchBy, query }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchResult, setSearchResult] = useState([]);

  const {
    data: institutionsData,
    error,
    isLoading: loadingInstitutionData,
  } = useGetInstitutionsQuery();

  const institutionData = [
    {
      name: "Logistics Solutions Inc.",
      image: images.AcMeet,
      type: "prv",
    },
    {
      name: "Export Masters Group",
      image: images.AcMeet,
      type: "prv",
    },
    {
      name: "International Freight Logistics",
      image: images.AcMeet,
      type: "prv",
    },
    {
      name: "Global Exporters Consortium",
      image: images.AcMeet,
      type: "ngo",
    },
    {
      name: "Customs and Trade Authority",
      image: images.AcMeet,
      type: "gov",
    },
    {
      name: "Oceanic Shipping Services",
      image: images.AcMeet,
      type: "prv",
    },
    {
      name: "Export Compliance Bureau",
      image: images.AcMeet,
      type: "gov",
    },
    {
      name: "Air Cargo Logistics",
      image: images.AcMeet,
      type: "prv",
    },
    {
      name: "Trade Development Council",
      image: images.AcMeet,
      type: "gov",
    },
    {
      name: "Global Supply Chain Network",
      image: images.AcMeet,
      type: "ngo",
    },
  ];
  useEffect(() => {
    if (institutionsData) {
      const lowerQuery = query.toLowerCase();
      console.log(lowerQuery);
      setSearchResult(
        institutionsData?.filter((institution) => {
          return institution?.institutionName
            .toLowerCase()
            .includes(lowerQuery);
        })
      );
    }
  }, [institutionsData, query]);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (loadingInstitutionData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {" "}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          ".Mui-selected": {
            color: `${colorTags.SECONDARY} !important`,
          },
        }}
        TabIndicatorProps={{
          sx: { backgroundColor: colorTags.SECONDARY },
        }}
        centered
        variant="fullWidth"
        style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
        <Tab
          label="Government"
          className={selectedTab === 0 ? classes.selectedTab : ""}
        />
        <Tab
          label="Private"
          className={selectedTab === 1 ? classes.selectedTab : ""}
        />
        <Tab
          label="NGO"
          className={selectedTab === 2 ? classes.selectedTab : ""}
        />
      </Tabs>
      {/* <SearchBox /> */}
      <div className={classes.content}>
        {selectedTab === 0 && (
          <InstitutionsPageGOV
            data={searchResult?.filter((product) => product.category === "GOV")}
          />
        )}
        {selectedTab === 1 && (
          <InstitutionsPagePRV
            data={searchResult?.filter((product) => product.category === "PRV")}
          />
        )}
        {selectedTab === 2 && (
          <InstitutionsPageNGO
            data={searchResult?.filter((product) => product.category === "NGO")}
          />
        )}
        {/* {selectedTab === 3 && <NonPerishables />}
        {selectedTab === 4 && <TopProducts />} */}
        {/* 
        {selectedTab === 2 && <InstitutionsPage />}
        {selectedTab === 3 && <LawsNormsPage />}
        {selectedTab === 4 && <FormsDocumentsPage />} */}
      </div>
    </div>
  );
};

export default ProductsPage;
