import React, { useEffect, useRef } from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DescriptionIcon from "@mui/icons-material/Description";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
    marginTop: theme.spacing(15),
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,

    height: "auto",
    backgroundColor: colorTags.SECONDARY,
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      margin: theme.spacing(0),
      padding: 2,
      paddingTop: 12,
    },
  },
  coloredHalf: {
    flex: 1,
    // backgroundColor: "rgba(240, 230, 140, 0.1)",

    width: "100%",
    minHeight: "300px",
    padding: theme.spacing(3),
    [theme.breakpoints.down(600)]: {
      marginBottom: theme.spacing(2),
    },
  },
  circle: {
    // width: 30,
    // height: 30,
    // borderRadius: "50%",
    textColor: colorTags.PRIMARY,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.8s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
    opacity: 1,
    animation: "$fadeInUp 1s forwards",
  },
  icon: {
    color: colorTags.PRIMARY,
  },
  contentContainer: {
    backgroundColor: "#ff3f",
    flex: 1,
    padding: theme.spacing(3),
  },
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));

const ExportType = () => {
  const classes = useStyles();

  const indirectRef = useRef(null);
  const directRef = useRef(null);
  const cooperativeRef = useRef(null);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const indirectObserver = new IntersectionObserver(
      handleIntersection,
      options
    );
    const directObserver = new IntersectionObserver(
      handleIntersection,
      options
    );
    const cooperativeObserver = new IntersectionObserver(
      handleIntersection,
      options
    );

    // Observe the icon divs
    if (indirectRef.current) {
      indirectObserver.observe(indirectRef.current);
    }
    if (directRef.current) {
      directObserver.observe(directRef.current);
    }
    if (cooperativeRef.current) {
      cooperativeObserver.observe(cooperativeRef.current);
    }

    // Cleanup the observers
    return () => {
      indirectObserver.disconnect();
      directObserver.disconnect();
      cooperativeObserver.disconnect();
    };
  }, []);

  return (
    <div className={classes.mainContainer}>
      {/* Card 1 */}
      <Card
        className={classes.coloredHalf}
        style={{
          // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
          boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
          borderRadius: "15px",
        }}>
        <div className={classes.circle} ref={indirectRef}>
          {/* <LocalShippingIcon className={classes.icon} /> */}
          <Typography
            variant="h6"
            className={classes.icon}
            style={{ marginBottom: "20px" }}>
            Indirect Export
          </Typography>
        </div>

        <Typography>
          Indirect export involves selling goods to an intermediary, such as an
          export agent or trading company, who then sells the products in
          foreign markets on behalf of the exporter. It is an effective way for
          businesses to enter international markets without direct involvement
          in export activities.
        </Typography>
      </Card>

      {/* Card 2 */}
      <Card
        className={classes.coloredHalf}
        style={{
          // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
          boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
          borderRadius: "15px",
        }}>
        <div className={classes.circle} ref={directRef}>
          {/* <MonetizationOnIcon className={classes.icon} /> */}
          <Typography
            variant="h6"
            className={classes.icon}
            style={{ marginBottom: "20px" }}>
            Direct Export
          </Typography>
        </div>

        <Typography>
          Direct export involves selling goods directly to customers in foreign
          markets. It allows businesses to have full control over the export
          process, build direct relationships with customers, and adapt to
          market demands more effectively. Direct exporting can lead to higher
          profit margins and greater market reach.
        </Typography>
      </Card>

      {/* Card 3 */}
      <Card
        className={classes.coloredHalf}
        style={{
          // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
          boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
          borderRadius: "15px",
        }}>
        <div className={classes.circle} ref={cooperativeRef}>
          {/* <DescriptionIcon className={classes.icon} /> */}
          <Typography
            variant="h6"
            className={classes.icon}
            style={{ marginBottom: "20px" }}>
            Cooperative Export
          </Typography>
        </div>

        <Typography>
          Cooperative export involves collaboration between multiple companies
          or exporters to combine their resources and expertise for
          international trade. It allows businesses to leverage each other's
          strengths and expand their market presence by working together to
          achieve common export goals.
        </Typography>
      </Card>
    </div>
  );
};

export default ExportType;
