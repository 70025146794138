import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddLink from "./AddLink";
import EditLink from "./EditLink";

import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import EditIcon from "@mui/icons-material/Edit";
import {
  useDeleteDestinationRequestMutation,
  useDeleteLinksMutation,
  useGetLinksQuery,
} from "../../../../features/api/apiSlice";
import LoadingScreen from "../../../../components/LoadingMain";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { BASE_FILE_URL, FILE_LINK_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
const linksDataq = [
  // add links data
  // [{ id, linkTitle, linkURL}]
  {
    id: 1,
    linkTitle: "q",
    linkURL: "q.com",
  },
];

export default function LinksDash() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "linkTitle",
      headerName: "Link Title",
      flex: 1,
    },
    {
      field: "linkUrl",
      headerName: "Link URL",
      flex: 2,
    },
    {
      field: "linkType",
      headerName: "Link Type",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              // delete link
              api={useDeleteLinksMutation}
              dialogLabel="Delete Link"
              id={params?.row?.id}
              name={`${params?.row?.linkTitle}`}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const handleTopModalClose = () => setTopModalOpen(false);
  const handleTopModalOpen = () => setTopModalOpen(true);

  const {
    data: linksData,
    isLoading: linksLoading,
    isError: linksError,
  } = useGetLinksQuery();

  //   if (isLoading) {
  //     return (
  //       <div
  //         style={{
  //           width: "100%",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}>
  //         <Bars
  //           height="40"
  //           width="40"
  //           color="#4fa94d"
  //           ariaLabel="bars-loading"
  //           wrapperStyle={{}}
  //           wrapperClass=""
  //           visible={true}
  //         />
  //       </div>
  //     );
  //   }

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "70%",

            backgroundColor: colorTags.PRIMARY,
          }}
          onClick={handleModalOpen}>
          Add Link
        </Button>
      </div>
      <AddLink
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Link"
        content="This is the modal content."
        actionText="Close"
      />

      <SimpleCard
        title="Links List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={linksData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
      <EditLink
        open={editModalOpen}
        onClose={handleEditModalClose}
        title="Edit Link"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
    </div>
  );
}
