export const mockUsers = [
  {
    id: 1,
    username: "abera@gmil.com",
    password: "123456",
    token: "mockToken1",
  },
  {
    id: 2,
    username: "user2@gmil.com",
    password: "password2",
    token: "mockToken2",
  },
  // ... add more mock users as needed
];
