import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Grid,
  IconButton,
  Link,
  TextField,
  DragHandle,
  Divider,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { colorTags } from "../../../../constants/variants";

import { images } from "../../../../assets/index";
import ChatIcon from "@mui/icons-material/Chat";
import FlagIcon from "@mui/icons-material/Flag";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import * as Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Remove,
  Edit,
  Delete,
  AddCircleOutline,
  FiberManualRecord,
} from "@mui/icons-material";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import FlagContentModal from "../../../../components/FlagContent";
import AddMainStep from "./AddMainStepCreate";
import EditMainStep from "./EditMainStepCreate";
import AddInstitutions from "./AddInstitutionsCreate";
// import AddDocuments from "./AddDocumentsCreate";
import { set } from "date-fns";

import DeleteDialog from "../../../../components/DeleteDialog";

import {
  useGetProceduresQuery,
  useGetProductByIdQuery,
  useGetProcedureByIdQuery,
  useCreateProcedureRequestMutation,
  useUpdateProcedureRequestMutation,
  useDeleteProcedureRequestMutation,
} from "../../../../features/api/apiSlice";

import { useGetInstitutionsQuery } from "../../../../features/api/apiSlice";
import { useGetDocumentsQuery } from "../../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#F7F7F7",
  },
  pageContainer: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(0),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      // display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  leftColumn: {
    width: "35%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  rightColumn: {
    width: "55%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(-7),
  },
  header: {
    backgroundColor: colorTags.PRIMARY,
    color: "#fff",
    textAlign: "center",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
  },
  title: {
    fontSize: 20,
  },
  mainTitle: {
    fontSize: 30,
    fontWeight: "bold",
    marginTop: theme.spacing(6),
    alignSelf: "left",
  },
  stepsContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: "#F7F7F7",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "transparent",
    // marginBottom: theme.spacing(2),
  },
  summaryTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#006738",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",

    borderRadius: "8px",
    overflow: "hidden",
    zIndex: 1,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  iconButton: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    backgroundColor: "red",
    boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
    color: "white",
    zIndex: 1000,
  },
  mainStepsContainer: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
}));
const styles2 = StyleSheet.create({
  container: {
    padding: "7%",
    fontFamily: "Helvetica",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
    color: "black",
  },
  summaryContainer: {
    padding: "7%",
  },
  summaryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "darkgreen",
  },
  summaryText: {
    fontSize: 14,
    marginBottom: 10,
  },
  stepsContainer: {
    paddingTop: "7%",
  },
  stepContainer: {
    marginBottom: 15,
  },
  stepDescription: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subStepContainer: {
    marginLeft: 20,
    marginBottom: 15,
  },
  subStepDescription: {
    fontSize: 16,
    marginTop: 10,

    fontWeight: "medium",
  },
  subStepDetail: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 10,
  },
  institutionsContainer: {
    marginTop: "7%",
  },
  institutionsTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  institutionName: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 20,
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    right: 30,
    fontSize: 10,
    flexDirection: "row",
    alignItems: "center",
    color: "#888",
  },
  footerIcon: {
    marginRight: 4,
  },
  waterMark: {
    position: "absolute",
    bottom: 100,
    right: 30,
    fontSize: 10,
    color: "#aaa",
  },

  // waterMark: {
  //   position: "absolute",
  //   fontSize: 50,
  //   opacity: 0.1,
  //   zIndex: -1,
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
});
const procedureD = {
  title: "Export Procedure For",
  productName: "",
  id: 0,
  mainSteps: [
    {
      description: "",
      subSteps: [
        {
          id: 0,
          description: "",
          detail: "",
        },
      ],
      id: 0,
    },
  ],
  summary: "",
  institutionsInvolved: [],
  requiredDocuments: [],
};

const AddProcedure = () => {
  const classes = useStyles();
  const classes2 = useStyles();

  const params = useParams();
  const {
    data: productDetail,
    isLoading: isProductDetailLoading,
    isError: isErrorProductDetail,
    error: productDetailError,
  } = useGetProductByIdQuery({ id: params.id });

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isErrorDocuments,
  } = useGetDocumentsQuery();

  const {
    data: institutionsData,
    isLoading: isInstitutionsLoading,
    isError: isErrorInstitutions,
  } = useGetInstitutionsQuery();

  const [
    createProcedureRequest,
    {
      isLoading: isCreating,
      isError: isErrorCreating,
      isSuccess: isSuccessCreating,
      error: errorCreating,
    },
  ] = useCreateProcedureRequestMutation();

  //   useEffect(() => {
  //     if (params.id) {
  //     }
  //   }, [params.id]);
  const [procedureData, setProcedureData] = useState(procedureD);

  useEffect(() => {
    if (productDetail?.procedureId) {
      console.log(productDetail);
      if (productDetail?.procedureId)
        window.location.href = "/dashboard/products";
    } else {
      setProcedureData({
        ...procedureData,
        productName: productDetail?.productName ?? "x",
      });
    }
  }, [productDetail]);

  const [stepIndex, setStepIndex] = useState(0);
  const [mainIndex, setMainIndex] = useState(0);
  const pdfContainerRef = useRef();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepDetailEdit, setStepDetailEdit] = useState(false);
  const [stepDetail, setStepDetail] = useState("");

  const handleStepDetailEdit = (text) => {
    setStepDetail(text);

    setStepDetailEdit(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isEditing, setIsEditing] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const handleSaveSummaryClick = () => {
    setIsEditingSummary(false);
  };

  const handleEditSummaryClick = () => {
    setIsEditingSummary(true);
  };

  const [editedDetail, setEditedDetail] = useState(
    procedureData.mainSteps[mainIndex].subSteps[stepIndex].detail
  );
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedDetail(
      procedureData?.mainSteps[mainIndex].subSteps[stepIndex].detail
    );
  };
  const [editedSummary, setEditedSummary] = useState(procedureData.summary);
  const handleCancelSummaryClick = () => {
    setIsEditingSummary(false);
    setEditedSummary(procedureData?.summary);
  };

  const [isEditSubStep, setIsEditSubStep] = useState(false);
  const [editSubStepDesc, setEditSubStepDesc] = useState("");
  const [editSubStepDetail, setEditSubStepDetail] = useState("");

  const [addMainStepOpen, setAddMainStepOpen] = useState(false);
  const [editMainStepOpen, setEditMainStepOpen] = useState(false);
  const [editMainStep, setEditMainStep] = useState(null);

  const [AddInstitutionsOpen, setAddInstitutionsOpen] = useState(false);
  const [AddDocumentsOpen, setAddDocumentsOpen] = useState(false);

  const [editSubStepModes, setEditSubStepModes] = useState(
    procedureData.mainSteps.map((mainStep) =>
      mainStep.subSteps.map(() => false)
    )
  );

  const handleEditStep = (mainStepIndex, subStepIndex, description, detail) => {
    setEditSubStepDesc(description);
    setEditSubStepDetail(detail);

    const updatedModes = editSubStepModes.map((mainStepModes, mIndex) =>
      mainStepModes.map((mode, sIndex) =>
        mIndex === mainStepIndex && sIndex === subStepIndex ? true : mode
      )
    );
    setEditSubStepModes(updatedModes);
  };
  const handleSaveStep = (mainStepIndex, subStepIndex) => {
    handleCancelEditStep(mainStepIndex, subStepIndex);
  };

  const handleCancelEditStep = (mainStepIndex, subStepIndex) => {
    const updatedModes = editSubStepModes.map((mainStepModes, mIndex) =>
      mainStepModes.map((mode, sIndex) =>
        mIndex === mainStepIndex && sIndex === subStepIndex ? false : mode
      )
    );
    setEditSubStepModes(updatedModes);
  };

  const isAnySubStepEditing = () => {
    for (let i = 0; i < editSubStepModes.length; i++) {
      for (let j = 0; j < editSubStepModes[i].length; j++) {
        if (editSubStepModes[i][j]) {
          return true;
        }
      }
    }
    return false;
  };
  const handleSaveRequiredDocumentsClick = () => {
    // Implement your save logic here, if needed
    // After saving, you can set isEditingRequiredDocuments back to false
    setIsEditingRequiredDocuments(false);
  };
  const handleRemoveStep = (mainStepIndex, subStepIndex) => {};

  const handleCancelEdit = () => {};

  const PdfDocument = () => (
    <Document>
      <Page size="A4">
        <View style={styles2.container}>
          <Text style={styles2.title}>
            {procedureData?.title + " " + procedureData?.productName}
          </Text>
          <View style={styles2.summaryContainer}>
            <Text style={styles2.summaryTitle}>Summary</Text>
            <Text style={styles2.summaryText}>{procedureData?.summary}</Text>
          </View>
          <View style={styles2.stepsContainer}>
            {procedureData?.mainSteps.map((step, index) => (
              <View key={index} style={styles2.stepContainer}>
                <Text style={styles2.stepDescription}>{step.description}</Text>
                {step.subSteps.map((subStep, index) => (
                  <View key={index} style={styles2.subStepContainer}>
                    <Text style={styles2.subStepDescription}>
                      {subStep.description}
                    </Text>
                    <Text style={styles2.subStepDetail}>{subStep.detail}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
      <Page size="A4">
        <View style={styles2.container}>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Institutions Involved</Text>
            <View>
              {procedureData.institutionsInvolved.map((institution, index) => (
                <View key={index} style={styles2.institutionName}>
                  <Text>{institution.name}</Text>
                </View>
              ))}
              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Required Documents</Text>
            <View>
              {procedureData.requiredDocuments.map((document, index) => (
                <View key={index} style={styles2.institutionName}>
                  <Text>{document.name}</Text>
                </View>
              ))}
              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
        </View>
        <Text style={styles2.footer}>
          <InfoCircleOutlined style={styles2.footerIcon} />
          Addis Ababa Chamber of Commerce and Sectoral Associations/AACCSA/ 2023
        </Text>
        <Text style={styles2.waterMark}>AACCSA</Text>
      </Page>
    </Document>
  );

  const [deleteInstitutions, setDeleteInstitutions] = useState([]);
  const [deleteDocuments, setDeleteDocuments] = useState([]);

  const toggleDeleteDocument = (documentId) => {
    if (deleteDocuments.includes(documentId)) {
      setDeleteDocuments(deleteDocuments.filter((id) => id !== documentId));
    } else {
      setDeleteDocuments([...deleteDocuments, documentId]);
    }
  };

  const handleSaveDeletedDocuments = () => {
    setDeleteDocuments([]);
  };

  const handleRemoveDocument = (documentIndex) => {
    const updatedDocuments = procedureData.requiredDocuments.map(
      (document, index) =>
        index === documentIndex
          ? { ...document, isDeleted: true }
          : { ...document }
    );
    setProcedureData({ ...procedureData, requiredDocuments: updatedDocuments });
    setIsEditingRequiredDocuments(true);
  };
  const handleCancelRemoveDocument = (index) => {
    const updatedDocuments = [...procedureData.requiredDocuments];

    updatedDocuments[index].isDeleted = false;

    setProcedureData((prevState) => ({
      ...prevState,
      requiredDocuments: updatedDocuments,
    }));
  };
  const [isEditingRequiredDocuments, setIsEditingRequiredDocuments] =
    useState(false);
  const [isEditingInstitutions, setIsEditingInstitutions] = useState(false);
  const [deletedInstitutions, setDeletedInstitutions] = useState([]);

  const handleRemoveInstitution = (index) => {
    // Add the institution ID to the deletedInstitutions array
    const institutionId = procedureData.institutionsInvolved[index].id;
    setDeletedInstitutions([...deletedInstitutions, institutionId]);

    // Update the institution's state to indicate it's deleted
    const updatedInstitutions = [...procedureData.institutionsInvolved];
    updatedInstitutions[index].isDeleted = true;
    setProcedureData({
      ...procedureData,
      institutionsInvolved: updatedInstitutions,
    });
    setIsEditingInstitutions(true);
  };

  const handleCancelRemoveInstitution = (index) => {
    // Remove the institution ID from the deletedInstitutions array
    const institutionId = procedureData.institutionsInvolved[index].id;
    const updatedDeletedInstitutions = deletedInstitutions.filter(
      (id) => id !== institutionId
    );
    setDeletedInstitutions(updatedDeletedInstitutions);

    // Update the institution's state to indicate it's not deleted
    const updatedInstitutions = [...procedureData.institutionsInvolved];
    updatedInstitutions[index].isDeleted = false;
    setProcedureData({
      ...procedureData,
      institutionsInvolved: updatedInstitutions,
    });
  };

  const handleSaveInstitutionsClick = () => {
    setIsEditingInstitutions(false);
  };

  const handleEditInstitutionsClick = () => {
    // Enable editing mode for institutions
    setIsEditingInstitutions(true);
  };
  const AddDocuments = ({ open, onClose }) => {
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const handleSelectChange = (event) => {
      setSelectedDocuments(event.target.value);
    };

    const handleSave = async (e) => {
      e.preventDefault();
      setProcedureData((prevState) => ({
        ...prevState,
        requiredDocuments: selectedDocuments.map((id) => ({ id })),
      }));
      await createProcedureRequest(procedureData).then((res) => {
        console.log(res);
      });
    };

    const handleClose = () => {
      setSelectedDocuments([]);
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Documents</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Select Documents:
          </Typography>
          <Select
            multiple
            value={selectedDocuments}
            onChange={handleSelectChange}
            fullWidth
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              getContentAnchorEl: null,
              style: { marginTop: "1%", maxHeight: 350 },
            }}>
            {documentsData?.map((document) => (
              <MenuItem key={document?.id} value={document?.id}>
                {document?.title} - {document?.documentName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            style={{ marginRight: 16 }}
            color="success">
            Save
          </Button>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.page}>
      <div style={{ padding: "40px" }}>
        <Typography
          variant="h5"
          style={{ marginTop: "10px", paddingLeft: "20px" }}
          className={classes.mainTitle}>
          {procedureData?.title + " " + procedureData?.productName}{" "}
        </Typography>
      </div>

      <div className={classes.pageContainer}>
        <div className={classes.leftColumn}>
          <Stack direction="column" spacing={2}>
            <Card className={classes.card}>
              <div className={classes.header}>
                <Typography className={classes.title}>Steps</Typography>
                <div
                  style={{
                    position: "relative",
                    // width: "70%",
                  }}>
                  {" "}
                  <IconButton
                    className={classes.addButton}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}
                    onClick={() => setAddMainStepOpen(true)}>
                    <AddCircleOutlineIcon fontSize="medium" />
                  </IconButton>
                </div>
              </div>
              <CardContent className={classes.mainStepsContainer} style={{}}>
                {procedureData.mainSteps.map((mainStep, mainStepIndex) => (
                  <div key={mainStep.id} variant="outlined">
                    <CardContent>
                      <Typography variant="h6">
                        {mainStep.description}
                        <IconButton
                          onClick={() => {
                            setEditMainStepOpen(true);
                            setEditMainStep(mainStep);
                          }}
                          className={classes.addButton}
                          color="primary"
                          style={{
                            backgroundColor: "white",
                            // position: "absolute",
                            // right: 0,
                            boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                          }}>
                          <Edit fontSize="medium" />
                        </IconButton>
                      </Typography>
                      <Divider />
                      {mainStep.subSteps.map((subStep, subStepIndex) => (
                        <div
                          key={subStepIndex}
                          variant="outlined"
                          style={{ marginTop: "15px" }}>
                          {/* <CardContent> */}
                          {editSubStepModes[mainStepIndex][subStepIndex] ? (
                            <TextField
                              multiline
                              fullWidth
                              value={editSubStepDesc}
                              onChange={(e) =>
                                setEditSubStepDesc(e.target.value)
                              }
                            />
                          ) : (
                            <Typography style={{ listStyleType: "circle" }}>
                              <FiberManualRecord
                                fontSize="small"
                                style={{ color: `#${mainStepIndex * 3}06738` }}
                              />
                              {"    "}
                              {subStep.description}
                            </Typography>
                          )}
                          {editSubStepModes[mainStepIndex][subStepIndex] ? (
                            <TextField
                              multiline
                              fullWidth
                              value={editSubStepDetail}
                              onChange={(e) =>
                                setEditSubStepDetail(e.target.value)
                              }
                            />
                          ) : (
                            <Typography>{subStep.detail}</Typography>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "-10px",
                            }}>
                            {editSubStepModes[mainStepIndex][subStepIndex] ? (
                              <div style={{ width: "fit-content" }}>
                                <Button
                                  onClick={() =>
                                    handleSaveStep(mainStepIndex, subStepIndex)
                                  }
                                  color="success">
                                  Save
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleCancelEditStep(
                                      mainStepIndex,
                                      subStepIndex
                                    )
                                  }
                                  color="secondary">
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <>
                                {!isAnySubStepEditing() && (
                                  <>
                                    <IconButton
                                      onClick={() =>
                                        handleEditStep(
                                          mainStepIndex,
                                          subStepIndex,
                                          subStep.description,
                                          subStep.detail
                                        )
                                      }>
                                      <Edit />
                                    </IconButton>
                                    {/* <IconButton
                                      onClick={() =>
                                        handleRemoveStep(
                                          mainStepIndex,
                                          subStepIndex
                                        )
                                      }>
                                      <Delete />
                                    </IconButton> */}
                                    <DeleteDialog
                                      api={useDeleteProcedureRequestMutation}
                                      dialogLabel="Delete FAQ Item"
                                      id={(mainStepIndex, subStepIndex)}
                                      name={subStep.description}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                      <Button
                        onClick={() => {
                          setEditMainStepOpen(true);
                          setEditMainStep(mainStep);
                        }}
                        style={{ color: colorTags.SECONDARY }}
                        startIcon={<AddCircleOutline />}>
                        Add Sub Step
                      </Button>
                    </CardContent>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Stack>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.iconContainer}>
            <div>
              <IconButton>
                <Delete color="error" />
              </IconButton>

              <PDFDownloadLink
                document={<PdfDocument />}
                fileName="ProcedureData.pdf"
                style={{ textDecoration: "none" }}>
                <IconButton style={{ color: "#006738" }}>
                  <DownloadIcon />
                </IconButton>
              </PDFDownloadLink>
            </div>
          </div>
          <Card className={classes.card}>
            <div className={classes.header}>
              <Typography className={classes.title}>Summary</Typography>
              <div
                style={{
                  position: "relative",
                  // width: "60%",
                }}>
                {" "}
                {isEditingSummary ? (
                  <div style={{ width: "fit-content" }}>
                    <Button
                      onClick={handleSaveSummaryClick}
                      color="success"
                      style={{
                        backgroundColor: "white",
                        color: colorTags.PRIMARY,
                        position: "absolute",
                        right: "100px",
                      }}>
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelSummaryClick}
                      color="secondary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: "0",
                      }}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <IconButton
                    onClick={handleEditSummaryClick}
                    className={classes.addButton}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}>
                    <Edit fontSize="medium" />
                  </IconButton>
                )}
              </div>
            </div>
            <CardContent>
              {/* <Typography variant="body1">{procedureData.summary}</Typography> */}
              {isEditingSummary ? (
                <TextField
                  multiline
                  fullWidth
                  value={editedSummary}
                  onChange={(e) => setEditedSummary(e.target.value)}
                />
              ) : (
                <Typography variant="body1">{editedSummary}</Typography>
              )}
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <div className={classes.header}>
              <Typography className={classes.title}>
                Step Detail
                <span style={{ fontSize: "13px" }}>
                  {
                    procedureData.mainSteps[mainIndex].subSteps[stepIndex]
                      .description
                  }
                </span>
              </Typography>
              <div style={{ position: "relative" }}>
                {/* {isEditing ? (
                  <div style={{ width: "fit-content" }}>
                    <Button
                      onClick={handleSaveClick}
                      color="success"
                      style={{
                        backgroundColor: "white",
                        color: colorTags.PRIMARY,

                        position: "absolute",
                        right: "100px",
                      }}>
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelClick}
                      color="secondary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: "0",
                      }}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <IconButton
                    onClick={handleEditClick}
                    className={classes.addButton}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}>
                    <Edit fontSize="medium" />
                  </IconButton>
                )} */}
              </div>
            </div>
            <CardContent>
              {/* {isEditing ? (
                <TextField
                  multiline
                  fullWidth
                  value={editedDetail}
                  onChange={(e) => setEditedDetail(e.target.value)}
                />
              ) : ( */}
              <Typography variant="body1">{editedDetail}</Typography>
              {/* )} */}
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <div className={classes.header}>
              <Typography className={classes.title}>
                Institutions Involved
              </Typography>
              <div style={{ position: "relative" }}>
                {isEditingInstitutions ? (
                  <Button
                    onClick={handleSaveInstitutionsClick}
                    variant="outlined"
                    style={{ color: "#fff" }}>
                    Save
                  </Button>
                ) : (
                  <IconButton
                    className={classes.addButton}
                    onClick={() => setAddInstitutionsOpen(true)}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}>
                    <AddCircleOutlineIcon fontSize="medium" />
                  </IconButton>
                )}
              </div>
            </div>
            <CardContent>
              <Grid container spacing={3}>
                {procedureData.institutionsInvolved.map(
                  (institution, index) => (
                    <Grid item xs={3} mb={2} key={index}>
                      <div className={classes.imageContainer}>
                        <img
                          src={institution.image}
                          alt={institution.name}
                          className={classes.image}
                          title={institution.name}
                        />

                        {institution.isDeleted ? (
                          <IconButton
                            style={{
                              backgroundColor: "white",
                              position: "absolute",
                              right: 0,
                              boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                            }}
                            onClick={() =>
                              handleCancelRemoveInstitution(index)
                            }>
                            <CancelIcon
                              style={{
                                position: "absolute",
                                bottom: "18px",
                                right: "5px",
                                backgroundColor: "white",
                                boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                color: "red",
                                borderRadius: "50%",
                                zIndex: 1000,
                              }}
                              color="error"
                              fontSize="small"
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              backgroundColor: "white",
                              position: "absolute",
                              right: 0,
                              boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                            }}
                            onClick={() => handleRemoveInstitution(index)}>
                            <Delete
                              style={{
                                position: "absolute",
                                bottom: "18px",
                                right: "5px",
                                backgroundColor: "white",
                                boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                color: "red",
                                borderRadius: "50%",
                                zIndex: 1000,
                              }}
                              color="error"
                              fontSize="small"
                            />
                          </IconButton>
                        )}
                      </div>
                      <Typography variant="body1" textAlign="center">
                        {institution.name}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <div className={classes.header}>
              <Typography className={classes.title}>
                Required Documents
              </Typography>
              <div
                style={{
                  position: "relative",
                }}>
                {isEditingRequiredDocuments ? (
                  <Button
                    onClick={handleSaveRequiredDocumentsClick}
                    variant="outlined"
                    style={{ color: "#fff" }}>
                    Save
                  </Button>
                ) : (
                  <IconButton
                    className={classes.addButton}
                    onClick={() => setAddDocumentsOpen(true)}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}>
                    <AddCircleOutlineIcon fontSize="medium" />
                  </IconButton>
                )}
              </div>
            </div>
            <CardContent>
              <Grid container spacing={2}>
                {procedureData.requiredDocuments.map((document, index) => (
                  <Grid item xs={3} mb={2} key={index}>
                    <div className={classes.imageContainer}>
                      <img
                        src={document.image}
                        alt={document.name}
                        className={classes.image}
                        title={document.name}
                      />

                      {document.isDeleted ? (
                        <IconButton
                          style={{
                            backgroundColor: "white",
                            position: "absolute",
                            right: 0,
                            boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                          }}
                          onClick={() => handleCancelRemoveDocument(index)}>
                          <CancelIcon
                            style={{
                              position: "absolute",
                              bottom: "18px",
                              right: "5px",
                              backgroundColor: "white",
                              boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                              color: "red",
                              borderRadius: "50%",
                              zIndex: 1000,
                            }}
                            color="error"
                            fontSize="small"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{
                            backgroundColor: "white",
                            position: "absolute",
                            right: 0,
                            boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                          }}
                          onClick={() => handleRemoveDocument(index)}>
                          <Delete
                            style={{
                              position: "absolute",
                              bottom: "18px",
                              right: "5px",
                              backgroundColor: "white",
                              boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                              color: "red",
                              borderRadius: "50%",
                              zIndex: 1000,
                            }}
                            color="error"
                            fontSize="small"
                          />
                        </IconButton>
                      )}
                    </div>
                    <Typography variant="body1" textAlign="center">
                      {document.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>

          <AddMainStep
            open={addMainStepOpen}
            onClose={() => setAddMainStepOpen(false)}
          />

          <EditMainStep
            open={editMainStepOpen}
            onClose={() => {
              setEditMainStepOpen(false);
              setEditMainStep(null);
            }}
            data={editMainStep}
          />

          <AddInstitutions
            open={AddInstitutionsOpen}
            onClose={() => setAddInstitutionsOpen(false)}
          />

          <AddDocuments
            open={AddDocumentsOpen}
            onClose={() => setAddDocumentsOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};
export default AddProcedure;
