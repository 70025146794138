import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography, Box } from "@mui/material";
import { colorTags } from "../../../../constants/variants";
import LawsRegulationsPage from "./LawsRegulations/LawsRegulations";
import FormsTemplatesPage from "./Forms/Forms";
import OtherDocumentsPage from "./Other/Others";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.7)`,
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "16px",
    marginRight: "10px",
    marginLeft: "10px",
    // backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.7)`,
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFC72C",
    },
    selected: {
      color: "#FFC72C !important",
    },
  },
  selectedTab: {
    backgroundColor: "#FFFFFF !important",
    color: `${colorTags.PRIMARY} !important`,

    borderRadius: theme.spacing(1),
  },
  tab: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "16px",
    borderRadius: "8px",
    marginRight: "10px",
    marginLeft: "10px",
    // backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.7)`,
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFC72C",
    },
  },
  indicator: {
    backgroundColor: "#FFC72C",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TabMenuPage() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          borderRadius: "8px",

          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          marginBottom: "5%",
        }}> */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="menu tabs"
        className={classes.tabs}
        TabIndicatorProps={{
          style: { backgroundColor: colorTags.SECONDARY },
        }}
        classes={{ indicator: classes.indicator }}>
        <Tab
          sx={{
            color: "#FFFFFF",
            "&:hover": {
              color: "#FFC72C",
            },
            selected: {
              color: "#FFC72C",
            },
          }}
          color=""
          label="Laws and Regulations"
          className={value === 0 ? classes.selectedTab : ""}
        />
        <Tab
          sx={{
            color: "#FFFFFF",
            "&:hover": {
              color: "#FFC72C",
            },
            selected: {
              color: "#FFC72C",
            },
          }}
          label="Forms and Clearences"
          // className={classes.tab}
          className={value === 1 ? classes.selectedTab : ""}
        />
        <Tab
          sx={{
            color: "#FFFFFF",
            "&:hover": {
              color: "#FFC72C",
            },
            selected: {
              color: "#FFC72C",
            },
          }}
          label="Other"
          // className={classes.tab}
          className={value === 2 ? classes.selectedTab : ""}
        />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <LawsRegulationsPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormsTemplatesPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OtherDocumentsPage />
      </TabPanel>
    </div>
  );
}
