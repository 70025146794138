import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "-10%",
    width: "100%",
    borderRadius: "10px",
    background: "rgba(255,255,255,1)",
    height: "70%",
    maxHeight: "315px",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  imageWrapper: {
    maxWidth: "33.33%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageWrapperGIZ: {
    maxWidth: "25.33%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardMedia2: {
    backgroundSize: "contain",
    height: "auto",
    maxHeight: "200px",
    width: "auto",
    maxWidth: "100%",
  },
}));

export default function Partners() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <img alt="Partner 1" src={images.GIZ} className={classes.cardMedia2} />
      </div>
      <div className={classes.imageWrapperGIZ}>
        <h3 style={{}}>Implemented by</h3>
        <img alt="Partner 2" src={images.GIZ2} className={classes.cardMedia2} />
      </div>
      <div className={classes.imageWrapper}>
        <img
          alt="Partner 3"
          src={images.MOI_LOGO}
          style={{
            maxHeight: "150px",
          }}
          className={classes.cardMedia2}
        />
      </div>
      <div className={classes.imageWrapper}>
        <img
          alt="Partner 3"
          src={images.Sequa}
          className={classes.cardMedia2}
        />
      </div>
    </div>
  );
}
