import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../components";
import { primaryColor, colorTags } from "../../../constants/variants";
import GridData from "../../../components/GridData";
import AddSponsors from "./AddSponsors";
import DeleteDialog from "../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import SponsorDecision from "./SponsorDecision";
import {
  useGetPromotionsQuery,
  useGetPromotionByIdQuery,
  useCreatePromotionRequestMutation,
  useUpdatePromotionRequestMutation,
  useDeletePromotionRequestMutation,
} from "../../../features/api/apiSlice";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const requestData = [
  {
    id: 1,
    companyName: "ExportCo",
    requestDate: "2023-06-01",
    startDate: "2023-06-10",
    endDate: "2023-06-15",
    level: "Platinum",
  },
  {
    id: 2,
    companyName: "GlobalExports",
    requestDate: "2023-06-02",
    startDate: "2023-06-12",
    endDate: "2023-06-18",
    level: "Gold",
  },
];

export default function PromotionPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [modalOpen2, setModalOpen2] = useState(false);
  const handleModalClose2 = () => setModalOpen2(false);
  const handleModalOpen2 = () => setModalOpen2(true);
  const [requestId, setRequestId] = useState(0);

  const {
    data: promotionData,
    isLoading: promotionDataLoading,
    isError: isErrorPromotionData,
    error: errorPromotionData,
  } = useGetPromotionsQuery();

  useEffect(() => {
    if (promotionData) console.log(promotionData);
  }, [promotionData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
    },
    {
      field: "requestDate",
      headerName: "Request Date",
      type: "date",
      renderCell: (params) => {
        return (
          <div>
            {params.row.endDate === null ? (
              <p>Not specified</p>
            ) : (
              <p>
                {new Date(params.row.requestDate).toLocaleDateString("en-US")}
              </p>
            )}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      renderCell: (params) => {
        return (
          <div>
            {params.row.endDate === null ? (
              <p>Not specified</p>
            ) : (
              <p>
                {new Date(params.row.startDate).toLocaleDateString("en-US")}
              </p>
            )}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      renderCell: (params) => {
        return (
          <div>
            {params.row.endDate === null ? (
              <p>Not specified</p>
            ) : (
              <p>{new Date(params.row.endDate).toLocaleDateString("en-US")}</p>
            )}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let cellStyle = {
          fontWeight: "bold",
          textTransform: "uppercase",
        };

        switch (params.value) {
          case "Approved":
            cellStyle.color = "green"; // Set the color for Approved status
            break;
          case "Requested":
            cellStyle.color = "blue"; // Set the color for Requested status
            break;
          case "Declined":
            cellStyle.color = "red"; // Set the color for Declined status
            break;
          default:
            cellStyle.color = "black"; // Default color for other statuses
        }

        return <span style={cellStyle}>{params.value}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                console.log(params);
                setRequestId(params.row);
                setModalOpen2(true);
              }}>
              <Info color="primary" />
            </IconButton>

            <DeleteDialog
              api={useDeletePromotionRequestMutation}
              dialogLabel="Delete Promotion Request from"
              id={params?.row?.id}
              name={params?.row?.companyName}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      {/* <Link style={{ textDecoration: "none" }} href="/dashboard/add-news"> */}{" "}
      <div>
        {" "}
        {modalOpen ? (
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: colorTags.SECONDARY,
            }}
            onClick={handleModalClose}>
            Close
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: primaryColor,
            }}
            onClick={handleModalOpen}>
            Add Promotion
          </Button>
        )}
      </div>
      {/* </Link> */}
      {modalOpen ? (
        <AddSponsors
          // title="Add Sponsors"
          // content="This is the modal content."
          open={modalOpen}
          onClose={handleModalClose}
          actionText="Close"
        />
      ) : null}
      <SponsorDecision
        title="Promotion Request"
        // content={"This is the modal content."}
        content={requestId}
        open={modalOpen2}
        onClose={handleModalClose2}
        actionText="Close"
      />
      <SimpleCard
        title="Promotion Requests"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={promotionData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
    </div>
  );
}
