import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import { Link as MuiLink, Link } from "@mui/material";

import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Typography } from "@material-ui/core";

import { Stack, IconButton, Fade, Card, CardContent } from "@mui/material";
import { ThinChevronLeft, ThinChevronRight } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useGetPublicationsQuery } from "../../../features/api/apiSlice";
import { FILE_DOWNLOAD_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  // Existing styles...
  recentPublicationsContainer: {
    // background: "#E8E8E8",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    textAlign: "center",
    marginTop: theme.spacing(5),
    position: "relative",
  },
  title: {
    fontSize: "45px",
    color: colorTags?.PRIMARY,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(900)]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "30px",
    },
  },
  publicationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    background: "none",
    [theme.breakpoints.down(900)]: {
      gap: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  publicationCard: {
    width: "14%",
    minWidth: "100px",
    height: "300px",
    borderRadius: "10px",
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    background: "none",
    border: "none",
    outline: "none",
    [theme.breakpoints.down(900)]: {
      minWidth: "230px",
      // height: "00px",
      // height: "240px",
    },
  },
  publicationImage: {
    width: "100%",
    height: "85%",
    objectFit: "cover",
    borderRadius: "10px",
    // boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 20px -7px",
    [theme.breakpoints.down(900)]: {
      minWidth: "200px",
      height: "60%",
    },
  },
  publicationTitle: {
    background: "none",
    border: "none",
    outline: "none",
    shadow: "none",
    // color: `${colorTags.PRIMARY}`,
    color: "#000",
    padding: theme.spacing(1),
    fontSize: "16px",
    fontWeight: "regular",
    textAlign: "center",

    marginTop: "35px",
  },
  iconButton: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",
    bottom: "55px",
    borderRadius: "50%",
    left: "20%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.9)",
    },
    [theme.breakpoints.down(900)]: {
      bottom: "85px",

      // height: "00px",
    },
    [theme.breakpoints.down(500)]: {
      bottom: "115px",

      // height: "00px",
    },
  },
  iconButton2: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",

    bottom: "55px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.9)",
    },
    right: "10%",
    [theme.breakpoints.down(900)]: {
      bottom: "85px",
      right: "0%",

      // height: "00px",
    },
    [theme.breakpoints.down(500)]: {
      bottom: "115px",

      // height: "00px",
    },
    // transform: "translateX(-50%)",
  },
  seeAllButton: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    textDecoration: "none", // Remove underline from Link
  },
}));

const RecentPublications = () => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // publicationsData
  const {
    data: publicationsData,
    isLoading,
    isError,
  } = useGetPublicationsQuery();

  // Intersection Observer callback function
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const publicationData = publicationsData?.slice(0, isSmallScreen ? 3 : 5);
  const handleOpenInNewTab = (url) => {
    window.open(BASE_FILE_URL + url, "_blank");
  };

  const handleDownloadPublication = (url) => {
    window.open(BASE_FILE_URL + url, "_blank");
  };
  const reversedPublicationsData = [...(publicationsData || [])].reverse();

  return (
    <div className={classes.recentPublicationsContainer}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "8%",
        }}> */}
      {/* <Typography className={classes.title} variant="h4" component="h2"> */}
      {/* <span style={{ color: colorTags.SECONDARY }}> Top Products </span>
        {"  "}
        <span style={{ color: colorTags.ACCENT }}>And </span> */}
      {/* {"  "} <span style={{ color: colorTags.PRIMARY }}>Publications</span>
      </Typography> */}
      {/* </div> */}
      <div>
        <Typography className={classes.title} variant="h4" component="h2">
          <span style={{ color: colorTags.PRIMARY }}>Publications</span>
        </Typography>
      </div>
      <div className={classes.publicationContainer}>
        {reversedPublicationsData
          ?.splice(0, isSmallScreen ? 3 : 5)
          ?.map((publication, index) => (
            <Card
              key={index}
              sx={{
                width: "14%",
                minWidth: "100px",
                height: "300px",
                borderRadius: "10px",
                margin: "1%",
                marginTop: "1%",
                padding: "1%",
                overflow: "hidden",
                position: "relative",
                cursor: "pointer",
                background: "transparent",
                border: "none",
                outline: "none",
              }}
              elevation={0}>
              <img
                src={
                  publication?.coverImage
                    ? BASE_FILE_URL + publication?.coverImage
                    : images?.PDF_PLACEHOLDER
                }
                alt={publication.title}
                className={classes.publicationImage}
              />
              <Typography
                variant="div"
                style={{ marginTop: "35px" }}
                className={classes.publicationTitle}>
                {publication?.title}
              </Typography>
              <div className={classes.iconButton}>
                <IconButton
                  onClick={() => handleOpenInNewTab(publication?.documentName)}
                  color="primary">
                  <OpenInNewIcon />
                </IconButton>
              </div>
              <div className={classes.iconButton2}>
                <a
                  href={FILE_DOWNLOAD_URL + publication.documentName}
                  download
                  // onClick={(e) =>
                  //   handleDownloadClick(e, item.documentName)
                  // }
                >
                  {" "}
                  <IconButton
                    // component={MuiLink}
                    // href=
                    // target="_blank"
                    // onClick={() =>
                    //   handleDownloadPublication(publication?.documentName)
                    // }
                    color="primary">
                    <GetAppIcon />
                  </IconButton>
                </a>
              </div>
            </Card>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "5%",
        }}>
        <Typography
          component={RouterLink}
          to="/posts/publications"
          style={{ color: colorTags?.PRIMARY, fontWeight: "bold" }}
          className={classes.seeAllLink}>
          See All
        </Typography>
      </div>
    </div>
  );
};

export default RecentPublications;
