import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { images } from "../../../../assets/index";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import {
  BASE_FILE_URL,
  FILE_DOWNLOAD_URL,
} from "../../../../constants/apiTags";
import { useGetDocumentsQuery } from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 0.3s",
  },
  listItemHovered: {
    backgroundColor: "#F0F0F0",
  },
  buttons: {
    display: "none",
  },
  buttonsVisible: {
    display: "block",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const FormsAndDocuments = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const { data, error, isLoading } = useGetDocumentsQuery();
  const [formsAndDocsData, setFormsData] = useState([]);
  useEffect(() => {
    if (data) {
      console.log(data);
      setFormsData(data.filter((item) => item.category !== "Law"));
    }
  }, [data]);

  const handleDownloadClick = (e, documentName) => {
    e.preventDefault(); // Prevent the default link behavior

    // Attempt to download the file
    fetch(FILE_DOWNLOAD_URL + documentName)
      .then((response) => {
        if (response.ok) {
          // If the response is successful (HTTP status 200), initiate the download
          toast.success("Download started!", {
            position: "top-right",
            autoClose: 2000, // Display the toast for 2 seconds
          });
          e.target.click(); // Trigger the download link
        } else {
          // If there is an error in the response, display an error toast
          toast.error("Error downloading file!", {
            position: "top-right",
            autoClose: 2000, // Display the toast for 2 seconds
          });
        }
      })
      .catch((error) => {
        // Handle other errors like network issues
        console.error("Error:", error);
        toast.error("Error downloading file!", {
          position: "top-right",
          autoClose: 2000, // Display the toast for 2 seconds
        });
      });
  };

  const lawsPerPage = 5;
  const totalLaws = formsAndDocsData.length;
  const totalPages = Math.ceil(totalLaws / lawsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClickPagination = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const showPaginationNumbers = () => {
    let paginationNumbers = [];

    if (currentPage <= 2) {
      for (let i = 1; i <= 3; i++) {
        paginationNumbers.push(i);
      }
    } else if (currentPage >= totalPages - 1) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        paginationNumbers.push(i);
      }
    }

    return paginationNumbers;
  };
  const handleListItemMouseEnter = (index) => {
    setOpen(index);
  };

  const handleListItemMouseLeave = () => {
    setOpen(null);
  };

  return (
    <div>
      <List className={classes.list}>
        {formsAndDocsData
          .slice((currentPage - 1) * lawsPerPage, currentPage * lawsPerPage)
          .map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem
                button
                onMouseEnter={() => handleListItemMouseEnter(index)}
                onMouseLeave={handleListItemMouseLeave}
                className={`${classes.listItem} ${
                  open === index ? classes.listItemHovered : ""
                }`}>
                <ListItemText primary={item.title} />
                <div
                  className={`${classes.buttons} ${
                    open === index ? classes.buttonsVisible : ""
                  }`}>
                  <Tooltip title="Open in new tab" placement="top">
                    <IconButton
                      style={{ color: "grey" }}
                      variant="text"
                      onClick={() => {
                        window.open(BASE_FILE_URL + item.documentName);
                      }}>
                      <OpenInNewOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <a
                      href={FILE_DOWNLOAD_URL + item.documentName}
                      download
                      // onClick={(e) =>
                      //   handleDownloadClick(e, item.documentName)
                      // }
                    >
                      <IconButton
                        style={{ color: "grey", marginLeft: "15px" }}
                        variant="text">
                        <GetAppOutlinedIcon />
                      </IconButton>
                    </a>
                  </Tooltip>
                </div>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
      <Box className={classes.pagination}>
        <ChevronLeftIcon
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={() => handleClickPagination(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {showPaginationNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClickPagination(pageNumber)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === pageNumber ? "#F0F0F0" : "white",
            }}>
            {pageNumber}
          </button>
        ))}
        <ChevronRightIcon
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => handleClickPagination(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Box>
    </div>
  );
};

export default FormsAndDocuments;
