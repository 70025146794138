import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { Stack, TextField } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { images } from "../../../assets/index";
import { colorTags } from "../../../constants/variants";
import Fade from "react-reveal/Fade";
import { LoadingButton } from "@mui/lab";
import { useContactUsMutation } from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    position: "relative",
    overflow: "hidden",
  },
  backgroundOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5))`, // Adjust gradient as needed
  },
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(12),
    position: "relative",
    zIndex: 1,
  },
  section: {
    marginBottom: theme.spacing(9),
    textAlign: "center",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: colorTags.PRIMARY,
  },
  sectionImage: {
    width: "100%",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
  },
  sectionDescription: {
    marginTop: theme.spacing(2),
  },
  contactInfo: {
    marginTop: theme.spacing(-22),
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(2),
    justifyContent: "flex-end",
    backgroundColor: colorTags.SECONDARY,
    color: "#fff",
    onHover: {
      backgroundColor: colorTags.SECONDARY,
    },
  },
  submitButton2: {
    marginTop: theme.spacing(2),
    justifyContent: "flex-end",
    backgroundColor: colorTags.SECONDARY,
    color: "#fff",
  },
  contactCard: {
    background: "rgba(255, 255, 255, 0.9)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down(600)]: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },
}));

const ContactUsPage = () => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const [contactUs, { isLoading: isLoadingContact, isError: isErrorContact }] =
    useContactUsMutation();
  const handleSubmit = async () => {
    const data = {
      firstName,
      lastName,
      email: emailAddress,
      message,
    };
    await contactUs(data)
      .then((res) => {
        if (res.data) {
          toast.success("Message sent successfully, Thank you!");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong, please try again later!");
      });
  };
  return (
    <div className={classes.mainRoot}>
      <div className={classes.backgroundOverlay}></div>
      <Header />
      {isSmallScreen ? (
        <Card className={classes.contactCard}>
          <CardContent>
            <Typography
              variant="h5"
              style={{ marginBottom: "15px", color: colorTags.SECONDARY }}>
              Get in touch
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    color="success"
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    color="success"
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    color="success"
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    color="success"
                    multiline
                    rows={4}
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  style={{
                    marginTop: "15px",
                    justifyContent: "flex-end",
                    backgroundColor: colorTags.SECONDARY,
                    color: "#fff",
                  }}
                  loading={isLoadingContact}
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  className={classes.submitButton}>
                  Submit
                </LoadingButton>
              </div>
            </form>
          </CardContent>
        </Card>
      ) : (
        <div className={classes.root}>
          <Fade bottom>
            <section className={classes.section}>
              <Typography
                variant="h3"
                color="primary"
                align="center"
                style={{ color: colorTags.PRIMARY, fontWeight: 450 }}>
                Reach Out To Us!
              </Typography>
              <Typography
                variant="body1"
                className={classes.sectionDescription}>
                Representing a wide spectrum of businesses based in Addis Abeba
                which constitute more than 60% of all businesses operating in
                the country. Being a member of the World Chambers Federation,
                AACCSA also has a prominent place in the international network
                of chambers.
              </Typography>
            </section>
          </Fade>
          <Stack
            direction="row"
            spacing={15}
            justifyContent="center"
            alignItems="center">
            <div className={classes.contactInfo}>
              <Typography variant="h5">Contact Information</Typography>
              <Typography variant="body1" style={{ marginTop: "20px" }}>
                Email: info@addischamber.com
              </Typography>
              <Typography variant="body1" style={{ marginTop: "7px" }}>
                Phone: 011 551 8055
              </Typography>
              <Typography variant="body1" style={{ marginTop: "7px" }}>
                Address: 2P5V+MQP, Addis Ababa, Ethiopia
              </Typography>
            </div>
            <Container maxWidth="sm">
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      variant="outlined"
                      color="success"
                      fullWidth
                      className={classes.textField}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      color="success"
                      fullWidth
                      className={classes.textField}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      color="success"
                      fullWidth
                      className={classes.textField}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      color="success"
                      multiline
                      rows={4}
                      fullWidth
                      className={classes.textField}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    style={{
                      marginTop: "15px",
                      justifyContent: "flex-end",
                      backgroundColor: colorTags.SECONDARY,
                      color: "#fff",
                    }}
                    loading={isLoadingContact}
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                    className={classes.submitButton2}>
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </Container>
          </Stack>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ContactUsPage;
