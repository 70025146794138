import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import ProductsPage from "./Products/Products";
import ProceduresPage from "./Procedures/Products";
import LawsAndNormsPage from "./LawsAndNorms/Products";
import InstitutionsPage from "./Institutions/Products";
import FormsAndDocsPage from "./FormsAndDocs/Products";
import Header from "../Header/Header";
import Sponsors from "../Sponsors/Sponsors";
import { colorTags } from "../../../constants/variants";
import SearchIcon from "@material-ui/icons/Search";
import SearchBox from "../../../components/SearchBox";
import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F1F1F1",
    padding: theme.spacing(12),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: theme.spacing(5),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    // margin: theme.spacing(2, 0),
    marginTop: theme.spacing(5),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF !important",
    color: `${colorTags.PRIMARY} !important`,

    borderRadius: theme.spacing(1),
  },

  // selectedTab: {
  //   color: `${colorTags.PRIMARY} !important`,
  // },
  tabsContainer: {
    overflowX: "auto",
    marginBottom: "20px",
    borderRadius: "10px",
    backgroundColor: "#D9D9D9",
  },
  searchBar: {
    width: "100%",
    position: "relative",
    // top: 80,
    borderRadius: "50px",
    backgroundColor: "none",
    padding: "20%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.down(600)]: {
    padding: "10%",
  },
}));

const RepositoryPage = () => {
  const classes = useStyles();
  const { id, searchBy, query } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(id);
    // if (!id) {
    //   navigate("/repository");
    // } else {
    if (id)
      if (id * 1 === 0) setSelectedTab(0);
      else if (id * 1 === 1) setSelectedTab(1);
      else if (id * 1 === 2) setSelectedTab(2);
      else if (id * 1 === 3) setSelectedTab(3);
      else if (id * 1 === 4) setSelectedTab(4);
      else setSelectedTab(0);
  }, [id]);

  useEffect(() => {
    if (!query) navigate("/repository");
  }, []);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSeparateSearch = () => {
    window.open("https://www.trademap.org/Index.aspx", "_blank");
  };

  const handleSeparateSearchHs = () => {
    window.open(
      `https://www.trademap.org/stCorrespondingProductCodes.aspx?nvpm=1%7C%7C%7C%7C%7C090111%7C%7C%7C%7C%7C%7C1%7C%7C%7C%7C%7C%7C`,
      "_blank"
    );
  };

  return (
    <div style={{ backgroundColor: "#F1F1F1" }}>
      <Header />
      <div style={{ marginTop: "5px" }}>
        <Sponsors />
      </div>

      <div className={classes.root}>
        {" "}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor={colorTags?.PRIMARY}
          TabIndicatorProps={{
            style: { backgroundColor: colorTags.PRIMARY },
          }}
          centered={window.innerWidth >= 600}
          variant={window.innerWidth < 600 ? "scrollable" : "fullWidth"}>
          <Tab
            label="Products"
            className={selectedTab === 0 ? classes.selectedTab : ""}
          />
          <Tab
            label="Procedures"
            className={selectedTab === 1 ? classes.selectedTab : ""}
          />
          <Tab
            label="Institutions"
            className={selectedTab === 2 ? classes.selectedTab : ""}
          />
          <Tab
            label="Laws and Norms"
            className={selectedTab === 3 ? classes.selectedTab : ""}
          />
          <Tab
            label="Forms and Documents"
            className={selectedTab === 4 ? classes.selectedTab : ""}
          />
        </Tabs>
        {/* <div className={classes.searchBar}>
          <SearchBox />
        </div> */}
        <div className={classes.content}>
          {selectedTab === 0 && (
            <ProductsPage searchBy={searchBy ?? ""} query={query ?? ""} />
          )}
          {selectedTab === 1 && (
            <ProceduresPage searchBy={searchBy ?? ""} query={query ?? ""} />
          )}

          {selectedTab === 2 && (
            <InstitutionsPage searchBy={searchBy ?? ""} query={query ?? ""} />
          )}
          {selectedTab === 3 && (
            <LawsAndNormsPage searchBy={searchBy ?? ""} query={query ?? ""} />
          )}
          {selectedTab === 4 && (
            <FormsAndDocsPage searchBy={searchBy ?? ""} query={query ?? ""} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}>
          <Typography
            variant="h5"
            style={{ textAlign: "left", marginBottom: "20px" }}>
            Repository search results for "{query}"
          </Typography>
          {/* Add the big, bold text */}
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Want to broaden your search?
          </Typography>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            Click the buttons below to search on separate websites
          </Typography>
          <Stack direction="row" spacing={2} mt={2} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: "10px",
                backgroundColor: "#006837",
                color: "#FFFFFF",
              }}
              onClick={handleSeparateSearch}
              style={{ marginTop: "10px" }}>
              Open Separate Search
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSeparateSearchHs}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#FFFFFF",
                color: "#006837",
              }}
              style={{ marginTop: "10px" }}>
              Open Separate Search For HS Code
            </Button>
          </Stack>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RepositoryPage;
