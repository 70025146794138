import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";

import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import {
  useGetTopProductsQuery,
  useGetTopDestinationsQuery,
  useCreateTopProductRequestMutation,
  useCreateTopDestinationRequestMutation,
  useUpdateTopProductRequestMutation,
  useUpdateTopDestinationRequestMutation,
  useDeleteTopProductRequestMutation,
  useDeleteTopDestinationRequestMutation,
  useGetDestinationsQuery,
} from "../../../../features/api/apiSlice";

import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    width: "50%",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AddTopDestinations = ({ open, onClose, title, onSave }) => {
  const classes = useStyles();
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const {
    data: getTopDestinations,
    isError: isGetTopDestinationsError,
    isLoading: isLoadingTopDestinationsError,
  } = useGetTopDestinationsQuery();
  const {
    data: getDestinations,
    isError: isGetDestinationsError,
    isLoading: isLoadingDestinationsError,
  } = useGetDestinationsQuery();
  const [
    createTopDestinationRequest,
    { data: createData, error: createError, isLoading: createLoading },
  ] = useCreateTopDestinationRequestMutation();

  const [
    updateTopDestinationRequest,
    { data: updateData, error: updateError, isLoading: updateLoading },
  ] = useUpdateTopDestinationRequestMutation();

  const [latestTopDestinations, setLatestTopDestinations] = useState([]);
  const {
    data: getTopDestinationsData,
    isLoading,
    isError,
  } = useGetTopDestinationsQuery();

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  // useEffect(() => {
  //   if (getTopDestinationsData) {
  //     console.log(getTopDestinationsData);
  //     // Sort the data by a property that represents its order or date, assuming there's such a property
  //     const sortedData = [...getTopDestinationsData].sort((a, b) => {
  //       // You might need to adjust this depending on the data structure and the property to sort by
  //       return a.timestamp - b.timestamp;
  //     });

  //     // Take the last 10 items (latest) and extract their IDs
  //     const latestIds = getTopDestinationsData
  //       .slice(0, 10)
  //       .map((destination) => destination.destinationId);

  //     setLatestTopDestinations(latestIds);
  //     setSelectedDestinations(latestIds);
  //     console.log(selectedDestinations);
  //   }
  // }, [getTopDestinationsData]);

  const handleSelectChange = (e) => {
    const selectedValues = e.target.value;

    if (selectedValues.length <= 10) {
      // Allow selection if the number of selected values is 10 or less
      setSelectedDestinations(selectedValues);
    }
    // You can optionally display a message to the user if they exceed the limit.
  };
  const handleSave = async () => {
    // Assuming createTopDestinationRequest creates a new destination
    console.log(selectedDestinations);

    if (selectedDestinations.length === 0) {
      // Handle the case where no destinations are selected
      toast.success("NO DESTINATIONS SELECTED");

      onClose();
      return;
    }

    // Create an array to store promises for individual API requests
    const saveRequests = selectedDestinations.map(async (destination) => {
      // Create an individual destination request
      const response = await createTopDestinationRequest({
        destinationId: destination,
      });
      return response;
    });

    // Wait for all the requests to complete
    const responses = await Promise.all(saveRequests);

    if (responses.some((response) => response.error)) {
      // Handle the case where some requests failed
      toast.error("Some requests failed");
      setSelectedDestinations([]);

      onClose();
      return;
    } else {
      toast.success("Data saved successfully");
      setSelectedDestinations([]);
    }

    // Check the responses if needed

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Typography
            variant="h5"
            className={classes.modalTitle}
            style={{ fontWeight: "medium" }}>
            {title}
          </Typography>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel
                id="selectedDestinations"
                style={{ color: "#006738" }}>
                Select Up to 10 Destinations
              </InputLabel>
              <Select
                label="Select Up to 10 Destinations"
                variant="outlined"
                multiple
                color="success"
                value={selectedDestinations} // Set value to the state
                onChange={handleSelectChange}
                inputProps={{
                  name: "selectedDestinations",
                  id: "selectedDestinations",
                }}>
                {getDestinations?.map((destination, index) => (
                  <MenuItem key={index} value={destination.id}>
                    {destination.destinationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </Button>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddTopDestinations;
