import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Stack } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Chip } from "@mui/material";
import { colorTags } from "../../../constants/variants";
import { useGetFAQsQuery } from "../../../features/api/apiSlice";
import LoadingScreen from "../../../components/LoadingMain";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  mainContent: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: 0,
  },
  faqContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  faqCard: {
    width: "60%",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  suggestionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 10%",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2%",
    },
  },
  // suggestionContainer: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   margin: theme.spacing(2),
  //   maxWidth: "100%",
  //   overflowX: "scroll",
  //   // padding: "0 10px 0 10px",
  //   "&::-webkit-scrollbar": {
  //     width: "1px",
  //     height: "4px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     background: "#888",
  //     borderRadius: "4px",
  //   },
  //   "&::-webkit-scrollbar-thumb:hover": {
  //     background: "#555",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     maxWidth: "100%",
  //     padding: "0 50px",
  //     margin: theme.spacing(0),
  //   },
  // },
  chipContainer: {
    display: "flex",
    maxWidth: "60%",
    overflowX: "auto",
    padding: "10px",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "0 10px",
      margin: theme.spacing(0),
    },
  },
  chip: {
    margin: "0 10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5px",
    },
  },
  iconButton: {
    width: 50,
    height: 50,
    padding: 0,
    cursor: "pointer",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    alignSelf: "left",
  },
}));

const FAQPage = () => {
  const classes = useStyles();
  const chipContainerRef = useRef(null);
  const handleNextSuggestion = () => {
    const container = chipContainerRef.current;
    container.scrollLeft += 200; // Scroll to the right
    setSuggestionIndex((prevIndex) => (prevIndex + 3) % suggestionData?.length);
  };

  const handlePrevSuggestion = () => {
    const container = chipContainerRef.current;
    container.scrollLeft -= 200; // Scroll to the left
    setSuggestionIndex((prevIndex) =>
      prevIndex === 0 ? suggestionData?.length - 3 : prevIndex - 1
    );
  };

  const { data: dataFaq, isError, isLoading } = useGetFAQsQuery();
  const subjectsSet = new Set();
  useEffect(() => {
    if (dataFaq) {
      const suggestions = generateSuggestions(dataFaq);
      setSuggestionIndex(0);
      setSelectedSuggestion("All");
      setCurrentPage(1);
    }
  }, [dataFaq]);

  const generateSuggestions = (faqData) => {
    const suggestions = [
      "All",
      ...[...new Set(faqData?.map((faq) => faq.subject))],
    ];

    return suggestions;
  };

  // Dummy FAQ data
  const faqData = [
    {
      subject: "Coffee",
      question: "What is Lorem Ipsum?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      subject: "Coffee",
      question: "What is Lorem Ipsum?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];
  const faqDataw = [
    {
      subject: "Top Exports",
      question: "What are the top export items?",
      answer:
        "The top export items include electronics, machinery, textiles, and more.",
    },
    {
      subject: "Top Exports",
      question: "Which countries are the biggest exporters?",
      answer:
        "The biggest exporters are China, the United States, and Germany.",
    },
    {
      subject: "Coffee",
      question: "How is coffee harvested?",
      answer:
        "Coffee is typically harvested by hand-picking the ripe coffee cherries.",
    },
    {
      subject: "Coffee",
      question: "What are the different coffee bean varieties?",
      answer: "Common coffee bean varieties include Arabica and Robusta.",
    },
    {
      subject: "Leather",
      question: "How is leather tanned?",
      answer:
        "Leather is tanned using various methods, including vegetable tanning and chrome tanning.",
    },
    {
      subject: "Leather",
      question: "What are the uses of leather?",
      answer:
        "Leather is used for making shoes, bags, clothing, and furniture.",
    },
    {
      subject: "Tea",
      question: "What are the health benefits of tea?",
      answer:
        "Tea is believed to have health benefits, such as antioxidants and potential cancer-fighting properties.",
    },
    {
      subject: "Tea",
      question: "Which countries are major tea producers?",
      answer: "Major tea producers include China, India, and Kenya.",
    },
    // Add more subjects and FAQ entries as needed
  ];

  const suggestions = generateSuggestions(dataFaq);
  // Dummy suggestion data
  const suggestionData = [
    "Top Exports",
    "Coffee",
    "Leather",
    "Tea",
    "Spices",
    "Textiles",
    "Electronics",
    "Machinery",
    "Automobiles",
    "Pharmaceuticals",
    "Chemicals",
    "Metals",
    "Plastics",
    "Furniture",
    "Food Products",
    "Jewelry",
    "Cosmetics",
    "Agricultural Products",
    "Toys",
    "Sports Equipment",
  ];

  const [suggestionIndex, setSuggestionIndex] = useState(0);

  suggestionData.unshift("All");
  const [selectedSuggestion, setSelectedSuggestion] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 10;

  const filteredFAQData =
    selectedSuggestion === "All"
      ? dataFaq
      : dataFaq.filter((faq) => faq.subject === selectedSuggestion);

  const totalPages = Math.ceil(filteredFAQData?.length / cardsPerPage);
  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredFAQData?.slice(
    indexOfFirstCard,
    indexOfLastCard
  );
  const isDataAvailable = dataFaq && dataFaq?.length > 0;

  if (isLoading) return <LoadingScreen />;

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.mainContent}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h5" component="h2">
            Frequently <span style={{ color: colorTags.SECONDARY }}>Asked</span>{" "}
            Questions
          </Typography>
        </div>

        {/* Suggestion Component */}
        <div className={classes.suggestionContainer}>
          <IconButton
            className={classes.iconButton}
            onClick={handlePrevSuggestion}>
            <NavigateBeforeIcon />
          </IconButton>
          <div className={classes.chipContainer} ref={chipContainerRef}>
            {suggestions?.map((suggestion, index) => (
              <Chip
                key={index}
                label={suggestion}
                className={classes.chip}
                style={{
                  margin: "0 5px",
                  backgroundColor: `${
                    suggestion === selectedSuggestion ? "#2196F3" : "#f2f2f2"
                  }`,
                  color: `${
                    suggestion === selectedSuggestion ? "#fff" : "#000"
                  }`,
                }}
                onClick={() => handleSuggestionClick(suggestion)}
              />
            ))}
          </div>
          <IconButton
            className={classes.iconButton}
            onClick={handleNextSuggestion}>
            <NavigateNextIcon />
          </IconButton>
        </div>
        {/* FAQ Cards */}
        {isDataAvailable ? (
          <div className={classes.faqContainer}>
            {currentCards?.map((faq, index) => (
              <Card key={index} className={classes.faqCard}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {faq.question}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {faq.answer}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <div className={classes.faqContainer}>
            <Card className={classes.faqCard}>
              <CardContent>
                <Typography variant="body1">No data available.</Typography>
              </CardContent>
            </Card>
          </div>
        )}

        {/* Pagination */}
        <Stack direction="row" spacing={2} justifyContent="center">
          <IconButton
            className={classes.iconButton}
            onClick={handlePrevPage}
            disabled={currentPage === 1}>
            <ChevronLeft />
          </IconButton>
          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>
          <IconButton
            className={classes.iconButton}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}>
            <ChevronRight />
          </IconButton>
        </Stack>
      </div>

      <Footer />
    </div>
  );
};

export default FAQPage;
