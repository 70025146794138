import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Link } from "@material-ui/core";
import { Facebook, YouTube, Telegram, Twitter } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "transparent",
    backgroundImage: `url(${images?.Logistics3})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    backdropFilter: "blur(10px)",

    padding: theme.spacing(3),
    color: "#fff",
  },
  overlay: {
    content: '""',
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",

    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    background: "rgba(0, 0, 0, 0.5)",
  },
  logo: {
    width: "50px",
    borderRadius: "50%",
  },
  company: {
    color: "#E7B035",
    paddingTop: theme.spacing(1),
  },
  aboutUs: {
    marginTop: theme.spacing(3),
  },
  aboutUsText: {
    width: "100%",
    margin: "10px auto",
  },
  contentLinks: {
    marginTop: theme.spacing(3),
  },
  contentLinksList: {
    listStyle: "none",
    padding: 0,
    "& li": {
      marginBottom: theme.spacing(1),
    },
  },
  socialIcons: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  socialIcon: {
    backgroundColor: "#0D8523",
    color: "#fff",
    padding: "4px",
    borderRadius: "4px",
    border: "1px solid #E1A411",
    marginRight: theme.spacing(1),
  },
  miniFooter: {
    backgroundColor: "#616161",
    padding: theme.spacing(1),
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.overlay}></div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Stack direction="row" spacing={2} alignItems="center">
              <img className={classes.logo} src={images?.ac7} alt="logo" />
              <Typography variant="h6" className={classes.company}>
                ADDIS CHAMBER
              </Typography>
            </Stack>
            <Typography variant="body2" className={classes.aboutUsText}>
              Unlocking Ethiopia's potential, one export at a time. Proudly
              connecting the world with our rich agricultural heritage and
              quality products.
            </Typography>
            <div className={classes.socialIcons}>
              <Facebook className={classes.socialIcon} />
              <YouTube className={classes.socialIcon} />
              <Telegram className={classes.socialIcon} />
              <Twitter className={classes.socialIcon} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Content</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/repository/0" color="inherit">
                  Products
                </Link>
              </li>
              <li>
                <Link href="/repository/1" color="inherit">
                  Procedures
                </Link>
              </li>
              <li>
                <Link href="/repository/2" color="inherit">
                  Institutions
                </Link>
              </li>
              <li>
                <Link href="/repository/3" color="inherit">
                  Laws and Regulations
                </Link>
              </li>
              <li>
                <Link href="/repository/4" color="inherit">
                  Forms and Documents
                </Link>
              </li>
              <li>
                <Link href="/services" color="inherit">
                  Services
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">
              <Link href="/resources" color="inherit">
                Links
              </Link>
            </Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link
                  href="https://nbe.gov.et/commercial-banks-exchange-rate/"
                  target="_blank"
                  color="inherit">
                  Exchange Rate
                </Link>
              </li>
              <li>
                <Link href="https://ehpea.org/" target="_blank" color="inherit">
                  EHPEA
                </Link>
              </li>
              <li>
                <Link href="http://ecc.gov.et/" target="_blank" color="inherit">
                  Customs Commissions
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            //  className={classes.aboutUs}
          >
            <Typography variant="h6">About Us</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="https://addischamber.com/" color="inherit">
                  Addis Chamber
                </Link>
              </li>
              <li>
                <Link href="/about-us" color="inherit">
                  Export Portal
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </footer>
      <div className={classes.miniFooter}>
        <div>
          <Typography variant="body2">
            Addis Ababa Chamber of Commerce and Sectoral Associations
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            <Link href="http://www.arcict.net" target="_blank" color="inherit">
              © AR C ICT Technologies
            </Link>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Footer;
