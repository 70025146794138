import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button, Link } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index";
import { colorTags } from "../../constants/variants";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import {
  useForgotPasswordMutation,
  useVerifyCodeMutation,
} from "../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
    backgroundImage: `linear-gradient(to top, #ffffff 50%, transparent 80%), url(${images.BlurBg1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // paddingTop: "50vh",
  },
  card: {
    maxWidth: 300,
    padding: theme.spacing(0),
    marginTop: theme.spacing(),
    textAlign: "center",
    borderRadius: theme.spacing(2),
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",

    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(0),
      textAlign: "center",
      borderRadius: theme.spacing(2),
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.SECONDARY,
    "&:hover": {
      backgroundColor: colorTags.PRIMARY,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    color: colorTags.PRIMARY,
  },
  otherText: {
    color: colorTags.PRIMARY,
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [forgotPassword, { isError, isLoading: isForgotLoading }] =
    useForgotPasswordMutation();

  const [verifyCode, { isError: isVerifyError, isLoading: isVerifyLoading }] =
    useVerifyCodeMutation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: email,
      // userType: toggle ? "user" : "member",
    };

    forgotPassword(data)
      .then((res) => {
        if (res) {
          toast.success("A reset email has been sent to your email address.");
          setEmailSubmitted(true);
          // navigate("/login");
        } else {
          toast.error("Something went wrong. Please try again later.");
        }
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again later.");
      });
  };
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleCodeSubmit = (event) => {
    event.preventDefault();

    // Password Policy Specifications
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{6,})/;

    if (!newPassword || newPassword.trim().length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    if (!/\d/.test(newPassword)) {
      toast.error("Password must include at least one digit.");
      return;
    }

    if (!/[a-z]/.test(newPassword)) {
      toast.error("Password must include at least one lowercase letter.");
      return;
    }

    if (!/[A-Z]/.test(newPassword)) {
      toast.error("Password must include at least one uppercase letter.");
      return;
    }

    if (!/[!@#$%^&*]/.test(newPassword)) {
      toast.error("Password must include at least one special character.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }
    if (newPassword === confirmPassword) {
      const data = {
        email: email,
        resetTokon: verificationCode,
        newPassword,
        // userType: toggle ? "user" : "member",
      };
      verifyCode(data)
        .then((res) => {
          if (res) {
            toast.success("Your password was changed successfully!");
            setEmailSubmitted(false);
            setEmail("");
            setVerificationCode("");
            navigate("/login");
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again later.");
        });
    } else {
      toast.error("Passwords do not match. Please try again.");
    }
  };

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/logine");
  //   }
  // }, [token, navigate]);
  const [toggle, setToggle] = useState(false);
  const handleSendAgain = () => {
    // Reset the form and allow the user to send the email again
    setEmailSubmitted(false);
    // setEmail("");
    setVerificationCode("");
  };

  return (
    <>
      <Header />
      <div className={classes.root}>
        <Card className={classes.card}>
          {/* <FormControlLabel
            control={
              <Switch
                checked={toggle}
                color="success"
                onChange={() => setToggle(!toggle)}
                name="staff-toggle"
              />
            }
            // label="Staff?"
            labelPlacement="start"
            style={{ position: "absolute", top: 50, right: 20 }}
          /> */}
          <CardContent>
            <Typography variant="h5" className={classes.title}>
              {emailSubmitted ? "Reset Password" : "Forgot Password"}
            </Typography>
            <form onSubmit={!emailSubmitted ? handleSubmit : handleCodeSubmit}>
              {!emailSubmitted && (
                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  variant="outlined"
                  color="success"
                  onChange={handleEmailChange}
                  fullWidth
                  className={classes.inputField}
                  sx={{
                    marginBottom: "20px",
                    marginTop: "10px",
                    width: "100%",
                  }}
                />
              )}
              {emailSubmitted && (
                <>
                  {" "}
                  <TextField
                    type="text"
                    label="Verification Code"
                    value={verificationCode}
                    variant="outlined"
                    color="success"
                    onChange={handleVerificationCodeChange}
                    fullWidth
                    className={classes.inputField}
                    sx={{
                      marginBottom: "0px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  />
                  <TextField
                    type="password"
                    label="New Password"
                    value={newPassword}
                    variant="outlined"
                    color="success"
                    onChange={handleNewPasswordChange}
                    fullWidth
                    className={classes.inputField}
                    sx={{
                      marginBottom: "10px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  />
                  <TextField
                    type="password"
                    label="Confirm Password"
                    value={confirmPassword}
                    variant="outlined"
                    color="success"
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    className={classes.inputField}
                    sx={{
                      marginBottom: "10px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  />
                  <div style={{ textAlign: "left", marginBottom: "10px" }}>
                    <Typography variant="body2" color="textSecondary">
                      - At least 6 characters long
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Must include at least one digit
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Must include at least one lowercase letter
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Must include at least one uppercase letter
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      - Must include at least one special character
                    </Typography>
                  </div>
                </>
              )}
              <LoadingButton
                loading={emailSubmitted ? isVerifyLoading : isForgotLoading}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}>
                {emailSubmitted ? "Change Password" : "Send Reset Code"}
              </LoadingButton>
              {/* {emailSubmitted && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleSubmit}
                  fullWidth
                  className={classes.button}>
                  Send Again
                </Button>
              )} */}
            </form>
            <Typography
              style={{ marginTop: "15px" }}
              variant="body2"
              color="textSecondary">
              {!emailSubmitted
                ? "Remember your password? "
                : "Didn't receive a code? "}
              {!emailSubmitted ? (
                <Link
                  // href={!emailSubmitted ? "/login" : "#"}
                  onClick={() => navigate("/login")}
                  className={classes.otherText}>
                  {"Sign In"}
                </Link>
              ) : (
                <Link
                  className={classes.otherText}
                  style={{ cursor: "pointer" }}
                  onClick={handleSendAgain}>
                  {"Send again"}
                </Link>
              )}
            </Typography>
            {emailSubmitted ? (
              <Typography mt={3} variant="body2" color="textSecondary">
                Remember your password?{" "}
                <Link href={"/login"} className={classes.otherText}>
                  {"Sign In"}
                </Link>
              </Typography>
            ) : null}
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
