import ac from "./ac2.jpg";
import ac7 from "./ac.jpg";

import cf from "./cf.jpg";
import ch from "./ch.jpg"
import documentsIcon from "./documentsIcon.png"
import eu from "./eu.jpg"
import fl from "./fl.jpg"
import h1 from "./h1.jpg"
import h2 from "./h2.png"
import institutionsIcon from "./institutionsIcon.jpg"
import lt from "./lt.jpg"
import mn from "./mn.jpg"
import proceduresIcon from "./proceduresIcon2.webp"
import productsIcon from "./productsIcon.webp"
import sa from "./sa.jpg"
import tradeIcon from "./tradeIcon.png"
import transportationIcon from "./transportationIcon.jpg"
import tx from "./tx.jpg"
import uae from "./uae.jpg"
import us from "./us.jpg"

import Africameet from "./Africameet.png"
import PanAfrican from "./PanAfrican.jpg"
import Coffeeview from "./coffeeview.jpg"


import LinkIcon from "./icons8-links-64.png"
import TruckIcon from "./icons8-loading-truck-50.png"
import DocumentIcon from "./icons8-documents-50.png"
import InstitutionIcon from "./icons8-institution-80 (1).png"
import ProductIcon from "./icons8-container-91.png"
import ProcedureIcon from "./icons8-workflow-64.png"

import MainImage from "./aaccsa.png"
import MainImage2 from "./aaccsa2.jpg"
import MainImage3 from "./aaccsa3.jpg"

import MainImage4 from "./aaccsa4.jpg"
import MainBG from "./bglight.jpg"
import MainBG2 from "./bggreen2=3.jpg"


import IcontSet from "./agriculture.gif"
import AcMeet from "./acmeet.jpg"
import MainBG4 from "./bglight2.jpg"
import MainBG3 from "./bglight3.jpg"


import ChatA from "./chat.jpg"
import Logistics from "./logistics.jpg";
import Logistics2 from "./logistics2.jpg";
import Logistics3 from "./logistics3.avif";
import Logistics4 from "./logistics4.webp";
import Logistics5 from "./logistics5.webp";
import Logistics6 from "./logistics6.jpg";
import Logistics7 from "./logistics7.jpg";





import Plane from "./plane.jpg";
import Ship from "./ship.jpg";
import Truck from "./truck.jpg";
// import Anim from "./anim.mp4";
import MainBG7 from "./mainBG7.jpg";
import PlaneTrs from "./planetrs.png";
import GlobeTrs from "./globetrs.png";
import DocCover from "./doc.svg"
import Law from "./Law.jpeg"
import Corp from "./corp.jpg"
import CorpLogo from "./corpLogo.png"
import Radio2 from "./radio2.png"
import Blog2 from "./blog7.png"
import Amplifier from "./amp3.png"
import BlurBg1 from "./blurbg1.avif"
import BlurBg2 from "./blurbg2.jpeg"
import BlurBg3 from "./blurbg3.jpeg"
import BlurBg4 from "./blurbg4.jpg"
import BlurBg5 from "./blurbg5.jpg"
import BlurBg6 from "./blurbg6.jpg"
import BlurBg7 from "./blurbg7.avif"
import BlurBg8 from "./blurbg8.avif"
import BlurBg9 from "./blurbg9.avif"
import BlurBg10 from "./blurbg10.jpg"
import BlurBg11 from "./blurbg11.jpeg"




import WaterBg1 from "./waterbg1.webp"


import Mission from "./mission.png"
import Vision from "./vision2.png"
import Values from "./values2.png"
import Truckts from "./truckts3.png"
import Rocket from "./rocket1.png"
import Services from "./Services.webp"

import Asia from "./asia.webp"
import Asia2 from "./asia2.jpg"


import Eu2 from "./eu2.png"
import Content from "./content.png"
import Publication from "./publication.jpeg"
import NodePattern from "./nodepattern.avif"

import ChatIcon from "./chatIcon.png"
import Avatar from "./avatar.jpeg"

import WIE1 from "./wie1.jpg"
import FemaleAvatar2 from "./FemaleAvatar2.png"
import FemaleAvatar from "./FemaleAvatar.png"
import WIE2 from "./wie2.jpg"

// partners
import GIZ from "./giz.bmp"
import GIZ2 from "./giz2.png"
import AcLogo from "./acLogo.png"
import Sequa from "./sequa.png"


// news
import NewsImage from "./newsImage.jpg"


// about-us
import Chamber from "./chamber.jpg"
import Chamber1 from "./chamber1.png"


// promotion cover
import Promote from './promotion.jpeg'


// Company Avatar
import CorpAvatar from './corp-avatar.png'

// Ministry of Industry logo
import MOI_LOGO from './Minstry-Of-Industry-LOGO.png'



// pdf place holder
import PDF_PLACEHOLDER from './pdf-placeholder.webp'

// news place holder
import NEWS_PLACEHOLDER from './news-placeholder.jpg'
// Add more image imports as needed

// Export the imported images as an object
export const images = {
  NEWS_PLACEHOLDER,
  PDF_PLACEHOLDER,
  Africameet,
  Coffeeview,
  PanAfrican,
  ac ,
  ac7 ,

   cf ,
  ch ,
   documentsIcon,
     eu,
     fl ,
     h1 ,
     h2 ,
     institutionsIcon ,
     lt ,
     mn ,
     proceduresIcon ,
     productsIcon ,
     sa ,
     tradeIcon ,
     transportationIcon ,
     tx ,
     uae ,
     us ,
    
      LinkIcon ,
      TruckIcon ,
      DocumentIcon,
      InstitutionIcon, 
      ProductIcon,
      ProcedureIcon,
      MainImage ,
     MainImage3 ,
     MainImage2,
     MainImage4 ,
     MainBG,
      MainBG2,
      IcontSet,
      AcMeet,
      MainBG3,
      MainBG4,
ChatA,
Logistics,
Plane,
Ship,
Truck,
Logistics2,
Logistics3,
// Anim,
MainBG7,
PlaneTrs,
GlobeTrs,
DocCover,
Law,
Corp,
CorpLogo,
Radio2,
Blog2,
Amplifier,
BlurBg1,
BlurBg2,
BlurBg3,
BlurBg4,
Mission,
Vision,
Values,
Truckts,
BlurBg5,
BlurBg6,
BlurBg7,
BlurBg8,
BlurBg9,
WaterBg1,
BlurBg10,
BlurBg11,
Rocket,
Services,
Content,
NodePattern,
Eu2,
Asia,
Asia2,
Publication,
ChatIcon,
Avatar,
WIE1,
FemaleAvatar,
FemaleAvatar2,
WIE2,

// partners
GIZ,
GIZ2,
AcLogo,
Sequa,
NewsImage,
Logistics4,
Logistics5,
Logistics6,
Logistics7
,
Chamber,
Chamber1,
Promote,
CorpAvatar,
MOI_LOGO

};