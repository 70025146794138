import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import TopBar from "./Header/TopBar";
import Cards from "./Cards/Cards";
import ServicesCards from "./Services/ServicesCard";
import RecentEvents from "./Posts/RecentEvents";
import SponsorsCard from "./Sponsors/Sponsors";
import TopTen from "./TopTen/TopTen";
import Footer from "./Footer/Footer";
import ChatComponent from "./Chat/Chat";
import ChatTwo from "./Chat/ChatTwo";
import Publications from "./Posts/Publications";
import OrganizedEvents from "./Posts/Events";
import FAQ from "./FAQ/FAQ";
import { images } from "../../assets/index";
import LoadingScreen from "../../components/LoadingMain";
import BackToTop from "../../components/BackToTop";
import TitleTop from "./Header/Title";
import Partners from "./Partners/Partners";
import {
  useGetCustomImagesQuery,
  usePostAnalyticsMutation,
} from "../../features/api/apiSlice"; // Import the analytics API hook
import { Helmet } from "react-helmet";
import axios from "axios";
import { BASE_URL } from "../../constants/apiTags";
const HomePage = () => {
  const [loading, setLoading] = useState(true);

  const { data: customData, error, isLoading } = useGetCustomImagesQuery();
  const [analyticsApi] = usePostAnalyticsMutation(); // Initialize the analytics API hook
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [visit, setVisit] = useState(false);

  const [postAnalyticsData, { isLoading: isAnalyticsLoading }] =
    usePostAnalyticsMutation();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!visit) {
      const data = {
        action: "visit",
        value: "1",
        content: "HomePage",
      };
      postAnalyticsData(data).then((res) => setVisit(true));
    }
  }, [visit]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Export Portal || Addis Chamber</title>
        <meta
          name="description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the 'Addis Chamber export portal for trade facilitation' for business and trade opportunities."
        />
        <meta
          name="keywords"
          content="Ethiopian export, Addis Chamber, trade opportunities, export news, business services, Ethiopia"
        />
        <meta
          name="author"
          content="Addis Chamber export portal for trade facilitation"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#0077B5" />
        <meta
          property="og:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          property="og:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <meta
          property="og:image"
          content="https://example.com/your-image.jpg"
        />{" "}
        {/* Add a relevant image URL */}
        <meta property="og:url" content="https://your-website-url.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />{" "}
        {/* Add your Twitter handle */}
        <meta
          name="twitter:title"
          content="Ethiopian Export and Addis Chamber - Your App Title"
        />
        <meta
          name="twitter:description"
          content="Stay updated on Ethiopian export news, services, and resources. Explore the Addis Chamber for business and trade opportunities."
        />
        <link rel="canonical" href="https://your-website-url.com" />
      </Helmet>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${images?.MainBG})`,
          paddingBottom: "3%",
          position: "fixed",
          width: "100%",
          zIndex: "-1",
          height: "750px",
          marginTop: "50px",
        }}></div>
      <div>
        <TitleTop text="Addis Chamber Export Portal For Trade Facilitation" />
        <TopBar />
      </div>
      <Header />

      <div>
        <Cards />
      </div>
      <ServicesCards />
      <div style={{ marginTop: "-30px" }}></div>
      <TopTen />
      <RecentEvents />
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${images?.MainBG2})`,
          paddingBottom: "3%",
        }}>
        <Publications />
        <OrganizedEvents />
        <FAQ />
        <SponsorsCard />
        <Partners />
      </div>

      {/* <ChatComponent /> */}

      <BackToTop />
      {(user && user?.role_name === "Member") ||
      user?.role_name === "member" ? (
        <ChatTwo />
      ) : null}
      <Footer />
    </div>
  );
};

export default HomePage;
