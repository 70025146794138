import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colorTags } from "../constants/variants";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "15px 30px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #068FFF 0%, #75C2F6  51%, #068FFF  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const MyComponent = ({ text }) => {
  return (
    <div>
      {/* ... other components */}
      <StyledButton>{text}</StyledButton>
      {/* ... other components */}
    </div>
  );
};

export default MyComponent;
