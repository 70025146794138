import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,

  isFirstTimeLoading: false,
  isFirstTimeSuccess: false,
  isFirstTimeError: false,

  isEditLoading: false,
  isEditSuccess: false,
  isEditError: false,

  isGetUserLoading: false,
  isGetUserSuccess: false,
  isGetUserError: false,

  isCheckTokenLoading: false,
  isCheckTokenSuccess: false,
  isCheckTokenError: false,

  errorMsg: "",
  isTokenExpired: false,
  user: localStorage.getItem("user") || null,
  // user: JSON.parse(localStorage.getItem("user")) || null,
};

export const firstTimeUserLogin = createAsyncThunk(
  "auth/firstTimeUserLogin",
  async (userData, { rejectWithValue }) => {
    try {
      return await authService.firstTimeUserLogin(userData);
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);





export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (userData, { rejectWithValue }) => {
    try {
      const data = await authService.userLogin(userData);
      if (data?.userAccount?.status === "Active") {
        return data;
      } else {
        return rejectWithValue(
          "Your account is INACTIVE, please contact your admin."
        );
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const editAccount = createAsyncThunk(
  "auth/editAccount",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user?.token;
      return await authService.editAccount(userData, token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  }
);

export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user?.token;
      return await authService.getUserById(id, token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  }
);

export const checkToken = createAsyncThunk(
  "auth/checkToken",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user?.token;
      return await authService.checkToken(token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [firstTimeUserLogin.pending]: (state) => {
      state.isFirstTimeLoading = true;
      state.isFirstTimeError = false;
      state.isFirstTimeSuccess = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isEditLoading = false;
      state.isEditError = false;
      state.isEditSuccess = false;
    },
    [firstTimeUserLogin.fulfilled]: (state, action) => {
      state.isFirstTimeLoading = false;
      state.isFirstTimeSuccess = true;
    },
    [firstTimeUserLogin.rejected]: (state, action) => {
      state.isFirstTimeLoading = false;
      state.isFirstTimeError = true;
      state.errorMsg = action.payload;
    },
    [userLogin.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isFirstTimeSuccess = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isEditLoading = false;
      state.isEditError = false;
      state.isEditSuccess = false;
    },
    [userLogin.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    },
    [userLogin.rejected]: (state, action) => {
      localStorage.removeItem("user");
      state.isLoading = false;
      state.user = null;
      state.isError = true;
      state.errorMsg = action.payload;
    },
    [editAccount.pending]: (state) => {
      state.isEditLoading = true;
      state.isEditError = false;
      state.isEditSuccess = false;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isFirstTimeLoading = false;
      state.isFirstTimeError = false;
      state.isFirstTimeSuccess = false;
    },
    [editAccount.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.isEditLoading = false;
      state.isEditSuccess = true;
      state.user = action.payload;
    },
    [editAccount.rejected]: (state, action) => {
      state.isEditLoading = false;
      state.isEditError = true;
      state.errorMsg = action.payload;
    },
    [getUserById.pending]: (state) => {
      state.isEditLoading = false;
      state.isEditError = false;
      state.isEditSuccess = false;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isFirstTimeLoading = false;
      state.isFirstTimeError = false;
      state.isFirstTimeSuccess = false;
      state.isGetUserLoading = true;
      state.isGetUserError = false;
      state.isGetUserSuccess = false;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.isGetUserLoading = false;
      state.isGetUserSuccess = true;
      state.user = { ...state.user, userAccount: action.payload?.data };
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    [getUserById.rejected]: (state, action) => {
      state.isGetUserLoading = false;
      state.isGetUserError = true;
      state.errorMsg = action.payload;
    },
    [checkToken.pending]: (state) => {
      state.isEditLoading = false;
      state.isEditError = false;
      state.isEditSuccess = false;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isFirstTimeLoading = false;
      state.isFirstTimeError = false;
      state.isFirstTimeSuccess = false;
      state.isGetUserLoading = false;
      state.isGetUserError = false;
      state.isGetUserSuccess = false;
      state.isCheckTokenLoading = true;
      state.isCheckTokenSuccess = false;
      state.isCheckTokenError = false;
    },
    [checkToken.fulfilled]: (state, action) => {
      state.isCheckTokenLoading = false;
      state.isCheckTokenSuccess = true;
      state.isTokenExpired = !action.payload?.data?.status;
    },
    [checkToken.rejected]: (state, action) => {
      state.isCheckTokenLoading = false;
      state.isCheckTokenError = true;
      state.errorMsg = action.payload?.data;
      state.user = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMsg = "";
      state.user = null;
    },
  },
});

export default authSlice.reducer;
