import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddEvent from "./AddEvent";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditEvent from "./EditEvents";
import EditIcon from "@mui/icons-material/Edit";

import {
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventRequestMutation,
  useUpdateEventRequestMutation,
  useDeleteEventRequestMutation,
} from "../../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const eventsDataq = [
  {
    id: 1,
    title: "Event 1",
    description: "Description of Event 1",
    location: "Location 1",
    startDate: "2023-06-01",
    endDate: "2023-06-03",
    createdOn: "2023-06-01",
    coverImage: "cover1.jpg",
  },
  {
    id: 2,
    title: "Event 2",
    description: "Description of Event 2",
    location: "Location 2",
    startDate: "2023-06-05",
    endDate: "2023-06-07",
    createdOn: "2023-06-02",
    coverImage: "cover2.jpg",
  },
];

export default function EventsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: eventsData,
    isLoading: isLoadingEventsData,
    isError: isErrorEventsData,
    error: errorEventsData,
  } = useGetEventsQuery();

  useEffect(() => {
    if (eventsData) console.log(eventsData);
  }, [eventsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "coverImage",
      headerName: "Cover Image",
      flex: 1,
      renderCell: (params) => (
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.coverImage}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => navigate("/posts/events/" + params?.row?.id)}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                console.log(params?.row);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteEventRequestMutation}
              dialogLabel="Delete Event Item"
              id={params?.row?.id}
              name={params?.row?.title}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isLoadingEventsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          backgroundColor: primaryColor,
        }}
        onClick={handleModalOpen}>
        Add Event
      </Button>
      <AddEvent
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Event"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Events"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={eventsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditEvent
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Event"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
    </div>
  );
}
