import React from "react";
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import {
  FILE_LINK_URL,
  BASE_FILE_URL,
  BASE_URL,
} from "../../../constants/apiTags";

import { useGetLinksQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: `linear-gradient(45deg, #FFC107 30%, #E91E63 90%)`,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    background: `url(${images?.BlurBg3}) center/cover fixed`,
    backdropFilter: "blur(3px)",
  },
  section: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: colorTags.PRIMARY,
  },
  mainCard: {
    width: "60%",
    background: "rgba(255, 255, 255, 0.2)", // Glass effect background
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    cursor: "pointer",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  resourceTitle: {
    fontWeight: "bold",
  },
  resourceLink: {
    color: colorTags.PRIMARY,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ResourcesPage = () => {
  const classes = useStyles();

  const resourceTypes = [
    {
      title: "Monetary",
      links: ["Exchange Rate", "Monitary Policy"],
    },
    {
      title: "Logistics",
      // links: ["Customs Exchange"],
      links: [
        "Customs Exchange",
        "Export Reports From NBE",
        "Ethiopian Horticulture Producer Exporters Association",
      ],
    },
    {
      title: "External Links for Trade Information",
      links: [
        // "Members",
        // "Support for Women in Export",
        // "Events",
        "Internal and External Challenges facing the AfCFTA Agreement, by Prof. Melaku Geboye Desta",
        "The Continental Online Tool/Mechanism for monitoring, reporting and elimination of Non-tariff Barriers (NTBs)",
        "Africa Regional Integration Index Report",
        "African Continental Free Trade Area (AfCFTA) Legal Texts and Policy Documents",
        "TRADE LAW CENTRE(TRALAC) Discussions",
        "Empirical Assessments made on AfCFTA",
        "African Economy",
        "World Trade Organization(WTO)",
        "World Customs Organization",
        "World Customs Organization - Tools",
        "FAO Statistical database",
        "International trade center | trade database | import and export data",
        "International Trade Statistics",
        "The European Union Vegetable Oil and Protein meal Industry",
        "The Food and Agriculture Organization of the United Nations",
        "CBI market information: Promising EU export markets. EU Expanding Exports Helpdesk",
        "EU Statistical Database",
        "The European Union Vegetable Oil and Protein meal Industry",
        "The Food and Agriculture Organization of the United Nations",
        "CBI (Center for Promotion of Imports from Developing Countries)",
        "The International Trade Centre (Geneva, Switzerland)",
      ],
    },
  ];

  const {
    data: linksData,
    isLoading: linksLoading,
    isError: linksError,
  } = useGetLinksQuery();
  const handleResourceLinkClick = (link) => {
    // Open link in a new tab
    window.open(link, "_blank");
  };

  // Group links by linkType
  const groupedLinks = linksData?.reduce((acc, cur) => {
    if (!acc[cur?.linkType]) {
      acc[cur?.linkType] = [];
    }
    acc[cur?.linkType].push({ title: cur?.linkTitle, url: cur?.linkUrl });
    return acc;
  }, {});

  return (
    <div className={classes.mainRoot}>
      <Header />
      <div className={classes.root}>
        <section className={classes.section}>
          <Typography
            variant="h3"
            color="primary"
            className={classes.sectionTitle}>
            Resources
          </Typography>
          <Typography variant="body1" className={classes.sectionDescription}>
            Explore the resources available to help your business succeed.
          </Typography>
        </section>
        {/* <Grid container spacing={2}>
          {resourceTypes?.map((resourceType, index) => (
            <Grid
              item
              xs={12}
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Card className={classes.mainCard}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.resourceTitle}>
                    {resourceType?.title}
                  </Typography>
                  {resourceType?.links?.map((link, linkIndex) => (
                    <Typography
                      key={linkIndex}
                      className={classes.resourceLink}
                      onClick={() => handleResourceLinkClick(link)}>
                      {link}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid> */}
        <Grid container spacing={2}>
          {Object.entries(groupedLinks ?? [])?.map(
            ([linkType, links], index) => (
              <Grid
                item
                xs={12}
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Card className={classes.mainCard}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h5" className={classes.resourceTitle}>
                      {linkType}
                    </Typography>
                    {links?.map((link, linkIndex) => (
                      <Typography
                        key={linkIndex}
                        onClick={() => handleResourceLinkClick(link?.url)}
                        className={classes.resourceLink}
                        style={{ cursor: "pointer" }}>
                        {link?.title}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </div>
      <Footer />
    </div>
  );

  // function handleResourceLinkClick(link) {
  //   const linkToURL = {
  //     "Exchange Rate": "https://nbe.gov.et/commercial-banks-exchange-rate/",
  //     "Customs Exchange": `http://www.ecx.com.et`,
  //     Members: "/members",
  //     "Support for Women in Export": `/women-in-export`,
  //     Events: `/posts/events`,
  //     "Export Reports": "https://nbe.gov.et/monthly-macroeconomic/",
  //     "Monitary Policy": "https://nbe.gov.et/monetary-policy/",
  //     "Ethiopian Horticulture Producer Exporters Association":
  //       "https://ehpea.org/",
  //     "Internal and External Challenges facing the AfCFTA Agreement, by Prof. Melaku Geboye Desta":
  //       "",
  //     "The Continental Online Tool/Mechanism for monitoring, reporting and elimination of Non-tariff Barriers (NTBs)":
  //       "https://afcfta.au.int/en/continental-online-toolmechanism-monitoring-reporting-and-elimination-non-tariff-barriers-ntbs ; https://tradebarriers.africa/",
  //     "Africa Regional Integration Index Report":
  //       "https://www.uneca.org/publications/africa-regional-integration-index-report-2016",
  //     "African Continental Free Trade Area (AfCFTA) Legal Texts and Policy Documents":
  //       "https://www.tralac.org/resources/by-region/cfta.html",
  //     "TRADE LAW CENTRE(TRALAC) Discussions":
  //       "https://www.tralac.org/discussions/",
  //     "Empirical Assessments made on AfCFTA":
  //       "https://www.uneca.org/publicacations/empirical-assessment-african-continental-free-trade-area-modalities-goods for details.",
  //     "African Economy":
  //       "https://www.afdb.org/en/documents/african-economic-outlook-2022",
  //     "World Trade Organization(WTO)":
  //       "https://www.wto.org/english/res_e/publications_e/ai17_e/ai17_e.htm",
  //     "World Customs Organization": "https://www.wcoomd.org",
  //     "World Customs Organization - Tools": "https://www.wcoomd.org",
  //     "FAO Statistical database": "http://www.fao.org/statistics/en/",
  //     "International trade center | trade database | import and export data":
  //       "http://www.trademap.org/Index.aspx",
  //     "International Trade Statistics": "http://www.trademap.org",
  //     "The European Union Vegetable Oil and Protein meal Industry":
  //       "http://www.fediol.eu/",
  //     "The Food and Agriculture Organization of the United Nations":
  //       "http://faostat3.fao.org/home/",
  //     "CBI market information: Promising EU export markets. EU Expanding Exports Helpdesk":
  //       "http://exporthelp.europa.eu-goto‘tradestatistics’.Eurostat",
  //     "EU Statistical Database": "https://ec.europa.eu/eurostat/",
  //     "The European Union Vegetable Oil and Protein meal Industry":
  //       "http://www.fediol.eu/",
  //     "The Food and Agriculture Organization of the United Nations":
  //       "http://faostat3.fao.org/home/",
  //     "CBI (Center for Promotion of Imports from Developing Countries)":
  //       "http://www.cbi.eu/",
  //     "The International Trade Centre (Geneva, Switzerland)":
  //       "http://www.intracen.org",
  //   };

  //   if (linkToURL[link]) {
  //     window.open(linkToURL[link], "_blank");
  //   }
  // }
};

export default ResourcesPage;
