import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { images } from "../../../assets/index";
import { useGetEventsQuery } from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  organizedEventsContainer: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
  title: {
    fontSize: "45px",
    color: colorTags?.PRIMARY,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(900)]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "30px",
    },
  },
  eventContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    gap: theme.spacing(5),
  },
  eventCard: {
    flexBasis: "calc(30% - 2% * 3)",
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    height: "auto",
    cursor: "pointer",
    borderRadius: "10px",
    background: "none",
    // boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 20px -12px",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down(900)]: {
      height: "fit-content",

      flexBasis: "calc(33.33% - 2% * 2)",

      padding: theme.spacing(1),
    },
    [theme.breakpoints.down(600)]: {
      flexBasis: "calc(70% - 2% * 1)",
    },
  },
  eventImage: {
    width: "100%",
    height: "65%",
    borderRadius: "10px",
    objectFit: "cover",
    transition: "transform 0.5s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  eventTitle: {
    color: colorTags?.PRIMARY,
    padding: theme.spacing(1),
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  },
  eventDescription: {
    fontSize: "14px",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(600)]: {
      height: "25%",
    },
  },
  seeAllLink: {
    [theme.breakpoints.down(600)]: {
      marginTop: "20px",
    },
    color: colorTags?.PRIMARY,
    fontWeight: "bold",
  },
}));

const OrganizedEvents = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // eventsData
  const { data: events, isLoading, isError } = useGetEventsQuery();

  const eventss = [
    {
      title: "Event 1",
      image:
        images?.Africameet ||
        "https://images.unsplash.com/photo-1632836926807-4b5b5b5b5b5b?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzNnx8fGVufDB8fHx8&ixlib=rb-1.2.1&w=1000&q=80",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse",
    },
    {
      title: "Event 2",
      image: images?.PanAfrican || "https://example.com/event2.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse",
    },
    {
      title: "Event 3",
      image: images?.Africameet || "https://example.com/event3.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse",
    },
  ];

  function truncateToWords(text, maxWords) {
    // Split the text into words
    const words = text.split(" ");

    // If the text has fewer or equal words than maxWords, return it as is
    if (words.length <= maxWords) {
      return text;
    }

    // Otherwise, truncate to maxWords and add an ellipsis
    const truncatedText = words.slice(0, maxWords).join(" ");
    return `${truncatedText}...`;
  }

  const originalText =
    "This is an example of a long text that needs to be truncated to a maximum of 12 words.";
  const truncatedText = truncateToWords(originalText, 12);

  // console.log(truncatedText);
  const reversedEventsData = [...(events || [])].reverse();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;
  return (
    <div className={classes.organizedEventsContainer}>
      <div>
        <Typography className={classes.title} variant="h4" component="h2">
          <span style={{ color: colorTags.PRIMARY }}>Events</span>
        </Typography>
      </div>
      <div className={classes.eventContainer}>
        {reversedEventsData?.splice(0, 3)?.map((event, index) => (
          <Card
            key={index}
            className={classes.eventCard}
            elevation={0}
            // component={Link}
            onClick={() => navigate(`/posts/events/${event?.id}`)}
            style={{ color: "black", textDecoration: "none" }}>
            <img
              src={BASE_FILE_URL + event?.coverImage}
              alt={event.title}
              className={classes.eventImage}
            />
            <Typography variant="h6" className={classes.eventTitle}>
              {event.title}
            </Typography>
            <Typography variant="body2" className={classes.eventDescription}>
              {truncateToWords(event.description, 10)}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "0px 25%",
              }}>
              <Typography
                variant="body2"
                style={{ color: colorTags.SECONDARY }}
                className={classes.eventDescription}>
                {/* Starting{"  "} */}
                {new Date(event?.startDate).toLocaleDateString("en-US")}
              </Typography>
              <Typography variant="body2" className={classes.eventDescription}>
                {/* Starting{"  "} */}
                {"  "} - {"  "}{" "}
              </Typography>

              <Typography
                variant="body2"
                style={{ color: colorTags.SECONDARY }}
                className={classes.eventDescription}>
                {/* Ends {"  "} */}
                {new Date(event?.endDate).toLocaleDateString("en-US")}
              </Typography>
            </div>
          </Card>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "5%",
        }}>
        <Typography
          component={RouterLink}
          to="/posts/events"
          className={classes.seeAllLink}>
          See All
        </Typography>
      </div>
    </div>
  );
};

export default OrganizedEvents;
