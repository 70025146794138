import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ExportPage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);

  const handleItemClick = (index) => {
    setOpen((prevOpen) => (prevOpen === index ? null : index));
  };

  return (
    <List className={classes.list}>
      <ListItem button onClick={() => handleItemClick(0)}>
        <ListItemText primary="Product 1" />
        {open === 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open === 0} timeout="auto" unmountOnExit>
        <ListItem button className={classes.nested}>
          <ListItemText primary="Get HS Code" />
        </ListItem>
        <ListItem button className={classes.nested}>
          <ListItemText primary="View Full Procedure" />
        </ListItem>
      </Collapse>
      <Divider />
      <ListItem button onClick={() => handleItemClick(1)}>
        <ListItemText primary="Product 2" />
        {open === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open === 1} timeout="auto" unmountOnExit>
        <ListItem button className={classes.nested}>
          <ListItemText primary="Get HS Code" />
        </ListItem>
        <ListItem button className={classes.nested}>
          <ListItemText primary="View Full Procedure" />
        </ListItem>
      </Collapse>
      <Divider />
      <ListItem button onClick={() => handleItemClick(2)}>
        <ListItemText primary="Product 3" />
        {open === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open === 2} timeout="auto" unmountOnExit>
        <ListItem button className={classes.nested}>
          <ListItemText primary="Get HS Code" />
        </ListItem>
        <ListItem button className={classes.nested}>
          <ListItemText primary="View Full Procedure" />
        </ListItem>
      </Collapse>
      <Divider />
    </List>
  );
};

export default ExportPage;
