import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Grid, Link } from "@mui/material";
import { colorTags } from "../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { images } from "../../../assets/index";
import { Link as RouterLink } from "react-router-dom";

import {
  useGetNewsQuery,
  useGetNewsByIdQuery,
} from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  recentEventsContainer: {
    // background: "#E8E8E8",
    padding: theme.spacing(4),
    textAlign: "center",
  },
  title: {
    fontSize: "35px",
    fontWeight: "bold",
    color: colorTags?.PRIMARY,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  bigEventCard: {
    width: "100%",
    height: "auto",
    borderRadius: "10px",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    position: "relative",
    transition: "0.3s",
    cursor: "pointer",
    "&:hover": {
      "& $eventTitle": {
        // filter: "blur(10px)",
      },
    },

    [theme.breakpoints.down(900)]: {
      height: "200px",
      width: "100%",
      marginBottom: theme.spacing(2),

      // height: "00px",
    },
  },
  smallEventCard: {
    width: "100%",
    height: "auto",
    borderRadius: "10px",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    position: "relative",
    transition: "0.3s",
    background: "none",
    border: "none",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 20px -12px",
    // alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $eventTitle": {
        // filter: "blur(10px)",
      },
    },
  },
  eventImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    // boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 20px -12px",

    borderRadius: "10px",
  },
  eventOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    opacity: 0,
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.down(900)]: {
      opacity: 1,
    },
  },
  eventOverlayM: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    opacity: 0,
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
  },
  eventOverlay2: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    opacity: 0,
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
  },
  eventTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    [theme.breakpoints.down(900)]: {
      marginBottom: theme.spacing(0),

      fontSize: "15px",
    },
  },
  eventTitle2: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    color: "#fff",
    [theme.breakpoints.down(900)]: {
      marginBottom: theme.spacing(0),

      fontSize: "15px",
    },
  },
  eventDescription: {
    fontSize: "14px",
    textAlign: "justify",
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "14px",
  },
}));

const RecentEvents = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const events = [
    {
      title: "Event 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: images.NewsImage,
    },
    {
      title: "Event 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: images.NewsImage,
    },
    {
      title: "Event 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: images.NewsImage,
    },
    {
      title: "Event 4",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: images.NewsImage,
    },
    {
      title: "Event 5",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: images.NewsImage,
    },
  ];

  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetNewsQuery();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  if (newsLoading) return <div>Loading...</div>;
  if (newsError) return <div>Error...</div>;
  const reversedNewsData = [...(newsData || [])].reverse();

  return (
    <div className={classes.recentEventsContainer}>
      <Typography
        style={{ fontSize: 40, fontWeight: "bold" }}
        className={classes.title}
        variant="h5"
        component="h2">
        <span style={{ color: colorTags.PRIMARY }}>Recent News</span>
      </Typography>

      {reversedNewsData && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card className={classes.bigEventCard}>
              {/* <Link to={`/posts/news/${event._id}`} target="_blank"> */}
              <Link href={`/posts/news/${reversedNewsData[0]?.id}`}>
                <img
                  src={BASE_FILE_URL + reversedNewsData[0]?.coverImage}
                  alt={reversedNewsData[0]?.title}
                  className={classes.eventImage}
                />
                <CardContent className={classes.eventOverlay}>
                  <Typography
                    variant="h6"
                    component="h3"
                    className={classes.eventTitle2}>
                    {reversedNewsData[0]?.title.length > 20
                      ? `${reversedNewsData[0]?.title.substring(0, 20)}...`
                      : reversedNewsData[0]?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.eventDescription}>
                    {reversedNewsData[0]?.intro.length > 20
                      ? `${reversedNewsData[0]?.intro.substring(0, 20)}...`
                      : reversedNewsData[0]?.intro}
                  </Typography>
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid item xs={12} md={isSmallScreen ? 2 : 8} mt={0}>
            <Grid container spacing={isSmallScreen ? 0 : 2}>
              {reversedNewsData?.slice(1, 5).map((event, index) => (
                <Grid item xs={isSmallScreen ? 12 : 6} key={index}>
                  <Card
                    style={{
                      background: "none",
                      border: "none",
                      boxShadow: "none",
                    }}>
                    <Grid container spacing={0}>
                      <Grid item xs={5}>
                        <div
                          style={{
                            height: "100%",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // backgroundColor: "blue",
                          }}>
                          <Card className={classes.smallEventCard}>
                            {/* <Link to={`/posts/news/${event._id}`} target="_blank"> */}
                            <Link href={`/posts/news/${event.id}`}>
                              <img
                                src={
                                  event?.coverImage && event?.coverImage
                                    ? BASE_FILE_URL + event.coverImage
                                    : images?.NEWS_PLACEHOLDER
                                }
                                alt={event.title}
                                className={classes.eventImage}
                              />
                              <CardContent className={classes.eventOverlayM}>
                                <Link href={`/posts/news/${event.id}`}>
                                  <Typography
                                    variant="h6"
                                    component="h3"
                                    className={classes.eventTitle2}>
                                    {event.intro.length > 20
                                      ? `${event.intro.substring(0, 20)}...`
                                      : event.title}
                                  </Typography>
                                </Link>
                              </CardContent>
                            </Link>
                          </Card>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{
                          padding: isSmallScreen ? "0px" : "5px",
                        }}>
                        <Link
                          href={`/posts/news/${event.id}`}
                          style={{ color: "black", textDecoration: "none" }}>
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="h3"
                              className={classes.eventTitle}>
                              {event.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.eventDescription}>
                              {event.intro.length > 40
                                ? `${event.intro.substring(0, 40)}...`
                                : event.intro}
                            </Typography>
                          </CardContent>
                        </Link>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "5%",
        }}>
        <Typography
          component={RouterLink}
          to="/posts/news"
          style={{ color: colorTags?.PRIMARY, fontWeight: "bold" }}
          className={classes.seeAllLink}>
          See All
        </Typography>
      </div>
    </div>
  );
};

export default RecentEvents;
