import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  styled,
  Button,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import {
  useCreateNewsRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";
const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "#F4EAD5",
  borderRadius: "5px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #285430  71%, #006737  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyless = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    width: "100%",
    minHeight: "200px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    resize: "vertical",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
}));

const AddNews = ({ onClose }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [link, setLink] = useState("");
  const [cover, setCover] = useState([]);
  const [contentType, setContentType] = useState("both");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [
    createNewsRequest,
    {
      isLoading: isLoadingCreateNews,
      isError: isErrorCreateNews,
      error: errorCreateNews,
    },
  ] = useCreateNewsRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const validateLink = (inputLink) => {
    // Regular expression for a simple URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    return urlRegex.test(inputLink);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === "") {
      toast.error("Please Enter Article Title");
      return;
    }

    if (contentType === "Link" && !validateLink(link)) {
      toast.error("Invalid Article Link");
      return;
    }

    if (cover.length === 0) {
      toast.error("Please Upload a cover image");
      return;
    }

    try {
      const imageResponse = await uploadImage(cover[0]);

      const data = {
        title,
        intro,
        link,
        contentType,
        coverImage: imageResponse?.dbPaths[0]?.documentName ?? "",
        userId: user?.user_id,
      };

      await createNewsRequest(data);
      toast.success("News Article Added Successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }

    setTitle("");
    setIntro("");
    setLink("");
    setCover([]);

    onClose();
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Typography
        variant="h4"
        style={{ color: colorTags.PRIMARY }}
        gutterBottom>
        Add News Article
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        required
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Category"
        variant="outlined"
        fullWidth
        style={{ marginTop: "15px" }}
        select
        value={contentType}
        onChange={(e) => setContentType(e.target.value)}>
        <MenuItem value="Link">Link</MenuItem>
        <MenuItem value="Article">Article</MenuItem>
        <MenuItem value="Both">Both</MenuItem>
      </TextField>
      <TextField
        label="Intro / Short Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        value={intro}
        onChange={(e) => setIntro(e.target.value)}
      />
      <TextField
        label="Article Link (optional)"
        variant="outlined"
        fullWidth
        margin="normal"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        error={contentType === "Link" && !validateLink(link)}
        helperText={
          contentType === "Link" && !validateLink(link)
            ? "Invalid Article Link"
            : ""
        }
      />
      <DropzoneArea
        dropzoneClass={classez?.dropZone}
        style={{ marginTop: "15px" }}
        showAlerts={false}
        useChipsForPreview={true}
        filesLimit={1}
        maxFiles={1}
        maxFileSize={5000000}
        acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/webp"]}
        dropzoneText={
          "Drop or Click to Upload Cover Image (Optional, JPG, JPEG, PNG, WEBP)"
        }
        onChange={(files) => setCover(files)}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledButton
          loading={isLoadingCreateNews}
          onClick={handleSubmit}
          color="primary">
          Submit Article
        </StyledButton>
      </div>
    </Container>
  );
};

export default AddNews;
