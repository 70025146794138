import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Grid,
  IconButton,
  Link,
  TextField,
  DragHandle,
  Divider,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { colorTags } from "../../../constants/variants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { images } from "../../../assets/index";
import ChatIcon from "@mui/icons-material/Chat";
import FlagIcon from "@mui/icons-material/Flag";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import * as Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  Remove,
  Edit,
  Delete,
  AddCircleOutline,
  FiberManualRecord,
} from "@mui/icons-material";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import FlagContentModal from "../../../components/FlagContent";

import { set } from "date-fns";

import DeleteDialog from "../../../components/";
import { LoadingButton } from "@mui/lab";
import {
  useGetProcedureByIdQuery,
  useGetInstitutionsQuery,
  useGetDocumentsQuery,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import LanguageIcon from "@mui/icons-material/Language";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Title from "../Header/Title";
import LoadingScreen from "../../../components/LoadingMain";
const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#F7F7F7",
    padding: theme.spacing(2),
  },
  pageContainer: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(10),
    [theme.breakpoints.down(600)]: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      justifyContent: "center",
    },
  },
  leftColumn: {
    width: "45%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  rightColumn: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(-7),
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  header: {
    backgroundColor: colorTags.PRIMARY,
    color: "#fff",
    textAlign: "center",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  card: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
  },
  title: {
    fontSize: 20,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  mainTitle: {
    fontSize: 30,
    // fontWeight: "bold",
    marginTop: theme.spacing(6),
    alignSelf: "left",
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
    },
  },
  stepsContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: "#F7F7F7",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "transparent",
    // marginBottom: theme.spacing(2),
  },
  summaryTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#006738",
  },
  mainStepsContainer: {
    maxHeight: "600px",
    overflowY: "scroll",
    [theme.breakpoints.down(600)]: {
      maxHeight: "300px",
    },
  },
  imageContainer: {
    position: "relative",
    // width: "100%",
    // height: "100%",
    width: "100px",
    height: "100px",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: 1,
    [theme.breakpoints.down(600)]: {
      width: "60px",
      height: "60px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const styles2 = StyleSheet.create({
  container: {
    padding: "7%",
    fontFamily: "Helvetica",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
    color: "black",
  },
  summaryContainer: {
    padding: "7%",
  },
  summaryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "darkgreen",
  },
  summaryText: {
    fontSize: 14,
    marginBottom: 10,
  },
  stepsContainer: {
    paddingTop: "7%",
  },
  stepContainer: {
    marginBottom: 15,
  },
  stepDescription: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subStepContainer: {
    marginLeft: 20,
    marginBottom: 15,
  },
  subStepDescription: {
    fontSize: 16,
    marginTop: 10,

    fontWeight: "medium",
  },
  subStepDetail: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 10,
  },
  institutionsContainer: {
    marginTop: "7%",
  },
  institutionsTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  institutionName: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 20,
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    right: 30,
    fontSize: 10,
    flexDirection: "row",
    alignItems: "center",
    color: "#888",
  },
  footerIcon: {
    marginRight: 4,
  },
  waterMark: {
    position: "absolute",
    bottom: 100,
    right: 30,
    fontSize: 10,
    color: "#aaa",
  },
  // waterMark: {
  //   position: "absolute",
  //   fontSize: 50,
  //   opacity: 0.1,
  //   zIndex: -1,
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
});

const ProceduresPage = () => {
  const classes = useStyles();
  const classes2 = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [mainIndex, setMainIndex] = useState(0);
  const pdfContainerRef = useRef();
  const { id } = useParams();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [procedureData, setProcedureData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const {
    data: procedureDataq,
    isLoading: isLoadingProcedure,
    isError: isErrorProcedure,
    error,
  } = useGetProcedureByIdQuery({ id: params?.id });

  useEffect(() => {
    if (procedureDataq) {
      setProcedureData(procedureDataq[0]);
    }
  }, [procedureDataq]);

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isErrorDocuments,
  } = useGetDocumentsQuery();

  const {
    data: institutionsData,
    isLoading: isInstitutionsLoading,
    isError: isErrorInstitutions,
  } = useGetInstitutionsQuery();

  useEffect(() => {
    if (isErrorProcedure) {
      toast.error("Error loading procedure");
      window.location.href = "/dashboard/procedures";
    }
  }, [isErrorProcedure]);

  // Print function to print the procedure data
  // const handlePrint = () => {
  //   window.print();
  // };
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const PdfDocument = () => (
    <Document>
      <Page size="A4">
        <View style={styles2.container}>
          <Text style={styles2.title}>
            {procedureData?.title.replace(
              "Product One",
              procedureData?.productName
            )}
          </Text>
          <View style={styles2.summaryContainer}>
            <Text style={styles2.summaryTitle}>Summary</Text>
            <Text style={styles2.summaryText}>{procedureData?.summary}</Text>
          </View>
          <View style={styles2.stepsContainer}>
            {procedureData?.mainSteps?.map((step, index) => (
              <View key={index} style={styles2.stepContainer}>
                <Text style={styles2.stepDescription}>{step.description}</Text>
                {step.subSteps.map((subStep, index) => (
                  <View key={index} style={styles2.subStepContainer}>
                    <Text style={styles2.subStepDescription}>
                      {subStep.description}
                    </Text>
                    <Text style={styles2.subStepDetail}>{subStep.detail}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
      <Page size="A4">
        <View style={styles2.container}>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Institutions Involved</Text>
            <View>
              {procedureData?.institutionsInvolved?.map(
                (institution, index) => {
                  // Find the details for this institution from institutionsData
                  const institutionDetails = institutionsData?.find(
                    (item) => item.id === institution.institutionId
                  );

                  if (!institutionDetails) {
                    return null;
                  } else {
                    return (
                      <View key={index} style={styles2.institutionName}>
                        <Text>{institutionDetails.institutionName}</Text>
                      </View>
                    );
                  }
                }
              )}

              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Required Documents</Text>
            <View>
              {procedureData?.requiredDocuments?.map((document, index) => {
                // Find the details for this document from documentsData
                const documentDetails = documentsData?.find(
                  (item) => item.id === document.documentId
                );

                return documentDetails ? (
                  <View key={index} style={styles2.institutionName}>
                    <Text>{documentDetails.documentName}</Text>
                  </View>
                ) : null;
              })}

              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
        </View>
        <Text style={styles2.footer}>
          <InfoCircleOutlined style={styles2.footerIcon} />
          Addis Ababa Chamber of Commerce and Sectoral Associations/AACCSA/ 2023
        </Text>
        <Text style={styles2.waterMark}>AACCSA</Text>
      </Page>
    </Document>
  );

  if (isLoadingProcedure) {
    return <LoadingScreen />;
  } else if (isErrorProcedure) {
    return <div>{error}</div>;
  } else {
    return (
      <>
        <Title text="AACCSA Export Portal | Procedures" />

        <Header />
        <div className={classes.page}>
          <div style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              style={{ marginTop: "10px", paddingLeft: "20px" }}
              className={classes.mainTitle}>
              {procedureData?.title + " " + procedureData?.productName}{" "}
            </Typography>
          </div>

          <div className={classes.pageContainer}>
            <div className={classes.leftColumn}>
              <Stack direction="column" spacing={2}>
                <Card className={classes.card}>
                  <div className={classes.header}>
                    <Typography className={classes.title}>Steps</Typography>
                  </div>
                  <CardContent className={classes.mainStepsContainer}>
                    {procedureData?.mainSteps?.map(
                      (mainStep, mainStepIndex) => (
                        <div key={mainStep.id} variant="outlined">
                          <CardContent>
                            <Typography variant="h6">
                              {mainStep.description}
                            </Typography>
                            <Divider />
                            {mainStep?.subSteps?.map(
                              (subStep, subStepIndex) => (
                                <div
                                  key={subStepIndex}
                                  variant="outlined"
                                  style={{ marginTop: "15px" }}>
                                  {" "}
                                  <Typography
                                    style={{ listStyleType: "circle" }}>
                                    <FiberManualRecord
                                      fontSize="small"
                                      style={{
                                        color: `#${mainStepIndex * 30}6738`,
                                      }}
                                    />
                                    {subStep?.description}
                                  </Typography>
                                  <Typography>{subStep?.detail}</Typography>
                                </div>
                              )
                            )}
                          </CardContent>
                        </div>
                      )
                    )}
                  </CardContent>
                </Card>
              </Stack>
            </div>
            <div className={classes.rightColumn}>
              <div className={classes.iconContainer}>
                <div>
                  <IconButton onClick={() => console.log("Chat icon clicked")}>
                    {(user && user?.role_name === "member") ||
                      (user?.role_name === "Member" ? (
                        <Link
                          href={`/chat`}
                          className={classes.otherText}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#068FFF",
                            fontSize: 16,
                          }}
                          target="_blank">
                          {" "}
                          <ChatIcon />
                        </Link>
                      ) : null)}
                  </IconButton>
                  <IconButton>
                    <FlagIcon
                      style={{ color: "#EF6262" }}
                      onClick={handleOpenModal}
                    />

                    <FlagContentModal
                      open={isModalOpen}
                      onClose={handleCloseModal}
                    />
                  </IconButton>
                  {/* <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton> */}

                  <PDFDownloadLink
                    document={<PdfDocument />}
                    fileName="ProcedureData.pdf"
                    style={{ textDecoration: "none" }}>
                    <IconButton style={{ color: "#006738" }}>
                      <DownloadIcon />
                    </IconButton>
                  </PDFDownloadLink>
                </div>
              </div>
              <Card className={classes.card}>
                <div className={classes.header}>
                  <Typography className={classes.title}>Summary</Typography>
                  <div
                    style={{
                      position: "relative",
                      // width: "60%",
                    }}>
                    {" "}
                  </div>
                </div>
                <CardContent>
                  <Typography variant="body1">
                    {procedureData?.summary}
                  </Typography>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <div className={classes.header}>
                  <Typography className={classes.title}>
                    Institutions Involved
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    {procedureData?.institutionsInvolved?.map(
                      (institution, index) => {
                        // Find the details for this institution from institutionsData
                        const institutionDetails = institutionsData?.find(
                          (item) => item.id === institution.institutionId
                        );

                        if (!institutionDetails) {
                          return null; // Skip if details are not found
                        }

                        return (
                          <Grid item xs={3} mb={2} key={index}>
                            <div className={classes.imageContainer}>
                              <img
                                src={BASE_FILE_URL + institutionDetails?.image}
                                alt={institutionDetails?.institutionName}
                                className={classes.image}
                                title={institutionDetails?.institutionName}
                              />
                            </div>
                            <Typography
                              mt={1}
                              pl={1}
                              variant="body2"
                              textAlign="justify">
                              {institutionDetails?.institutionName}
                              {institutionDetails?.website && (
                                <Link
                                  href={institutionDetails?.website}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}>
                                  <LanguageIcon
                                    style={{
                                      // color: colorTags.PRIMARY,
                                      color: "#00A9FF",
                                      marginLeft: "5px",
                                      width: "15px",
                                    }}
                                  />
                                </Link>
                              )}
                            </Typography>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <div className={classes.header}>
                  <Typography className={classes.title}>
                    Required Documents
                  </Typography>
                  <div style={{ position: "relative" }}></div>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    {procedureData?.requiredDocuments?.map(
                      (document, index) => {
                        // Find the details for this institution from institutionsData
                        const documentDetails = documentsData?.find(
                          (item) => item.id === document.documentId
                        );

                        if (!documentDetails) {
                          return null;
                        }

                        return (
                          <Grid item xs={3} mb={2} key={index}>
                            <div className={classes.imageContainer}>
                              <img
                                src={
                                  BASE_FILE_URL + documentDetails?.coverImage ??
                                  ""
                                }
                                alt={documentDetails?.documentName}
                                className={classes.image}
                                title={documentDetails?.documentName}
                              />
                            </div>
                            <Typography
                              mt={1}
                              pl={1}
                              variant="body2"
                              textAlign="justify">
                              {documentDetails?.title}
                            </Typography>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default ProceduresPage;
