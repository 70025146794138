import React, { useEffect, useRef } from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import AOS from "aos";

import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    padding: "2%",
    height: "auto",
    // background: "none",
    // backgroundColor: colorTags.SECONDARY,
    // backgroundColor: "#ff3f",
    // backgroundColor: `linear-gradient(rgba(${colorTags.PRIMARY_RGBA}, 0.3), rgba(${colorTags.SECONDARY_RGBA}, 0.3) 40%)`,

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
      padding: "1%",
      margin: theme.spacing(1),
    },
  },
  coloredHalf: {
    flex: 1,
    backgroundColor: "rgba(240, 230, 140, 0.06)",
    borderRadius: "0 8px 8px",
    width: "100%",
    padding: theme.spacing(3),
  },
  imageContainer: {
    flex: 1,
    height: "100%",
    borderRadius: "8px 0 0 8px",
    overflow: "hidden",
    backgroundColor: "#ff3f",
    width: "100%",
  },
  image: {
    backgroundColor: "#ff3f",
    width: "100%",
    height: "50%",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  circlesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    marginLeft: theme.spacing(-3.9),
    padding: theme.spacing(3),
    borderRadius: "8px",
    zIndex: 1,
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: colorTags.PRIMARY,
    margin: theme.spacing(1),
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },

    animationName: "$rollInFromLeft",
    animationDuration: "3.5s",
    animationTimingFunction: "ease",
  },
  circle2: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: colorTags.PRIMARY,
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
    animationName: "$rollInFromLeft",
    animationDuration: "4.5s",
    animationTimingFunction: "ease",
  },
  circle3: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: colorTags.PRIMARY,
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
    animationName: "$rollInFromLeft",
    animationDuration: "5.5s",
    animationTimingFunction: "ease",
  },
  icon: {
    color: "#fff",
    // fontSize: "1.5rem",
  },
  contentContainer: {
    backgroundColor: "#ff3f",
    flex: 1,
    padding: theme.spacing(3),
  },
  "@keyframes rollInFromLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
}));

const ExportType = ({ type }) => {
  const classes = useStyles();

  const shippingRef = useRef(null);
  const monetizationRef = useRef(null);
  const descriptionRef = useRef(null);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "slideInFromLeft 0.5s ease";
        observer.unobserve(target);
      }
    });
  };

  useEffect(() => {
    AOS.init();

    const handleInitialVisibility = () => {
      const element = document.getElementById("scrollToElement");
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible =
          elementRect.top < window.innerHeight && elementRect.bottom >= 0;
        if (isVisible) {
          // setStartCountUp(true);
        }
      }
    };

    handleInitialVisibility();

    const handleScrollVisibility = () => {
      const element = document.getElementById("scrollToElement");
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible =
          elementRect.top < window.innerHeight && elementRect.bottom >= 0;
        // setStartCountUp(isVisible);
      }
    };

    window.addEventListener("scroll", handleScrollVisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollVisibility);
    };
  }, []);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const shippingObserver = new IntersectionObserver(
      handleIntersection,
      options
    );
    const monetizationObserver = new IntersectionObserver(
      handleIntersection,
      options
    );
    const descriptionObserver = new IntersectionObserver(
      handleIntersection,
      options
    );

    if (shippingRef.current) {
      shippingObserver.observe(shippingRef.current);
    }
    if (monetizationRef.current) {
      monetizationObserver.observe(monetizationRef.current);
    }
    if (descriptionRef.current) {
      descriptionObserver.observe(descriptionRef.current);
    }

    return () => {
      shippingObserver.disconnect();
      monetizationObserver.disconnect();
      descriptionObserver.disconnect();
    };
  }, []);

  if (type === "plane")
    return (
      <Card className={classes.mainContainer}>
        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                {/* <LocalShippingIcon className={classes.icon} /> */}
                <MonetizationOnIcon className={classes.icon} />
              </div>
              {/* <div
                style={{
                  backgroundColor: "rgba(255,255,255,0.5)",
                  padding: "20px",
                  borderRadius: "10px",
                  margin: "20px",
                }}> */}
              <Typography
                variant="h6"
                data-aos="fade-right"
                data-aos-duration="1000"
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                  color: "#333",
                }}>
                Shipping Requirements and Arranging Shipment
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  color: "#666",
                  lineHeight: "1.4",
                }}>
                When shipping a product overseas, the exporter must be aware of
                all the requirements with regard to packing, labeling,
                documentation, and insurance. In particular, it is important
                that exporters ensure that the merchandise is shipped in the
                following conditions:
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Packed correctly so that it arrives in good condition.
                  </li>
                  <li>
                    Labeled correctly to ensure proper handling and on-time
                    delivery.
                  </li>
                  <li>
                    Documented correctly to meet the requirements of both the
                    Ethiopian and foreign governments and comply with their
                    collection standards.
                  </li>
                  <li>Insured against damage, loss, pilferage, and delay.</li>
                </ul>
                Most exporters rely on an international freight forwarder to
                perform these services because of the multitude of
                considerations involved in physically exporting goods.
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  variant="subtitle1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}>
                  Arranging Shipment
                </Typography>
                <ul
                  style={{
                    listStyleType: "decimal",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Book space in advance by contracting a shipping agent or
                    company.
                  </li>
                  <li>
                    To export goods by air, arrangements should be made with the
                    office of the Ethiopian Airlines Cargo five days in advance.
                  </li>
                  <li>
                    Pack goods in suitable containers, enclosing the packaging
                    list in the case.
                  </li>
                  <li>
                    The list describes the content of the case, including
                    quantity, brand, weight, and all other pertinent details
                    about the contents.
                  </li>
                  <li>
                    Label clearly on at least two sides of the package (case)
                    for easy identification, storage, and delivery.
                  </li>
                  <li>
                    Issue precise shipping instructions to the
                    clearing/forwarding agent.
                  </li>
                  <li>
                    Immediately notify the importer of the shipment by
                    dispatching the pertinent supporting documents.
                  </li>
                </ul>
              </Typography>
            </div>
            {/* </div> */}

            <div className={classes.coloredHalf}>
              <div className={classes.circle2}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography
                variant="h6"
                style={{ marginBottom: "20px" }}
                data-aos="fade-right"
                data-aos-duration="1000">
                Dry Port Facility
              </Typography>
              <Typography>
                Dry Port services for countries like Ethiopia, which are
                landlocked, are very important. Such an arrangement is well
                underway currently and facilities have been constructed by the
                government. Their purposes are: a) Provide such services as
                loading and unloading and the storage of import and export goods
                b) Stow and unpack containerized export and imported goods c)
                Provide container depot services , and d) Be engaged in other
                related activities conducive to the achievement of its purposes
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle3}>
                <LocalPostOfficeIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Postal services
              </Typography>
              <Typography>
                Postal parcel service, which accepts packages that weigh under
                30 kilogram’s, should not be overlooked, since they offer
                exporters an alternative mode of transport and may be highly
                advantageous. Postal parcel service is used mainly for sending
                samples of export products to prospective buyers.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
  else if (type === "truck")
    return (
      <Card className={classes.mainContainer}>
        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                {/* <LocalShippingIcon className={classes.icon} /> */}
                <MonetizationOnIcon className={classes.icon} />
              </div>
              {/* <div
              style={{
                backgroundColor: "rgba(255,255,255,0.5)",
                padding: "20px",
                borderRadius: "10px",
                margin: "20px",
              }}> */}
              <Typography
                variant="h6"
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                  color: "#333",
                }}>
                Shipping Requirements and Arranging Shipment
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  color: "#666",
                  lineHeight: "1.4",
                }}>
                When shipping a product overseas, the exporter must be aware of
                all the requirements with regard to packing, labeling,
                documentation, and insurance. In particular, it is important
                that exporters ensure that the merchandise is shipped in the
                following conditions:
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Packed correctly so that it arrives in good condition.
                  </li>
                  <li>
                    Labeled correctly to ensure proper handling and on-time
                    delivery.
                  </li>
                  <li>
                    Documented correctly to meet the requirements of both the
                    Ethiopian and foreign governments and comply with their
                    collection standards.
                  </li>
                  <li>Insured against damage, loss, pilferage, and delay.</li>
                </ul>
                Most exporters rely on an international freight forwarder to
                perform these services because of the multitude of
                considerations involved in physically exporting goods.
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}>
                  Arranging Shipment
                </Typography>
                <ul
                  style={{
                    listStyleType: "decimal",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Book space in advance by contracting a shipping agent or
                    company.
                  </li>
                  <li>
                    To export goods by air, arrangements should be made with the
                    office of the Ethiopian Airlines Cargo five days in advance.
                  </li>
                  <li>
                    Pack goods in suitable containers, enclosing the packaging
                    list in the case.
                  </li>
                  <li>
                    The list describes the content of the case, including
                    quantity, brand, weight, and all other pertinent details
                    about the contents.
                  </li>
                  <li>
                    Label clearly on at least two sides of the package (case)
                    for easy identification, storage, and delivery.
                  </li>
                  <li>
                    Issue precise shipping instructions to the
                    clearing/forwarding agent.
                  </li>
                  <li>
                    Immediately notify the importer of the shipment by
                    dispatching the pertinent supporting documents.
                  </li>
                </ul>
              </Typography>
            </div>
            {/* </div> */}

            <div className={classes.coloredHalf}>
              <div className={classes.circle2}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Dry Port Facility
              </Typography>
              <Typography>
                Dry Port services for countries like Ethiopia, which are
                landlocked, are very important. Such an arrangement is well
                underway currently and facilities have been constructed by the
                government. Their purposes are: a) Provide such services as
                loading and unloading and the storage of import and export goods
                b) Stow and unpack containerized export and imported goods c)
                Provide container depot services , and d) Be engaged in other
                related activities conducive to the achievement of its purposes
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle3}>
                <LocalPostOfficeIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Postal services
              </Typography>
              <Typography>
                Postal parcel service, which accepts packages that weigh under
                30 kilogram’s, should not be overlooked, since they offer
                exporters an alternative mode of transport and may be highly
                advantageous. Postal parcel service is used mainly for sending
                samples of export products to prospective buyers.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
  else if (type === "ship")
    return (
      <Card className={classes.mainContainer}>
        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                {/* <LocalShippingIcon className={classes.icon} /> */}
                <MonetizationOnIcon className={classes.icon} />
              </div>
              {/* <div
              style={{
                backgroundColor: "rgba(255,255,255,0.5)",
                padding: "20px",
                borderRadius: "10px",
                margin: "20px",
              }}> */}
              <Typography
                variant="h6"
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                  color: "#333",
                }}>
                Shipping Requirements and Arranging Shipment
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  color: "#666",
                  lineHeight: "1.4",
                }}>
                When shipping a product overseas, the exporter must be aware of
                all the requirements with regard to packing, labeling,
                documentation, and insurance. In particular, it is important
                that exporters ensure that the merchandise is shipped in the
                following conditions:
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Packed correctly so that it arrives in good condition.
                  </li>
                  <li>
                    Labeled correctly to ensure proper handling and on-time
                    delivery.
                  </li>
                  <li>
                    Documented correctly to meet the requirements of both the
                    Ethiopian and foreign governments and comply with their
                    collection standards.
                  </li>
                  <li>Insured against damage, loss, pilferage, and delay.</li>
                </ul>
                Most exporters rely on an international freight forwarder to
                perform these services because of the multitude of
                considerations involved in physically exporting goods.
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}>
                  Arranging Shipment
                </Typography>
                <ul
                  style={{
                    listStyleType: "decimal",
                    marginLeft: "20px",
                    paddingLeft: "0",
                  }}>
                  <li>
                    Book space in advance by contracting a shipping agent or
                    company.
                  </li>
                  <li>
                    To export goods by air, arrangements should be made with the
                    office of the Ethiopian Airlines Cargo five days in advance.
                  </li>
                  <li>
                    Pack goods in suitable containers, enclosing the packaging
                    list in the case.
                  </li>
                  <li>
                    The list describes the content of the case, including
                    quantity, brand, weight, and all other pertinent details
                    about the contents.
                  </li>
                  <li>
                    Label clearly on at least two sides of the package (case)
                    for easy identification, storage, and delivery.
                  </li>
                  <li>
                    Issue precise shipping instructions to the
                    clearing/forwarding agent.
                  </li>
                  <li>
                    Immediately notify the importer of the shipment by
                    dispatching the pertinent supporting documents.
                  </li>
                </ul>
              </Typography>
            </div>
            {/* </div> */}

            <div className={classes.coloredHalf}>
              <div className={classes.circle2}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Dry Port Facility
              </Typography>
              <Typography>
                Dry Port services for countries like Ethiopia, which are
                landlocked, are very important. Such an arrangement is well
                underway currently and facilities have been constructed by the
                government. Their purposes are: a) Provide such services as
                loading and unloading and the storage of import and export goods
                b) Stow and unpack containerized export and imported goods c)
                Provide container depot services , and d) Be engaged in other
                related activities conducive to the achievement of its purposes
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle3}>
                <LocalPostOfficeIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Postal services
              </Typography>
              <Typography>
                Postal parcel service, which accepts packages that weigh under
                30 kilogram’s, should not be overlooked, since they offer
                exporters an alternative mode of transport and may be highly
                advantageous. Postal parcel service is used mainly for sending
                samples of export products to prospective buyers.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
};

export default ExportType;
