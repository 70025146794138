import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import MemberCard from "./MemberCard";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";
import { makeStyles } from "@material-ui/core/styles";
import { useGetMembersQuery } from "../../../features/api/apiSlice";
import LoadingScreen from "../../../components/LoadingMain";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Select } from "@mui/material";
import Sponsors from "../Sponsors/Sponsors";
import Title from "../Header/Title";
const useStyles = makeStyles((theme) => ({
  membersContainer: {
    padding: theme.spacing(8),
    backgroundColor: "#FAFAFA",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  gridUp: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  loadMoreButton: {
    display: "block",
    margin: "0 auto",
    marginTop: theme.spacing(2),
    backgroundColor: colorTags.PRIMARY,
    color: "#fff",
    border: "none",
    padding: theme.spacing(1, 2),
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colorTags.PRIMARY_HOVER,
    },
  },
  searchInput: {
    marginBottom: theme.spacing(2),

    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  formControlContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const MembersPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: membersData, isLoading: isLoadingMembers } =
    useGetMembersQuery();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [displayedProfiles, setDisplayedProfiles] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    if (
      // !user ||
      // user?.user_id * 1 !== params?.id ||
      // user?.role_name !== "Member"

      !user
    )
      window.location.href = "/";
  }, [user]);

  useEffect(() => {
    if (membersData) {
      setDisplayedProfiles(membersData?.slice(0, itemsPerPage));
    }
  }, [membersData]);

  const itemsPerPage = 16;

  const filterProfiles = (
    profiles,
    searchText,
    selectedProduct,
    selectedDestination
  ) => {
    return profiles.filter((profile) => {
      const profileName = profile.companyName.toLowerCase();
      const searchWords = searchText.toLowerCase().split(" ");
      const matchingWords = searchWords.filter((word) =>
        profileName.includes(word)
      );

      // Filter based on selected product
      const productMatch =
        !selectedProduct ||
        JSON.parse(profile.products).includes(selectedProduct);

      // Filter based on selected destination
      const destinationMatch =
        !selectedDestination ||
        JSON.parse(profile.destinations).includes(selectedDestination);

      // At least 50% of the search words should match
      return (
        matchingWords.length / searchWords.length >= 0.5 &&
        productMatch &&
        destinationMatch
      );
    });
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    const formattedSearchText = searchText.split(/\s+/).join(" "); // Split by spaces and join with space character
    const filteredProfiles = filterProfiles(
      membersData,
      formattedSearchText,
      selectedProduct,
      selectedDestination
    );
    setDisplayedProfiles(filteredProfiles.slice(0, itemsPerPage));
    setPage(1);
  };

  const handleFilter = () => {
    const filteredProfiles = filterProfiles(
      membersData,
      searchText,
      selectedProduct,
      selectedDestination
    );
    setDisplayedProfiles(filteredProfiles.slice(0, itemsPerPage));
    setPage(1);
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    const startIndex = page * itemsPerPage;
    const endIndex = nextPage * itemsPerPage;
    const nextProfiles = membersData?.concat(
      filterProfiles(
        membersData,
        searchText,
        selectedProduct,
        selectedDestination
      ).slice(startIndex, endIndex)
    );
    setDisplayedProfiles(nextProfiles);
    setPage(nextPage);
  };

  // const classes = useStyles();
  const allProducts = membersData?.reduce((products, member) => {
    const memberProducts = JSON.parse(member.products);
    return products.concat(memberProducts);
  }, []);

  const uniqueProducts = Array.from(new Set(allProducts));

  // Get all unique destinations
  const allDestinations = membersData?.reduce((destinations, member) => {
    const memberDestinations = JSON.parse(member.destinations);
    return destinations.concat(memberDestinations);
  }, []);

  const uniqueDestinations = Array.from(new Set(allDestinations));

  if (isLoadingMembers) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Title text="Addis Chamber Export Portal For Trade Facilitation || Business Matching" />

      <Header />

      <div className={classes.membersContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}>
          <TextField
            label="Search Members"
            variant="outlined"
            id="searchMembers"
            name="searchMembers"
            color="success"
            style={{ color: colorTags.PRIMARY, maxWidth: "500px" }}
            fullWidth
            className={classes.searchInput}
            value={searchText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchChange(e);
              }
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className={classes.formControlContainer} style={{}}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="product-filter-label">Select Product</InputLabel>
            <Select
              labelId="product-filter-label"
              id="product-filter"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              label="Select Product">
              <MenuItem value="">All</MenuItem>
              {uniqueProducts.map((product) => (
                <MenuItem key={product} value={product}>
                  {product}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="destination-filter-label">
              Select Destination
            </InputLabel>
            <Select
              labelId="destination-filter-label"
              id="destination-filter"
              value={selectedDestination}
              onChange={(e) => setSelectedDestination(e.target.value)}
              label="Select Destination">
              <MenuItem value="">All</MenuItem>
              {uniqueDestinations.map((destination) => (
                <MenuItem key={destination} value={destination}>
                  {destination}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Stack alignItems="center" spacing={2}> */}
          <Button
            variant="contained"
            color="success"
            disabled={!selectedProduct && !selectedDestination}
            onClick={handleFilter}>
            Apply Filter
          </Button>
        </div>
        {/* </Stack> */}
        <Grid container spacing={3} className={classes.gridUp}>
          {displayedProfiles.map((profile) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              key={profile.id}
              onClick={() => {
                navigate(`/profile/${profile.id}`);
              }}>
              <MemberCard data={profile} />
            </Grid>
          ))}
        </Grid>

        {displayedProfiles.length < membersData?.length && (
          <Button className={classes.loadMoreButton} onClick={handleLoadMore}>
            Load More
          </Button>
        )}
        <Sponsors />
      </div>
      <Footer />
    </div>
  );
};

export default MembersPage;
