import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../assets/index";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  FILE_LINK_URL,
  BASE_FILE_URL,
  INTERNAL_LINK_URL,
} from "../../../../constants/apiTags";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 4fr))", // 5 items per row
    gap: theme.spacing(5),
    justifyContent: "space-evenly",
    alignItems: "center",
    paddinTop: "20px",
    padding: theme.spacing(2),
    maxWidth: "90%",
  },
  institutionItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  institutionImage: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  institutionName: {
    textAlign: "center",
    fontSize: "15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
    // backgroundColor: "red",
    background: "none",
  },
}));

const InstitutionsPage = ({ data }) => {
  const classes = useStyles();
  const [institutionData, setInstitutionData] = useState([]);

  useEffect(() => {
    if (data) setInstitutionData(data);
  }, [data]);

  const maxRows = 3;
  const institutionPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(institutionData.length / institutionPerPage);

  const handleClickPagination = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const showPaginationNumbers = () => {
    let paginationNumbers = [];

    if (currentPage <= 2) {
      for (let i = 1; i <= 3; i++) {
        paginationNumbers.push(i);
      }
    } else if (currentPage >= totalPages - 1) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        paginationNumbers.push(i);
      }
    }

    return paginationNumbers;
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {institutionData.map((institution, index) => (
          <div key={index} className={classes.institutionItem}>
            <img
              src={
                institution?.image.length > 0
                  ? BASE_FILE_URL + institution?.image
                  : images.AcMeet
              }
              alt={institution.institutionName}
              className={classes.institutionImage}
            />
            <div className={classes.institutionName}>
              {institution.institutionName}
              {institution?.website.trim() && (
                <LanguageIcon
                  onClick={() =>
                    window.open(
                      `https://${institution.website.trim()}`,
                      "_blank"
                    )
                  }
                  style={{
                    color: "#00A9FF",
                    marginLeft: "5px",
                    width: "15px",
                    cursor: "pointer", // Add a pointer cursor to indicate it's clickable
                  }}
                />
              )}
            </div>{" "}
          </div>
        ))}
      </div>
      <div className={classes.pagination}>
        <ChevronLeftIcon
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={() => handleClickPagination(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {showPaginationNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClickPagination(pageNumber)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === pageNumber ? "#F0F0F0" : "white",
            }}>
            {pageNumber}
          </button>
        ))}
        <ChevronRightIcon
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => handleClickPagination(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </div>
    </div>
  );
};

export default InstitutionsPage;
