import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItem,
} from "@material-ui/core";
import {
  Chat as ChatIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { colorTags } from "../../../constants/variants";
import ChatMini from "./ChatMini";
import ChatAvatar from "./ChatAvatar";
import LaunchIcon from "@material-ui/icons/Launch";

import {
  useGetChatByIdQuery,
  useCreateChatRequestMutation,
  useUpdateChatRequestMutation,
  useDeleteChatRequestMutation,
} from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(1),
    zIndex: 9999,
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(2),
      bottom: theme.spacing(3),
    },
  },
  chatMinimized: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    width: "50px",
    height: "50px",
    justifyContent: "center",
    borderRadius: "50%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    marginRight: theme.spacing(2),
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
    },
  },
  chatMinimized2: {
    backgroundColor: "#fff",
    // borderRadius: "8px",
    width: "0px",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    // cursor: "pointer",
  },
  chatExpanded: {
    backgroundColor: "#FAFAFA",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    bottom: "64px",
    right: "0",
    width: "300px",
    height: "400px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      bottom: "0",
      right: "0",
    },
  },
  chatText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: "auto",
    // right: theme.spacing(1),
  },
}));

const ChatComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // const {data:conversationData, isLoading, isError} = useGetChatByIdQuery({
  //   id: user?.id,
  // })

  const handleToggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className={classes.chatContainer}>
      {!expanded && (
        <div className={classes.chatMinimized} onClick={handleToggleExpand}>
          <ChatIcon
            style={{
              color: colorTags.SECONDARY,
              fontSize: "30px",
              "&:hover": {
                color: colorTags.PRIMARY,
              },
            }}
          />
        </div>
      )}
      {expanded && (
        <div style={{ width: isSmallScreen ? "300px" : "400px" }}>
          <IconButton
            className={classes.expandIcon}
            onClick={handleToggleExpand}
            style={{ marginBottom: isSmallScreen ? "-230px" : "-185px" }}>
            <ExpandMoreIcon />
          </IconButton>
          <IconButton
            className={classes.navIcon}
            onClick={() => navigate("/chat")}
            style={{
              marginBottom: "-190px",
              marginLeft: isSmallScreen ? "255px" : "292px",
            }}>
            <LaunchIcon />
          </IconButton>
          <ChatMini />
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
