import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Link,
} from "@mui/material";
import { images } from "../../../assets/index";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import ExportType from "./ExportType";
import CountUp from "react-countup";
import { colorTags } from "../../../constants/variants";
import SponsorsCard from "../Sponsors/Sponsors";
import ExportTypeCost from "../Logistics/ExportTypeCost";
import { useLocation, useParams } from "react-router-dom";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import BackToTop from "../../../components/BackToTop";
import ChatTwo from "../Chat/ChatTwo";

const useStyles = makeStyles((theme) => {
  const keyframeIncrement = () => {
    let keyframes = "";
    for (let i = 0; i <= 101; i++) {
      keyframes += `${i}% { content: '${i}'; }`;
    }
    return keyframes;
  };

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      backdropFilter: "blur(10px)",
      justifyContent: "flex-start",

      overflow: "hidden",
    },

    topCard: {
      position: "relative",
      width: "100%",
      height: 400, // Adjust the overall height as needed
      // backgroundImage: `url(${images?.Logistics3})`,

      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      // alignItems: "center",
      justifyContent: "flex-start",
      // overflow: "hidden",
      background: "rgba(255, 255, 255, 0.7)", // Add a background color with opacity

      [theme.breakpoints.down(600)]: {
        height: 250, // Adjust the height for smaller screens
      },

      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(to top,  rgba(255, 255, 255, 0),rgba(0,0,0, 0.7))", // White gradient overlay
      },
    },

    title: {
      fontSize: "40px",
      fontWeight: 600,
      // fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
      paddingTop: theme.spacing(10),
    },
    circularContainer: {
      width: "80%",

      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(2),
      marginTop: theme.spacing(8),
      zIndex: 1,
    },

    circular: {
      width: "80%",
      height: 150,
      borderRadius: "0%",
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(9),
      marginRight: theme.spacing(9),
      justifyContent: "center",
      cursor: "pointer",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.1)",
      },

      boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
    },
    circularTitle: {
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: theme.spacing(1),
      color: "#000",
    },
    circularImage: {
      width: "100%",
      height: "100%",
      borderRadius: "0%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    exportDescription: {
      width: "100%",
      backgroundColor: "#F0E68C",
      padding: theme.spacing(2),
    },
    exportDescriptionTitle: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    exportDescriptionText: {
      fontSize: 16,
      lineHeight: 1.6,
      textAlign: "justify",
      color: theme.palette.common.black,
    },
    descriptionContainer: {
      display: "flex",
      width: "100%",
      textAlign: "center",
      padding: theme.spacing(4),
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(5),
    },
    descriptionText: {
      fontSize: 18,
      lineHeight: 1.6,
      textAlign: "justify",
      color: theme.palette.common.black,
    },
    customCardContainer: {
      width: "100%",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(2),
      marginTop: theme.spacing(8),
    },
    customCard: {
      width: "100%",
      height: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
      backgroundColor: "#2eee",
      opacity: 0.7,
    },
    customCardImage: {
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundPosition: "center",
    },
    customCardList: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    links: {
      color: colorTags.PRIMARY,
    },
    customCardListItem: {
      fontSize: 16,
      color: theme.palette.common.black,
    },
    incrementContainer: {
      fontSize: 32,
      position: "relative",
      "&:before": {
        content: "'0'",
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        animation: `$incrementAnimation 10s ease-in-out forwards`,
      },
    },
    "@keyframes incrementAnimation": {
      from: {
        content: "'0'",
      },
      to: {
        content: "'101'",
      },
      ...keyframeIncrement(),
    },
    section: {
      marginBottom: theme.spacing(9),
      textAlign: "left",
    },
    sectionTitle: {
      fontWeight: "bold",
      fontSize: "2rem",
      marginBottom: theme.spacing(2),
      color: colorTags.PRIMARY,
    },
    mainDescription: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(2),
      color: colorTags.PRIMARY,
      textAlign: "center",
    },
    sectionDescription: {
      marginTop: theme.spacing(2),
    },

    robustDescriptionContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      textAlign: "left",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
      [theme.breakpoints.down(600)]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    robustDescriptionTitle: {
      fontWeight: "bold",
      fontSize: "2rem",
      marginBottom: theme.spacing(5),
      // color: colorTags.PRIMARY,
      // color: "#fff",
      color: "#EEEDED",
      textAlign: "left",
      [theme.breakpoints.down(600)]: {
        fontSize: "1.5rem",
      },
    },
    robustDescriptionText: {
      fontSize: "1.6rem",
      lineHeight: 1.6,
      textAlign: "left", // Add this line to align text to the left
      // color: colorTags.PRIMARY,
      color: "#fff",
      marginBottom: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: "yellow",
      "&:hover": {
        // backgroundColor: colorTags.PRIMARY,
        backgroundColor: "#fff",
      },
    },
  };
});

const ExportPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const [selectedExport, setSelectedExport] = useState("plane");
  const [startCountUp, setStartCountUp] = useState(false);
  const sectionToScroll = useParams();

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // console.log(sectionToScroll);

    if (sectionToScroll.id) {
      const element = document.getElementById(sectionToScroll.id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [sectionToScroll]);

  const handleExportClick = (exportType) => {
    setSelectedExport(exportType);
  };

  useEffect(() => {
    AOS.init();
  }, []);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [image, setImage] = useState("");
  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 5 });
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  useEffect(() => {
    if (imageData) {
      setImage(imageData?.image);
    }
  }, [imageData]);
  return (
    <div
      style={{
        backgroundImage: `
      linear-gradient(rgba(0,0,0, 0.8), rgba(255, 255, 255, 0.93)), 
      url(${images?.Logistics3})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
      }}>
      <Header />
      <div className={classes.container}>
        <Card
          className={classes.topCard}
          style={{
            backgroundImage: image
              ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${
                  BASE_FILE_URL + image
                })`
              : `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${images?.Logistics3})`,
          }}>
          <CardContent>
            <Typography
              // className={classes.title}
              data-aos={isSmallScreen ? null : "fade-up"}
              data-aos-offset="700"
              sx={{
                fontSize: "95px",
                fontWeight: 600,
                // fontWeight: "bold",
                // background: "linear-gradient(to right, #f12711, #f5af19)",
                color: "#fff",
                textAlign: "left",
                paddingTop: "45%",
                "@media (max-width: 600px)": {
                  paddingTop: "50%",
                  fontSize: "70px",
                  zIndex: 1,
                },
                // paddingTop: theme.spacing(10),
              }}>
              Export
            </Typography>
          </CardContent>
        </Card>

        <div id="howTo" style={{ marginBottom: "2%" }}></div>
        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            style={{ marginBottom: "1.5%", fontSize: "2.1rem" }}
            className={classes.robustDescriptionTitle}>
            {" "}
            Starting Export in Ethiopia
          </Typography>

          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            style={{ marginBottom: "1.5%", color: "#D8D9DA" }}
            className={classes.robustDescriptionTitle}>
            Requirements for Export Start-up in Ethiopia
          </Typography>
          <Typography variant="body1" className={classes.robustDescriptionText}>
            Trade and Regional Integration undertakes the registration of a new
            export company in Ethiopia. If everything is in order and the stamp
            duties and the fees are paid, a certificate of incorporation is
            issued. Effective from the moment the Company is has a legal
            existence with perpetual succession and a common seal; it may
            commence its business operations.
          </Typography>
          {/* <Link
            href="#"
            className={classes.links}
            sx={{
              // color: colorTags.SECONDARY,

              color: "lightblue",
              "&:hover": {
                // color: colorTags.PRIMARY,
                color: "white",
              },
            }}>
            Find out about more Here
          </Link> */}
        </Box>
        {/* <div style={{ marginTop: "-4%" }}>
          <ExportTypeCost />
        </div> */}

        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%", color: colorTags.SECONDARY }}>
            The Export Licensing
          </Typography>
          <Typography variant="body1" className={classes.robustDescriptionText}>
            After Registration, a letter of application to export should be
            submitted to the Ministry of Trade and Regional Integration, which
            will issue the export license. In this regard, the following
            documents are necessary:
            <ul style={{ marginTop: "15px" }}>
              <li style={{ marginTop: "10px" }}>
                &#8226; Submitting a letter of application in two copies
              </li>
              <li style={{ marginTop: "10px" }}>
                &#8226; A principal registration certificate
              </li>
              <li style={{ marginTop: "10px" }}>
                &#8226; A passport-sized photograph
              </li>
              <li style={{ marginTop: "10px" }}>
                &#8226; An investment permit, as well as a residence permit, if
                there is foreign partnership
              </li>
              <li style={{ marginTop: "10px" }}>
                &#8226; A Memorandum of Association and Article of Association
                or a contract of partnership
              </li>
            </ul>
          </Typography>
        </Box>

        <div id="howTop" style={{ marginBottom: "2%" }}></div>

        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%", color: colorTags.SECONDARY }}>
            Getting Organized for Export
          </Typography>
          <Typography variant="body1" className={classes.robustDescriptionText}>
            The export trade is governed by both national and international
            rules and regulations. Thus, the decision to enter an export market
            should be taken after a careful assessment of the advantages and
            disadvantages.
          </Typography>
          <Link
            href="/repository/0"
            className={classes.links}
            sx={{
              color: "lightblue",
              "&:hover": {
                // color: colorTags.PRIMARY,
                color: "white",
              },
            }}>
            See all Products Exported
          </Link>
        </Box>

        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%", color: colorTags.SECONDARY }}
            textAlign="left">
            Export Procedures and Documentation
          </Typography>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I. VAT Registration
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.I Export Procedures and Documentation
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.I.I Document Preparations
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.I.II Negotiation and Contract
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              II. Knowing Essential Elements of the Export Sales Contract
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.I.II.III Documentary credit/Letter of Credit (L/C)
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.II What to know in respect of L/C
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.II.III.II Preparing cargo for Export
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.II.III.III Banking Permit
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.II.III.IV Getting Export Authorization and Quality Certificates
              for Shipment
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.III Customs Clearance
            </Typography>
          </Box>
          <Box className={classes.robustDescriptionContainer}>
            <Typography
              variant="body1"
              className={classes.robustDescriptionText}>
              I.III.I Preparation of the Bank Documents/Shipping Documents
            </Typography>
          </Box>
        </Box>
        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%", color: "#3F2305" }}>
            Transport and Logistics
          </Typography>
          <Typography
            style={{ color: "#3F2305" }}
            variant="body1"
            className={classes.robustDescriptionText}>
            Knowing Mode of Transport and Necessary Logistics
          </Typography>
          <Typography
            style={{ color: "#3F2305" }}
            variant="body1"
            className={classes.robustDescriptionText}>
            Choice of the Mode of Transport
          </Typography>
          <Typography
            style={{ color: "#3F2305" }}
            variant="body1"
            className={classes.robustDescriptionText}>
            Exporters may find it useful to consult with a freight forwarder
            when determining the method of international shipping. Since
            carriers are often used for large and bulky shipments, the exporter
            should reserve space on the carrier well before the shipment date.
            This reservation is called the booking contract.
          </Typography>
          <Typography
            style={{ color: "#3F2305" }}
            variant="body1"
            className={classes.robustDescriptionText}>
            International shipments are increasingly made through a bill of
            lading under a multimodal contract. The multimodal transit operator
            (frequently one of the transporters) takes charge of the entire
            movement from the factory of the exportable goods to their final
            destination. Put differently, they are the ones who shoulder the
            whole responsibility.
          </Typography>
          <Link
            href="/logistics"
            className={classes.links}
            sx={{
              color: "blue",
              "&:hover": {
                color: "white",
              },
            }}>
            Find out more about Logistics
          </Link>
        </Box>
        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%", color: "#3F2305" }}>
            Institutions Involved in the Export Process
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#3F2305" }}
            className={classes.robustDescriptionText}>
            The following are institutions, in one way or another, involved in
            the export process:
            <ul>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  FDRE, Ministry of Trade and Regional Integration
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  FDRE, Ministry of Revenue
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  Ethiopian Customs Commission
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  Commercial Banks
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  The National Bank of Ethiopia, particularly the Banking and
                  Foreign Exchange Directorate (
                  <Link
                    href="https://www.nbe.gov.et"
                    target="_blank"
                    className={classes.links}>
                    www.nbe.gov.et
                  </Link>
                  )
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  className={classes.robustDescriptionText}>
                  The Ministry of Agriculture and Rural Development, in
                  particular, the animal and plant regulatory directorate (
                  <Link
                    href="https://www.moard.gov.et"
                    target="_blank"
                    className={classes.links}>
                    www.moard.gov.et
                  </Link>
                  )<span style={{ fontSize: "1.5rem" }}>...</span>
                </Typography>
              </li>
            </ul>
          </Typography>

          <Link
            href="/repository/2"
            className={classes.links}
            sx={{
              color: "blue",
              "&:hover": {
                color: "white",
              },
            }}>
            Discover All Institutions Involved In The Export Landscape
          </Link>
        </Box>

        {/* <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%" }}>
            1.3.3 Forms and Documents (Clearances and Forms to be Filled )
          </Typography>
          <Typography variant="body1" className={classes.robustDescriptionText}>
            Note  Please contact Ministry of Trade and Regional Integration for
            this , Also consult Alem Get from our Chamber
          </Typography>
          <Link
            href="/repository/0"
            className={classes.links}
            sx={{
              color: colorTags.SECONDARY,
              "&:hover": {
                color: colorTags.PRIMARY,
              },
            }}>
            See all Products Exported
          </Link>
        </Box>
        <Box className={classes.robustDescriptionContainer}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            className={classes.robustDescriptionTitle}
            style={{ marginBottom: "1.5%" }}>
            1.3.4 How to Start Export in Ethiopia
          </Typography>
          <Typography variant="body1" className={classes.robustDescriptionText}>
             Please contact consult Alem Get from our Chamber
          </Typography>
          <Link
            href="/repository/0"
            className={classes.links}
            sx={{
              color: colorTags.SECONDARY,
              "&:hover": {
                color: colorTags.PRIMARY,
              },
            }}>
            See all Products Exported
          </Link>
        </Box> */}
      </div>
      {(user && user?.role_name === "Member") ||
      user?.role_name === "member" ? (
        <ChatTwo />
      ) : null}
      <BackToTop />

      <SponsorsCard />

      <Footer />
    </div>
  );
};

export default ExportPage;
