import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop } from "@material-ui/core";
import { Stack } from "@mui/system";
import {
  Fade,
  Typography,
  Divider,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { colorTags } from "../../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneAreaBase } from "material-ui-dropzone";

import { useStyles } from "../../../../App";
import {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,
  useGetUserRolesQuery,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    minHeight: "50px !important",
    border: "1px dashed #006738",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 4%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "500px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));
const EditUser = ({ open, onClose, title, data, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [avatarS, setAvatarS] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    department: "",
    role: "",
  });
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };
  const {
    data: userRolesData,
    isLoading: isLoadingUserRolesData,
    isError: isErrorUserRolesData,
    error: errorUserRolesData,
  } = useGetUserRolesQuery();

  const [
    updateUserRequest,
    {
      data: updateData,
      error: updateError,
      isLoading: updateLoading,
      isError: isErrorUpdateRequest,
    },
  ] = useUpdateUserRequestMutation();

  useEffect(() => {
    setName(data?.name);
    setRole(data?.role);
    setEmail(data?.email);
    setPhone(data?.phone);
    setAddress(data?.address);
    setDepartment(data?.department);
    setStatus(data?.status);
    setAvatarS(data?.avatar);
  }, [data]);
  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (name.length > 50) {
      valid = false;
      errors.name = "Name should not exceed 50 characters.";
    }

    // Add email validation (you can use a regex pattern)
    // ...

    if (role.length === 0) {
      valid = false;
      errors.role = "Role is required.";
    }
    if (phone.length > 15 || !/^\d+$/.test(phone)) {
      valid = false;
      errors.phone = "Invalid phone number.";
    }

    if (address.length > 50) {
      valid = false;
      errors.address = "Address should not exceed 50 characters.";
    }

    if (department.length > 60) {
      valid = false;
      errors.department = "Department should not exceed 60 characters.";
    }

    setValidationErrors(errors);
    return valid;
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updateData = {
      id: data?.id,
      name,
      email,
      phone,
      address,
      department,
      role,
      status,
      userId: user?.user_id,
    };

    if (avatar.length > 0) {
      try {
        const imageResponse = await uploadImage(avatar[0]);

        updateData.avatar =
          imageResponse?.dbPaths[0]?.documentName ?? data?.avatar;
        console.log(updateData.avatar);
      } catch (error) {
        toast.error("Error uploading image");
        updateData.avatar = data?.avatar;
        return;
      }
    } else {
      updateData.avatar = data?.avatar;
    }
    await updateUserRequest(updateData)
      .then((res) => {
        console.log(res);
        if (res.data) {
          toast.success("User Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Updating User");
      });

    onClose();
  };
  const acceptedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
  ];
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Full Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!validationErrors.name}
              helperText={validationErrors.name}
            />
            <TextField
              label="Email"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!validateEmail(email)}
              helperText={!validateEmail(email) && "Invalid email format"}
            />
            <TextField
              label="Phone"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={!validatePhone(phone)}
              helperText={
                !validatePhone(phone) &&
                "Invalid phone number format. It should be 10 digits"
              }
            />
            <TextField
              label="Address"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={!!validationErrors.address}
              helperText={validationErrors.address}
            />
            <TextField
              label="Department"
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              error={!!validationErrors.department}
              helperText={validationErrors.department}
            />
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Role" style={{}}>
                Role
              </InputLabel>
              <Select
                className={classes.textField}
                label="Role"
                variant="outlined"
                value={role}
                error={!!validationErrors.role}
                helperText={validationErrors.role}
                onChange={(e) => setRole(e.target.value)}>
                {userRolesData?.map((option) => (
                  <MenuItem key={option.id} value={option.roleName}>
                    {option.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Status" style={{}}>
                Status
              </InputLabel>
              <Select
                className={classes.textField}
                label="Status"
                variant="outlined"
                value={status}
                onChange={(e) => setStatus(e.target.value)}>
                {["Active", "Banned", "Inactive"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginTop: "15px" }}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                acceptedFiles={acceptedFileTypes}
                dropzoneText={"Drop or Click to Upload Avatar (Optional)"}
                onChange={(files) => setAvatar(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </Button>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditUser;
