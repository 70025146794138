import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Avatar,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Stack,
  ListItemIcon,
  ListItemText,
  IconButton,
  Badge,
  Hidden,
  Divider,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  Phone,
  Menu as MenuIcon,
  HelpOutline,
  ArrowBack,
} from "@mui/icons-material";
// import { } from "@material-ui/core";
import {
  // ... (other imports)
  Slide, // Add this import for the transition
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  colorTags,
  primaryColor,
  secondaryColor,
  accentColor,
} from "../../../constants/variants";
import logoImage from "../../../assets/ac.png";
import { AuthContext } from "../../../features/auth/authContext";
import { images } from "../../../assets/index";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Notifications from "../../NotificationPage/NotificationsPage";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import { useUser } from "../../../features/auth2/UserContext";
import { set } from "lodash";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import {
  useGetUserByIdQuery,
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
} from "../../../features/api/apiSlice";
import { logout } from "../../../features/auth2/authSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginBottom: "18px",

    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  logoHolder: {
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
}));

const Header = () => {
  const navigate = useNavigate();
  // const user = useSelector((state) => state.auth.user);
  // const token = useSelector((state) => state.auth.accessToken);
  const auth = useSelector((state) => state.auth);

  // const { data: notifs } = useGetNotificationsQuery();
  // const { state } = useUser();

  // const { user, token } = useUser();

  // Now you can use both 'user' and 'token' variables in your component
  // console.log("User:", user);
  // console.log("Token:", token);
  // console.log("Auth:", auth);
  // console.log("Token:", token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElC, setAnchorElC] = useState(null);
  // const { token } = useContext(AuthContext);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  // const user = storedUser ? storedUser : null;
  // const user = user0 && user0[0];
  // const [user, setUser] = useState(null);u

  useEffect(() => {
    if (user) {
      // setUser(userA);
      // console.log("UserA:", userA);
      // console.log("User:", user);
      // console.log("avatar", user.avatar);
    }
  }, [user]);
  const {
    data: notifs,
    isLoading: notificationsLoading,
    isError: isErrorNotifications,
    error: notificationsError,
  } = useGetNotificationByIdQuery({ id: user && user?.user_id });

  const handleNotificationsOpen = (event) => {
    if (window.innerWidth <= 500) {
      navigate("/notifications");
    } else setNotificationsAnchorEl(event.currentTarget);
  };

  // const { data: userA } = useGetUserByIdQuery({ id: user && user[0]?.user_id });
  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };
  const [openNotifications, setOpenNotifications] = useState(false);

  const classes = useStyles();
  const [anchorEl3, setAnchorEl3] = useState(null);
  const dispatch = useDispatch();
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuOpenC = (event) => {
    setAnchorElC(event.currentTarget);
  };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleMenuCloseC = () => {
    setAnchorElC(null);
  };
  const handleMenuClose3 = () => {
    setAnchorEl3(null);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    handleMenuClose3();
    window.location.reload();
  };

  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  // const renderMobileMenu = (
  //   // <Slide direction="right" in={openSidebar} mountOnEnter unmountOnExit>
  //   //   <div>
  //   //     <IconButton
  //   //       onClick={handleSidebarClose}
  //   //       style={{ position: "absolute", left: "10px", top: "10px" }}>
  //   //       <ArrowBack />
  //   //     </IconButton>
  //   //     <Sidebar open={openSidebar} handleSidebarClose={handleSidebarClose} />
  //   //   </div>
  //   // </Slide>
  // );
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 595);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const [openSidebar, setOpenSidebar] = useState(false); // State for sidebar

  // Function to open the sidebar
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  // Function to close the sidebar
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const [anchorElMore, setAnchorElMore] = useState(null);

  // Function to open the "More" dropdown menu
  const handleMoreMenuOpen = (event) => {
    setAnchorElMore(event.currentTarget);
  };

  // Function to close the "More" dropdown menu
  const handleMoreMenuClose = () => {
    setAnchorElMore(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        height: 45,
        backgroundColor: "rgba(232, 231, 237, 0.8)",
        backdropFilter: "blur(10px)",
      }}>
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div className={classes.logoHolder} style={{}}>
          <img
            src={images.AcLogo}
            alt="Logo"
            onClick={() => navigate("/")}
            style={{ width: "31px", height: "33px" }}
          />
          {isMidScreen ? (
            <div>
              <Hidden smDown>
                <div style={{ marginLeft: 25, marginTop: "10px" }}>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={() => navigate("/")}>
                    Home
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={handleMenuOpenC}>
                    Content
                    <ExpandMore />
                  </Button>

                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={() => navigate("/services")}>
                    Services
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={() => navigate("/about-us")}>
                    About Us
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={handleMoreMenuOpen} // Open the "More" dropdown
                  >
                    More
                    <ExpandMore />
                  </Button>
                  <Menu
                    anchorEl={anchorElC}
                    open={Boolean(anchorElC)}
                    onClose={handleMenuCloseC}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    // style={{ marginTop: 40 }}
                  >
                    <MenuItem onClick={() => navigate("/repository/0")}>
                      Products
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/repository/1")}>
                      Procedures
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/repository/2")}>
                      Institutions
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/resources")}>
                      Resources
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/logistics")}>
                      Logistics
                    </MenuItem>

                    {/* <MenuItem onClick={handleMenuCloseC}>Other</MenuItem> */}
                  </Menu>
                  <Menu
                    anchorEl={anchorElMore}
                    open={Boolean(anchorElMore)}
                    onClose={handleMoreMenuClose} // Close the "More" dropdown
                  >
                    <MenuItem onClick={() => navigate("/faq")}>FAQ</MenuItem>
                    <MenuItem onClick={() => navigate("/posts")}>
                      Posts
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/resources")}>
                      Resources
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/business-matching")}>
                      Business Matching
                    </MenuItem>
                    {/* <MenuItem onClick={() => navigate("/women-in-export")}>
                      Women in Export
                    </MenuItem> */}
                  </Menu>
                </div>
              </Hidden>

              <Hidden mdUp>
                {isSmallScreen ? (
                  <IconButton onClick={handleSidebarOpen}>
                    <MenuIcon />
                  </IconButton>
                ) : null}
                <Slide
                  direction="right"
                  in={openSidebar}
                  mountOnEnter
                  unmountOnExit>
                  <div>
                    <IconButton
                      onClick={handleSidebarClose}
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}>
                      <ArrowBack />
                    </IconButton>
                    <Sidebar
                      open={openSidebar}
                      handleSidebarClose={handleSidebarClose}
                    />
                  </div>
                </Slide>
              </Hidden>
            </div>
          ) : (
            <div>
              <Hidden smDown>
                <div style={{ marginLeft: 25 }}>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 20 }}
                    onClick={() => navigate("/")}>
                    Home
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 20 }}
                    onClick={handleMenuOpenC}>
                    Content
                    <ExpandMore />
                  </Button>

                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 20 }}
                    onClick={() => navigate("/services")}>
                    Services
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 20 }}
                    onClick={() => navigate("/about-us")}>
                    About Us
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 20 }}
                    onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </Button>
                  <Button
                    style={{ color: colorTags?.PRIMARY, marginLeft: 10 }}
                    onClick={handleMoreMenuOpen} // Open the "More" dropdown
                  >
                    More
                    <ExpandMore />
                  </Button>

                  <Menu
                    anchorEl={anchorElC}
                    open={Boolean(anchorElC)}
                    onClose={handleMenuCloseC}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    // style={{ marginTop: 40 }}
                  >
                    <MenuItem onClick={() => navigate("/repository/0")}>
                      Products
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/repository/1")}>
                      Procedures
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/repository/2")}>
                      Institutions
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/resources")}>
                      Resources
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/logistics")}>
                      Logistics
                    </MenuItem>
                    {/* <MenuItem onClick={handleMenuCloseC}>Other</MenuItem> */}
                  </Menu>
                  <Menu
                    anchorEl={anchorElMore}
                    open={Boolean(anchorElMore)}
                    onClose={handleMoreMenuClose} // Close the "More" dropdown
                  >
                    <MenuItem onClick={() => navigate("/faq")}>FAQ</MenuItem>
                    <MenuItem onClick={() => navigate("/posts")}>
                      Posts
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/export")}>
                      Export
                    </MenuItem>
                    {/* <MenuItem onClick={() => navigate("/women-in-export")}>
                      Women in Export
                    </MenuItem> */}
                    <MenuItem onClick={() => navigate("/resources")}>
                      Resources
                    </MenuItem>
                    {user ? (
                      <MenuItem onClick={() => navigate("/business-matching")}>
                        Business Matching
                      </MenuItem>
                    ) : null}
                  </Menu>
                </div>
              </Hidden>
              <Hidden mdUp>
                <IconButton onClick={handleSidebarOpen}>
                  <MenuIcon />
                </IconButton>
                <Slide
                  direction="right"
                  in={openSidebar}
                  mountOnEnter
                  unmountOnExit>
                  <div>
                    <IconButton
                      onClick={handleSidebarClose}
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}>
                      <ArrowBack />
                    </IconButton>
                    <Sidebar
                      open={openSidebar}
                      handleSidebarClose={handleSidebarClose}
                    />
                  </div>
                </Slide>
              </Hidden>
            </div>
          )}
        </div>

        {user ? (
          <Stack direction="row" spacing={2}>
            <div>
              {isSmallScreen ? (
                <IconButton
                  size="large"
                  aria-label="show notifications"
                  onClick={handleNotificationsOpen}
                  color={colorTags.PRIMARY}>
                  <Badge badgeContent={notifs && notifs?.length} color="error">
                    <NotificationsOutlinedIcon />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  size="large"
                  aria-label="show notifications"
                  // onClick={() => navigate("/notifications")}
                  onClick={handleNotificationsOpen}
                  color={colorTags.PRIMARY}>
                  <Badge badgeContent={notifs && notifs?.length} color="error">
                    <NotificationsOutlinedIcon />
                  </Badge>
                </IconButton>
              )}
              <Menu
                style={{ paddingTop: "0px" }}
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={handleNotificationsClose}>
                <Notifications />
              </Menu>
            </div>
            <div>
              <Avatar
                // src={images?.us}
                src={user && user ? BASE_FILE_URL + user?.avatar : images?.us}
                alt={user?.name ?? user?.companyName}
                className={classes.avatar}
                onClick={handleMenuOpen3}
              />
              <Menu
                anchorEl={anchorEl3}
                open={Boolean(anchorEl3)}
                onClose={handleMenuClose3}>
                {/* <Link style={{ color: "#000" }}>
                  {" "} */}
                <MenuItem
                  onClick={() => {
                    if (user && user.role_name === "Member")
                      window.open(`/profile/${user && user.user_id}`);
                    else if (
                      (user && user.role_name === "Admin") ||
                      user.role_name === "Staff" ||
                      user.role_name === "User"
                    )
                      window.open(
                        `/dashboard/profile-detail/${user && user.user_id}`
                      );
                  }}>
                  Profile
                </MenuItem>
                {/* </Link> */}
                {(user && user.role_name === "Admin") ||
                user.role_name === "Staff" ||
                user.role_name === "User" ? (
                  <MenuItem onClick={() => window.open("/dashboard", "_blank")}>
                    Dashboard
                  </MenuItem>
                ) : null}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Stack>
        ) : (
          <Button
            style={{ marginBottom: "18px", color: colorTags?.SECONDARY }}
            // color="inherit"
            onClick={() => navigate("/login")}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
