import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import { images } from "../assets/index";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logo: {
    width: 80,
    height: 80,
    animation: "$fadeInOut 2s ease-in-out infinite",
  },
  message: {
    marginTop: theme.spacing(2),
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <img src={images?.ac} alt="Logo" className={classes.logo} />
    </div>
  );
};

export default LoadingScreen;
