import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { images } from "../../../assets/index";
import {
  useGetCustomImagesQuery,
  useGetCustomImageByIdQuery,
  useCreateCustomImageRequestMutation,
  useUpdateCustomImageRequestMutation,
  useDeleteCustomImageRequestMutation,
  useUploadFileMutation,
} from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const AddImageModal = ({ open, onClose, onSave, data }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [imageC, setImageC] = useState([]);
  const [dataImage, setDataImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");

  const [submitFileUpload, { isLoading: uploadingFile, isError: uploadError }] =
    useUploadFileMutation();

  useEffect(() => {
    if (data) {
      // setProfilePicture(data?.image);
      setDataImage(data?.image);
    }
  }, [data]);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [createCustomImageRequest, { isLoading, isError, isSuccess, error }] =
    useCreateCustomImageRequestMutation();
  const [
    updateCustomImage,
    {
      isLoading: loadingUpdate,
      isError: errorUpdate,
      isSuccess: successUpdate,
      error: errorUpdateMsg,
    },
  ] = useUpdateCustomImageRequestMutation();

  const handleImageChange = (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/webp",
    ];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImageC([selected]);
      setFileError("");
      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(selected);
    } else {
      setImageC([]);
      setProfilePicture(null);
      setFileError(
        "Invalid file type. Please select a JPEG, JPG, PNG, or WEBP file."
      );
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updateData = {
      id: data?.id,
      page1: data?.page1,
      image: dataImage,
      createdAt: data?.createdAt,
      updatedAt: new Date(),
      updatedBy: "",
      // userId: user.user_id,
    };

    if (imageC.length === 0) {
      setLoading(false);
      onClose();
    }

    if (imageC.length > 0) {
      try {
        const imageResponse = await uploadImage(imageC[0]);

        updateData.image = imageResponse?.dbPaths[0]?.documentName ?? imageC;
      } catch (error) {
        toast.error("Error uploading image");
        updateData.image = dataImage;
        setLoading(false);
        return;
      }
    } else {
      updateData.image = dataImage;
    }

    await updateCustomImage(updateData)
      .then((res) => {
        if (res.status !== 500 || res.status !== 400) {
          toast.success("Image Updated Successfully");
          onClose();
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Error Updating Image");
        setLoading(false);
      });
  };

  const handleClose = () => {
    setProfilePicture(null);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add/Edit Image</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            <label htmlFor="image-upload">
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  cursor: "pointer",
                  width: "100%",
                  overflow: "hidden",
                  aspectRatio: "1920 / 540", // Set the aspect ratio
                  border: "2px solid red", // Red color border
                }}>
                {profilePicture ? (
                  <>
                    <img
                      alt="Profile"
                      src={profilePicture ?? BASE_FILE_URL + dataImage}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      alt="Insert Image Here"
                      src={""}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </>
                )}
              </div>
            </label>
            <input
              type="file"
              id="image-upload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
              key={profilePicture} // Force re-render the file input
            />
            {fileError && (
              <Typography variant="caption" color="error" mt={1}>
                {fileError}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleSave} color="primary">
          Save
        </LoadingButton>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddImageModal;
