import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    background: "transparent",
    boxShadow:
      " rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
    borderRadius: theme.spacing(2),
    // padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: 400,
    height: 400,
  },
  cardMedia: {
    width: "100%",
    height: "150px",
    // borderRadius: "50%",
    objectFit: "cover", // Ensure the image covers the entire space
  },
  content: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContainer: {
    maxHeight: 100, // Set the maximum height for the container
    overflowY: "auto", // Enable vertical scrolling if the content overflows
  },
}));

const MemberCard = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={
          data?.coverImage && data?.coverImage
            ? BASE_FILE_URL + data?.coverImage
            : images?.CorpAvatar
        }
        title="Member Image"
      />
      <CardContent className={classes.content}>
        <Typography variant="div" gutterBottom>
          {data.companyName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Sector: {data.sector}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Products:
        </Typography>
        <div className={classes.chipContainer}>
          {JSON.parse(data.products).map((product, index) => (
            <Chip
              key={index}
              label={`${product.substring(0, 20)}${
                product.length > 20 ? "..." : ""
              }`}
              variant="outlined"
              className={classes.chip}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default MemberCard;
