import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { SimpleCard } from "../../../components";
import { colorTags } from "../../../constants/variants";
import EditFlag from "./EditFlag";
import DetailFlag from "./DetailFlag";
import GridData from "../../../components/GridData";
import DeleteDialog from "../../../components/DeleteDialog";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetFlagsQuery,
  useGetFlagByIdQuery,
  useCreateFlagRequestMutation,
  useUpdateFlagRequestMutation,
  useDeleteFlagRequestMutation,
} from "../../../features/api/apiSlice";
import FlagContentModal from "../../../components/FlagContent";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const flagsDataq = [
  {
    id: 1,
    raisedBy: "User1",
    reason: "Inappropriate Content",
    description: "Flagged for containing inappropriate content.",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    createdOn: "2023-06-01",
    updatedOn: "2023-06-01",
  },
  {
    id: 2,
    raisedBy: "User2",
    reason: "Spam",
    description: "Flagged for being spammy.",
    content: "Suspendisse potenti. Sed auctor velit eget mi placerat pretium.",
    createdOn: "2023-06-02",
    updatedOn: "2023-06-02",
  },
  {
    id: 3,
    raisedBy: "User3",
    reason: "Harassment",
    description: "Flagged for harassment.",
    content: "Vestibulum efficitur mi eget vestibulum.",
    createdOn: "2023-06-03",
    updatedOn: "2023-06-03",
  },
  {
    id: 4,
    raisedBy: "User4",
    reason: "False Information",
    description: "Flagged for containing false information.",
    content: "Donec facilisis lectus in mauris tincidunt, in vehicula libero.",
    createdOn: "2023-06-04",
    updatedOn: "2023-06-04",
  },
  {
    id: 5,
    raisedBy: "User5",
    reason: "Inappropriate Content",
    description: "Flagged for containing inappropriate content.",
    content:
      "Curabitur commodo odio nec mauris eleifend, a lacinia odio fringilla.",
    createdOn: "2023-06-05",
    updatedOn: "2023-06-05",
  },
];

export default function Flags() {
  const { user } = useSelector((state) => state.auth);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const {
    data: flagsData,
    isLoading: isLoadingFlagsData,
    isError: isErrorFlagsData,
    error: errorFlagsData,
  } = useGetFlagsQuery();

  useEffect(() => {
    if (user?.role === "Staff") {
      navigate("/app/dashboard");
    }
  }, [user]);

  useEffect(() => {
    if (flagsData) {
      console.log(flagsData);
    }
  }, [flagsData]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      flex: 0.5,
    },
    {
      field: "raisedBy",
      headerName: "Raised By",
      flex: 0.9,
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "content",
      headerName: "Content",
      flex: 2,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "date",
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params?.value).toLocaleString("en-US");
      },
    },
    // {
    //   field: "updatedOn",
    //   headerName: "Updated On",
    //   type: "date",
    //   flex: 1,
    //   valueFormatter: (params) => {
    //     return new Date(params?.value).toLocaleString("en-US");
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setDetailModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteFlagRequestMutation}
              dialogLabel="Delete Flag"
              id={params?.row?.id}
              name={`Flag ${params?.row?.id}`}
            />
          </div>
        );
      },
    },
  ];

  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  return (
    <div
      style={{
        marginLeft: "5%",
        marginTop: "5%",
        width: "90%",
      }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          // backgroundColor: colorTags.PRIMARY,
          backgroundColor: "#EF6262",
        }}
        onClick={handleModalOpen}>
        Add Flag
      </Button>
      <FlagContentModal open={modalOpen} onClose={handleModalClose} />
      <EditFlag
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Flag"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
      <DetailFlag
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        title="Flag Details"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="Flags"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={flagsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
    </div>
  );
}
