import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import logoImage from "../../../assets/ac.png";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    // position: "sticky",
    // top: 0,
    // zIndex: 100,
    height: "35px",
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(1),

      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(0),

      paddingRight: theme.spacing(1),
    },
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
  },
  mainImage: {
    width: "25px",
    height: "25px",
    color: colorTags?.PRIMARY,
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),

    color: "#006738",
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
    },
  },
  mainTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#445069",
    marginLeft: "5px",
    [theme.breakpoints.down(600)]: {
      fontSize: "0.67rem",
    },
  },
  mail: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Title = ({ text }) => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Intersection Observer callback function
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className={classes.titleContainer}>
      <div className={classes.contactInfo}>
        {/* Phone icon and number */}
        <PhoneIcon className={classes.icon} />

        {isSmallScreen ? null : (
          <Typography variant="p" style={{ marginRight: "20px" }}>
            +251 115 518055
          </Typography>
        )}
        {/* Main icon and email */}
        <MailOutlineIcon className={classes.icon} />
        <Typography variant="p" className={classes.mail}>
          info@addischamber.com
        </Typography>
      </div>
      <div className={classes.contactInfo}>
        {/* <div className={classes.contactInfo}>
      <img
          src={logoImage}
          alt="logo"
          style={{ width: "30px", marginRight: "10px" }}
        />
        <Typography variant="div" style={{ color: "#445069",marginRight: "20px" }}>
          {text}
        </Typography>
</div> */}
        {/* <div className={classes.contactInfo}> */}
        <img
          src={logoImage}
          alt="logo"
          style={{}}
          className={classes.mainImage}
        />
        <Typography variant="h6" className={classes.mainTitle} style={{}}>
          {text}
        </Typography>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Title;
