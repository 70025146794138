import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Fade,
  withStyles,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { colorTags } from "../../../constants/variants";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetFAQsQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(5),
    height: "auto",
    minHeight: "300px",
    maxHeight: "500px",
    marginTop: theme.spacing(7),
    margin: "0 auto",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  carouselCard: {
    width: "70%",
    height: "90%",
    maxHeight: "200px",
    display: "flex",
    margin: "5%",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    top: 0,

    borderRadius: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
  },
  carouselCard2: {
    width: "100%", // Adjusted card width
    height: "90%",
    maxHeight: "200px",
    display: "flex",
    // margin: "5%",
    backgroundColor: "none",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    // flexDirection: "column",
    // alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    top: 65,
    left: 0,
    right: 0,
    borderRadius: "10px",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
  },
  arrowButton: {
    position: "absolute",
    top: "50%",
    width: "60px",
    height: "60px",
    transform: "translateY(-50%)",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  leftArrowButton: {
    color: colorTags?.PRIMARY,
    left: 50,
    [theme.breakpoints.down(600)]: {
      left: 5,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(900)]: {
      left: 20,
      width: "40px",
      height: "40px",
    },
  },
  rightArrowButton: {
    color: colorTags?.PRIMARY,
    right: 50,
    [theme.breakpoints.down(900)]: {
      right: 20,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(600)]: {
      right: 5,
      width: "40px",
      height: "40px",
    },
  },
  subject: {
    color: "#00A3FF",
    fontSize: "18px",
  },
  question: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down(600)]: {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  answer: {
    fontSize: "16px",
    textAlign: "justify",
    [theme.breakpoints.down(600)]: {
      fontSize: "13px",
      padding: "0px 10px",
    },
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(3),
  },
  seeAllLink: {
    position: "absolute",
    cursor: "pointer",
    color: colorTags?.PRIMARY,
    bottom: theme.spacing(2),
    right: theme.spacing(6),
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "16px",
    [theme.breakpoints.down(900)]: {
      bottom: 0,
    },
  },
}));

const getArrowSize = (theme, screenWidth) => {
  if (screenWidth <= theme.breakpoints.values.sm) {
    return "32px";
  } else if (screenWidth <= theme.breakpoints.values.md) {
    return "48px";
  } else {
    return "60px";
  }
};

const ThinChevronLeft = withStyles((theme) => ({
  root: {
    fontSize: getArrowSize(theme, window.innerWidth),
    strokeWidth: "1.5",
  },
}))(ChevronLeft);

const ThinChevronRight = withStyles((theme) => ({
  root: {
    fontSize: getArrowSize(theme, window.innerWidth),
    strokeWidth: "1.5",
  },
}))(ChevronRight);

const Carousel = ({ items }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items?.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items?.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Stack direction="column" spacing={3}>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          position: "absolute",
          left: "17%",
          fontWeight: "bold",
          color: "#00A3FF",
          marginTop: "1%",
          cursor: "pointer",
        }}
        onClick={() => navigate("./faq")}>
        FAQ
      </Typography>{" "}
      <div
        className={classes.carouselContainer}
        style={{ alignItems: "center", justifyContent: "center" }}>
        <IconButton
          className={`${classes.arrowButton} ${classes.leftArrowButton}`}
          onClick={handlePrevious}>
          <ThinChevronLeft />
        </IconButton>
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {items?.map((item, index) => (
            <Fade
              key={index}
              in={index === currentIndex}
              timeout={{ enter: 1000, exit: 1000 }}>
              <div className={classes.carouselCard2}>
                <Card
                  className={classes.carouselCard}
                  style={{
                    opacity: index === currentIndex ? 1 : 0,
                  }}>
                  <CardContent>
                    <Typography className={classes.subject}>
                      {item.subject}
                    </Typography>
                    <Typography className={classes.question}>
                      {item.question}
                    </Typography>
                    <Typography className={classes.answer}>
                      {item.answer}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Fade>
          ))}
        </div>
        <IconButton
          className={`${classes.arrowButton} ${classes.rightArrowButton}`}
          onClick={handleNext}>
          <ThinChevronRight />
        </IconButton>
        {/* "See All" link */}
        <Typography
          component="span"
          style={{ color: colorTags?.PRIMARY }}
          className={classes.seeAllLink}
          onClick={() => navigate("./faq")}>
          See All
        </Typography>
      </div>
    </Stack>
  );
};

const Events = () => {
  const { data: faqData, isLoading, isError } = useGetFAQsQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  if (faqData) {
    return (
      <div style={{ width: "100%" }}>
        <Carousel items={faqData} />
      </div>
    );
  }

  return null;
};

export default Events;
