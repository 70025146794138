import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";

import { DropzoneArea } from "material-ui-dropzone";

import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import {
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useCreateNewsRequestMutation,
  useUpdateNewsRequestMutation,
  useDeleteNewsRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";
const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "#F4EAD5",
  borderRadius: "5px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #285430  71%, #006737  100%)",

  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    width: "100%",
    minHeight: "200px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    resize: "vertical",
  },
}));

const EditNews = ({ data, open, onClose, onSubmit }) => {
  const classes = useStyless();
  const dropzoneClass = useStyles();
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [content, setContent] = useState("");
  const [cover, setCover] = useState([]);
  const [newField, setNewField] = useState("");
  const [contentType, setContentType] = useState("");
  const [link, setLink] = useState("");
  const [titleError, setTitleError] = useState("");
  const [linkError, setLinkError] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setIntro(data.intro);
      setContent(data.content);
      // setCover(data.coverImage);
      setLink(data.link);
      // setNewField(data.newField);
      setContentType(data.contentType);
    }
  }, [data]);

  const [
    updateNewsRequest,
    {
      isLoading: updateNewsLoading,
      isSuccess: updateNewsSuccess,
      isError: updateNewsError,
    },
  ] = useUpdateNewsRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage2 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const validateLink = (inputLink) => {
    // Replace with your regex validation logic
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate title
    if (!title.trim()) {
      setTitleError("Please enter a title");
      return;
    }

    // Validate link
    if (contentType === "Link" && !validateLink(link)) {
      setLinkError("Invalid link format");
      return;
    }

    // Create an object with the updated data
    const updatedData = {
      id: data.id,
      title,
      intro,
      content,
      contentType,
      link,
      // newField,
      userId: user?.user_id,
    };

    if (cover.length > 0)
      try {
        const imageResponse = await uploadImage2(cover[0]);

        updatedData.coverImage = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading file");
        updatedData.coverImage = "";
        return;
      }

    await updateNewsRequest(updatedData)
      .then((res) => {
        toast.success("News Updated Successfully");
      })
      .catch((err) => {
        toast.error("News Updated Failed");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.container}>
          <Typography
            variant="h5"
            style={{ color: colorTags.PRIMARY }}
            gutterBottom>
            Edit News Article
          </Typography>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!titleError}
            helperText={titleError}
          />
          <TextField
            className={classes.textField}
            label="Category"
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
            select
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}>
            <MenuItem value="Link">Link</MenuItem>

            <MenuItem value="Article">Article</MenuItem>
            <MenuItem value="Both">Both</MenuItem>
          </TextField>

          <TextField
            label="Intro / Short Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            margin="normal"
            value={intro}
            onChange={(e) => setIntro(e.target.value)}
          />
          <TextField
            label="Link (optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            error={!!linkError}
            helperText={linkError}
          />
          <TextareaAutosize
            className={classes.textArea}
            placeholder="Write your article here..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <DropzoneArea
            dropzoneClass={dropzoneClass.dropZone}
            style={{ marginTop: "15px" }}
            showAlerts={false}
            useChipsForPreview={true}
            filesLimit={1}
            maxFiles={1}
            maxFileSize={5000000}
            acceptedFiles={[
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/webp",
            ]}
            dropzoneText={
              "Drop or Click to Upload Cover Image (Optional, JPG, JPEG, PNG, WEBP)"
            }
            onChange={(files) => setCover(files)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}>
            <StyledButton
              onClick={handleSubmit}
              color="primary"
              loading={updateNewsLoading}>
              Save Changes
            </StyledButton>
            <Button
              onClick={onClose}
              style={{ marginLeft: "15px" }}
              variant="text"
              color="error">
              Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditNews;
