
// export const BASE_URL = "https://localhost:7255/api";
// export const BASE_URL = "https://www.aaexport.somee.com/api";
// export const BASE_FILE_URL = "https://www.aaexport.somee.com/cs/";
// export const FILE_LINK_URL = "https://www.aaexport.somee.com/api/File"
// export const FILE_DOWNLOAD_URL = "https://www.aaexport.somee.com/api/File/download?fileUrl=cs/"

// export const INTERNAL_LINK_URL = "http://localhost:3000/"
export const INTERNAL_LINK_URL = "https://exportportal.addischamber.com/"


export const BASE_URL = "https://exportportal.addischamber.com/api";
export const BASE_FILE_URL = "https://exportportal.addischamber.com/cs/";
export const FILE_LINK_URL = "https://exportportal.addischamber.com/api/File"
export const FILE_DOWNLOAD_URL = "https://exportportal.addischamber.com/api/File/download?fileUrl="



export const API_TAGS = {
    AUTH: "auth",
    PRODUCT: "product",
    DASHBOARD: "Dashboard",
DESTINATION: "Destination",
INSTITUTION: "Institution",
DOCUMENT: "Document",
NEWS: "News",
PUBLICATION: "Publication",
EVENT: "Event",
CHAT: "Chat",
PROMOTION: "Promotion",
MEMBER: "Member",
USER: "User",
USERROLE: "UserRole",
IMAGE: "Image",
LOG: "Log",
FAQ: "FAQ",
FLAG: "Flag",
TOPPRODUCT: "TopProduct",
TOPDESTINATION: "TopDestination",
NOTIFICATION: "Notification",
PROCEDURE: "Procedure",
AUTH: "Auth",
CONTACT_US: "ContactUs",
ANALYTICS: "Analytics",
LINKS:"Links"
}