import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import XIcon from "@material-ui/icons/Close";
import TwitterIcon from "@mui/icons-material/Twitter";
import { INTERNAL_LINK_URL } from "../../../constants/apiTags";
import copy from "clipboard-copy";

const ShareDialog = ({ open, onClose, news }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (value) => {
    try {
      await copy(value);
      return true;
    } catch (error) {
      console.error("Copy failed: ", error);
      return false;
    }
  };
  const handleCopyLink = async () => {
    const isCopied = await copyToClipboard(
      INTERNAL_LINK_URL + "posts/news/" + news?.id
    );
    if (isCopied) {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };
  const shareNews = (platform) => {
    // Get the news link to share
    const newsLink = INTERNAL_LINK_URL + "posts/news/" + news?.id;

    if (platform === "facebook") {
      // Handle Facebook sharing
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        newsLink
      )}`;
      window.open(facebookShareUrl, "_blank");
    } else if (platform === "twitter") {
      // Handle Twitter sharing
      const twitterShareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
        "Check out this news: " + news.title
      )}&url=${encodeURIComponent(newsLink)}`;
      window.open(twitterShareUrl, "_blank");
    } else if (platform === "telegram") {
      // Handle Telegram sharing
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        newsLink
      )}&text=${encodeURIComponent(news.title)}`;
      window.open(telegramShareUrl, "_blank");
    } else if (platform === "whatsapp") {
      // Handle WhatsApp sharing
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        "Check out this news: " + news.title + " " + newsLink
      )}`;
      window.open(whatsappShareUrl, "_blank");
    } else if (platform === "copy") {
      // Handle copying the link to the clipboard
      const tempInput = document.createElement("input");
      tempInput.value = news.link;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      // Display a confirmation message to the user
      alert("Link copied to clipboard");
    } else if (platform === "x") {
      // Handle "X" sharing (replace with your actual handling logic)
      // Example: Open a new window with the "X" share URL
      const xShareUrl = `https://example.com/share?text=${encodeURIComponent(
        "Check out this news: " + news.title
      )}&url=${encodeURIComponent(newsLink)}`;
      window.open(xShareUrl, "_blank");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle style={{ fontSize: "1rem", color: "#000" }}>
        Share News
      </DialogTitle>
      <DialogContent>
        <IconButton onClick={() => shareNews("facebook")}>
          <FacebookIcon />
        </IconButton>
        {/* <IconButton onClick={() => shareNews("x")}>
          <XIcon /> 
        </IconButton>
        <IconButton onClick={() => shareNews("twitter")}>
      
          <ShareIcon />
        </IconButton> */}
        <IconButton onClick={() => shareNews("twitter")}>
          <TwitterIcon />
          <span style={{ fontSize: "12px" }}> "now X.com"</span>
        </IconButton>
        <IconButton onClick={() => shareNews("telegram")}>
          <TelegramIcon />
        </IconButton>
        <IconButton onClick={() => shareNews("whatsapp")}>
          <WhatsAppIcon />
        </IconButton>
        {/* <IconButton onClick={() => shareNews("copy")}>
          <FileCopyIcon />
        </IconButton> */}
        <Button
          variant="text"
          startIcon={<FileCopyIcon />}
          onClick={async () => {
            const isCopied = await copyToClipboard(
              INTERNAL_LINK_URL + "posts/news/" + news?.id
            );
            if (isCopied) {
              handleCopyLink();
            }
          }}
          disableElevation
          disableFocusRipple
          //   style={{ marginLeft: "55px" }}
          disableRipple>
          {isCopied ? "Copied!" : ""}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          color="primary"
          style={{ borderRadius: "20px" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
