import React, { useState, useEffect } from "react";
import {} from "@material-ui/core";

import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";

import {
  useGetFlagsQuery,
  useGetFlagByIdQuery,
  useCreateFlagRequestMutation,
  useUpdateFlagRequestMutation,
  useDeleteFlagRequestMutation,
} from "../features/api/apiSlice";
import { toast } from "react-toastify";
import { reasonsList } from "../constants/constantOptions";

const FlagContentModal = ({ open, onClose }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [description, setDescription] = useState("");
  const currentUrl = window.location.href;
  const [createFlagRequest, { isLoading, isError, error, isSuccess }] =
    useCreateFlagRequestMutation();
  const handleReasonChange = (event) => {
    const { value } = event.target;
    setSelectedReason(value);
    if (value !== "Other") {
      setOtherReason("");
    }
  };

  const handleFlag = async (e) => {
    e.preventDefault();
    // Perform the flagging logic here with the selectedReason, otherReason, and description
    const data = {
      reason: selectedReason ?? otherReason,

      description,
      content: currentUrl,
    };
    // Create the flag request
    await createFlagRequest(data)
      .then((res) => {
        if (res.data) {
          toast.success("Flagged successfully");
        }
      })
      .catch((err) => {
        toast.error("Error flagging");
      });
    setSelectedReason("");
    setOtherReason("");
    setDescription("");

    // Close the modal after flagging
    onClose();
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <div
        style={{
          width: isSmallScreen ? "290px" : "500px",
        }}>
        <DialogTitle>Flag Content</DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: "1rem" }} fullWidth>
            <InputLabel>Reason</InputLabel>
            <Select
              label="Reason"
              value={selectedReason}
              onChange={handleReasonChange}
              fullWidth>
              {reasonsList.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedReason === "Other" && (
            <TextField
              label="Other Reason"
              placeholder="Maximum 100 characters"
              variant="outlined"
              value={otherReason}
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  setOtherReason(e.target.value);
                }
              }}
              InputProps={{
                inputProps: {
                  maxLength: 100,
                },
              }}
              fullWidth
              style={{ marginTop: "1rem" }}
            />
          )}
          <TextField
            // label="Description"
            value={description}
            variant="outlined"
            label="Details"
            placeholder="Maximum 500 characters"
            InputProps={{
              inputProps: {
                maxLength: 500,
              },
            }}
            onChange={(e) => {
              if (e.target.value.length <= 500) {
                setDescription(e.target.value);
              }
            }}
            fullWidth
            multiline
            style={{ marginTop: "1rem" }}
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleFlag} color="primary">
            Flag
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FlagContentModal;
