import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";

import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useGetDestinationsQuery,
  useGetDestinationByIdQuery,
  useCreateDestinationRequestMutation,
  useCreateLinksMutation,
  useUpdateDestinationRequestMutation,
  useDeleteProductRequestMutation,
  useUploadFileMutation,
  useUpdateTopDestinationRequestMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "50%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const AddLink = ({ open, onClose, title, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [linkTitle, setLinkTitle] = useState("");
  const [linkType, setLinkType] = useState("");

  const [linkURL, setLinkURL] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [formErrors, setFormErrors] = useState({
    destinationsName: "",
    summary: "",
    countryCode: "",
    tarifCode: "",
    tarif: "",
    tarifCode: "",
    mainCurrency: "",
  });
  const [
    createLinksRequest,
    {
      isLoading: isLoadingCreateLink,
      isError: isErrorCreateLink,
      error: createLinkError,
    },
  ] = useCreateLinksMutation();

  // SAVE NEW LINK

  const handleSave = async (e) => {
    e.preventDefault();

    const updatedLink = {
      linkTitle: linkTitle,
      linkUrl: linkURL,
      linkType,
    };

    await createLinksRequest(updatedLink)
      .then((res) => {
        if (res.data) toast.success("Link added successfully.");
      })
      .catch((err) => {
        toast.error("Error addimg link. Please try again.");
      });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />

          <div className={classes.modalBody}>
            <TextField
              label="Link Title"
              variant="outlined"
              placeholder="Enter Link Title"
              required
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={linkTitle}
              onChange={(e) => setLinkTitle(e.target.value)}
            />

            <TextField
              label="Link URL"
              required
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={linkURL}
              onChange={(e) => setLinkURL(e.target.value)}
              //   error={formErrors.countryCode}
              //   helperText={formErrors.countryCode}
            />
            <TextField
              label="Link Type"
              variant="outlined"
              required
              placeholder="Enter Link Type"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={linkType}
              onChange={(e) => setLinkType(e.target.value)}
            />
            {/* link type */}
          </div>

          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingCreateLink}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddLink;
