import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Divider, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useState } from "react";

import { Typography } from "@mui/material";
import { toast } from "react-toastify";

export default function DeleteDialog({ id, name, api, dialogLabel }) {
  const [open, setOpen] = useState(false);

  const [deleteFunc, { isLoading: isDeleteLoading }] = api();

  const onSubmitClick = async (e) => {
    e.preventDefault();

    await deleteFunc({
      id: id,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.error.data.message);
          setOpen(false);

          return;
        }
        toast.success("Successfully Deleted");
        setOpen(false);
      })
      .catch((err) => toast.error("Data is not saved"));
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(true)} title="Delete">
        <DeleteOutlineIcon color="error" />
      </IconButton>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>{dialogLabel}</DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={onSubmitClick}>
            <Grid container spacing={5} justifyContent="center">
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <Typography gutterBottom variant="body2">
                    Are you sure do you want to delete <b>{name}</b>
                  </Typography>
                  <div className="flex gap-2 justify-end pt-3">
                    <LoadingButton
                      type="submit"
                      loading={isDeleteLoading}
                      variant="contained"
                      color="error"
                      startIcon={<DeleteOutlineIcon />}>
                      Delete
                    </LoadingButton>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<CloseIcon />}
                      onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
