import React, { useState, useRef, useEffect } from "react";
import {
  Divider,
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  Stack,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import "./Chat.css";
import { images } from "../../../assets/index";
import { colorTags } from "../../../constants/variants";
import {
  useGetChatsQuery,
  useGetChatByIdQuery,
  useCreateChatRequestMutation,
  useUpdateChatRequestMutation,
  useDeleteChatRequestMutation,
  useCreateFAQRequestMutation,
} from "../../../features/api/apiSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditFAQ from "../FAQ/EditFAQ";
import { styled } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { BASE_URL } from "../../../constants/apiTags";
import { Bars } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  customMenu: {
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
  },
  dropzone: {
    minHeight: "50px !important",
    border: "1px dashed #006738",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
    zIndex: 900,
  },
  modalContent: {
    backgroundColor: "rgba(255,255,255,0.8)",

    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    zIndex: 1000,
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  modalBody: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "450px",
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));
const MessageBubble = styled(Paper)(({ sent }) => ({
  padding: "8px",
  margin: "8px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  alignSelf: sent ? "flex-end" : "flex-start",
  backgroundColor: sent ? "#1976D2" : "#FFC107",
  color: sent ? "white" : "black",
  whiteSpace: "pre-wrap", // Wrap text to the next line if needed
  maxWidth: "70%",
  wordWrap: "break-word", // Ensure long words break and wrap
  overflowWrap: "break-word", // Ensure overflow text wraps
  wordBreak: "break-word", // Handle word breaking for non-Latin languages
}));
const ChatResponse = ({ open, onSave, chat, onClose }) => {
  const chatsData = [
    {
      user: {
        id: 1,
        name: "John",
      },
      conversation: [
        {
          id: 1,
          sender: "John",
          message: "Hello, how are you?",
          time: "2023-06-01 10:00 AM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hi John, I'm doing well. How can I assist you today?",
          time: "2023-06-01 10:05 AM",
        },
        {
          id: 3,
          sender: "John",
          message: "I have a question about my account balance.",
          time: "2023-06-01 10:10 AM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "Sure, please provide me with your account details.",
          time: "2023-06-01 10:15 AM",
        },
        // ... other messages
      ],
    },
    {
      user: {
        id: 2,
        name: "Emily",
      },
      conversation: [
        {
          id: 1,
          sender: "Emily",
          message: "Hi there!",
          time: "2023-06-01 09:30 AM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hello Emily, how can I help you?",
          time: "2023-06-01 09:35 AM",
        },
        {
          id: 3,
          sender: "Emily",
          message: "I'm having trouble logging into my account.",
          time: "2023-06-01 09:40 AM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "I can help you with that. Please provide your username.",
          time: "2023-06-01 09:45 AM",
        },
        // ... other messages
      ],
    },
    {
      user: {
        id: 3,
        name: "Michael",
      },
      conversation: [
        {
          id: 1,
          sender: "Michael",
          message: "Hey, how's it going?",
          time: "2023-06-01 11:30 AM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hi Michael, I'm here to help. What can I do for you?",
          time: "2023-06-01 11:35 AM",
        },
        {
          id: 3,
          sender: "Michael",
          message: "I need assistance with a recent order.",
          time: "2023-06-01 11:40 AM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "Sure thing! Please provide your order number.",
          time: "2023-06-01 11:45 AM",
        },
        // ... other messages
      ],
    },
    {
      user: {
        id: 4,
        name: "Sarah",
      },
      conversation: [
        {
          id: 1,
          sender: "Sarah",
          message: "Hello!",
          time: "2023-06-01 02:00 PM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hi Sarah, how may I assist you today?",
          time: "2023-06-01 02:05 PM",
        },
        {
          id: 3,
          sender: "Sarah",
          message: "I have a question about your services.",
          time: "2023-06-01 02:10 PM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "Of course! Feel free to ask anything.",
          time: "2023-06-01 02:15 PM",
        },
        // ... other messages
      ],
    },
    {
      user: {
        id: 5,
        name: "Alex",
      },
      conversation: [
        {
          id: 1,
          sender: "Alex",
          message: "Hey there!",
          time: "2023-06-02 09:30 AM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hello Alex, how can I assist you?",
          time: "2023-06-02 09:35 AM",
        },
        {
          id: 3,
          sender: "Alex",
          message: "I'm having trouble accessing my account.",
          time: "2023-06-02 09:40 AM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "I'm here to help. Please provide your email.",
          time: "2023-06-02 09:45 AM",
        },
        // ... other messages
      ],
    },
    {
      user: {
        id: 6,
        name: "Olivia",
      },
      conversation: [
        {
          id: 1,
          sender: "Olivia",
          message: "Hi!",
          time: "2023-06-02 01:00 PM",
        },
        {
          id: 2,
          sender: "Admin",
          message: "Hi Olivia, how can I assist you today?",
          time: "2023-06-02 01:05 PM",
        },
        {
          id: 3,
          sender: "Olivia",
          message: "I need information about your products.",
          time: "2023-06-02 01:10 PM",
        },
        {
          id: 4,
          sender: "Admin",
          message: "Certainly! What specific products are you interested in?",
          time: "2023-06-02 01:15 PM",
        },
        // ... other messages
      ],
    },
    // ... other conversations
  ];

  // const history = useHistory();

  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [openEditFAQ, setOpenEditFAQ] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState("");
  const [answerFAQ, setAnswerFAQ] = useState("");
  const [subjectFAQ, setSubjectFAQ] = useState("");
  const [customSubject, setCustomSubject] = useState("");
  const [chatData2, setChatData2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [
    createFAQRequest,
    {
      data,
      isLoading: isLoadingCreateRequest,
      isError: isErrorCreateRequest,
      isSuccess,
      error: errorCreateRequest,
    },
  ] = useCreateFAQRequestMutation();

  const handleAddToFAQ = () => {
    if (selectedChat) {
      navigator.clipboard.writeText(selectedChat).then(() => {
        setCopiedMessage(selectedChat);
        window.location.href = "/dashboard/faq";
      });
    }
    handleClose();
  };

  // const {
  //   data: chatData,
  //   isLoading: isLoadingChat,
  //   isError: isErrorChat,
  //   error: errorChat,
  // } = useGetChatByIdQuery({ id: chat && chat });

  const [isAddToFAQFormVisible, setIsAddToFAQFormVisible] = useState(false);
  const handleToggleAddToFAQForm = () => {
    setIsAddToFAQFormVisible(!isAddToFAQFormVisible);
    handleClose(); // Close the menu
  };

  const handleClickOpenEditFAQ = () => {
    setOpenEditFAQ(true);
  };
  const handleCloseEditFAQ = () => {
    setOpenEditFAQ(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const {
  //   data: conversationData,
  //   isLoading: isLoadingConversation,
  //   isError: isErrorConversation,
  //   error: errorConversation,
  // } = useGetChatByIdQuery({ id: chat });
  const [error, setError] = useState(null);
  useEffect(() => {
    if (chat) {
      console.log(chat);
      const fetchData = () => {
        fetch(
          `${BASE_URL}/Chats/${
            // user && user?.role_name === "Member" ? user?.user_id : null
            chat && chat
          }`
        )
          .then((response) => response.json())
          .then((data) => {
            setChatData2(data[0]);
            setMessages(data[0].conversation);
            setUserName(data[0].user[0]?.name);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      };

      // Fetch data initially and then set up an interval to fetch data every second
      fetchData();
      const intervalId = setInterval(fetchData, 5000);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [chat]);
  const extractHourAndMinute = (time) => {
    const dateTimeParts = time.split("T");
    if (dateTimeParts.length === 2) {
      const timeParts = dateTimeParts[1].split(":");
      if (timeParts.length === 3) {
        const [hour, minute] = timeParts.slice(0, 2);

        // Convert to Ethiopian Time (ETC)
        const utcDateTime = new Date(
          `${dateTimeParts[0]}T${hour}:${minute}:00Z`
        );
        const etcDateTime = utcDateTime.toLocaleString("en-US", {
          timeZone: "Africa/Addis_Ababa", // ETC timezone
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        });

        return etcDateTime;
      }
    }
    return "";
  };

  const [updateChatRequest, { isLoading: isUpdatingChatRequest }] =
    useUpdateChatRequestMutation();
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleSendMessage = async (e) => {
    if (newMessage?.trim() !== "") {
      const newMessageObj = {
        // id: messages?.length + 2,
        sender: user && user?.name + " (" + user?.role_name + ")",
        message: newMessage,
        time: new Date(),
      };

      if (!messages || messages.length === 0) {
        toast.error("This chat is empty");
      } else {
        // If the conversation array is not empty, update the existing chat
        const updatedChatData = {
          ...chatData2,
          conversation: [...messages, newMessageObj],
          user: chatData2?.user,
        };

        // Call the updateChatRequest function to update the chat
        await updateChatRequest(updatedChatData)
          .then((res) => {
            if (res.data) {
              // Handle success if needed
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error sending message");
          });
      }

      setNewMessage("");
    }
  };

  const handleAddToFAQSubmit = async (e) => {
    e.preventDefault();
    // if (answerFAQ.trim() === "") {
    //   toast.error("Please enter an answer");
    //   return;
    // }
    if (subjectFAQ.trim() === "") {
      toast.error("Please select a subject");
      return;
    }
    if (subjectFAQ === "Custom" && customSubject.trim() === "") {
      toast.error("Please enter a custom subject");
      return;
    }
    const subject = subjectFAQ === "Custom" ? customSubject : subjectFAQ;
    const data = {
      question: selectedChat,
      subject,
      answer: answerFAQ,
    };
    await createFAQRequest(data)
      .then((res) => {
        if (res.data) {
          toast.success("FAQ added successfully");
          setAnswerFAQ("");
          setSubjectFAQ("");
          setCustomSubject("");
          setSelectedChat("");
          setIsAddToFAQFormVisible(false);
        }
      })
      .catch((err) => {
        toast.error("Error adding FAQ");
      });
  };

  useEffect(() => {
    console.log("messages", messages);
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // useEffect(() => {
  //   if (conversationData) {
  //     setMessages(conversationData?.conversation);

  //     setUserName(conversationData?.user[0]?.name);
  //   }
  // }, [conversationData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div
            className={classes.modalHead}
            style={{
              //   widdth: "200px",

              position: "fixed",
              height: "120px",
              paddingBottom: "15px",
            }}>
            <div
              style={{
                width: "100%",
                // backgroundColor: "rgba(255,255,255,0.8)",

                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography
                variant="h5"
                className={classes.modalTitle}
                style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
                Chat with {userName}
              </Typography>
            </div>
            <Divider style={{ backgroundColor: "#00FF00" }} />
          </div>
          <div className={classes.modalBody}>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Bars
                  height="40"
                  width="40"
                  color="#4fa94d"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div
                ref={chatContainerRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  flexGrow: 1,
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  padding: "20px",
                  maxHeight: "400px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#007bff #f5f5f5",
                  zIndex: 1000,
                }}>
                {messages &&
                  messages?.map((msg, index) => (
                    <div key={msg.id}>
                      {index === 0 || messages[index - 1].date !== msg.date ? (
                        <div
                          style={{
                            textAlign: "center",
                            marginBottom: "5px",
                            fontSize: "12px",
                            color: "#888",
                          }}>
                          {new Date(msg?.time).toLocaleDateString("en-US")}
                          {/* // ?.toLocaleTimeString()} */}
                        </div>
                      ) : null}
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent:
                              msg.sender !== "member"
                                ? "flex-end"
                                : "flex-start",
                            marginBottom: "5px",
                          }}>
                          <MessageBubble
                            key={msg.id}
                            sent={msg.sender !== "member"}
                            elevation={3}>
                            {msg.message}
                            <br />
                            <Stack
                              direction="row"
                              justifyContent={"space-between"}>
                              <Typography
                                variant="body2"
                                style={{ fontSize: 10 }}>
                                {new Date(msg?.time).toLocaleDateString(
                                  "en-US"
                                )}
                                <span style={{ marginLeft: "7px" }}>
                                  {" "}
                                  {extractHourAndMinute(msg.time)}
                                </span>
                              </Typography>
                              {msg.sender !== "member" ? null : (
                                <IconButton
                                  style={{
                                    fontSize: "10px",
                                    width: "20px",
                                    height: "20px",
                                    margnLeft: "10px",
                                  }}
                                  aria-controls="message-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    setSelectedChat(msg?.message);
                                    handleClick(e);
                                  }}>
                                  <MoreVertIcon
                                    style={{
                                      width: "17px",
                                      height: "17px",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Stack>
                          </MessageBubble>
                          {/* Menu Icon */}

                          {/* Menu Options */}
                          <Menu
                            id="message-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{
                              // boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
                              marginTop: "10px",
                            }}
                            classes={{ paper: classes.customMenu }}>
                            {/* <MenuItem onClick={handleClose}>Flag</MenuItem> */}
                            <MenuItem onClick={handleToggleAddToFAQForm}>
                              Add to FAQ
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {isAddToFAQFormVisible && (
              <div>
                {/* Add to FAQ Form */}
                <TextField
                  label="FAQ Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  maxLength={500}
                  placeholder="Maximum of 500 characters"
                  value={selectedChat}
                  onChange={(e) => setSelectedChat(e.target.value)}
                />
                <FormControl
                  color="success"
                  fullWidth
                  style={{ marginTop: "15px" }}>
                  <InputLabel id="Routes">Subject</InputLabel>
                  <Select
                    className={classes.textField}
                    label="Subject"
                    variant="outlined"
                    placeholder="Select Subject (50 characters)"
                    color="success"
                    maxLength={50}
                    value={subjectFAQ}
                    onChange={(e) => setSubjectFAQ(e.target.value)}
                    renderValue={(value) =>
                      value === "" ? "Select or type a subject" : value
                    }>
                    <MenuItem value="">Select or type a subject</MenuItem>
                    {["Custom", "Coffee", "Textile", "Ship", "Plane"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {subjectFAQ === "Custom" && (
                    <TextField
                      label="Custom Subject"
                      variant="outlined"
                      color="success"
                      maxLength={50}
                      fullWidth
                      style={{ marginTop: "5px" }}
                      value={customSubject}
                      onChange={(e) => setCustomSubject(e.target.value)}
                    />
                  )}
                </FormControl>

                <TextField
                  label="Answer"
                  variant="outlined"
                  multiline
                  maxLength={2000}
                  rows={3}
                  className={classes.textField}
                  fullWidth
                  style={{ marginTop: "15px" }}
                  value={answerFAQ}
                  onChange={(e) => setAnswerFAQ(e.target.value)}
                />
                <Stack
                  direction="row"
                  justifyContent={"flex-end"}
                  style={{ marginTop: "10px" }}>
                  <LoadingButton
                    variant="outlined"
                    loading={isLoadingCreateRequest}
                    color="primary"
                    onClick={handleAddToFAQSubmit}>
                    Add to FAQ
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: "10px" }}
                    onClick={handleToggleAddToFAQForm}>
                    Cancel
                  </Button>
                </Stack>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "20px",
                paddingTop: "15px",
              }}>
              {/* <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                style={{
                  flexGrow: "1",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              /> */}

              <input
                type="text"
                maxLength={2000}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                placeholder="Type your message..."
                style={{
                  flexGrow: "1",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />

              <LoadingButton
                loading={isUpdatingChatRequest}
                onClick={handleSendMessage}
                style={{
                  marginLeft: "10px",
                  // padding: "10px 10px",
                  // backgroundColor: "#007bff",
                  // color: "#fff",
                  // border: "none",
                  // borderRadius: "50%",
                  // cursor: "pointer",
                }}>
                <SendIcon />
              </LoadingButton>
            </div>
            {/* <EditFAQ
              open={openEditFAQ}
              onClose={() => setOpenEditFAQ(false)}
              title="Add FAQ"
              content="This is the modal content."
              actionText="Close"
              data={{ question: selectedChat, subject: "", answer: "" }}
            /> */}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ChatResponse;
