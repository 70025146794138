import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { useStyles } from "../../../../App";
import {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductRequestMutation,
  useUpdateProductRequestMutation,
  useDeleteProductRequestMutation,
  useUploadFileMutation,
  useGetDestinationsQuery,
} from "../../../../features/api/apiSlice";
import LoadingButton from "@mui/lab/LoadingButton";

import { toast } from "react-toastify";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";
const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    minHeight: "50px !important",
    border: "1px dashed #006738",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "60%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const EditProduct = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [hsCode, setHsCode] = useState("");
  const [destinations, setDestinations] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [cover, setCover] = useState([]);
  const [submitFileUpload] = useUploadFileMutation();
  const { data: DestinationsData } = useGetDestinationsQuery();
  const [formErrors, setFormErrors] = useState({
    productName: "",
    description: "",
    type: "",
    hsCode: "",
  });
  // Update Product API
  const [updateProductRequest, { isLoading: isUpdateProductLoading }] =
    useUpdateProductRequestMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };
  useEffect(() => {
    if (data) {
      console.log(data);
      setProductName(data?.productName);
      setHsCode(data?.hsCode);
      setType(data?.type);
      setDescription(data?.description);
      setRoutes(JSON.parse(data?.routes));
      // setRoutes(data?.routes);

      setDestinations(JSON.parse(data?.destinations));
      // setDestinations(data?.destinations);
    }
  }, [data]);

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleSave = async (e) => {
    const errors = {};

    if (!productName) {
      errors.productName = "Product Name is required";
    } else if (productName.length > 50) {
      errors.productName = "Product Name cannot exceed 50 characters";
    }

    if (!hsCode) {
      errors.hsCode = "HS Code is required";
    } else if (!/^\d+$/.test(hsCode)) {
      errors.hsCode = "HS Code must contain only numbers";
    } else if (hsCode.length > 10) {
      errors.hsCode = "HS Code cannot exceed 10 characters";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const updateData = {
      id: data.id,
      description,
      type,
      routes,

      productName,
      hsCode,
      destinations,
      userId: user?.user_id,
    };
    if (cover.length > 0) {
      try {
        const imageResponse = await uploadImage(cover[0]);

        updateData.coverImage = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading image");
        updateData.coverImage = "";
        return;
      }
    } else {
      updateData.coverImage = "";
    }

    await updateProductRequest(updateData)
      .then((res) => {
        toast.success("Data is saved");
      })
      .catch((err) => toast.error("Error saving data"));
    setProductName("");
    setHsCode("");
    setType("");
    setDescription("");
    setRoutes([]);
    setDestinations([]);
    setCover([]);

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Product Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              error={!!formErrors.productName}
              helperText={formErrors.productName}
            />
            <TextField
              label="Description"
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              label="HS Code"
              variant="outlined"
              fullWidth
              required
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={hsCode}
              onChange={(e) => setHsCode(e.target.value)}
              error={!!formErrors.hsCode}
              helperText={formErrors.hsCode}
            />
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Type" style={{}}>
                Type
              </InputLabel>
              <Select
                className={classes.textField}
                label="Type"
                variant="outlined"
                value={type}
                onChange={(e) => setType(e.target.value)}>
                {["Temporary", "Perishable", "Non-Perishable"].map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Routes" style={{}}>
                Routes
              </InputLabel>
              <Select
                className={classes.textField}
                label="Routes"
                variant="outlined"
                multiple
                value={routes}
                onChange={(e) => setRoutes(e.target.value)}>
                {["Road", "Train", "Ship", "Plane"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Routes" style={{}}>
                Destinations
              </InputLabel>
              <Select
                className={classes.textField}
                label="Routes"
                variant="outlined"
                multiple
                value={destinations}
                onChange={(e) => setDestinations(e.target.value)}>
                {DestinationsData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.destinationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ height: "200px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={"Drop or Click to Upload Attachment"}
                onChange={(files) => setCover(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  variant="contained"
                  loading={isUpdateProductLoading}
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditProduct;
