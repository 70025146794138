import react, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";
import { SimpleCard } from "../../../components";
import {
  colorTags,
  primaryColor,
  secondaryColor,
  accentColor,
} from "../../../constants/variants";
import AddFAQ from "./AddFAQ";
import EditFAQ from "./EditFAQ";
import DetailFAQ from "./DetailFAQ";
import GridData from "../../../components/GridData";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../../components/DeleteDialog";

import {
  useGetFAQsQuery,
  useGetFAQByIdQuery,
  useCreateFAQRequestMutation,
  useUpdateFAQRequestMutation,
  useDeleteFAQRequestMutation,
} from "../../../features/api/apiSlice";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const faqDataq = [
  {
    id: 1,
    question: "What is Lorem Ipsum?",
    subject: "General",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    submittedBy: "User1",
    submittedOn: "2023-06-01",
  },
  {
    id: 2,
    question: "Why do we use it?",
    subject: "General",
    answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    submittedBy: "User2",
    submittedOn: "2023-06-02",
  },
  {
    id: 3,
    question: "Where does it come from?",
    subject: "General",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    submittedBy: "User3",
    submittedOn: "2023-06-03",
  },
  {
    id: 4,
    question: "Where can I get some?",
    subject: "General",
    answer:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    submittedBy: "User4",
    submittedOn: "2023-06-04",
  },
  {
    id: 5,
    question: "What is Lorem Ipsum?",
    subject: "General",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    submittedBy: "User5",
    submittedOn: "2023-06-05",
  },
  {
    id: 6,
    question: "Why do we use it?",
    subject: "General",
    answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    submittedBy: "User6",
    submittedOn: "2023-06-06",
  },
  {
    id: 7,
    question: "Where does it come from?",
    subject: "General",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    submittedBy: "User7",
    submittedOn: "2023-06-07",
  },
  {
    id: 8,
    question: "Where can I get some?",
    subject: "General",
    answer:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    submittedBy: "User8",
    submittedOn: "2023-06-08",
  },
  {
    id: 9,
    question: "What is Lorem Ipsum?",
    subject: "General",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    submittedBy: "User9",
    submittedOn: "2023-06-09",
  },
  {
    id: 10,
    question: "Why do we use it?",
    subject: "General",
    answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    submittedBy: "User10",
    submittedOn: "2023-06-10",
  },
];

export default function ProductsPage() {
  const { user } = useSelector((state) => state.auth);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const {
    data: faqData,
    isLoading: isLoadingFaqData,
    isError: isErrorFaqData,
  } = useGetFAQsQuery();

  useEffect(() => {
    if (faqData) {
      console.log(faqData);
    }
  }, [isErrorFaqData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
    },
    {
      field: "answer",
      headerName: "answer",
      flex: 2,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      flex: 1,
    },
    {
      field: "submittedOn",
      headerName: "Submitted On",
      type: "date",
      flex: 1,

      valueFormatter: (params) => {
        return new Date(params?.value).toLocaleString("en-US");
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setDetailModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                console.log(params?.row);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            {/* {user?.userAccount?.userRole?.productPermissions
              ?.canUpdateProducts && (
              <UpdateProductDialog productData={params?.row} /> 
            )}
            {user?.userAccount?.userRole?.productPermissions
              ?.canDeleteProducts && (
              <DeleteDialog
                api={useDeleteProductMutation} 
                dialogLabel="Delete Product"
                id={params?.row?.id}
                name={params?.row?.productName}
              />
            )} */}
            <DeleteDialog
              api={useDeleteFAQRequestMutation}
              dialogLabel="Delete FAQ Item"
              id={params?.row?.id}
              name={params?.row?.question}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);

  return (
    <div
      style={{
        // marginRight: "10%",
        marginLeft: "5%",
        marginTop: "5%",
        width: "90%  ",
      }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",

          backgroundColor: colorTags.PRIMARY,
        }}
        onClick={handleModalOpen}>
        Add FAQ
      </Button>
      <AddFAQ
        open={modalOpen}
        onClose={handleModalClose}
        title="Add FAQ"
        content="This is the modal content."
        actionText="Close"
      />
      <EditFAQ
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit FAQ"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
      <DetailFAQ
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        title="FAQ Details"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="FAQ"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={faqData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
    </div>
  );
}
