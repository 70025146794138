import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { makeStyles } from "@material-ui/core";
import { images } from "../../../assets/index";
import DeleteDialog from "./DeleteImage";
import AddImage from "./AddImage";
import {
  useGetCustomImagesQuery,
  useGetCustomImageByIdQuery,
  useCreateCustomImageRequestMutation,
  useUpdateCustomImageRequestMutation,
  useDeleteCustomImageRequestMutation,
  useUploadFileMutation,
  useGetFileLinkQuery,
  useDownloadFileQuery,
} from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  gridItem: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "235px",
    padding: theme.spacing(0),
  },
  addButton: {
    // position: "absolute",
    // bottom: theme.spacing(2),
    // left: theme.spacing(1),
    width: "40px",
    height: "40px",
  },
  deleteButton: {
    // position: "absolute",
    // bottom: theme.spacing(2),
    // right: theme.spacing(1),
    width: "40px",
    height: "40px",
  },
  cardTitle: {
    width: "100%",
    position: "absolute",
    bottom: theme.spacing(0),
    backgroundColor: "white",
    fontSize: "1.5rem",
    left: theme.spacing(1),
    color: "black",
    paddingLeft: theme.spacing(1),
  },

  buttonsContainer: {
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: "15px",
    position: "absolute",
    display: "flex",
    // flexDirection: "column",
    gap: theme.spacing(3),
    top: theme.spacing(2.3),
    left: theme.spacing(2.3),
  },
}));

const ImageGallery = () => {
  const classes = useStyles();
  const pages = [
    { page1: "Home", image: images.MainImage2 },
    { page1: "news", image: images.Corp },
    { page1: "events", image: images.Corp },
    // { page: "events", image: "events" },
    { page1: "logistics", image: images.Logistics3 },
    { page1: "export", image: images.Logistics3 },
    { page1: "posts", image: images.Content },
    { page1: "services", image: images.Services },
  ];

  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const {
    data: imagesData,
    isLoading: isLoadingImagesData,
    isError: isErrorImagesData,
    error: errorImagesData,
  } = useGetCustomImagesQuery();

  return (
    <div style={{ padding: "5%" }}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {imagesData?.length > 0
            ? imagesData?.map((page, index) => (
                <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
                  <Card key={index} className={classes.gridItem}>
                    <CardContent>
                      <div
                        style={{
                          // backgroundImage: `url(${
                          //   BASE_FILE_URL + "/" + page?.image
                          // })`,
                          backgroundImage: page?.image
                            ? `url(${BASE_FILE_URL + "/" + page?.image})`
                            : null,

                          backgroundSize: "cover", // You can adjust these styles
                          backgroundPosition: "center center", // to fit your needs
                          minHeight: "200px",
                          width: "100%",
                        }}>
                        <div className={classes.cardHeader}>
                          <div className={classes.cardTitle}>
                            <Typography
                              variant="div"
                              fontFamily="sans-serif"
                              style={{ textTransform: "capitalize" }}>
                              {page.page1}
                            </Typography>
                          </div>
                          <div className={classes.buttonsContainer}>
                            <IconButton
                              className={classes.addButton}
                              color="primary"
                              style={{
                                backgroundColor: "white",
                                // width: "50px",
                                // height: "50px",
                              }}
                              onClick={() => {
                                setProfilePicturePreview(page);
                                handleDialogOpen();
                              }}>
                              <AddCircleOutlineIcon fontSize="medium" />
                            </IconButton>
                            <IconButton
                              className={classes.deleteButton}
                              color="error"
                              style={{ backgroundColor: "white" }}
                              // onClick={() => handleDeleteImage(page)}
                            >
                              <DeleteDialog
                                api={useUpdateCustomImageRequestMutation}
                                dialogLabel="Delete Current Image"
                                id={page.id}
                                name={`${page.page1}`}
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            : pages?.map((page, index) => (
                <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
                  <Card key={index} className={classes.gridItem}>
                    <CardContent>
                      <div
                        style={{
                          // backgroundImage: `url(${
                          //   BASE_FILE_URL + "/" + page?.image
                          // })`,
                          backgroundImage: page?.image
                            ? `url(${BASE_FILE_URL + "/" + page?.image})`
                            : null,

                          backgroundSize: "cover", // You can adjust these styles
                          backgroundPosition: "center center", // to fit your needs
                          minHeight: "200px",
                          width: "100%",
                        }}>
                        <div className={classes.cardHeader}>
                          <div className={classes.cardTitle}>
                            <Typography
                              variant="div"
                              fontFamily="sans-serif"
                              style={{ textTransform: "capitalize" }}>
                              {page.page1}
                            </Typography>
                          </div>
                          <div className={classes.buttonsContainer}>
                            <IconButton
                              className={classes.addButton}
                              color="primary"
                              style={{
                                backgroundColor: "white",
                                // width: "50px",
                                // height: "50px",
                              }}
                              onClick={() => {
                                setProfilePicturePreview(page);
                                handleDialogOpen();
                              }}>
                              <AddCircleOutlineIcon fontSize="medium" />
                            </IconButton>
                            <IconButton
                              className={classes.deleteButton}
                              color="error"
                              style={{ backgroundColor: "white" }}
                              // onClick={() => handleDeleteImage(page)}
                            >
                              <DeleteDialog
                                api={useUpdateCustomImageRequestMutation}
                                dialogLabel="Delete Current Image"
                                id={page.id}
                                name={`${page.page1}`}
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
        </Grid>

        <AddImage
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSave={() => setDialogOpen(false)}
          data={profilePicturePreview}
        />
      </Container>
    </div>
  );
};

export default ImageGallery;
