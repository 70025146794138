import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import {
  useGetNewsQuery,
  useGetPublicationsQuery,
  useGetEventsQuery,
  useGetDocumentsQuery,
  useGetProductsQuery,
} from "../../../features/api/apiSlice";

export default function PolarAreaChart() {
  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetNewsQuery();

  const {
    data: publicationsData,
    isLoading: publicationsLoading,
    isError: publicationsError,
  } = useGetPublicationsQuery();

  const {
    data: eventsData,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetEventsQuery();

  const {
    data: productsData,
    isLoading: productsLoading,
    isError: productsError,
  } = useGetProductsQuery();

  const {
    data: documentsData,
    isLoading: documentsLoading,
    isError: documentsError,
  } = useGetDocumentsQuery();

  const chartRef = useRef(null);

  useEffect(() => {
    // Organize the data
    const categories = ["News", "Publications", "Events", "Products", "Documents"];
    const dataValues = [
      newsData?.length || 0,
      publicationsData?.length || 0,
      eventsData?.length || 0,
      productsData?.length || 0,
      documentsData?.length || 0,
    ];

    const data = {
      labels: categories,
      datasets: [
        {
          label: "Count",
          data: dataValues,
          backgroundColor: [
            "rgba(54, 162, 235, 0.5)",
            "rgba(255, 206, 86, 0.5)",
            "rgba(75, 192, 192, 0.5)",
            "rgba(153, 102, 255, 0.5)",
            "rgba(255, 159, 64, 0.5)",
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    // Chart configuration
    const config = {
      type: "polarArea",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Data Distribution",
          },
        },
      },
    };

    // Destroy existing chart
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Get chart canvas and create chart
    const ctx = document.getElementById("polarAreaChart");
    chartRef.current = new Chart(ctx, config);
  }, [newsData, publicationsData, eventsData, productsData, documentsData]);

  return (
    <div>
      <canvas id="polarAreaChart" width="350" height="350"></canvas>
    </div>
  );
}
