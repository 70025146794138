import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Slide,
  Box,
  Typography,
  IconButton,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { images } from "../../../assets/index";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    padding: theme.spacing(10),
    paddingTop: theme.spacing(0),
    justifyContent: "center",

    width: "100%",
    // height: "100vh",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  slidingPanel: {
    position: "fixed",
    top: 25,
    right: 0,
    width: "50%",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    overflowY: "auto",
    transition: "transform 0.3s ease",
    transform: "translateX(100%)",
  },
  slidingPanelVisible: {
    transform: "translateX(50%)",
  },
  slidingPanelContent: {
    padding: theme.spacing(2),
  },
  coverImage: {
    width: "100%",
    height: "30vh",
    objectFit: "contain",
  },
}));

const DestinationsPage = () => {
  const classes = useStyles();
  const [openIndex, setOpenIndex] = useState(null);

  const handleItemClick = (index) => {
    setOpenIndex((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };

  const destinationData = [
    {
      name: "Destination 1",
      summary: "Summary for Destination 1...",
      tarifCode: "12345",
      countryCode: "ABC",
      tarif: "Sample Tarif",
      tradePortal: true,
      tradeLinks: ["Link 1", "Link 2"],
      mainCurrency: "USD",
      continent: "North America",
      coverImage: images.Eu2, 
    },
    {
      name: "Destination 2",
      summary: "Summary for Destination 2...",
      tarifCode: "67890",
      countryCode: "XYZ",
      tarif: "Sample Tarif 2",
      tradePortal: false,
      tradeLinks: ["Link 3", "Link 4"],
      mainCurrency: "EUR",
      continent: "Europe",
      coverImage: images.Asia2,
    },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <div style={{ flex: 1 }}>
        <div className={classes.container}>
          <Typography
            variant="h4"
            style={{ color: colorTags.PRIMARY }}
            gutterBottom>
            Destinations
          </Typography>
          <List className={classes.list}>
            {destinationData.map((item, index) => (
              <>
                <ListItem
                  button
                  onClick={() => handleItemClick(index)}
                  key={index}>
                  <ListItemText primary={item.name} />
                  {openIndex === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
          {destinationData.map((item, index) => (
            <Slide
              key={index}
              direction="left"
              in={openIndex === index}
              mountOnEnter
              unmountOnExit>
              <Box
                className={`${classes.slidingPanel} ${
                  openIndex === index ? classes.slidingPanelVisible : ""
                }`}>
                <div className={classes.slidingPanelContent}>
                  <IconButton
                    className={classes.hideButton}
                    onClick={() => setOpenIndex(null)}>
                    <ArrowForwardIosIcon />
                  </IconButton>

                  <Typography
                    variant="h6"
                    style={{ color: colorTags.SECONDARY, marginTop: "2%" }}>
                    {item.name}
                  </Typography>
                  <img
                    src={item.coverImage}
                    alt={item.name}
                    className={classes.coverImage}
                  />
                  <Typography style={{ marginTop: "2%" }}>
                    {item.summary}
                  </Typography>
                  <Typography variant="h6" style={{ marginTop: "2%" }}>
                    Tarif Code: {item.tarifCode}
                  </Typography>
                  <Typography variant="h6">
                    Country Code: {item.countryCode}
                  </Typography>
                  <Typography variant="h6">Tarif: {item.tarif}</Typography>
                  <Typography variant="h6">
                    Trade Portal: {item.tradePortal ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="h6">Other Verfied Links:</Typography>
                  <List>
                    {item.tradeLinks.map((link, linkIndex) => (
                      <ListItem key={linkIndex} className={classes.nested}>
                        <ListItemText primary={link} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="h6">
                    Main Currency: {item.mainCurrency}
                  </Typography>
                  <Typography variant="h6">
                    Continent: {item.continent}
                  </Typography>
                </div>
              </Box>
            </Slide>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DestinationsPage;
