import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../constants/variants";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "medium",
    color: colorTags.PRIMARY,
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DetailFAQ = ({ open, onClose, title, data }) => {
  const classes = useStyless();
  const [question, setQuestion] = useState(data?.question);
  const [answer, setAnswer] = useState(data?.answer);
  const [subject, setSubject] = useState(data?.subject);

  useEffect(() => {
    if (data) {
      setQuestion(data?.question);
      setAnswer(data?.answer);
      setSubject(data?.subject);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography variant="h5" className={classes.modalTitle}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <Typography variant="body1" style={{ marginTop: "15px" }}>
              Question: {question}
            </Typography>

            <Typography variant="body1" style={{ marginTop: "15px" }}>
              Subject: {subject}
            </Typography>

            <Typography variant="body1" style={{ marginTop: "15px" }}>
              Answer: {answer}
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <Button
                  variant="outlined"
                  style={{ fontWeight: "bold", color: colorTags.PRIMARY }}
                  onClick={onClose}>
                  Close
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DetailFAQ;
