import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../../constants/variants";
import { useStyles } from "../../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useCreateDocumentRequestMutation,
  useUpdateDocumentRequestMutation,
  useDeleteDocumentRequestMutation,
  useUploadFileMutation,
} from "../../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../../constants/apiTags";
const useStyless = makeStyles((theme) => ({
  // ... (your other styles)
}));

const EditForm = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [docTitle, setDocTitle] = useState("");
  const [description, setDescription] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [category, setCategory] = useState("");
  const [document, setDocument] = useState([]);
  const [cover, setCover] = useState([]);
  const [form, setForm] = useState([]);
  const [coverImage, setCoverImage] = useState("");
  const [titleError, setTitleError] = useState("");
  const [fileError, setFileError] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    if (data) {
      setDocTitle(data.title || "");
      setDescription(data.description || "");
      setDocumentName(data.documentName || "");
      setCoverImage(data.coverImage || "");
      setCategory(data.category || "");
    }
  }, [data]);

  const [
    updateDocumentRequest,
    {
      isLoading: isLoadingUpdateRequest,
      isError: isErrorUpdateRequest,
      error: errorUpdateRequest,
    },
  ] = useUpdateDocumentRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    setTitleError("");
    setFileError("");

    const updateData = {
      id: data.id,
      title: docTitle,
      description: description,
      category: category,
      documentName: documentName,
      userId: user?.user_id,
    };

    // Validate document title
    if (docTitle.trim() === "") {
      setTitleError("Document title is required");
      return;
    }

    // Validate document file
    if (form.length > 0) {
      // Validate document size
      const fileSizeLimit = 5000000; // 5 MB
      if (form[0].size > fileSizeLimit) {
        setFileError("Document size exceeds the limit (5 MB)");
        return;
      }

      // Validate document type (accept only PDF files)
      const allowedFileTypes = ["application/pdf"];
      if (!allowedFileTypes.includes(form[0].type)) {
        setFileError("Only PDF files are allowed");
        return;
      }

      try {
        const imageResponse = await uploadImage(form[0]);

        data.documentName =
          imageResponse?.dbPaths[0]?.documentName ?? documentName;
      } catch (error) {
        toast.error("Error uploading file");
        data.documentName = documentName;
        return;
      }
    }

    if (cover.length > 0) {
      try {
        const imageResponse = await uploadImage(cover[0]);

        updateData.coverImage =
          imageResponse?.dbPaths[0]?.documentName ?? coverImage;
      } catch (error) {
        toast.error("Error uploading image");
        updateData.coverImage = coverImage;
        return;
      }
    } else {
      updateData.coverImage = coverImage;
    }

    await updateDocumentRequest(updateData)
      .then((res) => {
        toast.success("Document Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error Updating Document");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Form Title"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
            />
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={"Drop or Click to Upload Document"}
                onChange={(files) => {
                  setForm(files);
                  setFileError(""); // Clear file error when new file is selected
                }}
              />
              {fileError && (
                <Typography variant="body2" color="error">
                  {fileError}
                </Typography>
              )}
            </div>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={"Drop or Click to Upload Cover Image(Optional)"}
                onChange={(files) => setCover(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingUpdateRequest}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditForm;
