import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@mui/material";

import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import AddUser from "./AddUser";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../../../components/DeleteDialog";

import {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,
} from "../../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../../constants/apiTags";

import EditUser from "./EditUser";
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const usersDataq = [
  {
    id: 1,
    name: "John Doe",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-01",
  },
  {
    id: 2,
    name: "Jane Smith",
    avatar: images.Avatar,
    role: "Admin",
    isAdmin: true,
    createdOn: "2023-06-02",
  },
  {
    id: 3,
    name: "Alice Johnson",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-03",
  },
  {
    id: 4,
    name: "Robert Anderson",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-04",
  },
  {
    id: 5,
    name: "William Brown",
    avatar: images.Avatar,
    role: "Admin",
    isAdmin: true,
    createdOn: "2023-06-05",
  },
  {
    id: 6,
    name: "Linda Garcia",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-06",
  },
  {
    id: 7,
    name: "Michael Martinez",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-07",
  },
  {
    id: 8,
    name: "Jennifer Thompson",
    avatar: images.Avatar,
    role: "Admin",
    isAdmin: true,
    createdOn: "2023-06-08",
  },
  {
    id: 9,
    name: "Christopher Young",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-09",
  },
  {
    id: 10,
    name: "Jessica Hernandez",
    avatar: images.Avatar,
    role: "User",
    isAdmin: false,
    createdOn: "2023-06-10",
  },
];

export default function UsersPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: usersData,
    isLoading: isLoadingUsersData,
    isError: isErrorUsersData,
    error: errorUsersData,
  } = useGetUsersQuery();

  useEffect(() => {
    if (usersData) console.log(usersData);
  }, [usersData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "avatar",
      headerName: "Avatar",
      flex: 1,
      renderCell: (params) => (
        <img
          src={BASE_FILE_URL + params.row.avatar}
          alt={params.row.name}
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      flex: 1,
      renderCell: (params) => <div>{params.row.isAdmin ? "Yes" : "No"}</div>,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                console.log(params);
                // navigate(`/users/${params.row.id}`);
                navigate(`/dashboard/profile-detail/${params.row.id}`);
              }}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                // console.log(params?.row);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteUserRequestMutation}
              dialogLabel="Remove User"
              id={params?.row?.id}
              name={`User ${params?.row?.id}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: primaryColor,
          }}
          onClick={handleModalOpen}>
          Add User
        </Button>
      )}

      <AddUser
        open={modalOpen}
        onClose={handleModalClose}
        title="Add User"
        content="This is the modal content."
        actionText="Close"
      />

      <EditUser
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Status"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="Users"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={usersData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
}
