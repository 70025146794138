import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Divider, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";

export default function UpdateDialog({ id, name, api, dialogLabel }) {
  const [open, setOpen] = useState(false);

  const [updateFunc, { isLoading: isUpdateLoading }] = api();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const onSubmitClick = async (e) => {
    e.preventDefault();

    // Assuming you have a function like updateFunc that updates the data
    await updateFunc({
      id: 5,
      page1: `${name}`,
      image: "",
      updatedBy: `${user?.name}(id: ${user?.user_id})`,
      userId: user?.user_id,
    })
      .then((res) => {
        if (res.error) {
          toast.error("Error updating data");
          setOpen(false);
          return;
        }
        toast.success("Updated Successfully");
        setOpen(false);
      })
      .catch((err) => toast.error("Data is not updated"));
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(true)} title="Edit">
        <EditIcon color="primary" />
      </IconButton>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>{dialogLabel}</DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={onSubmitClick}>
            <Grid container spacing={5} justifyContent="center">
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <Typography gutterBottom variant="body2">
                    Delete the current <b>{name}</b> page cover image?
                  </Typography>
                  {/* Include your form fields for updating data here */}
                  <div className="flex gap-2 justify-end pt-3">
                    <LoadingButton
                      type="submit"
                      loading={isUpdateLoading}
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}>
                      Delete
                    </LoadingButton>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
