import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItem,
} from "@material-ui/core";
import {
  Chat as ChatIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { colorTags } from "../constants/variants";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";

// import ChatMini from "./ChatMini";
// import ChatAvatar from "./ChatAvatar";
const useStyles = makeStyles((theme) => ({
  chatContainer: {
    position: "fixed",
    bottom: theme.spacing(3), // Adjust the vertical position as needed
    left: theme.spacing(3),
    zIndex: 9999,
  },

  expandIcon: {
    marginLeft: "auto",
    // right: theme.spacing(1),
  },
  backToTop: {
    // position: "fixed",
    // bottom: theme.spacing(2),
    // left: theme.spacing(2),
    backgroundColor: "#fff", // Dark green background color
    color: "#006738",
    "&:hover": {
      backgroundColor: "#006738", // Change the background color on hover
      color: "#fff", // Change the text color on hover
    },
  },
}));

const BackToTop = () => {
  const classes = useStyles();
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 200) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.chatContainer}>
      {showBackToTop && (
        <div>
          <Fab
            sx={{
              backgroundColor: "#fff",
              color: "#006738",
              "&:hover": {
                backgroundColor: "#006738",
                color: "#fff",
              },
            }}
            size="small"
            className={classes.backToTop}
            onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      )}
    </div>
  );
};

export default BackToTop;
