import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Pagination, Card, CardContent, IconButton } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";
import { makeStyles } from "@material-ui/core/styles";
import EventsMini from "../NewsBlogs/EventsMini";
import { images } from "../../../assets/index";

import { useLocation, useParams } from "react-router-dom";
import { Link as MuiLink, Link } from "@mui/material";

import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ShareIcon from "@material-ui/icons/Share";
import {
  useGetNewsQuery,
  useGetPublicationsQuery,
  usePostAnalyticsMutation,
} from "../../../features/api/apiSlice";
import {
  FILE_LINK_URL,
  BASE_FILE_URL,
  INTERNAL_LINK_URL,
  FILE_DOWNLOAD_URL,
} from "../../../constants/apiTags";
import ShareDialog from "./Share";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  topCard: {
    position: "relative",
    width: "100%",
    height: 200,
    backgroundImage: `url(${images?.Content})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "justify",
    paddingTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down(600)]: {
      fontSize: 40,
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
    },
  },
  postsContainer: {
    padding: theme.spacing(8),
    backgroundColor: "rgba(255,255,255,0.6)",
    minHeight: "100vh",
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
    },
  },
  sectionContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(9),
  },
  iconButton: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    // bottom: "55px",
    color: colorTags.PRIMARY,

    borderRadius: "50%",
    left: "20%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.5)`,

      color: colorTags.SECONDARY,
    },
  },
  iconButton2: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    color: colorTags.PRIMARY,
    // bottom: "55px",
    borderRadius: "50%",
    "&:hover": {
      // backgroundColor: "rgba(0,0,0,2)",
      backgroundColor: `rgba(${colorTags.SECONDARY_RGBA},0.5)`,

      color: colorTags.SECONDARY,
    },
    right: "10%",
    // transform: "translateX(-50%)",
  },
  sectionTitle: {
    // fontSize: "2rem",
    // // fontWeight: "bold",
    // marginBottom: theme.spacing(3),
    // color: colorTags.PRIMARY,
    alignSelf: "flex-start",
    marginBottom: theme.spacing(5),
    textAlign: "left",
    // marginLeft: theme.spacing(42),
    fontWeight: "medium",
    fontSize: "35px",
    color: colorTags.PRIMARY,
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  card: {
    width: "calc(50% - 20px)",
    maxWidth: "200px",
    marginBottom: theme.spacing(3),
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 60px 40px -7px",
    borderRadius: "10px",
    // backgroundColor: "rgba(255,255,255,0.6)",
    backgroundColor: "transparent",
    // backgroundColor: "red",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  cardTitles: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    textAlign: "left",
    // color: colorTags.PRIMARY,
    color: "#000",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  cardDates: {
    fontSize: "0.7rem",
    fontWeight: "bold",
    textAlign: "flex-start",
    // paddingLeft: theme.spacing(2),
    color: colorTags.ACCENT,
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
}));

// const itemsPerPage = 5;

const itemsPerPage = 5;

const PostsPage = () => {
  const classes = useStyles();
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  const [visit, setVisit] = useState(false);
  const [share, setShare] = useState(false);
  const [download, setDownload] = useState(false);

  const [postAnalyticsData, { isLoading: isAnalyticsLoading }] =
    usePostAnalyticsMutation();
  useEffect(() => {
    if (share) {
      const data = {
        action: "share",
        value: "1",
        content: "News",
      };
      postAnalyticsData(data).then((res) => setShare(false));
    }
  }, [share]);

  useEffect(() => {
    if (download) {
      const data = {
        action: "download",
        value: "1",
        content: "Publication",
      };
      postAnalyticsData(data).then((res) => setDownload(false));
    }
  }, [download]);

  useEffect(() => {
    if (visit) {
      const data = {
        action: "visit",
        value: "1",
        content: "Publication",
      };
      postAnalyticsData(data).then((res) => setVisit(false));
    }
  }, [visit]);

  const openShareDialog = (news) => {
    setSelectedNews(news);
    setShare(true);
    setShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setSelectedNews(null);
    setShare(false);

    setShareDialogOpen(false);
  };
  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetNewsQuery();
  const {
    data: publicationsData,
    isLoading: publicationsLoading,
    isError: publicationsError,
  } = useGetPublicationsQuery();

  const [newsPage, setNewsPage] = useState(1);
  const [publicationsPage, setPublicationsPage] = useState(1);
  const [eventsPage, setEventsPage] = useState(1);

  const recentNews = newsData || [];
  const defaultCoverImage = BASE_FILE_URL + "9UTUE2LB86O9AEBG3HDD.jpg";
  const defaultCoverImagePublication =
    BASE_FILE_URL + "ZB4KYOVHRQDLI7W5VJK5.jpeg";
  const handleShareNews = (news) => {
    const newsLink = news.link; // Replace with the actual URL you want to share

    // Create an HTML element for copying the link to the clipboard
    const tempInput = document.createElement("input");
    tempInput.value = newsLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Define sharing text and URL (with a hypothetical "X" platform for Twitter)
    const shareText = `Check out this news: ${news.title}`;
    const shareUrl = newsLink;

    if (navigator.share) {
      navigator
        .share({
          title: news.title,
          text: shareText,
          url: shareUrl,
        })
        .then(() => console.log("News shared successfully"))
        .catch((error) => console.error("Error sharing news:", error));
    } else {
      const twitterShareUrl = `https://example.com/share?text=${encodeURIComponent(
        shareText
      )}&url=${encodeURIComponent(shareUrl)}`;
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`;
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText
      )}%20${encodeURIComponent(shareUrl)}`;

      console.log("Link copied to clipboard");

      window.open(twitterShareUrl, "_blank");
      window.open(facebookShareUrl, "_blank");
      window.open(whatsappShareUrl, "_blank");
    }
  };

  const renderRecentNews = () => {
    const reversedNews = _.cloneDeep(recentNews);

    return reversedNews
      ?.reverse()
      .slice(startIndexN, endIndexN)
      ?.map((news) => (
        <div
          className={classes.card}
          key={news.id}
          style={{ position: "relative" }}>
          <div style={{ position: "relative", backgroundColor: "transparent" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ height: "50%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className={classes.iconButton}>
                    <IconButton
                      onClick={() =>
                        handleOpenInNewTabNews(
                          (news?.contentType === "Link" && news.link
                            ? news?.link
                            : INTERNAL_LINK_URL + "posts/news/" + news.id) ||
                            (news?.contentType === "Both" &&
                              INTERNAL_LINK_URL + "posts/news/" + news.id) ||
                            (news?.contentType === "Article" &&
                              INTERNAL_LINK_URL + "posts/news/" + news.id)
                        )
                      }
                      style={{ color: colorTags.SECONDARY }}>
                      <OpenInNewIcon />
                    </IconButton>
                  </div>
                  <div className={classes.iconButton2}>
                    <IconButton
                      onClick={() => openShareDialog(news)}
                      style={{ color: colorTags.PRIMARY }}>
                      <ShareIcon />
                    </IconButton>
                  </div>
                </div>
                <img
                  src={
                    news.coverImage
                      ? BASE_FILE_URL + news.coverImage
                      : defaultCoverImage
                  }
                  alt={news.title}
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}>
              <Typography className={classes.cardTitles}>
                {news.title}
              </Typography>
            </div>
          </div>
          <Typography
            variant="body2"
            className={classes.cardDates}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "white", // Background color behind the date
              padding: "5px", // Adjust as needed
            }}>
            {new Date(news.createdOn).toLocaleDateString("en-US")}
          </Typography>
        </div>
      ));
  };

  const publications = publicationsData || [];
  const renderPublications = () => {
    const reversedPublication = _.cloneDeep(publications);

    return reversedPublication
      ?.reverse()
      .slice(startIndexP, endIndexP)
      ?.map((publication) => (
        <div
          className={classes.card}
          key={publication.id}
          style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ height: "50%", width: "100%" }}>
                <div
                  style={{
                    backgroundColor: "red",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <div className={classes.iconButton}>
                    <IconButton
                      onClick={() => {
                        handleOpenInNewTab(publication.documentName);
                        setVisit(true);
                      }}
                      style={{ color: colorTags.SECONDARY }}>
                      <OpenInNewIcon />
                    </IconButton>
                  </div>
                  <div className={classes.iconButton2}>
                    <a
                      href={FILE_DOWNLOAD_URL + publication.documentName}
                      download
                      onClick={(e) => setDownload(true)}>
                      <IconButton
                        component={MuiLink}
                        href={FILE_DOWNLOAD_URL + publication.documentName}
                        style={{ color: colorTags.PRIMARY }}>
                        <GetAppIcon />
                      </IconButton>
                    </a>
                  </div>
                </div>
                <img
                  src={
                    publication.coverImage
                      ? BASE_FILE_URL + publication.coverImage
                      : defaultCoverImagePublication
                  }
                  alt={publication.title}
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}>
              <Typography className={classes.cardTitles}>
                {publication.title}
              </Typography>
            </div>
          </div>
          <Typography
            variant="body2"
            className={classes.cardDates}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "white", // Background color behind the date
              padding: "5px", // Adjust as needed
            }}>
            {new Date(publication.createdOn).toLocaleDateString("en-US")}
          </Typography>
        </div>
      ));
  };

  const location = useLocation();
  const sectionToScroll = useParams();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (sectionToScroll)
      if (sectionToScroll.section) {
        const element = document.getElementById(sectionToScroll.section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
  }, [sectionToScroll]);

  const handleEventsPageChange = (event, newPage) => {
    setEventsPage(newPage);
  };
  const handleNewsPageChange = (event, newPage) => {
    setNewsPage(newPage);
  };
  const handlePublicationsPageChange = (event, newPage) => {
    setPublicationsPage(newPage);
  };

  const startIndexE = (eventsPage - 1) * itemsPerPage;
  const endIndexE = startIndexE + itemsPerPage;
  const startIndexN = (newsPage - 1) * itemsPerPage;
  const endIndexN = startIndexN + itemsPerPage;
  const startIndexP = (publicationsPage - 1) * itemsPerPage;
  const endIndexP = startIndexP + itemsPerPage;

  const recentNewsPaginated = newsData?.slice(startIndexN, endIndexN);
  const publicationsPaginated = publications.slice(startIndexP, endIndexP);
  const handleOpenInNewTab = (url) => {
    window.open(BASE_FILE_URL + url, "_blank");
  };
  const handleOpenInNewTabNews = (url) => {
    window.open(url, "_blank");
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Intersection Observer callback function
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div>
      <Header />
      <Card className={classes.topCard}>
        <CardContent>
          <Typography className={classes.title} variant="h1">
            Posts
          </Typography>
          <Typography
            variant="div"
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "justify",
              paddingTop: "10px",
              marginLeft: "10px",
              color: "#040D12",
            }}>
            Recent News || Publications || Events{" "}
          </Typography>
        </CardContent>
      </Card>

      <div id="news" style={{ marginBottom: "100px" }}></div>
      <div className={classes.postsContainer}>
        {/* Section: Recent News */}

        <div className={classes.sectionContainer}>
          <Typography variant="h3" className={classes.sectionTitle}>
            Recent News
          </Typography>
          <div className={classes.cardContainer}>{renderRecentNews()}</div>

          <div className={classes.paginationContainer}>
            <Pagination
              count={Math.ceil(recentNews.length / itemsPerPage)}
              page={newsPage}
              onChange={handleNewsPageChange}
              size="large"
            />
          </div>
        </div>

        {/* Section: Publications */}
        <div className={classes.sectionContainer} id="publications"></div>
        <Typography variant="h3" className={classes.sectionTitle}>
          Publications
        </Typography>
        <div className={classes.cardContainer}>{renderPublications()}</div>
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(publications.length / itemsPerPage)}
            page={publicationsPage}
            onChange={handlePublicationsPageChange}
            size="large"
          />
        </div>
      </div>
      <div className={classes.sectionContainer} id="events"></div>

      <div className={classes.sectionContainer}>
        <EventsMini />

        {/* <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(publications.length / itemsPerPage)}
            page={eventsPage}
            onChange={handleEventsPageChange}
            size="large"
          />
        </div> */}
      </div>
      {isShareDialogOpen && (
        <ShareDialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          news={selectedNews}
        />
      )}
      <Footer />
    </div>
  );
};

export default PostsPage;
