import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  Button,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import copy from "clipboard-copy";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
    // backgroundColor: "red",
    background: "none",
  },
}));

const ExportPage = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const handleCopyClick = async (value) => {
    try {
      await copy(value);
      console.log(value);
      setIsCopied(true);

      // Reset 'Copied!' message after 3 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Copy failed: ", error);
    }
  };

  const [productData, setProductData] = useState([]);
  useEffect(() => {
    if (data) setProductData(data);
  }, [data]);

  const handleItemClick = (index) => {
    setOpen((prevOpen) => (prevOpen === index ? null : index));
  };

  const productsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productData.length / productsPerPage);

  const handleClickPagination = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const showPaginationNumbers = () => {
    let paginationNumbers = [];

    if (currentPage <= 2) {
      for (let i = 1; i <= 3; i++) {
        paginationNumbers.push(i);
      }
    } else if (currentPage >= totalPages - 1) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        paginationNumbers.push(i);
      }
    }

    return paginationNumbers;
  };
  const handleNavigation = (id) => {
    navigate("/export/procedures/" + id);
  };
  return (
    <div>
      <List className={classes.list}>
        {productData
          .slice(
            (currentPage - 1) * productsPerPage,
            currentPage * productsPerPage
          )
          ?.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem
                button
                onClick={() =>
                  setOpen((prevOpen) => (prevOpen === item.id ? null : item.id))
                }>
                <ListItemText primary={item.productName} />
                {open === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={open === item.id} timeout="auto" unmountOnExit>
                {/* {["Get HS Code", "View Full Procedure"].map((option, index) => ( */}
                <ListItem className={classes.nested}>
                  <div
                    onClick={() => handleCopyClick(item?.hsCode)}
                    style={{ marginLeft: "20px" }}>
                    {item?.hsCode ?? "HS Code missing!"}
                  </div>
                  <Button
                    variant="text"
                    startIcon={<FileCopyOutlinedIcon />}
                    onClick={() => handleCopyClick(item?.hsCode)}
                    disableElevation
                    disableFocusRipple
                    style={{ marginLeft: "55px" }}
                    disableRipple>
                    {isCopied ? "Copied!" : "Copy"}
                  </Button>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemText
                    primary={"View Full Procedure"}
                    onClick={() => handleNavigation(item?.procedureId)}
                    style={{ marginLeft: "20px" }}
                  />
                </ListItem>
                {/* ))} */}
              </Collapse>
              <Divider />
            </React.Fragment>
          ))}
      </List>
      <div className={classes.pagination}>
        <ChevronLeftIcon
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={() => handleClickPagination(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {showPaginationNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClickPagination(pageNumber)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === pageNumber ? "#F0F0F0" : "white",
            }}>
            {pageNumber}
          </button>
        ))}
        <ChevronRightIcon
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => handleClickPagination(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </div>
    </div>
  );
};

export default ExportPage;
