import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Avatar,
  Typography,
  Button,
  Card,
  CardContent,
  Chip,
} from "@material-ui/core";
import { Email, Phone, Facebook, Twitter, LinkedIn } from "@material-ui/icons";
import { Done } from "@material-ui/icons";
import { Stack, Link } from "@mui/material";
import { images } from "../../../assets/index";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/Language";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  useGetUserByIdQuery,
  useGetMemberByIdQuery,
} from "../../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingMain";
import { BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    backgroundColor: "#f2f2f2",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
    },
  },
  coverContainer: {
    position: "relative",
    width: "80%",
    height: theme.spacing(30),
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: theme.spacing(20),
    },
  },
  coverImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    bottom: 0,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: `3px solid ${theme.palette.background.default}`,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
    margin: theme.spacing(-5, 0, 2),
  },
  name: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#016638",
    marginBottom: theme.spacing(1),
  },
  email: {
    fontSize: "16px",
    color: "#666",
    marginBottom: theme.spacing(1),
  },
  bio: {
    fontSize: "16px",
    color: "#333",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  smallCard: {
    width: "100%",
    backgroundColor: "#016638",
    color: "#fff",
    textAlign: "center",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  smallCard2: {
    width: "100%",
    backgroundColor: "#016638",
    color: "#fff",
    textAlign: "center",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  smallCardTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  smallCardValue: {
    fontSize: "22px",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    color: colorTags.SECONDARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  exportCard: {
    width: "80%",
    margin: theme.spacing(5),
    // backgroundColor: "rgba(255,255,255,0.5)",
    backgroundColor: "transparent",
    border: "",
    color: "#fff",
    boxShadow:
      " rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),

    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: theme.spacing(2),
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
  },
  // chipsContainer: {
  //   display: "flex",
  //   overflowX: "auto",
  //   "&::-webkit-scrollbar": {
  //     height: "4px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     background: "#888",
  //     borderRadius: "4px",
  //   },
  //   "&::-webkit-scrollbar-thumb:hover": {
  //     background: "#555",
  //   },
  // },
  // chips: {
  //   backgroundColor: "#9BABB8",
  //   paddingLeft: theme.spacing(1),
  //   paddingRight: theme.spacing(1),
  // },
  chipsContainer: {
    display: "flex",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  chips: {
    backgroundColor: "#9BABB8",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  exportTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: theme.spacing(5),
    color: "#016638",
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(1),
    color: "#666",
    cursor: "pointer",
    "&:hover": {
      color: "#016638",
    },
  },
  editProfileBtn: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#E0A822",
    color: "#fff",
    fontSize: "14px",
    padding: theme.spacing(1, 3),
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const params = useParams();
  const [userData, setUserData] = useState({});
  const [socials, setSocials] = useState({});
  const [products, setProducts] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const {
    data: userData2,
    isLoading,
    isError,
  } = useGetMemberByIdQuery({ id: params.id });

  const userX = {
    name: "Company One",
    email: "CompanyOne@google.com",
    phone: "+251 923-456-790",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat bibendum turpis, nec luctus justo semper at.",
    avatar: images.CorpLogo,
    cover: images.Corp,
    sector: "Agriculture",
    exports: "Exports",
    subsector: "Destinations",
    activeSince: "1999",
    exportProducts: [
      "Product 1",
      "Product 2",
      "Product 3",
      "Product 4",
      "Product 5",
      "Product 6",
    ],
    exportDestinations: [
      "Country 1",
      "Country 2",
      "Country 3",
      "Country 4",
      "Country 5",
    ],
    isProfileOwner: true,
    socialMedia: {
      facebook: "https://www.facebook.com",
      twitter: "https://www.twitter.com",
      linkedin: "https://www.linkedin.com",
    },
  };

  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  useEffect(() => {
    if (userData2) {
      setUserData(userData2[0]);
      const socialsData = JSON.parse(userData2[0]?.socials);
      setSocials(socialsData);
      const productsData = JSON.parse(userData2[0]?.products);
      const destinationsData = JSON.parse(userData2[0]?.destinations);
      // const socialsData = userData2[0]?.socials;
      // setSocials(socialsData);
      // const productsData = userData2[0]?.products;
      // const destinationsData = userData2[0]?.destinations;
      setProducts(productsData);
      setDestinations(destinationsData);
    }
  }, [userData2]);
  const handleEditProfile = () => {};
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const navigate = useNavigate();

  if (isLoading) return <LoadingScreen />;
  // if (isError) return navigate("/404");

  return (
    <div>
      <Header />
      <div className={classes.root}>
        <div className={classes.coverContainer}>
          <img
            src={
              userData?.coverImage
                ? BASE_FILE_URL + userData?.coverImage
                : images.Corp
            }
            alt="Cover"
            className={classes.coverImage}
          />
          {user?.user_id * 1 === userData.id && user?.role_name === "Member" ? (
            <Button
              variant="contained"
              className={classes.editProfileBtn}
              onClick={() => navigate(`/edit-profile/${user?.user_id}`)}>
              Edit Profile
            </Button>
          ) : null}
        </div>
        <Avatar
          alt={userData.companyName}
          src={
            userData?.avatar ? BASE_FILE_URL + userData?.avatar : userX.avatar
          }
          className={classes.avatar}
        />

        <Grid
          container
          justifyContent="center"
          // style={{ backgroundColor: "red" }}
          alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            style={{ padding: "0 5%" }}
            justifyContent="center"
            alignItems="center">
            <Typography variant="h4" className={classes.name}>
              {/* {user.name} */}
              {userData?.companyName}
            </Typography>

            <Typography variant="body1" className={classes.bio}>
              {/* {user.bio} */}
              {userData?.bio}
            </Typography>
            <div className={classes.socialIcons}>
              <Stack
                direction={isSmallScreen ? "column" : "row"}
                spacing={isSmallScreen ? 1 : 5}>
                <Typography variant="h6" className={classes.email}>
                  <Email /> {userData?.email}
                </Typography>
                <Typography variant="h6" className={classes.email}>
                  <Phone /> {userData?.contactPhone}
                </Typography>
              </Stack>
            </div>
            <div className={classes.socialIcons}>
              {socials?.whatsapp ? (
                <WhatsAppIcon
                  className={classes.icon}
                  style={{ color: "#25D366" }}
                  onClick={() => window.open(socials?.whatsapp, "_blank")}
                />
              ) : null}
              {/* {socials?.facebook ? (
                <Facebook
                  className={classes.icon}
                  onClick={() => window.open(socials?.facebook, "_blank")}
                />
              ) : null} */}
              {/* {socials?.twitter ? (
                <Twitter
                  className={classes.icon}
                  onClick={() => window.open(socials?.twitter, "_blank")}
                />
              ) : null} */}
              {socials?.website ? (
                <LanguageIcon
                  className={classes.icon}
                  style={{ color: "#016638" }}
                  onClick={() =>
                    window.open(`https://${socials?.website}`, "_blank")
                  }
                />
              ) : null}
              {socials?.linkedIn ? (
                <LinkedIn
                  className={classes.icon}
                  onClick={() => window.open(socials?.linkedIn, "_blank")}
                  style={{ color: "#0e76a8" }}
                />
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "15px",
              }}>
              <Typography variant="h6" className={classes.email}>
                Want to promote your business? click{" "}
                <Link
                  href="/promotion"
                  className={classes.links}
                  sx={{
                    color: colorTags.PRIMARY,
                    "&:hover": {
                      color: colorTags.SECONDARY,
                    },
                    textAlign: "center",
                    fontWeight: "bold",
                  }}>
                  here
                </Link>
              </Typography>
            </div>
            {isSmallScreen ? (
              <Grid container spacing={2} style={{ padding: "0 5%" }}>
                <Grid item xs={6}>
                  <Card className={classes.smallCard}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.smallCardTitle}>
                        Sector
                      </Typography>
                      <Typography
                        variant="div"
                        className={classes.smallCardValue}>
                        {userData.sector}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className={classes.smallCard}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.smallCardTitle}>
                        Sub Sector
                      </Typography>
                      <Typography
                        variant="div"
                        className={classes.smallCardValue}>
                        {userData.subsector}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className={classes.smallCard}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.smallCardTitle}>
                        Exports
                      </Typography>
                      <Typography
                        variant="div"
                        className={classes.smallCardValue}>
                        {products?.length}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card className={classes.smallCard}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.smallCardTitle}>
                        Contact Person
                      </Typography>
                      <Typography
                        variant="div"
                        className={classes.smallCardValue}>
                        {userData?.contactPerson}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Stack direction="row" spacing={2}>
                <Card className={classes.smallCard}>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      className={classes.smallCardTitle}>
                      Sector
                    </Typography>
                    <Typography variant="h6" className={classes.smallCardValue}>
                      {userData?.sector}
                    </Typography>
                  </CardContent>
                </Card>

                <Card className={classes.smallCard}>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      className={classes.smallCardTitle}>
                      Sub Sector
                    </Typography>
                    <Typography variant="h6" className={classes.smallCardValue}>
                      {userData?.subSector}
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.smallCard}>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      className={classes.smallCardTitle}>
                      Exports
                    </Typography>
                    <Typography variant="h6" className={classes.smallCardValue}>
                      {products?.length}
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.smallCard}>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      className={classes.smallCardTitle}>
                      Contact Person
                    </Typography>
                    <Typography variant="h6" className={classes.smallCardValue}>
                      {userData?.contactPerson}
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            )}
          </Grid>
        </Grid>
        <Card className={classes.exportCard}>
          <Typography variant="h5" className={classes.exportTitle}>
            Export Products
          </Typography>
          <Stack direction="row" spacing={1} className={classes.chipsContainer}>
            {products?.map((product, index) => (
              <Chip
                key={index}
                label={product}
                color="primary"
                variant="outlined"
                style={{ margin: "5px" }}
              />
            ))}
          </Stack>
        </Card>
        <Card className={classes.exportCard}>
          <Typography variant="h5" className={classes.exportTitle}>
            Export Destinations
          </Typography>
          <Stack direction="row" spacing={1} className={classes.chipsContainer}>
            {destinations?.map((destination, index) => (
              <Chip
                key={index}
                label={destination}
                color="primary"
                variant="outlined"
                style={{ margin: "5px" }}
              />
            ))}
          </Stack>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
