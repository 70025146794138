import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Stack, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  // Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  // Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { images } from "../../assets/index";
import Chart from "chart.js/auto";

import { Bar } from "react-chartjs-2";
import PolarAreaChart from "./Charts/PolarAreaChart";
import DoughnutChart from "./Charts/Doughnut";
import {
  useGetAnalyticsQuery,
  useGetDashboardStatsQuery,
  useGetNewsQuery,
  useGetPublicationsQuery,
  useGetEventsQuery,
  useGetDocumentsQuery,
  useGetProductsQuery,
  useGetPromotionsQuery,
  useGetProceduresQuery,
  useGetFAQsQuery,
  useGetFlagsQuery,
  useGetMembersQuery,
} from "../../features/api/apiSlice";
import { Line } from "react-chartjs-2";
import Chat from "./Chat/ChatDash";

const useStyles = makeStyles((theme) => ({
  cardsHolder: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    // backgroundImage: `linear-gradient(to left, #ffffff 50%, transparent 50%), url(${images.BlurBg1})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundColor: "#f1f1f1",
    margin: "5% 0px 20px 0px",
    padding: "0px 10px 0px 10px",
    backgroundSize: "cover",
  },
  smallCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
    backgroundColor: "#0DC3FC",
    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  },
  smallCardTitles: {
    color: "#fff",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
//   datasets: [
//     {
//       label: "Monthly Activities",
//       backgroundColor: "rgba(75, 192, 192, 0.2)",
//       borderColor: "rgba(75, 192, 192, 1)",
//       borderWidth: 1,
//       data: [4000, 3000, 2000, 2780, 1890, 2390, 3490],
//     },
//   ],
// };

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
const pieData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const chatData = [
  {
    user: {
      id: 1,
      name: "User 1",
      avatar: "url_to_avatar_1",
    },
    messages: [{ id: 1, text: "Hello!", time: "10:00 AM" }],
  },
  {
    user: {
      id: 2,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 3,
      name: "User 1",
      avatar: "url_to_avatar_1",
    },
    messages: [{ id: 1, text: "Hello!", time: "10:00 AM" }],
  },
  {
    user: {
      id: 4,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 5,
      name: "User 1",
      avatar: "url_to_avatar_1",
    },
    messages: [{ id: 1, text: "Hello!", time: "10:00 AM" }],
  },
  {
    user: {
      id: 6,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 7,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 6,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 7,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 8,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 9,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 10,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
  {
    user: {
      id: 11,
      name: "User 2",
      avatar: "url_to_avatar_2",
    },
    messages: [{ id: 1, text: "Hi there!", time: "11:00 AM" }],
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const AnimatedCounter = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const interval = setInterval(() => {
      if (start >= value) {
        clearInterval(interval);
        setCount(value); // Set the final value when the animation is done
      } else {
        setCount(start);
        start += 10;
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  return <span style={{ color: "#fff", fontSize: "25px" }}>{count}</span>;
};

export default function DashboardPage() {
  // DASHBOARD STATISTICS DATA
  const {
    data: dashboardData,
    isLoading: isLoadingDashboardData,
    isError: isErrorDashboardData,
    error: errorDashboardData,
  } = useGetDashboardStatsQuery();

  const {
    data: analyticsData,
    isLoading: isLoadingAnalyticsData,
    isError: isErrorAnalyticsData,
  } = useGetAnalyticsQuery();

  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetNewsQuery();
  const {
    data: publicationsData,
    isLoading: publicationsLoading,
    isError: publicationsError,
  } = useGetPublicationsQuery();
  const {
    data: eventsData,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetEventsQuery();
  const {
    data: productsData,
    isLoading: productsLoading,
    isError: productsError,
  } = useGetProductsQuery();
  const {
    data: documentsData,
    isLoading: documentsLoading,
    isError: documentsError,
  } = useGetDocumentsQuery();
  const {
    data: proceduresData,
    isLoading: proceduresLoading,
    isError: proceduresError,
  } = useGetProceduresQuery();
  const {
    data: fAQsData,
    isLoading: fAQsLoading,
    isError: fAQsError,
  } = useGetFAQsQuery();
  const {
    data: flagsData,
    isLoading: flagsLoading,
    isError: flagsError,
  } = useGetFlagsQuery();
  const {
    data: membersData,
    isLoading: membersLoading,
    isError: membersError,
  } = useGetMembersQuery();

  const [contentValue, setContentValue] = useState(0);
  const [viewsValue, setViewsValue] = useState(0);
  const [flagsValue, setFlagsValue] = useState(0);
  const [membersValue, setMembersValue] = useState(0);

  //       borderColor: "rgba(75, 192, 192, 1)",

  useEffect(() => {
    if (newsData) {
      setContentValue(newsData.length);
    }
    if (publicationsData) {
      setContentValue(contentValue + publicationsData.length);
    }
    if (eventsData) {
      setContentValue(contentValue + eventsData.length);
    }
    if (proceduresData) {
      setContentValue(contentValue + proceduresData.length);
    }
    if (documentsData) {
      setContentValue(contentValue + documentsData.length);
    }
    if (fAQsData) {
      setContentValue(contentValue + fAQsData.length);
    }
  }, [
    newsData,
    publicationsData,
    eventsData,
    proceduresData,
    documentsData,
    fAQsData,
  ]);

  useEffect(() => {
    if (analyticsData) {
      // filter the analyticsData array by the action property
      const filteredData = analyticsData.filter(
        (item) => item.action === "visit"
      );
      // get the length of the filtered array
      const count = filteredData.length; // display the count or do something else with it
      console.log(count);
      setViewsValue(count);
    }
  }, [analyticsData]);

  useEffect(() => {
    if (flagsData) {
      // filter the analyticsData array by the action property
      const filteredData = flagsData.filter((item) => item.comment === null);
      // get the length of the filtered array
      const count = filteredData.length; // display the count or do something else with it
      console.log(count);
      setFlagsValue(count);
    }
  }, [flagsData]);

  useEffect(() => {
    if (membersData) {
      // filter the analyticsData array by the action property
      const filteredData = membersData.filter(
        (item) => item.status === "Active"
      );
      const count = filteredData.length; // display the count or do something else with it
      console.log(count);
      setMembersValue(count);
    }
  }, [membersData]);

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [chatsPage, setChatsPage] = useState(1);

  const usersPerChatsPage = 4;
  const totalPagesChats = Math.ceil(chatData.length / usersPerChatsPage);

  const startIndexC = (chatsPage - 1) * usersPerChatsPage;
  let endIndexC = startIndexC + usersPerChatsPage;

  if (endIndexC > chatData.length) {
    endIndexC = chatData.length;
  }

  const chatsPaginated = chatData.slice(startIndexC, endIndexC);

  const handleChatsPageChange = (event, newPage) => {
    setChatsPage(newPage);
  };

  useEffect(() => {
    if (user) {
      console.log(user);
    }
  }, [user]);
  const classes = useStyles();

  const [data, setData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Traffic",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: "#006738",
        tension: 0.1,
      },
      {
        label: "Shares and Downloads",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: "#006738",
        tension: 0.1,
      },
    ],
  });

  const [ad, setAd] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (analyticsData) {
      analyticsData.forEach((item) => {
        const monthIndex = new Date(item.createdAt).getMonth();
        data.datasets[0].data[monthIndex]++;
      });
      setAd(true);
      setLoading(false);
    }
  }, [analyticsData]);

  const config = {
    type: "line",
    data: data,
    options: {
      scales: {
        x: {
          type: "category",
          labels: data.labels,
        },
        y: {
          beginAtZero: true,
        },
      },
      elements: {
        point: {
          radius: 5, // Optional: Increase point radius for better visibility
        },
      },
    },
  };

  const options = {
    scales: {
      x: {
        type: "category",
        labels: data.labels,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  if (isLoadingAnalyticsData) return <h1>Loading...</h1>;
  return (
    <Stack style={{ marginTop: "20px", paddingTop: "15px" }}>
      {/* <PageTitle title="Welcome" /> */}

      <Grid container spacing={3} mt={2}>
        <Grid item xs={6}>
          {/* Line Chart */}
          {/* <Paper> */}
          {/* <Bar data={data} options={options} /> */}
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <Line data={data} options={config} />
          )}

          {/* </Paper> */}
        </Grid>
        <Grid item xs={6}>
          {/* Bar Chart */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            {" "}
            <DoughnutChart />
          </div>
        </Grid>
      </Grid>
      <div className={classes.cardsHolder}>
        <Grid
          container
          mb={8}
          mt={2}
          spacing={5}
          style={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid item xs={2}>
            <Paper
              className={classes.smallCard}
              style={{
                position: "relative",
                backgroundColor: "#0DC3FC",
                borderRadius: "10px",
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}>
              {/* Semi-transparent overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "45%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  zIndex: 1,
                  borderRadius: "10px",
                }}></div>

              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles}>Content</h3>
                <AnimatedCounter value={contentValue} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.smallCard}
              style={{
                position: "relative",
                backgroundColor: "#65E42A",
                borderRadius: "10px",
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}>
              {/* Semi-transparent overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "45%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  zIndex: 1,
                  borderRadius: "10px",
                }}></div>

              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles}>Views</h3>
                <AnimatedCounter value={viewsValue} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.smallCard}
              style={{
                position: "relative",
                backgroundColor: "#FF5050",
                borderRadius: "10px",
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}>
              {/* Semi-transparent overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "45%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  zIndex: 1,
                  borderRadius: "10px",
                }}></div>

              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles}>Flags</h3>
                <AnimatedCounter value={flagsValue} />
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper
              className={classes.smallCard}
              style={{
                position: "relative",
                backgroundColor: "#F256FF",
                borderRadius: "10px",
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}>
              {/* Semi-transparent overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "45%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  zIndex: 1,
                  borderRadius: "10px",
                }}></div>

              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles}>Members</h3>
                <AnimatedCounter value={membersValue} />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {/* Circular Chart */}
          <div style={{ width: "100%", height: "70%" }}>
            <PolarAreaChart />
          </div>
        </Grid>
        <Grid item xs={6}>
          {/* <div
            elevation={3}
            style={{
              padding: "16px",
              background: "none",
            }}>
          
            <div
              style={{
                height: "30px",
                borderRadius: "10px",
                marginBottom: "16px",
                backgroundColor: "#1967FF",
                // backgroundColor: "white",
                padding: "16px",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // maxHeight: "60px",
              }}>
              <h3 className={classes.smallCardTitles}>Chats</h3>

           
              <h3 style={{ color: "#fff" }}>Member</h3>

              <h3 style={{ color: "#fff" }}>Message</h3>
            </div>

          
            {chatsPaginated.map((chat) => (
              <Paper
                elevation={3}
                key={chat.user.id}
                style={{
                  backgroundColor: "white",
                  padding: "16px",
                  marginBottom: "16px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxHeight: "60px",
                }}>
             
                <img
                  src={chat.user.avatar}
                  alt={chat.user.name}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />

                
                <h3>{chat.user.name}</h3>

             
                <ul style={{ padding: 0 }}>
                  {chat.messages.map((message) => (
                    <li key={message.id}>
                      <p>{message.text}</p>
                      <p>{message.time}</p>
                    </li>
                  ))}
                </ul>
              </Paper>
            ))}
          </div> */}
          <Chat />
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              // marginTop: "-16px",
            }}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate("/dashboard/chat")}>
              see all chats
            </Button>
          </div> */}
        </Grid>
      </Grid>
    </Stack>
  );
}
