import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { images } from "../../../assets/index";
import { Stack } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";
import EventsMini from "./EventsMini";
import {
  useGetEventByIdQuery,
  useGetCustomImageByIdQuery,
  usePostAnalyticsMutation,
} from "../../../features/api/apiSlice";
import LoadingScreen from "../../../components/LoadingMain";

import { useParams, useNavigate, Link } from "react-router-dom";
import { BASE_FILE_URL } from "../../../constants/apiTags";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(0),
  },
  bigPictureContainer: {
    position: "relative",
    width: "100%",
    height: "400px",
    overflow: "hidden",
    marginBottom: theme.spacing(4),
  },
  bigPicture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  greenCardContainer: {
    position: "absolute",
    bottom: "25%",
    left: "10%",
    minHeight: "100px",
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(7),

    backgroundColor: "rgba(1, 102, 56, 0.7)",
    color: "#fff",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down(600)]: {
      left: "0%",
    },
  },
  newsTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  newsSummary: {
    fontSize: "16px",
  },
  circleContainer: {
    position: "absolute",
    alignSelf: "right",
    right: "8%",
    top: "41%",
    width: theme.spacing(15),
    height: theme.spacing(15),
    borderRadius: "50%",
    border: "3px solid #016638",
    overflow: "hidden",
    [theme.breakpoints.down(900)]: {
      top: "32%",
    },
    [theme.breakpoints.down(500)]: {
      top: "46%",
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  circleImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  newsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    padding: theme.spacing(5),
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: theme.spacing(0),
      padding: theme.spacing(3),
    },
  },
  dateContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(-10),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: "#526D82",
    [theme.breakpoints.down(600)]: {
      // marginTop: theme.spacing(0),
      textAlign: "left",
      paddingLeft: theme.spacing(0),
    },
  },
  dateContain: {
    width: "60%",

    textAlign: "center",
    // backgroundColor: "#016638",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: "#92222",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  newsTitleContainer: {
    width: "100%",
    textAlign: "center",

    color: colorTags.PRIMARY,
    [theme.breakpoints.down(600)]: {
      // marginTop: theme.spacing(0),
      textAlign: "left",
    },
  },
  newsDescription: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(3),
    fontSize: "18px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0),
      fontSize: "17px",
      marginTop: "10px",
    },
    //  [theme.breakpoints.down(900)]: {
    //   paddingLeft: theme.spacing(),

    // },
    // backgroundColor: "red",
  },
  imageContainer: {
    maxWidth: "40%",
    width: "auto",
    height: "auto",
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
    paddingLeft: theme.spacing(10),

    [theme.breakpoints.down(900)]: {
      maxWidth: "50%",
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      marginTop: "10px",
    },
  },
  paragraphContainer: {
    width: "60%",
    paddingLeft: theme.spacing(7),

    [theme.breakpoints.down(900)]: {
      width: "60%",
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginTop: "30px",
      paddingLeft: theme.spacing(0),
    },
  },
}));
const convertToDateWithMonthName = (dateString) => {
  const dateParts = dateString.split("/");
  const monthNumber = parseInt(dateParts[0]);
  const day = dateParts[1];
  const year = dateParts[2];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[monthNumber - 1];

  return `${monthName} ${day}, ${year}`;
};
const dummyNewsData = {
  image: images.Corp,
  title: "Ethiopian Export",
  summary: "Summary of the news goes here. It should be a short description.",
  date: "10/05/2022",
  description:
    "This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a paragraph with exactly 1000 characters. It's intended to be quite lengthy, allowing you to work with a substantial amount of text. You can use this paragraph for testing, formatting, or any other purposes you may have in mind. Feel free to manipulate, cut, or divide it as needed for your specific use case. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius, neque ac rhoncus vehicula, tortor est varius lectus, sed pellentesque lorem purus a lectus. Sed ultrices, risus at vehicula aliquam, arcu metus ultricies erat, vel tincidunt tortor orci quis quam. Proin venenatis vel odio eu vestibulum. Sed laoreet, turpis nec bibendum egestas, eros felis venenatis purus, at convallis odio elit id arcu. Nulla varius ante nec diam sagittis, vel hendrerit tellus laoreet. Vestibulum sed massa eu urna auctor pellentesque. Maecenas ultrices urna vel consectetur vestibulum. Sed ultricies fringilla velit, ut dictum quam venenatis ut. Maecenas vestibulum massa eget arcu malesuada, vel egestas nunc eleifend. Vivamus id arcu vitae quam bibendum malesuada. Sed nec mi non ex tincidunt condimentum.",
};

const NewsPage = () => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // const { data: eventData, isError, isLoading } = useGetEventByIdQuery(id);
  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 3 });

  const {
    data: eventData,
    isError: isErrorEvent,
    isLoading: isLoadingEvent,
  } = useGetEventByIdQuery({ id: id });

  const [visit, setVisit] = useState(false);

  const [postAnalyticsData, { isLoading: isAnalyticsLoading }] =
    usePostAnalyticsMutation();
  useEffect(() => {
    if (!visit) {
      const data = {
        action: "visit",
        value: "1",
        content: "EventPage",
      };
      postAnalyticsData(data).then((res) => setVisit(true));
    }
  }, [visit]);

  function divideParagraph(paragraph) {
    if (!paragraph) return ["", "", ""];

    const introMaxLength = 534;
    const firstPartMaxLength = 900;

    if (paragraph.length <= introMaxLength) {
      return [paragraph, "", ""];
    }

    const intro = paragraph.slice(0, introMaxLength);
    const remaining = paragraph.slice(introMaxLength);

    if (remaining.length <= firstPartMaxLength) {
      return [intro, remaining, ""];
    }

    const firstPart = remaining.slice(0, firstPartMaxLength);
    const remaining2 = remaining.slice(firstPartMaxLength);

    return [intro, firstPart, remaining2];
  }

  const sections = divideParagraph(eventData?.description);
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  if (isLoadingEvent || isLoadingImageData) {
    return <LoadingScreen />;
  }
  if (isErrorEvent) {
    navigate("/404");
  }

  return (
    <div>
      <Header />
      <div className={classes.root}>
        <div className={classes.bigPictureContainer}>
          <img
            src={
              (imageData?.image && BASE_FILE_URL + imageData?.image) ||
              images?.MainImage2
            }
            // alt={`${eventData?.title} AACCSA event`}
            alt="event"
            className={classes.bigPicture}
          />
          <div className={classes.greenCardContainer}>
            <Typography variant="h4" className={classes.newsTitle}>
              {eventData?.title}
            </Typography>
            <Typography variant="body1" className={classes.newsSummary}>
              {eventData?.summary}
            </Typography>
          </div>
        </div>
        <div className={classes.circleContainer}>
          <img
            src={images.Amplifier}
            alt="Events"
            className={classes.circleImage}
          />
        </div>
        <div className={classes.newsContainer}>
          <Stack
            direction="column"
            spacing={isSmallScreen ? 2 : 4}
            style={{ width: "100%", paddingLeft: "10%" }}
            className={classes.dateContain}>
            <div className={classes.dateContainer}>
              <Typography variant={isSmallScreen ? "h6" : "h5"}>
                {eventData &&
                  convertToDateWithMonthName(
                    new Date(eventData?.createdOn).toLocaleDateString("en-US")
                  )}{" "}
              </Typography>
            </div>
            <div className={classes.newsTitleContainer}>
              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                style={{ fontWeight: 400 }}>
                {eventData?.title}
              </Typography>
            </div>
          </Stack>
          <Typography
            // variant={isSmallScreen ? "div" : "body1"}
            className={classes.newsDescription}>
            {/* {dummyNewsData.description} */}
            {sections[0] ?? ""}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.newsDescription}>
          {/* {dummyNewsData.description} */}
          {sections[1] ?? ""}
        </Typography>

        <div className={classes.newsContainer}>
          <div
            className={classes.imageContainer}
            style={{ maxWidth: sections[2] ? "40%" : "90%" }}>
            <img
              src={
                eventData?.coverImage
                  ? BASE_FILE_URL + eventData?.coverImage
                  : images?.MainImage2
              }
              alt={eventData?.title}
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </div>
          <div className={classes.paragraphContainer}>
            <Typography variant="body1">
              {/* {dummyNewsData.description} */}
              {sections[2] ?? ""}
            </Typography>
          </div>
        </div>
        {eventData?.link?.length > 0 && (
          <div className={classes.newsContainer}>
            {/* <li> */}
            <a href={eventData?.link} target="_blank" rel="noreferrer">
              Click here to view the event
            </a>
            {/* </li> */}
          </div>
        )}
      </div>
      <EventsMini />
      <Footer />
    </div>
  );
};

export default NewsPage;
