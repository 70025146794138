import React, { useEffect, useState } from "react";
import { Button, Paper, IconButton, Tooltip } from "@mui/material";
import { Bars } from "react-loader-spinner";
import { useGetChatsQuery } from "../../../features/api/apiSlice";
import ChatResponse from "./ChatResponse";
import { images } from "../../../assets/index";
import { Info } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  smallCardTitles: {
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function ChatPage() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const { data: chatsData, isLoading: isLoadingChatsData } = useGetChatsQuery();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      // Fetch and set your chat data

      // For demo purposes, let's assume `chatsData` is an array of chat objects
      // You need to adjust this part based on your actual data structure
      const recentMessages = chatsData?.map((chat) => {
        const user = JSON.parse(chat?.user);
        // const user = chat?.user;
        // console.log(user);
        const conversation = JSON.parse(chat?.conversation);

        const lastMessage = conversation[conversation?.length - 1];

        return {
          id: chat.id,
          user,
          message: lastMessage.message,
          time: lastMessage.time,
        };
      });
      console.log(recentMessages);

      // Sort the messages based on dates
      const sortedMessages = recentMessages?.sort(
        (a, b) => new Date(b.time) - new Date(a.time)
      );

      // Limit to 5 messages
      const limitedMessages = sortedMessages?.slice(0, 5);

      setMessages(limitedMessages);
      setLoading(false);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [chatsData]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars height="40" width="40" color="#1967FF" ariaLabel="bars-loading" />
      </div>
    );
  }

  const extractHourAndMinute = (time) => {
    const dateTimeParts = time.split("T");
    if (dateTimeParts.length === 2) {
      const timeParts = dateTimeParts[1].split(":");
      if (timeParts.length === 3) {
        const [hour, minute] = timeParts.slice(0, 2);

        // Convert to Ethiopian Time (ETC)
        const utcDateTime = new Date(
          `${dateTimeParts[0]}T${hour}:${minute}:00Z`
        );
        const etcDateTime = utcDateTime.toLocaleString("en-US", {
          timeZone: "Africa/Addis_Ababa", // ETC timezone
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        });

        return etcDateTime;
      }
    }
    return "";
  };

  return (
    <>
      {/* List of Chat Cards */}
      <div style={{ padding: "10%", paddingBottom: "2%" }}>
        <Paper
          elevation={3}
          style={{
            height: "50px",
            borderRadius: "5px",
            marginBottom: "16px",
            backgroundColor: "#1967FF",
            padding: "16px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <h3 className={classes.smallCardTitles}>Chats</h3>
        </Paper>

        <div style={{ height: "90%", overflow: "auto" }}>
          {messages?.length > 0 ? (
            messages?.map((message) => (
              <Paper
                elevation={3}
                key={message.user.id}
                style={{
                  backgroundColor: "white",
                  padding: "16px",
                  marginBottom: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxHeight: "60px",
                }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "auto",
                    fontSize: "12px",
                  }}>
                  <h4>{message.user[0].name}</h4>
                </div>
                <div
                  style={{
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}>
                  {/* <p
                    style={{
                      marginLeft: "auto",
                      marginRight: "15%",
                      textAlign: "left",
                    }}>
                    {message.message}
                  </p> */}
                  <p
                    style={{
                      marginLeft: "auto",
                      marginRight: "15%",
                      textAlign: "left",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap", // Ensure text doesn't wrap
                    }}>
                    {`${message.message.substring(0, 40)}${
                      message.message.length > 40 ? "..." : ""
                    }`}
                  </p>

                  <p style={{ marginRight: "25px", fontSize: "12px" }}>
                    {new Date(message.time).toLocaleDateString("en-US")}
                    <span style={{ marginLeft: "7px" }}>
                      {extractHourAndMinute(message.time)}
                    </span>
                  </p>
                  <Tooltip title="Open Conversation" placement="bottom">
                    <Button
                      onClick={() => {
                        setUser(message.id);
                        setModalOpen(true);
                      }}>
                      <img
                        src={images.ChatIcon}
                        alt={"Open Chat"}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              </Paper>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontFamily: "Arial, sans-serif",
                fontSize: "24px",
                color: "#333",
                backgroundColor: "#f0f0f0",
                padding: "20px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}>
              <h3>No Chats Open</h3>
            </div>
          )}
        </div>
      </div>

      {/* See All Button */}
      {messages?.length > 5 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              borderRadius: "5px",
              color: "#1967FF",
              borderColor: "#1967FF",
            }}
            onClick={() => navigate("/dashboard/chats")}>
            See All
          </Button>
        </div>
      )}
      <ChatResponse
        chat={user && user}
        open={modalOpen}
        onSave={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}
