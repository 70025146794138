import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
// import ProductsPage from "./Products/Products";
import { images } from "../../../../assets/index";
import { colorTags } from "../../../../constants/variants";

import InstitutionsPageNGO from "./ngo";
import InstitutionsPagePRV from "./prv";
import InstitutionsPageGOV from "./gov";
import { useGetInstitutionsQuery } from "../../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#F1F1F1",
    // padding: theme.spacing(12),
    // paddingTop: theme.spacing(2),
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    // marginTop: theme.spacing(2),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
}));

const ProductsPage = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    data: institutionsData,
    error,
    isLoading,
  } = useGetInstitutionsQuery();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (institutionsData) {
      console.log(institutionsData);
    }
  }, [institutionsData]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  else
    return (
      <div className={classes.root}>
        {" "}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            ".Mui-selected": {
              color: `${colorTags.SECONDARY} !important`,
            },
          }}
          TabIndicatorProps={{
            sx: { backgroundColor: colorTags.SECONDARY },
          }}
          centered
          variant="fullWidth"
          style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
          <Tab
            label="Government"
            className={selectedTab === 0 ? classes.selectedTab : ""}
          />
          <Tab
            label="Private"
            className={selectedTab === 1 ? classes.selectedTab : ""}
          />
          <Tab
            label="NGO"
            className={selectedTab === 2 ? classes.selectedTab : ""}
          />
        </Tabs>
        {/* <SearchBox /> */}
        <div className={classes.content}>
          {selectedTab === 0 && (
            <InstitutionsPageGOV
              data={institutionsData?.filter(
                (product) => product.category === "GOV"
              )}
            />
          )}
          {selectedTab === 1 && (
            <InstitutionsPagePRV
              data={institutionsData?.filter(
                (product) => product.category === "PRV"
              )}
            />
          )}
          {selectedTab === 2 && (
            <InstitutionsPageNGO
              data={institutionsData?.filter(
                (product) => product.category === "NGO"
              )}
            />
          )}
        </div>
      </div>
    );
};

export default ProductsPage;
