import React, { useState } from "react";
import {
  Stack,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  Divider,
} from "@material-ui/core";
import {
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
} from "@mui/material";
import { colorTags } from "../../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { useStyles } from "../../../../App";
import {
  // Settings Users
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,
  useUploadFileMutation,
  useGetUserRolesQuery,
  use,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { BASE_URL, FILE_LINK_URL } from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 4%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "500px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const AddUser = ({ open, onClose, title, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [avatar, setAvatar] = useState([]);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  // Password visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Validation state
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    department: "",
    role: "",
  });
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };
  const {
    data: userRolesData,
    isLoading: isLoadingUserRolesData,
    isError: isErrorUserRolesData,
    error: errorUserRolesData,
  } = useGetUserRolesQuery();

  const [createUserRequest, { error, isLoading }] =
    useCreateUserRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (name.length > 50) {
      valid = false;
      errors.name = "Name should not exceed 50 characters.";
    }

    // Add email validation (you can use a regex pattern)
    // ...

    if (password.length === 0) {
      valid = false;
      errors.password = "Password is required.";
    }
    if (role.length === 0) {
      valid = false;
      errors.role = "Role is required.";
    }
    if (phone.length > 15 || !/^\d+$/.test(phone)) {
      valid = false;
      errors.phone = "Invalid phone number.";
    }

    if (address.length > 50) {
      valid = false;
      errors.address = "Address should not exceed 50 characters.";
    }

    if (department.length > 60) {
      valid = false;
      errors.department = "Department should not exceed 60 characters.";
    }

    setValidationErrors(errors);
    return valid;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone,
      address,
      department,
      role,
      status,
      userId: user?.user_id,
    };

    if (!validateInputs()) {
      return;
    }

    if (avatar.length > 0) {
      try {
        const imageResponse = await uploadImage(avatar[0]);

        data.avatar = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading image");
        data.avatar = "";
        return;
      }
    } else {
      data.avatar = "";
    }
    await createUserRequest(data)
      .then((res) => {
        console.log(res);
        if (res.data) {
          toast.success("User Created Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Creating User");
      });
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setDepartment("");
    setRole("");
    setStatus("");
    setAvatar([]);

    onClose();
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        clickable: false,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Full Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!validationErrors.name}
              helperText={validationErrors.name}
            />
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              required
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!validateEmail(email)}
              helperText={!validateEmail(email) && "Invalid email format"}
            />
            <TextField
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              required
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={!validatePhone(phone)}
              helperText={
                !validatePhone(phone) &&
                "Invalid phone number format. It should be less than 15 digits"
              }
            />
            <TextField
              label="Address"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={!!validationErrors.address}
              helperText={validationErrors.address}
            />
            <TextField
              label="Department"
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              error={!!validationErrors.department}
              helperText={validationErrors.department}
            />
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Role" style={{}}>
                Role
              </InputLabel>
              <Select
                className={classes.textField}
                label="Role"
                variant="outlined"
                required
                value={role}
                error={!!validationErrors.role}
                helperText={validationErrors.role}
                onChange={(e) => setRole(e.target.value)}>
                {userRolesData?.map((option) => (
                  <MenuItem key={option.id} value={option.roleName}>
                    {option.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Status" style={{}}>
                Status
              </InputLabel>
              <Select
                className={classes.textField}
                label="Status"
                variant="outlined"
                value={status}
                onChange={(e) => setStatus(e.target.value)}>
                {["Active", "Banned", "Inactive"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginTop: "15px" }}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                acceptedFiles={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                ]}
                dropzoneText={
                  "Drop or Click to Upload Avatar (Optional, Max 5MB, JPG, PNG, WEBP, JPEG)"
                }
                onChange={(files) => setAvatar(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Button
              variant="contained"
              color="success"
              style={{ fontWeight: "bold", color: "#fff" }}
              onClick={handleSave}>
              Save
            </Button>
            <Button
              variant="outlined"
              style={{ color: "#FF0000", marginLeft: "10px" }}
              onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddUser;
