import React, { useEffect, useState } from "react";
import { makeStyles, Container, TextField } from "@material-ui/core";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  Select,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
// import FlagContent from "../../../components/FlagContent";
import FlagContentModal from "../../../components/FlagContent";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #068FFF 0%, #75C2F6  51%, #068FFF  100%)",

  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    background: "#fff",
    marginBottom: theme.spacing(8),
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(0),
    // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    boxShadow:
      " rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: theme.spacing(1),
    background: "#fff",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  mainTitle: {
    textAlign: "center",
    color: colorTags.PRIMARY,
    marginTop: theme.spacing(8),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(35),
    minWidth: "150px",
    minHeight: "150px",
    height: theme.spacing(35),
    cursor: "pointer",
  },
  imageInput: {
    display: "none",
  },
}));
const dummyData = {
  name: "John Doe",
  email: "john.doe@example.com",
  phone: "123-456-7890",
  bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  facebook: "john.doe.fb",
  twitter: "john.doe.tw",
  linkedin: "john.doe.linkedin",
  industry: "Technology",
  exports: "Software",
  destinations: "USA",
  activeSince: new Date("2020-01-01"),
  profilePicture: null,
  status: "Active",
  role: "Admin",
};

const EditProfilePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const [userData, setUserData] = useState(null);
  const { data: userDataAPI, isLoading: isUserLoading } = useGetUserByIdQuery({
    id: params.id,
  });
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [password, setPassword] = useState("");
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (userDataAPI) setUserData(userDataAPI[0]);
  }, [userDataAPI]);

  const [formData, setFormData] = useState(dummyData);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      profilePicture: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [profilePicturePreview, setProfilePicturePreview] = useState(
    dummyData.profilePicture
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      activeSince: date,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  if (isUserLoading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  if (!userData) return <div>No user found</div>;

  return (
    <div style={{ margin: "5%" }}>
      <div className={classes.root}>
        <div
          maxWidth="md"
          className={classes.formContainer}
          style={{ background: "#fff" }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{ padding: "3%" }}>
              {/* Left Section */}
              <Grid style={{ padding: "2%" }} item xs={12} sm={6} md={4}>
                <div className={classes.avatarContainer}>
                  <label htmlFor="image-upload">
                    {profilePicturePreview ? (
                      <Avatar
                        alt={userData.name}
                        src={profilePicturePreview}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar
                        alt={formData.name}
                        src={
                          userData && userData.avatar
                            ? BASE_FILE_URL + userData.avatar
                            : images.CorpLogo
                        }
                        className={classes.avatar}
                      />
                    )}
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      className={classes.imageInput}
                      onChange={handleImageChange}
                    />
                  </label>
                  {/* <div style={{ marginTop: "5%" }}></div>
                  <Typography variant="div">
                    facebook:&nbsp;&nbsp;
                    {formData.facebook}
                  </Typography>
                  <Typography variant="div">
                    twitter:&nbsp;&nbsp;
                    {formData.twitter}
                  </Typography>
                  <Typography variant="div">
                    linkedin:&nbsp;&nbsp;
                    {formData.linkedin}
                  </Typography> */}
                </div>
              </Grid>

              {/* Right Section */}
              <Grid
                style={{
                  padding: "2%",
                }}
                item
                xs={12}
                sm={6}
                md={8}>
                <div
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}>
                  {" "}
                  <div style={{ marginTop: "5%" }}></div>
                  <Typography variant="div">
                    Name: &nbsp;{userData?.name}
                  </Typography>
                  <div style={{ marginTop: "3%" }}></div>
                  <Typography variant="div">
                    Email: &nbsp;{userData.email}
                  </Typography>
                  <div style={{ marginTop: "3%" }}></div>
                  <Typography variant="div">
                    Phone: &nbsp;{userData.phone}
                  </Typography>
                  <div style={{ marginTop: "3%" }}></div>
                  <Typography variant="div">
                    Address: &nbsp;{userData.address}
                  </Typography>
                  <div style={{ marginTop: "3%" }}></div>
                  <Typography variant="div">
                    Role: &nbsp;{userData.role}
                  </Typography>
                  <div style={{ marginTop: "3%" }}></div>
                  <Typography variant="div">
                    Department: &nbsp;{userData?.department}
                  </Typography>
                </div>
                <div style={{ marginTop: "6%" }}></div>
                <div style={{ marginTop: "3%" }}></div>
                <Typography variant="div">
                  Status: &nbsp;{userData?.status}
                </Typography>
                <div style={{ marginTop: "3%" }}></div>

                <InputLabel shrink>Added On</InputLabel>
                <Typography variant="div">
                  {new Date(userData?.createdAt).toLocaleDateString("en-US")}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "6%", paddingBottom: "4%" }}>
              {" "}
              {userData?.id === user?.user_id && (
                <Link
                  href="/dashboard/edit-user"
                  style={{ textDecoration: "none", color: "#fff" }}>
                  {" "}
                  <StyledButton
                    onClick={() =>
                      window.open(
                        `/dashboard/edit-profile/${user.user_id}`,
                        "_blank"
                      )
                    }
                    // color="primary"
                    type="submit">
                    Edit Profile
                  </StyledButton>
                </Link>
              )}
            </div>

            {/* <div>
              <Button onClick={handleOpenModal}>Open Flag Modal</Button>
              <FlagContentModal open={isModalOpen} onClose={handleCloseModal} />
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfilePage;
