import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { images } from "../../../assets/index";
import { Stack } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(0),
  },
  bigPictureContainer: {
    position: "relative",
    width: "100%",
    height: "400px",
    overflow: "hidden",
    marginBottom: theme.spacing(4),
  },
  bigPicture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  greenCardContainer: {
    position: "absolute",
    bottom: "25%",
    left: "10%",
    minHeight: "100px",
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(7),

    backgroundColor: "rgba(1, 102, 56, 0.7)",
    color: "#fff",
    borderRadius: theme.spacing(1),
  },
  newsTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  newsSummary: {
    fontSize: "16px",
  },
  circleContainer: {
    position: "absolute",
    alignSelf: "right",
    right: "8%",
    top: "51%",
    width: theme.spacing(15),
    height: theme.spacing(15),
    borderRadius: "50%",
    border: "3px solid #016638",
    overflow: "hidden",
  },
  circleImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  newsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    padding: theme.spacing(5),
  },
  dateContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(-10),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: "#526D82",
  },
  dateContain: {
    width: "60%",

    textAlign: "center",
    // backgroundColor: "#016638",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: "#92222",
  },
  newsTitleContainer: {
    width: "100%",
    textAlign: "center",

    color: colorTags.PRIMARY,
  },
  newsDescription: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(3),
  },
  imageContainer: {
    width: "40%",
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
    paddingLeft: theme.spacing(10),
  },
  paragraphContainer: {
    width: "60%",
    paddingLeft: theme.spacing(7),
  },
}));
const convertToDateWithMonthName = (dateString) => {
  const dateParts = dateString.split("/");
  const monthNumber = parseInt(dateParts[0]);
  const day = dateParts[1];
  const year = dateParts[2];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[monthNumber - 1];

  return `${monthName} ${day}, ${year}`;
};
const dummyNewsData = {
  image: images.Corp,
  title: "Ethiopian Publication",
  summary: "Summary of the news goes here. It should be a short description.",
  date: "10/05/2022",
  description:
    "This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.This is a longer paragraph describing the news in more detail. It can include multiple lines and should display properly on the page. You can add line breaks, and the text should wrap accordingly.",
};

const NewsPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <div className={classes.root}>
        <div className={classes.bigPictureContainer}>
          <img
            src={dummyNewsData.image}
            alt="News"
            className={classes.bigPicture}
          />
          <div className={classes.greenCardContainer}>
            <Typography variant="h4" className={classes.newsTitle}>
              {dummyNewsData.title}
            </Typography>
            <Typography variant="body1" className={classes.newsSummary}>
              {dummyNewsData.summary}
            </Typography>
          </div>
        </div>
        <div className={classes.circleContainer}>
          <img src={images.Blog2} alt="News" className={classes.circleImage} />
        </div>
        <div className={classes.newsContainer}>
          <Stack
            direction="column"
            spacing={4}
            style={{ width: "100%", paddingLeft: "10%" }}
            className={classes.dateContain}>
            <div className={classes.dateContainer}>
              <Typography variant="h5">
                {convertToDateWithMonthName(dummyNewsData.date)}
              </Typography>
            </div>
            <div className={classes.newsTitleContainer}>
              <Typography variant="h4" style={{ fontWeight: 400 }}>
                {dummyNewsData.title}
              </Typography>
            </div>
          </Stack>
          <Typography variant="body1" className={classes.newsDescription}>
            {dummyNewsData.description}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.newsDescription}>
          {dummyNewsData.description}
        </Typography>

        <div className={classes.newsContainer}>
          <div className={classes.imageContainer}>
            <img
              src={dummyNewsData.image}
              alt="News"
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
          </div>
          <div className={classes.paragraphContainer}>
            <Typography variant="body1">{dummyNewsData.description}</Typography>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
