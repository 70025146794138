import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
  //   CssBaseline,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Notifications from "./Notifications";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import { colorTags } from "../../../src/constants/variants";
import {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationRequestMutation,
  useUpdateNotificationRequestMutation,
  useDeleteNotificationRequestMutation,
  useMarkNotificationAsReadRequestMutation,
} from "../../features/api/apiSlice";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    height: "100vh",
  },
}));

const notifications = [
  {
    message: "Notification 1",
    date: "2021-10-10",
    time: "09:30 AM",
    seen: false,
    subject: "Subject 1",
    id: 1,
  },
  {
    message: "Notification 2",
    date: "2021-10-10",
    time: "10:15 AM",
    seen: false,
    subject: "Subject 2",
    id: 2,
  },
  {
    message: "Notification 3",
    date: "2021-10-11",
    time: "11:45 AM",
    seen: false,
    subject: "Subject 3",
    id: 3,
  },
  {
    message: "Notification 4",
    date: "2021-10-11",
    time: "02:30 PM",
    seen: false,
    subject: "Subject 4",
    id: 4,
  },
];

const NotificationsPage = () => {
  const classes = useStyles();
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  // const {
  //   data: notificationsData,
  //   isLoading: notificationsLoading,
  //   isError: isErrorNotifications,
  //   error: notificationsError,
  // } = useGetNotificationsQuery();
  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isErrorNotifications,
    error: notificationsError,
  } = useGetNotificationByIdQuery({ id: user?.user_id });

  const [
    updateNotificationRequest,
    {
      isLoading: isLoadingUpdateRequest,
      isError: isErrorUpdateRequest,
      error: errorUpdateRequest,
    },
  ] = useUpdateNotificationRequestMutation();

  const handleNotificationClose = () => {};

  const [groupedNotifications, setGroupedNotifications] = useState([]);

  useEffect(() => {
    if (notificationsData) {
      setGroupedNotifications(
        notificationsData.reduce((acc, notification) => {
          const date = new Date(notification.notifDate).toLocaleDateString(
            "en-US"
          );
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(notification);
          return acc;
        }, {})
      );
    }
  }, [notificationsData]);
  const [
    markAllAsRead,
    { isLoading: isMarkLoading, isError: isMarkError, error: markError },
  ] = useMarkNotificationAsReadRequestMutation();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    if (!isChecked) {
      setOpenRead(true);
    } else {
      setOpenRead(false);
    }
  };
  const [openRead, setOpenRead] = useState(false);
  const handleMarkAllAsRead = async (e) => {
    // setOpenRead(true)
    e.preventDefault();
    await markAllAsRead({ id: user?.user_id })
      .then((res) => {
        if (res.data) {
          setOpenRead(false);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  return (
    <div style={{}}>
      <Header />
      <Container mt={5} maxWidth="md" className={classes.content}>
        {/* <Notifications open={true} onClose={handleNotificationClose} /> */}

        <Typography variant="h6" align="left" gutterBottom>
          Notifications
        </Typography>
        {groupedNotifications && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {" "}
            {notificationsData && groupedNotifications.length > 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="success" // You can change the color here
                  />
                }
                label="Mark all as read"
              />
            )}
            {openRead ? (
              <Button
                style={{ color: colorTags.SECONDARY }}
                onClick={handleMarkAllAsRead}>
                Save
              </Button>
            ) : null}
            {/* <Button style={{color:'white'}}>Clear All</Button> */}
          </div>
        )}
        <Card
          style={{
            //  boxShadow: "rgba(100, 100, 111, 0.7) 0px 7px 29px 0px"
            boxShadow:
              "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            overflow: "auto",
          }}
          className={classes.card}>
          {/* <CardHeader title="Notifications" />
          <Divider /> */}
          <List>
            {groupedNotifications &&
              Object.entries(groupedNotifications).map(
                ([date, notifications]) => (
                  <React.Fragment key={date}>
                    <ListItem
                      style={{
                        boxShadow: "rgba(100, 100, 1, 0.2) 0px 7px 8px 0px",
                        // boxShadow:
                        //   "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                        marginBottom: "10px",
                      }}>
                      <ListItemText primary={date} />
                    </ListItem>
                    {notifications.map((notification) => (
                      <ListItem
                        key={notification.id}
                        style={{
                          // boxShadow: "rgba(100, 100, 111, 0.7) 0px 7px 8px 0px",
                          // boxShadow:
                          //   "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                          marginBottom: "10px",
                        }}>
                        <ListItemText
                          primary={notification.message}
                          secondary={new Date(
                            notification.notifDate
                          ).toLocaleDateString("en-US")}
                          secondaryTypographyProps={{ align: "right" }}
                        />
                      </ListItem>
                    ))}
                  </React.Fragment>
                )
              )}
            {!groupedNotifications && (
              <ListItem
                style={{
                  boxShadow: "rgba(100, 100, 1, 0.2) 0px 7px 8px 0px",
                  // boxShadow:
                  //   "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  marginBottom: "10px",
                }}>
                <ListItemText primary="No notifications" />
              </ListItem>
            )}
          </List>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default NotificationsPage;
