import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  LocalMallOutlined,
  AssignmentOutlined,
  CommuteOutlined,
  DescriptionOutlined,
  BusinessOutlined,
  MonetizationOnOutlined,
} from "@material-ui/icons";
import { colorTags } from "../../../constants/variants";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { images } from "../../../assets/index";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  servicesContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    background: "none",
    [theme.breakpoints.down(900)]: {
      marginTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  serviceItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 70,
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform 0.3s ease-in-out",
    },
  },
  serviceIcon: {
    // fontSize: "2rem",
    width: "60px",
    height: "60px",
    marginBottom: theme.spacing(1),
  },
  serviceText: {
    textAlign: "center",
    fontWeight: "semi-bold",
    fontSize: "16px",
    color: colorTags?.PRIMARY,
    whiteSpace: "nowrap",
    // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
  },

  servicesContainerM: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  serviceItemM: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform 0.3s ease-in-out",
    },
  },
  serviceIconM: {
    width: "60px",
    height: "60px",
    justifyContent: "center",
    backgroundColor: "red",
    marginBottom: theme.spacing(1),
  },
  serviceTextM: {
    textAlign: "center",
    fontWeight: "semi-bold",
    fontSize: "16px",
    color: colorTags?.PRIMARY,
    whiteSpace: "nowrap",
  },
}));

const Services = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const services = [
    {
      icon: images?.ProductIcon,
      title: `${"Export Products"}`,
      id: 0,
    },
    {
      icon: images?.ProcedureIcon,
      title: "Procedures",
      id: 1,
    },
    {
      icon: images?.InstitutionIcon,
      title: "Institutions",
      id: 2,
    },
    {
      icon: images?.DocumentIcon,
      title: "Documentations",
      id: 3,
    },
    {
      icon: images?.TruckIcon,
      title: "Logistics",
    },
    {
      icon: images?.LinkIcon,
      title: "Links",
      id: 4,
    },
  ];
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 6000 });
  }, []);
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "30px",
            width: "100%",
            dislay: "flex",
            justifyContent: "center",
            padding: "10px 20px",
          }}>
          <Grid
            container
            style={{ width: "100%", dislay: "flex", justifyContent: "center" }}>
            {services.map((service, index) => (
              <Grid
                key={index}
                item
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
                xs={6}
                className={classes.serviceItemM}
                onClick={() => {
                  if (service.title === "Logistics") navigate(`/Logistics`);
                  else if (service.title === "Links") navigate(`/resources`);
                  else navigate(`/repository/${service.id}`);
                }}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  data-aos="fade-in"
                  data-aos-delay={1000 + index * 100} // Adjust the delay to control the sequence
                >
                  <img
                    src={service.icon}
                    alt="service"
                    style={{
                      width: "60px",
                      height: "60px",
                      marginBottom: "8px",
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ background: "transparent" }}
                    className={classes.serviceTextM}>
                    {service.title}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <div className={classes.servicesContainer}>
          {services.map((service, index) => (
            <div
              key={index}
              className={classes.serviceItem}
              style={{ marginBottom: "30px", cursor: "pointer" }}
              onClick={() => {
                if (service.title === "Logistics") navigate(`/Logistics`);
                else if (service.title === "Links") navigate(`/resources`);
                else navigate(`/repository/${service.id}`);
              }}>
              <div data-aos="fade-in" data-aos-delay={500 + index * 100}>
                <img
                  src={service.icon}
                  alt="service"
                  className={classes.serviceIcon}
                />
              </div>
              <Typography
                variant="subtitle2"
                sx={{ background: "transparent" }}
                className={classes.serviceText}>
                {service.title}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Services;
