import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import DetailFAQ from "../FAQ/DetailFAQ";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(4), // Increased padding
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "95%",
    minWidth: "500px",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "bold", // Thin text
    color: colorTags.PRIMARY,
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DetailFlag = ({ open, onClose, title, data }) => {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [user, setUser] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (data) {
      setReason(data.reason);
      setDescription(data.description);
      setComment(data?.comment ?? "No comment given");
      setStatus(data?.status ?? "");
      setUser(data?.raisedBy ?? "");
      setContent(data?.content ?? "");
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography variant="h5" className={classes.modalTitle}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <span style={{ color: "#000", fontSize: "17px" }}>
                {" "}
                Raised By:
              </span>
              &nbsp;&nbsp; {user}
            </Typography>

            <Typography
              variant="h6"
              style={{ fontWeight: "bold" }}
              onClick={() => window.open(content, "_blank")}>
              <span
                style={{
                  color: "#000",
                  fontSize: "17px",
                  cursor: "pointer",
                }}>
                {" "}
                Content:
              </span>
              &nbsp;&nbsp;{" "}
              <span
                style={{
                  color: "#00A9FF",
                  fontSize: "17px",
                  cursor: "pointer",
                }}>
                <a
                  style={{ textDecoration: "none" }}
                  href={content}
                  target="_blank"></a>
              </span>
              {content}
            </Typography>

            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <span style={{ color: "#000", fontSize: "17px" }}> Reason:</span>
              &nbsp;&nbsp; {reason}
            </Typography>

            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <span style={{ color: "#000", fontSize: "17px" }}>
                {" "}
                Description:
              </span>
              &nbsp;&nbsp; {description}
            </Typography>

            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              <span style={{ color: "#000", fontSize: "17px" }}> Comment:</span>
              &nbsp;&nbsp; {comment}
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <Button
                  variant="outlined"
                  style={{ fontWeight: "bold", color: colorTags.PRIMARY }}
                  onClick={onClose}>
                  Close
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DetailFlag;
