import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Card, MenuItem } from "@mui/material";
import bgi from "../../../assets/h2.png";
import SearchIcon from "@material-ui/icons/Search";
import { images } from "../../../assets/index";
import {
  colorTags,
  primaryColor,
  secondaryColor,
  accentColor,
} from "../../../constants/variants";
import SearchBox from "../../../components/SearchBox";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  topBar: {
    width: "100%",
    height: 300,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchBar: {
    width: "60%",
    position: "relative",
    top: 87,
    borderRadius: "50px",
    // backgroundColor: "white",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: "0px",
    [theme.breakpoints.down(600)]: {
      width: "80%",
    },
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const [searchBy, setSearchBy] = useState("Product");
  const [searchText, setSearchText] = useState("");
  const [image, setImage] = useState("");
  const handleSearch = () => {};
  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 1 });
  useEffect(() => {
    if (imageData) {
      setImage(imageData?.image);
    }
  }, [imageData]);

  return (
    <div
      className={classes.topBar}
      style={{
        backgroundImage: image
          ? `url(${BASE_FILE_URL + image})`
          : `url(${images?.MainImage2})`,
      }}>
      {/* <TextField
        className={classes.searchBar}
        variant="outlined"
        placeholder="Search"
      /> */}
      {/* <Card
        style={{ padding: "5px", borderRadius: "20px" }}
        className={classes.searchBar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: "15px",
          }}>
          <div
            style={{
              marginRight: "8px",
              marginLeft: "10px",
              borderRadius: "15px",
            }}>
            Search by:
          </div>
          <TextField
            select
            variant="outlined"
            color="success"
            // label="Search by"
            // focused
            // variant="standard"
            fullWidth
            value={searchBy}
            onChange={(e) => {
              setSearchBy(e.target.value);
            }}
            style={{ width: "20%", borderRadius: "4px" }}
            SelectProps={{
              style: {
                borderRadius: "14px",

                backgroundColor: "#F0F0F0", 
                height: "32px",
                // padding: "0 8px 8px 8px",
                alignItems: "center",
              },
            }}>
            <MenuItem value="hsCode">HS Code</MenuItem>
            <MenuItem value="product">Product Name</MenuItem>
            <MenuItem value="destination">Destination</MenuItem>
          </TextField>

          <TextField
            variant="outlined"
            placeholder="Search"
            color="success"
            value={searchText}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  onClick={handleSearch}
                  style={{ cursor: "pointer" }}
                />
              ),
              style: {
                borderRadius: "14px",
                backgroundColor: "#F0F0F0", 
                height: "32px",
                padding: "0 8px",
                marginLeft: "30px", 
                marginRight: "10px", 
              },
            }}
            style={{ flex: 1 }} 
          />
        </div>
      </Card> */}
      <div className={classes.searchBar}>
        <SearchBox />
      </div>
    </div>
  );
};

export default TopBar;
