import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { colorTags } from "../../../../constants/variants";

const AddMainStep = ({ open, onClose }) => {
  const [mainStepDescription, setMainStepDescription] = useState("");
  const [substeps, setSubsteps] = useState([]);
  const [substepDescription, setSubstepDescription] = useState("");
  const [substepDetail, setSubstepDetail] = useState("");

  const handleAddSubstep = () => {
    if (substepDescription.trim() !== "" && substepDetail.trim() !== "") {
      const newSubstep = {
        description: substepDescription,
        detail: substepDetail,
      };
      setSubsteps([...substeps, newSubstep]);
      setSubstepDescription("");
      setSubstepDetail("");
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = [...substeps];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSubsteps(items);
  };

  const handleSave = () => {
    // You can save the main step description and reordered substeps here
    console.log("Main Step Description:", mainStepDescription);
    console.log("Reordered Substeps:", substeps);
    handleClose();
  };

  const handleClose = () => {
    setMainStepDescription("");
    setSubsteps([]);
    setSubstepDescription("");
    setSubstepDetail("");
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add Main Step</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Main Step Description"
          variant="outlined"
          color="success"
          value={mainStepDescription}
          onChange={(e) => setMainStepDescription(e.target.value)}
          margin="normal"
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="substeps">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  maxHeight: "350px", // Set a maximum height for scrolling
                  overflowY: "auto", // Enable vertical scrolling
                }}>
                {substeps.map((substep, index) => (
                  <Draggable
                    key={index}
                    draggableId={`substep-${index}`}
                    index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Card
                          key={index}
                          variant="outlined"
                          style={{ marginTop: 16 }}>
                          <CardContent>
                            <Typography
                              variant="subtitle1"
                              style={{ color: colorTags.SECONDARY }}
                              gutterBottom>
                              Substep {index + 1}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Description: {substep.description}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Detail: {substep.detail}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              color="success"
              label="Substep Description"
              variant="outlined"
              value={substepDescription}
              onChange={(e) => setSubstepDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              color="success"
              label="Substep Detail"
              variant="outlined"
              value={substepDetail}
              onChange={(e) => setSubstepDetail(e.target.value)}
            />
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddSubstep}
            style={{ marginTop: 16 }}>
            Add Substep
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          style={{ marginRight: 16 }}
          color="success">
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMainStep;
