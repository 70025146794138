import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Fade,
  Divider,
  Select,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";

import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useGetDestinationsQuery,
  useGetDestinationByIdQuery,
  useCreateDestinationRequestMutation,
  useUpdateDestinationRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "50%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));
const currencyOptions = [
  "USD",
  "EUR",
  "GBP",
  "JPY",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
  // Add more currency options as needed
];
const EditDestination = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [destinationName, setDestinationName] = useState("");
  const [summary, setSummary] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [tarifCode, setTarifCode] = useState("");
  const [tarif, setTarif] = useState("");
  const [tradePortal, setTradePortal] = useState("");
  const [mainCurrency, setMainCurrency] = useState("");
  const [continent, setContinent] = useState("");

  const [cover, setCover] = useState([]);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [formErrors, setFormErrors] = useState({
    destinationsName: "",
    summary: "",
    countryCode: "",
    tarifCode: "",
    tarif: "",
    tarifCode: "",
    mainCurrency: "",
  });

  const [
    updateDestinationRequest,
    {
      isLoading: isUpdateDestinationLoading,
      isSuccess: isUpdateDestinationSuccess,
      isError: isUpdateDestinationError,
      error: updateDestinationError,
    },
  ] = useUpdateDestinationRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  useEffect(() => {
    if (data) {
      setDestinationName(data?.destinationName);
      setSummary(data?.summary);
      setCountryCode(data?.countryCode);
      setTarifCode(data?.tarifCode);
      setTarif(data?.tarif);
      setTradePortal(data?.tradePortal);
      setMainCurrency(data?.mainCurrency);
      setContinent(data?.continent);
    }
  }, [data]);

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!destinationName) {
      errors.destinationsName = "Destination name is required";
    } else if (destinationName.length > 50) {
      errors.hsCode = "Destination name cannot exceed 50 characters";
    }
    if (summary.length > 100) {
      errors.summary = "Summary cannot exceed 100 characters";
    }
    if (countryCode.length > 3) {
      errors.countryCode = "Country code cannot exceed 3 characters";
    }
    if (tarifCode.length > 10) {
      errors.tarifCode = "Tarif code cannot exceed 10 characters";
    }
    if (tarif.length > 10) {
      errors.tarif = "Tarif cannot exceed 10 characters";
    }
    if (mainCurrency.length > 3) {
      errors.mainCurrency = "Currency cannot exceed 3 characters";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const dataUp = {
      id: data?.id,
      destinationName,
      tarifCode,
      summary,
      countryCode,
      tarif,
      mainCurrency,
      continent,
      tradePortal,
      tradeLinks: [],
      userId: user?.user_id,
    };

    if (cover.length > 0) {
      try {
        const imageResponse = await uploadImage(cover[0]);

        dataUp.coverImage =
          imageResponse?.dbPaths[0]?.documentName ?? data?.coverImage;
      } catch (error) {
        toast.error("Error uploading image");
        dataUp.coverImage = data?.coverImage;
        return;
      }
    } else {
      dataUp.coverImage = data?.coverImage;
    }

    await updateDestinationRequest(dataUp)
      .then((res) => {
        toast.success("Data is saved");
      })
      .catch((err) => toast.error("Data is not saved"));

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Destination Name"
              variant="outlined"
              placeholder="Destination Name"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={destinationName}
              onChange={(e) => setDestinationName(e.target.value)}
              error={formErrors.destinationsName}
              helperText={formErrors.destinationsName}
            />
            <TextField
              label="Summary(optional)"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              error={formErrors.summary}
              helperText={formErrors.summary}
            />
            <TextField
              label="Country Code(optional)"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              error={formErrors.countryCode}
              helperText={formErrors.countryCode}
            />
            {/* <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Continent" style={{}}>
                Continent(optional)
              </InputLabel>
              <Select
                className={classes.textField}
                label="Continent(optional)"
                variant="outlined"
                value={continent}
                onChange={(e) => setContinent(e.target.value)}>
                {[
                  "Africa",
                  "Asia",
                  "Europe",
                  "North America",
                  "South America",
                  "Oceania",
                  "Antarctica",
                ].map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Tarif Code(optional)"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={tarifCode}
              onChange={(e) => setTarifCode(e.target.value)}
              error={formErrors.tarifCode}
              helperText={formErrors.tarifCode}
            />
            <TextField
              label="Tarif(optional)"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={tarif}
              onChange={(e) => setTarif(e.target.value)}
              error={formErrors.tarif}
              helperText={formErrors.tarif}
            /> */}
            {/* <TextField
              label="Trade Portal"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              value={tradePortal}
              onChange={(e) => setTradePortal(e.target.value)}
            /> */}
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}>
              <InputLabel id="main-currency-label">
                Currency(optional)
              </InputLabel>
              <Select
                labelId="main-currency-label"
                id="main-currency"
                value={mainCurrency}
                onChange={(e) => setMainCurrency(e.target.value)}
                label="Currency(optional)">
                {currencyOptions.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={500000}
                dropzoneText={"Drop or Click to Upload Cover Image(optional)"}
                onChange={(files) => setCover(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </Button>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditDestination;
