import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info, OpenInNew } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../../components";
import { primaryColor, colorTags } from "../../../../../constants/variants";
import GridData from "../../../../../components/GridData";
import AddLaw from "./AddLaw";
import DeleteDialog from "../../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useCreateDocumentRequestMutation,
  useUpdateDocumentRequestMutation,
  useDeleteDocumentRequestMutation,
} from "../../../../../features/api/apiSlice";
import DownloadAttachment from "../../../../../components/DownloadAttachment";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { BASE_FILE_URL } from "../../../../../constants/apiTags";

import EditLaw from "./EditLaw";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const lawsData = [
  {
    id: 1,
    title: "Law 1",
    documentName: "document1.pdf",
    description: "Description for Law 1",
    createdOn: "2023-06-01",
    updatedOn: "2023-06-05",
  },
  {
    id: 2,
    title: "Law 2",
    documentName: "document2.pdf",
    description: "Description for Law 2",
    createdOn: "2023-06-02",
    updatedOn: "2023-06-06",
  },
  {
    id: 3,
    title: "Law 3",
    documentName: "document3.pdf",
    description: "Description for Law 3",
    createdOn: "2023-06-03",
    updatedOn: "2023-06-07",
  },
  {
    id: 4,
    title: "Law 4",
    documentName: "document4.pdf",
    description: "Description for Law 4",
    createdOn: "2023-06-04",
    updatedOn: "2023-06-08",
  },
  {
    id: 5,
    title: "Law 5",
    documentName: "document5.pdf",
    description: "Description for Law 5",
    createdOn: "2023-06-05",
    updatedOn: "2023-06-09",
  },
  {
    id: 6,
    title: "Law 6",
    documentName: "document6.pdf",
    description: "Description for Law 6",
    createdOn: "2023-06-06",
    updatedOn: "2023-06-10",
  },
  {
    id: 7,
    title: "Law 7",
    documentName: "document7.pdf",
    description: "Description for Law 7",
    createdOn: "2023-06-07",
    updatedOn: "2023-06-11",
  },
  {
    id: 8,
    title: "Law 8",
    documentName: "document8.pdf",
    description: "Description for Law 8",
    createdOn: "2023-06-08",
    updatedOn: "2023-06-12",
  },
  {
    id: 9,
    title: "Law 9",
    documentName: "document9.pdf",
    description: "Description for Law 9",
    createdOn: "2023-06-09",
    updatedOn: "2023-06-13",
  },
  {
    id: 10,
    title: "Law 10",
    documentName: "document10.pdf",
    description: "Description for Law 10",
    createdOn: "2023-06-10",
    updatedOn: "2023-06-14",
  },
];

export default function LawsRegulationsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [documents, setDocuments] = useState([]);

  const {
    data: documentsData,
    isLoading: isLoadingDocumentsData,
    isError: isErrorDocumentsData,
    error: DocumentsDataError,
  } = useGetDocumentsQuery();

  useEffect(() => {
    if (documentsData) {
      setDocuments(documentsData.filter((item) => item.category === "Law"));
    }
  }, [documentsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "documentName",
      headerName: "Document",
      flex: 1,
      renderCell: (params) => (
        <div>
          {" "}
          <IconButton
            href={BASE_FILE_URL + "/" + params?.row?.documentName}
            target="_blank"
            download>
            <OpenInNew color="primary" />
          </IconButton>{" "}
        </div>
      ),
    },

    {
      field: "createdOn",
      headerName: "Created On",
      type: "date",
      flex: 1,
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            {/* <IconButton
              onClick={() => navigate("/law-detail/" + params?.row?.id)}>
              <Info color="primary" />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                console.log(params?.row);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteDocumentRequestMutation}
              dialogLabel="Delete Law Document"
              id={params?.row?.id}
              name={`${params?.row?.title}`}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  if (isLoadingDocumentsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          backgroundColor: primaryColor,
        }}
        onClick={handleModalOpen}>
        Add Law/Regulation
      </Button>
      <AddLaw
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Law/Regulation"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Laws and Regulations List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={documents ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditLaw
        open={editModalOpen}
        onClose={handleEditModalClose}
        onSave={handleModalClose}
        title="Edit Law Document"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
    </div>
  );
}
