import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useGetPublicationsQuery,
  useGetPublicationByIdQuery,
  useCreatePublicationRequestMutation,
  useUpdatePublicationRequestMutation,
  useDeletePublicationRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { FILE_LINK_URL } from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const EditPublication = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [docTitle, setDocTitle] = useState("");
  const [cover, setCover] = useState([]);
  const [document, setDocument] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [coverError, setCoverError] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    if (data) {
      setDocTitle(data?.title);
    }
  }, [data]);

  const [
    updatePublicationRequest,
    {
      isError: isErrorUpdateRequest,
      isLoading: isLoadingUpdateRequest,
      error: errorUpdateRequest,
    },
  ] = useUpdatePublicationRequestMutation();

  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updateData = {
      id: data?.id,
      documentName: data?.documentName,
      coverImage: data?.coverImage ?? "",
      title: docTitle,
      userId: user?.user_id,
    };

    // Validate document file
    if (document.length > 0) {
      try {
        const imageResponse = await uploadImage(document[0]);

        updateData.documentName =
          imageResponse?.dbPaths[0]?.documentName ?? data?.documentName;
      } catch (error) {
        toast.error("Error uploading file");
        updateData.documentName = data?.documentName;
        return;
      }
    }

    // Validate cover image
    if (cover.length > 0) {
      try {
        const imageResponse = await uploadImage(cover[0]);

        updateData.coverImage =
          imageResponse?.dbPaths[0]?.documentName ?? data?.coverImage;
      } catch (error) {
        toast.error("Error uploading image");
        updateData.coverImage = data?.coverImage;
        return;
      }
    }

    await updatePublicationRequest(updateData)
      .then((res) => {
        toast.success("Publication updated successfully");
        onClose();
      })
      .catch((err) => {
        toast.error("Error updating publication");
        onClose();
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Publication Title"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
            />
            {/* Include other fields to edit here */}
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={"Drop or Click to Upload Publication File"}
                onChange={(files) => setDocument(files)}
                acceptedFiles={[".pdf"]}
                error={!!documentError}
                helperText={documentError}
              />
            </div>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                required={true}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                acceptedFiles={[
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                  "image/webp",
                ]}
                dropzoneText={"Drop or Click to Upload Cover Image (Optional)"}
                onChange={(files) => setCover(files)}
                error={!!coverError}
                helperText={coverError}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingUpdateRequest}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditPublication;
