import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
// import ProductsPage from "./Products/Products";

import { colorTags } from "../../../../constants/variants";

import ExportPage from "./Exports";
import TemporaryExports from "./TemporaryExports";
import Perishables from "./Perishables";
import NonPerishables from "./NonPerishables";
import TopProducts from "./TopProducts";
import { useGetProductsQuery } from "../../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#F1F1F1",
    // padding: theme.spacing(12),
    // paddingTop: theme.spacing(2),
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    // marginTop: theme.spacing(2),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
}));

const ProductsPage = ({ searchBy, query }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchResult, setSearchResult] = useState([]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const {
    data: productData,
    isError: errorProductData,
    isLoading: loadingProductData,
  } = useGetProductsQuery();
  // ...

  // ...

  useEffect(() => {
    if (productData) {
      const lowerQuery = query.toLowerCase();

      setSearchResult(
        productData?.filter((product) => {
          if (!searchBy) {
            const destinationsArray = JSON.parse(product.destinations);

            return (
              product.productName?.toLowerCase().includes(lowerQuery) ||
              (Array.isArray(destinationsArray) &&
                destinationsArray.some((dest) =>
                  dest?.toLowerCase().includes(lowerQuery)
                )) ||
              product.hsCode?.toLowerCase().includes(lowerQuery)
            );
          }
          if (searchBy === "destination") {
            const destinationsArray = JSON.parse(product.destinations);

            return (
              Array.isArray(destinationsArray) &&
              destinationsArray.some((dest) =>
                dest?.toLowerCase().includes(lowerQuery)
              )
            );
          } else if (searchBy === "product" || searchBy === "Product") {
            return product.productName?.toLowerCase().includes(lowerQuery);
          } else if (searchBy === "hsCode") {
            return product.hsCode?.toLowerCase().includes(lowerQuery);
          }
          return false;
        })
      );
    }
  }, [productData, searchBy, query]);

  // ...

  // ...

  // import { Bars } from "react-loader-spinner";
  if (loadingProductData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {" "}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          ".Mui-selected": {
            color: `${colorTags.SECONDARY} !important`,
          },
        }}
        TabIndicatorProps={{
          sx: { backgroundColor: colorTags.SECONDARY },
        }}
        centered
        variant="fullWidth"
        style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
        <Tab
          label="Export"
          className={selectedTab === 0 ? classes.selectedTab : ""}
        />
        <Tab
          label="Temporary Export"
          className={selectedTab === 1 ? classes.selectedTab : ""}
        />
        <Tab
          label="Perishable Goods"
          className={selectedTab === 2 ? classes.selectedTab : ""}
        />
        <Tab
          label="Non-Perishable Goods"
          className={selectedTab === 3 ? classes.selectedTab : ""}
        />
        {/* <Tab
          label="Top"
          className={selectedTab === 4 ? classes.selectedTab : ""}
        /> */}
      </Tabs>
      {/* <SearchBox /> */}
      <div className={classes.content}>
        {selectedTab === 0 && <ExportPage data={searchResult} />}
        {selectedTab === 1 && (
          <TemporaryExports
            data={searchResult?.filter(
              (product) => product.type === "Temporary"
            )}
          />
        )}
        {selectedTab === 2 && (
          <Perishables
            data={searchResult?.filter(
              (product) => product.type === "Perishable"
            )}
          />
        )}
        {selectedTab === 3 && (
          <NonPerishables
            data={searchResult?.filter(
              (product) => product.type === "Non-Perishable"
            )}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
