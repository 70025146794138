import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button, Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index";
import { colorTags } from "../../constants/variants";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import { TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { authService } from "../../features/auth2/authService";
import { useUser } from "../../features/auth2/UserContext";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { login } from "../../features/auth2/authSlice";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.5) 50%, transparent -100%), url(${images.BlurBg1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  card: {
    maxWidth: 300,
    padding: theme.spacing(0),
    marginTop: theme.spacing(-7),
    textAlign: "center",
    borderRadius: theme.spacing(2),
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",

    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(0),
      textAlign: "center",
      borderRadius: theme.spacing(2),
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.PRIMARY,
    "&:hover": {
      backgroundColor: colorTags.SECONDARY,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  otherText: {
    color: colorTags.PRIMARY,
    fontSize: "13px",
  },
  otherText2: {
    color: colorTags.PRIMARY,
    fontSize: "14px",
  },
  leftText: {
    display: "none",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
      position: "absolute",
      left: "8%",
      top: "35%",
      textAlign: "left",
      color: "#006738",
      zIndex: 1,
    },
  },
}));

const Login = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggle, setToggle] = useState(false);
  const [userType, setUserType] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoad(true);
    // const token = "your-authentication-token";
    // onLogin(token);
    // window.location.reload();
    if (toggle) {
      setUserType("user");
    } else {
      // setUserType("member");
      setUserType("user");
    }
    const data = {
      email: email,
      userPassword: password,
      userType: toggle ? "user" : "member",
      // userType: toggle ? "user" : "user",
    };
    console.log(data);
    try {
      const response = await authService.login(data);
      // Store the authentication token in local storage
      console.log(response);
      dispatch(
        login({ user: response.user, accessToken: response.accessToken })
      );

      localStorage.setItem("authToken", response.accessToken);
      localStorage.setItem("user", JSON.stringify(response.user[0]));

      toast.success("welcome");
      if (response.user && response.user.isAdmin) {
        window.open("/dashboard", "_self");
        navigate(`/`);
      } else {
        navigate(`/profile/${response.user[0].id}`);
        navigate(`/`);
      }
    } catch (error) {
      console.error(error);
      toast.error("Login Failed");
    }
    setLoad(false);
  };
  if (localStorage.getItem("authToken")) {
    window.open("/", "_self");
  } else
    return (
      <div>
        <Header />
        <div className={classes.root}>
          <div className={classes.leftText}>
            <div>
              <img
                src={images.AcLogo}
                alt="logo"
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "20px",
                }}
              />
            </div>
            {/* <Typography variant="h4">Welcome to AACCSA Export Portal</Typography> */}
            <Typography variant="body1">
              Enjoy the perks of being a member.
            </Typography>
          </div>

          <Card className={classes.card}>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={toggle}
                  color="success"
                  onChange={() => setToggle(!toggle)}
                  name="staff-toggle"
                />
              }
              // label="Staff?"
              labelPlacement="start"
              style={{ position: "absolute", top: 20, right: 20 }}
            /> */}
            <CardContent>
              <Typography
                variant="h5"
                className={classes.title}
                style={{ color: colorTags.SECONDARY, zIndex: 1000 }}>
                Login
              </Typography>
              <form onSubmit={handleLogin}>
                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  className={classes.inputField}
                  variant="outlined"
                  color="success"
                  sx={{
                    marginBottom: "20px",
                    marginTop: "10px",
                    width: "100%",
                  }}
                />

                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  color="success"
                  onChange={handlePasswordChange}
                  fullWidth
                  className={classes.inputField}
                  variant="outlined"
                />
                <LoadingButton
                  loading={load}
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{
                    marginTop: "10px",
                  }}
                  className={classes.button}>
                  Sign In
                </LoadingButton>
              </form>
              <Typography
                // textAlign={"end"}
                style={{ zIndex: 10000, marginTop: "10px" }}
                variant="body2">
                <Button
                  onClick={() => navigate("/forgot-password")}
                  className={classes.otherText}>
                  Forgot password?
                </Button>
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                Don't have an account?{" "}
                <LoadingButton
                  onClick={() => navigate("/#")}
                  // onClick={() => navigate("/signup")}

                  className={classes.otherText2}>
                  Sign Up
                </LoadingButton>
              </Typography> */}
            </CardContent>
          </Card>
        </div>
        <Footer />
      </div>
    );
};

export default Login;
