import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button, Link } from "@material-ui/core";
import { Stack } from "@mui/material";
import { images } from "../../../assets/index";
import {
  useGetPromotionsQuery,
  useGetMembersQuery,
} from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import _ from "lodash";
import { colorTags } from "../../../constants/variants";

const img = images.Promote;

const useStyles = makeStyles((theme) => ({
  container: {
    height: 220,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    overflow: "hidden",
  },
  carousel: {
    display: "flex",
    animation: "$slideCarousel 30s linear infinite",
  },
  card: {
    width: 250,
    height: 200, // Increased the card height
    margin: theme.spacing(0, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 10,
    backgroundColor: "#FAFAFA",
    position: "relative", // Added for button positioning
  },
  cardImage: {
    width: "100%",
    height: "60%", // Increased the image height
    objectFit: "cover",
  },
  companyName: {
    color: "#016638",
    fontWeight: "bold",
    fontSize: "16px", // Increased the font size
    textAlign: "left", // Justified the text
  },
  description: {
    textAlign: "center",
    fontSize: "12px",
  },
  button: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#fff",
    fontSize: "14px",
    padding: theme.spacing(1),
  },
  "@keyframes slideCarousel": {
    "0%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
}));

const CustomComponent = () => {
  const classes = useStyles();
  const carouselRef = useRef(null);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const {
    data: promotionsData,
    isLoading: isLoadingPromotionsData,
    isError: isErrorPromotionsData,
  } = useGetPromotionsQuery();
  // const {
  //   data: membersData,
  //   isLoading: isLoadingMembersData,
  //   isError: isErrorMembersData,
  // } = useGetMembersQuery();
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleMouseEnter = () => {
      carousel.style.animationPlayState = "paused";
    };

    const handleMouseLeave = () => {
      carousel.style.animationPlayState = "running";
    };

    carousel.addEventListener("mouseenter", handleMouseEnter);
    carousel.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      carousel.removeEventListener("mouseenter", handleMouseEnter);
      carousel.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    if (promotionsData) {
      const filteredAndUpdatedPromotions = promotionsData
        .filter((promotion) => promotion.status === "Approved")
        .map((promotion) => ({
          ...promotion,
          companyName:
            promotion.companyName === ""
              ? "Promote your company here!"
              : promotion.companyName,
          // Assuming coverImage and bio are already set in promotionsData
        }));

      setPromotions(filteredAndUpdatedPromotions);
    }
  }, [promotionsData]);

  const sponsors = [
    {
      companyName: "Promote your company here!",
      description: "",
      coverImage: null,
    },
    {
      companyName: "Promote your company here!",
      description: "",
      coverImage: null,
    },
    {
      companyName: "Promote your company here!",
      description: "",
      coverImage: null,
    },
    {
      companyName: "Promote your company here!",
      description: "",
      coverImage: null,
    },
  ];

  return (
    <Stack direction="column">
      <Stack direction="column" spacing={1} pt={2}>
        <div className={classes.container}>
          <div className={classes.carousel} ref={carouselRef}>
            {promotions?.length > 0
              ? promotions?.map((sponsor, index) => (
                  <Card
                    className={classes.card}
                    key={index}
                    style={{
                      boxShadow:
                        sponsor?.level === "Platinum"
                          ? "0px 0px 15px rgba(255, 223, 186, 0.8)" // Premium color for Platinum
                          : sponsor.level === "Gold"
                          ? "0px 0px 15px rgba(255, 215, 0, 0.8)" // Premium color for Gold
                          : sponsor.level === "Silver"
                          ? "0px 0px 15px rgba(192, 192, 192, 0.8)" // Premium color for Silver
                          : null,
                      cursor: "pointer",
                    }}>
                    <img
                      src={
                        sponsor?.coverImage && sponsor?.coverImage
                          ? BASE_FILE_URL + sponsor?.coverImage
                          : img
                      }
                      alt="Sponsor"
                      className={classes.cardImage}
                    />
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.companyName}
                        onClick={() => {
                          if (
                            sponsor?.companyName !==
                            "Promote your company here!"
                          ) {
                            window.location.href = `/profile/${sponsor.memberID}`;
                          } else {
                            // Replace "/request-sponsorship" with the correct URL if needed
                            window.location.href = "/promotion";
                          }
                        }}
                        style={{ cursor: "pointer" }}>
                        {sponsor.companyName}
                      </Typography>

                      {sponsor.bio ? (
                        <Typography
                          variant="body2"
                          className={classes.description}>
                          {sponsor.bio.length > 70
                            ? sponsor.bio.slice(0, 70) + "..."
                            : sponsor.bio}
                        </Typography>
                      ) : null}

                      <Link
                        onClick={() => {
                          if (
                            sponsor?.companyName !==
                            "Promote your company here!"
                          ) {
                            window.location.href = `/profile/${sponsor.memberID}`;
                          } else {
                            // Replace "/request-sponsorship" with the correct URL if needed
                            window.location.href = "/promotion";
                          }
                        }}
                        underline="none"
                        className={classes.button}
                        style={{
                          // width: "50%",
                          backgroundColor: "transparent",
                          color: colorTags.SECONDARY,
                          // sponsor.level === "Platinum"
                          //   ? "#000" // Premium color for Platinum
                          //   : sponsor.level === "Gold"
                          //   ? "#000" // Premium color for Gold
                          //   : sponsor.level === "Silver"
                          //   ? "#000" // Premium color for Silver
                          //   : null,

                          //  sponsor.level === "Platinum"
                          //     ? "#FFDFBA" // Premium color for Platinum
                          //     : sponsor.level === "Gold"
                          //     ? "#FFD700" // Premium color for Gold
                          //     : sponsor.level === "Silver"
                          //     ? "#C0C0C0" // Premium color for Silver
                          //     : null,
                        }}>
                        {sponsor.companyName ===
                        "Promote your company here!" ? (
                          sponsor.level
                        ) : (
                          <>Visit Profile</>
                        )}
                      </Link>
                    </CardContent>
                  </Card>
                ))
              : sponsors.map((sponsor, index) => (
                  <Card
                    className={classes.card}
                    key={index}
                    style={{
                      boxShadow:
                        sponsor.level === "Platinum"
                          ? "0px 0px 15px rgba(255, 223, 186, 0.8)" // Premium color for Platinum
                          : sponsor.level === "Gold"
                          ? "0px 0px 15px rgba(255, 215, 0, 0.8)" // Premium color for Gold
                          : sponsor.level === "Silver"
                          ? "0px 0px 15px rgba(192, 192, 192, 0.8)" // Premium color for Silver
                          : null,
                      cursor: "pointer",
                    }}>
                    <img
                      src={
                        sponsor.coverImage
                          ? BASE_FILE_URL + sponsor.coverImage
                          : img
                      }
                      alt="Sponsor"
                      className={classes.cardImage}
                    />
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.companyName}
                        onClick={() => {
                          if (
                            sponsor?.companyName !==
                            "Promote your company here!"
                          ) {
                            window.location.href = `/profile/${sponsor.memberID}`;
                          } else {
                            // Replace "/request-sponsorship" with the correct URL if needed
                            window.location.href = "/promotion";
                          }
                        }}>
                        {sponsor.companyName}
                      </Typography>

                      {sponsor.bio ? (
                        <Typography
                          variant="body2"
                          className={classes.description}>
                          {sponsor.bio.length > 70
                            ? sponsor.bio.slice(0, 70) + "..."
                            : sponsor.bio}
                        </Typography>
                      ) : null}

                      <Link
                        onClick={() => {
                          if (
                            sponsor?.companyName !==
                            "Promote your company here!"
                          ) {
                            window.location.href = `/profile/${sponsor.memberID}`;
                          } else {
                            // Replace "/request-sponsorship" with the correct URL if needed
                            window.location.href = "/promotion";
                          }
                        }}
                        underline="none"
                        className={classes.button}
                        style={{
                          // width: "50%",
                          backgroundColor: "transparent",
                          color: colorTags.SECONDARY,
                          // sponsor.level === "Platinum"
                          //   ? "#000" // Premium color for Platinum
                          //   : sponsor.level === "Gold"
                          //   ? "#000" // Premium color for Gold
                          //   : sponsor.level === "Silver"
                          //   ? "#000" // Premium color for Silver
                          //   : null,

                          //  sponsor.level === "Platinum"
                          //     ? "#FFDFBA" // Premium color for Platinum
                          //     : sponsor.level === "Gold"
                          //     ? "#FFD700" // Premium color for Gold
                          //     : sponsor.level === "Silver"
                          //     ? "#C0C0C0" // Premium color for Silver
                          //     : null,
                        }}>
                        {sponsor.companyName ===
                        "Promote your company here!" ? (
                          sponsor.level
                        ) : (
                          <>Visit Profile</>
                        )}
                      </Link>
                    </CardContent>
                  </Card>
                ))}
          </div>
        </div>
      </Stack>
    </Stack>
  );
};

export default CustomComponent;
