export const genderList = [
  { id: 0, label: "Male", value: "Male" },
  { id: 1, label: "Female", value: "Female" },
];

export const countryList = [{ id: 0, label: "Ethiopia", value: "Ethiopia" }];

export const reasonsList = [
  "Inappropriate content",
  "Offensive language",
  "Spam",
  "Other",
];
