import {
  BusinessCenter as BusinessCenterIcon,
  HowToReg as HowToRegIcon,
  History as HistoryIcon,
  WorkOutline as WorkOutlineIcon,
  Notifications as NotificationsIcon,
  SwapHoriz as SwapHorizIcon,
  ReceiptLongOutlined as ReceiptLongOutlinedIcon,
  Group as GroupIcon,
  HowToRegOutlined as HowToRegOutlinedIcon,
  PersonRemoveAlt1 as PersonRemoveAlt1Icon,
  Badge as BadgeIcon,
  PostAdd as PostAddIcon,
  Groups as GroupsIcon,
  Person as PersonIcon,
  ViewWeek as ViewWeekIcon,
  CleanHands as CleanHandsIcon,
  MonitorHeart as MonitorHeartIcon,
  AccountTree as AccountTreeIcon,

  GetAppOutlined ,
  CategoryOutlined ,
  LocationOnOutlined,
  AssignmentOutlined,
  BusinessOutlined,
  ArticleOutlined,
  AnnouncementOutlined,
  DescriptionOutlined,
  MonetizationOnOutlined ,
  SupervisorAccountOutlined ,
  NotificationsOutlined ,
  SettingsOutlined,
  ChatOutlined,
  FlagOutlined,
  ContactSupportOutlined,
  LinkOutlined
  
} from "@mui/icons-material";
 import GalleryIcon from "@mui/icons-material/PhotoLibrary";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Event } from "@mui/icons-material";
import { LibraryBooks } from "@mui/icons-material";
import { Description } from "@material-ui/icons";



export default function () {
  const menus = [
   
    {
      label: "Export",
      icon: <GetAppOutlined />,
      permission: true,

      children: [
        {
          to: "/dashboard/products",
          label: "Products",
          permission: true,

          icon: <CategoryOutlined  />,
        },
        {
          to: "/dashboard/destinations",
          label: "Destinations",
          permission: true,

          // permission:
          //   userRole?.hrPermissions?.canViewSettings ||
          //   userRole?.hrPermissions?.canViewDepartments,
          icon: <LocationOnOutlined />,
        },
        {
          to: "/dashboard/procedures-list",
          label: "Procedures",
          permission: true,

          icon: <AssignmentOutlined />,
        },
        {
          to: "/dashboard/institutions",
          label: "Institutions",
          permission: true,

          icon: <BusinessOutlined />,
        },
        {
          to: "/dashboard/documents",
          label: "Documents",
          permission: true,

          icon: <Description />,
        },
        {
          to: "/dashboard/links",
          label: "Links",
          permission: true,

          icon: <LinkOutlined
          />,
        },
      ],
    },
    {
      label: "Posts",
      icon: <ArticleOutlined />,
      children: [
        {
          to: "/dashboard/news",
          label: "News",
          permission: true,
        
          icon: <AnnouncementOutlined />,
        },
        {
          to: "/dashboard/publications",
          label: "Publications",
          permission: true,
        
          icon: <DescriptionOutlined />,
        },
        {
          to: "/dashboard/events",
          label: "Events",
          permission: true,
        
          icon: <Event />,
        },
      ],
    },
    {
      to: "/dashboard/chats",
      label: "Chat",
   permission:true,
      icon:<ChatOutlined />,
    },
    {
      to: "/dashboard/promotion",
      label: "Promotion",
   permission:true,
      icon: <MonetizationOnOutlined  />,
    },
   , {
    to: "/dashboard/members",
    label: "Members",
permission:true,

    icon:  <GroupsIcon />,
  },
    {
      label: "Setting",
      icon: <SettingsOutlined />,
      children: [
        {
          to: "/dashboard/users",
          label: "Users",
   permission:true,

          icon:  <GroupsIcon />,
        },
        {
          to: "/dashboard/user-roles",
          label: "User Roles",
          icon: <SupervisorAccountOutlined  />,
   permission:true,

          selected: ["/add-user-role"],
        },
        
        {
          to: "/dashboard/custom-images",
          label: "Images",
   permission:true,

          icon: <GalleryIcon />,
        },
        {
          to: "/dashboard/logs",
          label: "Logs",
   permission:true,

          icon: <AssignmentOutlined />,
        },
       
      ],
    },
    {
      to: "/dashboard/faq",
      label: "FAQ",
permission:true,

      icon: <ContactSupportOutlined />,
    },
    {
      to: "/dashboard/flags",
      label: "Flags",
   permission:true,
      icon: <FlagOutlined />,
    },

  ];

  return menus.map((obj) =>
    obj.children ? handleMultipleMenu(obj) : handleSingleMenu(obj)
  );
}

const handleSingleMenu = (obj) => {
  return obj.selected
    ? { ...obj, selected: [...obj.selected, obj.to] }
    : { ...obj, selected: [obj.to] };
};

const handleMultipleMenu = (obj) => {
  var menu = { ...obj };

  menu.permission = menu.children.reduce(
    (prev, current) => prev || current.permission,
    false
  );
  menu.hasChildren = true;
  menu.children = menu.children.map((child) => handleSingleMenu(child));

  return menu;
};
