import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
// import FlagContent from "../../../components/FlagContent";
import FlagContentModal from "../../../components/FlagContent";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { useParams } from "react-router-dom";
import {
  useGetUserByIdQuery,
  useUpdateUserRequestMutation,
  useGetUserRolesQuery,
} from "../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import ChangePasswordModal from "../../../components/changePasswordModal";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #068FFF 0%, #75C2F6  51%, #068FFF  100%)",

  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),

    marginBottom: theme.spacing(8),
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(0),
    // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    boxShadow:
      " rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: theme.spacing(1),
    background: "none",
  },
  formField: {
    marginBottom: theme.spacing(2),
    marginTop: "15px",
  },
  mainTitle: {
    textAlign: "center",
    color: colorTags.PRIMARY,
    marginTop: theme.spacing(8),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(35),
    minWidth: "150px",
    minHeight: "150px",
    height: theme.spacing(35),
    cursor: "pointer",
  },
  imageInput: {
    display: "none",
  },
}));
const dummyData = {
  name: "John Doe",
  email: "john.doe@example.com",
  phone: "123-456-7890",
  bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  facebook: "john.doe.fb",
  twitter: "john.doe.tw",
  linkedin: "john.doe.linkedin",
  industry: "Technology",
  exports: "Software",
  destinations: "USA",
  activeSince: new Date("2020-01-01"),
  profilePicture: null,
  status: "Active",
  role: "Admin",
};

const EditProfilePage = () => {
  const classes = useStyles();
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [avatarI, setAvatarI] = useState([]);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const {
    data: userRolesData,
    isLoading: isLoadingUserRolesData,
    isError: isErrorUserRolesData,
    error: errorUserRolesData,
  } = useGetUserRolesQuery();

  const params = useParams();
  const {
    data: userDataAPI,
    isLoading: isLoadingFetch,
    isError: isErrorFetch,
  } = useGetUserByIdQuery({ id: user.user_id });
  const [
    updateUserRequest,
    { isLoading: isLoadingUpdate, isError: isErrorUpdate },
  ] = useUpdateUserRequestMutation();

  useEffect(() => {
    if (userDataAPI) {
      setUserData(userDataAPI[0]);
      setFormData(userDataAPI[0]);
    }
  }, [userDataAPI]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/webp",
    ];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setAvatarI([selected]);

      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicturePreview(reader.result);
      };
      reader.readAsDataURL(selected);
    } else {
      setAvatarI([]);
      setProfilePicturePreview(null);
    }
  };
  const [profilePicturePreview, setProfilePicturePreview] = useState(
    dummyData.avatar
  );
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      activeSince: date,
    }));
  };
  useEffect(() => {
    if (!user && user.user_id !== params.id) window.location.href = "/";
  }, [user, params.id, userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = {
      id: user?.user_id,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      address: formData.address ?? "",
      department: formData.department,
      role: user?.role_name,
      status: status,
      avatar: userData?.avatar,
      userId: user?.user_id,
    };

    if (avatarI.length > 0) {
      try {
        const imageResponse = await uploadImage(avatarI[0]);

        updateData.avatar = imageResponse?.dbPaths[0]?.documentName ?? avatarI;
      } catch (error) {
        toast.error("Error uploading image");
        updateData.avatar = userData?.avatar;

        return;
      }
    } else {
      updateData.avatar = userData?.avatar;
    }
    await updateUserRequest(updateData).then((res) => {
      if (res?.data) {
        toast.success("User updated successfully");
      } else {
        toast.error("Error updating user");
      }
    });
  };

  if (Number(user?.user_id) !== Number(params?.id)) {
    return <h1>Not Authorized</h1>;
  }
  if (isLoadingFetch) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  if (isErrorFetch) return <div>No user found</div>;
  else
    return (
      <div style={{ margin: "5%" }}>
        <div className={classes.root}>
          <div
            maxWidth="md"
            className={classes.formContainer}
            style={{ background: "none" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} style={{}}>
                {/* Left Section */}
                <Grid style={{ padding: "4%" }} item xs={12} sm={6} md={4}>
                  <div className={classes.avatarContainer}>
                    <label htmlFor="image-upload">
                      {profilePicturePreview ? (
                        <Avatar
                          alt={userData?.name}
                          src={profilePicturePreview}
                          className={classes.avatar}
                        />
                      ) : (
                        <Avatar
                          alt={userData?.name}
                          src={
                            userData && userData?.avatar
                              ? BASE_FILE_URL + userData?.avatar
                              : images.CorpLogo
                          }
                          className={classes.avatar}
                        />
                      )}
                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        className={classes.imageInput}
                        onChange={handleImageChange}
                      />
                    </label>
                  </div>
                </Grid>

                {/* Right Section */}
                <Grid
                  style={{
                    padding: "4%",
                  }}
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  spacing={3}>
                  <TextField
                    className={classes.formField}
                    label="Name"
                    name="name"
                    InputProps={{
                      inputProps: {
                        maxLength: 50,
                      },
                    }}
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    className={classes.formField}
                    label="Email"
                    type="email"
                    name="email"
                    InputProps={{
                      inputProps: {
                        maxLength: 100,
                      },
                    }}
                    value={formData?.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!validateEmail(formData?.email)}
                    helperText={
                      !validateEmail(formData?.email) && "Invalid email format"
                    }
                  />
                  <TextField
                    className={classes.formField}
                    label="Phone"
                    name="phone"
                    value={formData?.phone}
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        maxLength: 15,
                      },
                    }}
                    fullWidth
                    required
                    error={!validatePhone(formData?.phone)}
                    helperText={
                      !validatePhone(formData?.phone) &&
                      "Invalid phone number format. It should be less than 15 digits"
                    }
                  />
                  <TextField
                    className={classes.formField}
                    label="Address"
                    name="address"
                    value={formData?.address}
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        maxLength: 100,
                      },
                    }}
                    fullWidth
                    required
                  />
                  <TextField
                    className={classes.formField}
                    label="Department (Optional)"
                    name="department"
                    value={formData?.department}
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        maxLength: 100,
                      },
                    }}
                    fullWidth
                    required
                  />

                  {user && user.role_name === "Admin" && (
                    <FormControl fullWidth style={{ marginTop: "15px" }}>
                      <InputLabel id="Routes" style={{}}>
                        Role
                      </InputLabel>
                      <Select
                        className={classes.textField}
                        label="Role"
                        variant="outlined"
                        value={formData?.role}
                        color="success"
                        onChange={handleChange}>
                        {userRolesData?.map((option) => (
                          <MenuItem
                            value={
                              option.roleName !== "Member" && option.roleName
                            }>
                            {option.roleName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <div style={{ marginTop: "15px" }}></div>
                  {user && user.role_name === "Admin" && (
                    <FormControl fullWidth>
                      <InputLabel id="Routes" style={{ marginTop: "15px" }}>
                        Status
                      </InputLabel>
                      <Select
                        className={classes.textField}
                        label="Status"
                        variant="outlined"
                        value={formData?.status}
                        color="success"
                        onChange={handleChange}>
                        {["Active", "Suspended", "Inactive"].map((option) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <div style={{ paddingLeft: "6%", paddingBottom: "4%" }}>
                <StyledButton
                  onClick={handleSubmit}
                  color="primary"
                  type="submit">
                  Save Edit
                </StyledButton>

                <Button
                  color="error"
                  variant="outlined"
                  style={{
                    //  marginLeft: "30px",
                    borderRadius: "10px",
                  }}
                  onClick={() => window.close()}>
                  Discard Changes and Return
                </Button>
              </div>
              <div className={classes.avatarContainer}>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    //  marginLeft: "30px",
                    borderRadius: "10px",
                  }}
                  onClick={() => setChangePasswordOpen(true)}>
                  Change Password
                </Button>
              </div>
              {/* <div>
              <Button onClick={handleOpenModal}>Open Flag Modal</Button>
              <FlagContentModal open={isModalOpen} onClose={handleCloseModal} />
            </div> */}
            </form>
            <ChangePasswordModal
              open={changePasswordOpen}
              onClose={() => setChangePasswordOpen(false)}
              data={
                userData && {
                  id: userData?.id,
                  email: userData?.email,
                }
              }
            />
          </div>
        </div>
      </div>
    );
};

export default EditProfilePage;
