import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { images } from "../../../../assets/index";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import { useGetDocumentsQuery } from "../../../../features/api/apiSlice";
import {
  BASE_FILE_URL,
  FILE_DOWNLOAD_URL,
} from "../../../../constants/apiTags";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 0.3s",
  },
  listItemHovered: {
    backgroundColor: "#F0F0F0",
  },
  buttons: {
    display: "none",
  },
  buttonsVisible: {
    display: "block",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const FormsAndDocuments = ({ searchBy, query }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [formsData, setFormsData] = useState([]);
  const {
    data: documentsData,
    error: documentsError,
    isLoading: loadingDocumentsData,
  } = useGetDocumentsQuery();
  useEffect(() => {
    if (documentsData) {
      const lowerQuery = query?.toLowerCase();
      console.log(lowerQuery);
      setFormsData(
        documentsData?.filter((document) => {
          return (
            document?.title.toLowerCase().includes(lowerQuery) &&
            document?.category !== "Law"
          );
        })
      );
    }
  }, [documentsData, query]);
  const formsAndDocsData = [
    {
      name: "Form 1",
      id: 1,
      image: images.Form,
    },
    {
      name: "Form 2",
      id: 2,
      image: images.Form,
    },
    {
      name: "Form 3",
      id: 3,
      image: images.Form,
    },
    {
      name: "Form 4",
      id: 4,
      image: images.Form,
    },
    {
      name: "Form 5",
      id: 5,
      image: images.Form,
    },
    {
      name: "Form 6",
      id: 6,
      image: images.Form,
    },
    {
      name: "Form 7",
      id: 7,

      image: images.Form,
    },
    {
      name: "Form 8",
      id: 8,
      image: images.Form,
    },
    {
      name: "Form 9",
      id: 9,
      image: images.Form,
    },
    {
      name: "Form 10",
      id: 10,
      image: images.Form,
    },
    {
      name: "Doc 1",
      id: 11,

      image: images.Document,
    },
    {
      name: "Doc 2",
      id: 12,
      image: images.Document,
    },
    {
      name: "Doc 3",
      id: 13,
      image: images.Document,
    },
    {
      name: "Doc 4",
      image: images.Document,
    },
    {
      name: "Doc 5",
      image: images.Document,
    },
    {
      name: "Doc 6",
      image: images.Document,
    },
    {
      name: "Doc 7",
      image: images.Document,
    },
    {
      name: "Doc 8",
      image: images.Document,
    },
    {
      name: "Doc 9",
      image: images.Document,
    },
    {
      name: "Doc 10",
      image: images.Document,
    },
  ];
  const lawsPerPage = 5;
  const totalLaws = formsAndDocsData.length;
  const totalPages = Math.ceil(totalLaws / lawsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClickPagination = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const showPaginationNumbers = () => {
    let paginationNumbers = [];

    if (currentPage <= 2) {
      for (let i = 1; i <= 3; i++) {
        paginationNumbers.push(i);
      }
    } else if (currentPage >= totalPages - 1) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        paginationNumbers.push(i);
      }
    }

    return paginationNumbers;
  };
  const handleListItemMouseEnter = (index) => {
    setOpen(index);
  };

  const handleListItemMouseLeave = () => {
    setOpen(null);
  };

  if (loadingDocumentsData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <List className={classes.list}>
        {formsData
          ?.slice((currentPage - 1) * lawsPerPage, currentPage * lawsPerPage)
          .map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem
                button
                onMouseEnter={() => handleListItemMouseEnter(index)}
                onMouseLeave={handleListItemMouseLeave}
                className={`${classes.listItem} ${
                  open === index ? classes.listItemHovered : ""
                }`}>
                <ListItemText primary={item.title} />
                <div
                  className={`${classes.buttons} ${
                    open === index ? classes.buttonsVisible : ""
                  }`}>
                  <Tooltip title="Open in new tab" placement="top">
                    <IconButton
                      style={{ color: "grey" }}
                      variant="text"
                      onClick={() => {
                        window.open(BASE_FILE_URL + item.documentName);
                      }}>
                      <OpenInNewOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <a
                      href={FILE_DOWNLOAD_URL + item.documentName}
                      download
                      // onClick={(e) =>
                      //   handleDownloadClick(e, item.documentName)
                      // }
                    >
                      <IconButton
                        style={{ color: "grey", marginLeft: "15px" }}
                        variant="text">
                        <GetAppOutlinedIcon />
                      </IconButton>
                    </a>
                  </Tooltip>
                </div>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
      <Box className={classes.pagination}>
        <ChevronLeftIcon
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={() => handleClickPagination(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {showPaginationNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClickPagination(pageNumber)}
            style={{
              margin: "5px",
              padding: "8px 12px",
              backgroundColor: currentPage === pageNumber ? "#F0F0F0" : "white",
            }}>
            {pageNumber}
          </button>
        ))}
        <ChevronRightIcon
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => handleClickPagination(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Box>
    </div>
  );
};

export default FormsAndDocuments;
