import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  FormLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { useStyles } from "../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useGetPromotionsQuery,
  useGetPromotionByIdQuery,
  useCreatePromotionRequestMutation,
  useUpdatePromotionRequestMutation,
  useDeletePromotionRequestMutation,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));
const requestData = [
  {
    id: 1,
    companyName: "ExportCo",
    requestDate: "2023-06-01",
    startDate: "2023-06-10",
    endDate: "2023-06-15",
    level: "Platinum",
  },
  {
    id: 2,
    companyName: "GlobalExports",
    requestDate: "2023-06-02",
    startDate: "2023-06-12",
    endDate: "2023-06-18",
    level: "Gold",
  },
];
const PromotionDecision = ({ open, onClose, title, content }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [requestDate, setRequestDate] = useState(new Date());
  const [status, setStatus] = useState("Approved");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  // const [sponsor, setSponsor] = useState(
  //   requestData.find((x) => x.id === content.id)
  // );

  useEffect(() => {
    // console.log(content);
    if (content) {
      setSelectedCompany(content.companyName);
      setSelectedLevel(content.level);
      setStartDate(content.startDate);
      setEndDate(content.endDate);
      setRequestDate(content.requestDate);
    }
  }, [content]);

  const [
    updatePromotionRequest,
    {
      // data,
      isLoading: isLoadingUpdateRequest,
      isError: isErrorUpdateRequest,
      error: errorUpdateRequest,
      // isSuccess,
    },
  ] = useUpdatePromotionRequestMutation();

  const handleSave = async (e) => {
    e.preventDefault();
    const updateData = {
      id: content.id,
      companyName: selectedCompany,
      level: selectedLevel,
      startDate: startDate,
      endDate: endDate,
      requestDate: requestDate,
      status: "Approved",
      userId: user?.user_id,
    };

    await updatePromotionRequest(updateData)
      .then((res) => {
        if (res) toast.success("Promotion request approved successfully");
      })
      .catch((err) => {
        toast.error("Failed to approve promotion request");
      });

    // onSave(formData);
    onClose();
  };

  const handleDecline = async (e) => {
    e.preventDefault();
    const updateData = {
      id: content.id,
      company: selectedCompany,
      level: selectedLevel,
      startDate: startDate,
      endDate: endDate,
      requestDate: requestDate,
      status: "Declined",
      userId: user?.user_id,
    };

    await updatePromotionRequest(updateData)
      .then((res) => {
        toast.success("Promotion request declined successfully");
      })
      .catch((err) => {
        toast.error("Failed to decline promotion request");
      });

    // onSave(formData);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              //   className={classes.inputField}
              label="Company Name"
              variant="outlined"
              maxLength="100"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
            />
            <TextField
              //   className={classes.inputField}
              label="Request Date"
              variant="outlined"
              //   className={classes.textField}
              disabled
              value={new Date(requestDate).toLocaleDateString("en-US")}
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              onChange={(e) => setRequestDate(e.target.value)}
            />

            <TextField
              className={classes.textField}
              label="Level"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              select // Set the select attribute
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}>
              <MenuItem value="Platinum">Platinum</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
              <MenuItem value="Silver">Silver</MenuItem>
              <MenuItem value="Bronze">Bronze</MenuItem>
            </TextField>

            <Stack
              direction="row"
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">start date</FormLabel>

                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={startDate}
                      minDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">end date</FormLabel>

                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>
            </Stack>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingUpdateRequest}
                  variant="outlined"
                  style={{ fontWeight: "bold", color: colorTags.PRIMARY }}
                  onClick={handleSave}>
                  Approve
                </LoadingButton>

                <LoadingButton
                  loading={isLoadingUpdateRequest}
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "20px" }}
                  onClick={handleDecline}>
                  Decline
                </LoadingButton>
                <Button
                  variant="outlined"
                  style={{ marginLeft: "20px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PromotionDecision;
