import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Stack, styled } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #9DC08B  51%, #006737  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    backgroundColor: "#f2f2f2",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(4),
    // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    boxShadow:
      " rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: theme.spacing(1),
    backgroundColor: "#fff",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  mainTitle: {
    textAlign: "center",
    color: colorTags.PRIMARY,
    marginTop: theme.spacing(8),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    cursor: "pointer",
  },
  imageInput: {
    display: "none",
  },
}));

const CreateProfilePage = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    bio: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    industry: "",
    exports: "",
    destinations: "",
    activeSince: null,
    profilePicture: null,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      profilePicture: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      activeSince: date,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <Header />
      <Typography variant="h4" gutterBottom className={classes.mainTitle}>
        Create Your Profile
      </Typography>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Left Section */}
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.avatarContainer}>
                  <label htmlFor="image-upload">
                    {profilePicturePreview ? (
                      <Avatar
                        alt={formData.name}
                        src={profilePicturePreview}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar
                        alt={formData.name}
                        src={images.CorpLogo}
                        className={classes.avatar}
                      />
                    )}
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      className={classes.imageInput}
                      onChange={handleImageChange}
                    />
                  </label>
                  <TextField
                    color="success"
                    sx={{ color: "green" }}
                    className={classes.formField}
                    label="Facebook"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fab fa-facebook"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className={classes.formField}
                    label="Twitter"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fab fa-twitter"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className={classes.formField}
                    label="LinkedIn"
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fab fa-linkedin"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>

              {/* Right Section */}
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  className={classes.formField}
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  className={classes.formField}
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  className={classes.formField}
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  className={classes.formField}
                  label="Bio"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
                <TextField
                  className={classes.formField}
                  label="Industry"
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  className={classes.formField}
                  label="Exports"
                  name="exports"
                  value={formData.exports}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginBottom: "10px" }}
                  className={classes.formField}
                  label="Destinations"
                  name="destinations"
                  value={formData.destinations}
                  onChange={handleChange}
                  fullWidth
                  required
                />

                <InputLabel shrink>Active Since</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    spacing={3}
                    sx={{ width: isSmallScreen ? "100%" : "50%" }}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={formData.activeSince}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <div
              style={{
                width: isSmallScreen ? "100%" : "100%",
                display: "flex",
                justifyContent: isSmallScreen ? "center" : "flex-start",
                marginTop: "10px",
              }}>
              <StyledButton color="primary" type="submit">
                Create Profile
              </StyledButton>
            </div>
          </form>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CreateProfilePage;
