import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { useState } from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../../features/auth/authContext";
import { Stack } from "@mui/material";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));
const DashboardLayout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(true);

  const handleDrawerOpen = () => setOpenDrawer(true);
  const handleDrawerClose = () => setOpenDrawer(false);
  return (
    <>
      <AuthProvider>
        <Stack direction="row" spacing={2}>
          <SideBar
            openDrawer={openDrawer}
            handleDrawerClose={handleDrawerClose}
            DrawerHeader={DrawerHeader}
          />
          <Stack direction="column" spacing={2} style={{ width: "100%" }}>
            <NavBar
              openDrawer={openDrawer}
              handleDrawerOpen={handleDrawerOpen}
            />

            {/* {children} */}
            <div style={{ width: "100%", paddingTop: "4%" }}>
              {/* The nested routes will be rendered here */}
              <Outlet />
            </div>
          </Stack>
        </Stack>
      </AuthProvider>
    </>
  );
};

export default DashboardLayout;
