import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BASE_FILE_URL } from "../../constants/apiTags";
import {} from "../../features/api/apiSlice";
// import { logout } from "../../features/auth2/authSlice";
import { logout } from "../../features/auth2/authSlice";

import sidebarMenus from "../../helper/sidebarMenus";
import ListItemLink from "../ListItemLink";
import SideBarMenuSingle from "./SideBarMenuSingle";
import { AuthProvider } from "../../features/auth/authContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MiniFooter from "../../pages/Home/Footer/MiniFooter";
import { Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  marginTop: "2px",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar({
  openDrawer,
  DrawerHeader,
  handleDrawerClose,
}) {
  // const { user } = useSelector((state) => state.auth);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [openIndex, setOpenIndex] = useState(-1);
  const [openAppointmentList, setOpenAppointmentList] = useState(false);
  const [openCasesList, setOpenCasesList] = useState(false);
  const [openResourceList, setOpenResourceList] = useState(false);
  const [openSettingList, setOpenSettingList] = useState(false);
  const [menus, setMenus] = useState([]);

  const closeAll = () => {
    setOpenAppointmentList(false);
    setOpenCasesList(false);
    setOpenResourceList(false);
    setOpenSettingList(false);
  };

  useEffect(() => setMenus(sidebarMenus()), [user?.userAccount]);
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    window.location.href = "/login";
  };

  return (
    <AuthProvider>
      <Drawer
        variant="permanent"
        open={openDrawer}
        PaperProps={{
          sx: {
            background: "#006738",
            color: "#EEEEEE",
            border: "unset",
          },
        }}>
        <Stack
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: "5%",
          }}>
          <IconButton onClick={handleDrawerClose} sx={{ width: "25px" }}>
            {theme.direction === "rtl" ? (
              <MenuIcon style={{ fontSize: "21px", color: "#fff" }} />
            ) : (
              <MenuIcon style={{ fontSize: "21px", color: "#fff" }} />
            )}
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          py={2}
          mt={3}
          justifyContent="center">
          {theme.direction !== "rtl" && (
            <div>
              <Avatar
                alt={""}
                sx={{ width: 48, height: 48 }}
                src={user && BASE_FILE_URL + user?.avatar}
              />
            </div>
          )}
          <div></div>
        </Stack>

        <List style={{ height: "100%" }}>
          <Stack
            justifyContent={"space-between"}
            style={{ height: "100%", paddingTop: "10px" }}>
            <div className="flex flex-col h-full justify-between">
              <div>
                <ListItemLink
                  to="/dashboard"
                  primary="Dashboard"
                  bgColor={pathname === "/dashboard" ? "#EEEEEE" : "#006738"}
                  textColor={pathname === "/dashboard" ? "#006738" : "#EEEEEE"}
                  icon={
                    <DashboardIcon
                      sx={{
                        color:
                          pathname === "/dashboard" ? "#006738" : "#EEEEEE",
                      }}
                    />
                  }
                />

                {menus?.map((obj, index) =>
                  obj.hasChildren ? (
                    obj.permission && (
                      <>
                        <ListItemButton
                          onClick={() => {
                            setOpenIndex(openIndex == index ? -1 : index);
                          }}
                          sx={{ paddingLeft: 3 }}>
                          <ListItemIcon>
                            <div
                              style={{
                                color: "#EEEEEE",
                              }}>
                              {obj.icon}
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={obj.label} />
                          {openIndex == index ? (
                            <ExpandLess color="#EEEEEE" />
                          ) : (
                            <ExpandMore color="#EEEEEE" />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={openIndex == index}
                          timeout="auto"
                          unmountOnExit>
                          <List component="div" sx={{ paddingLeft: 2 }}>
                            {obj.children?.map((obj2, index) => (
                              <SideBarMenuSingle key={index} menu={obj2} />
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )
                  ) : (
                    <SideBarMenuSingle menu={obj} />
                  )
                )}
              </div>

              <div>
                <div>
                  <Divider />
                  <ListItemButton
                    onClick={handleLogout}
                    sx={{ paddingLeft: 3 }}>
                    <ListItemIcon>
                      <LogoutIcon sx={{ color: "#EEEEEE" }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </div>
                <Divider />
                <Typography
                  variant="body2"
                  sx={{
                    color: "#EEEEEE",
                    fontSize: "12px",
                    textAlign: "center",
                    marginTop: "5px",
                  }}>
                  Powered by
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#EEEEEE",
                    fontSize: "12px",
                    textAlign: "center",
                    marginTop: "5px",
                  }}>
                  <a href="https://www.arcict.net/" target="_blank">
                    AR C ICT Technologies
                  </a>
                </Typography>
              </div>
            </div>
          </Stack>
        </List>
      </Drawer>
    </AuthProvider>
  );
}
