import React, { useState, useEffect } from "react";
import { Card, TextField, MenuItem, Link } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "800px",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "red",
    marginBottom: theme.spacing(3),
    margin: "0 auto", // Center alignment within the parent container
  },
  select: {
    width: "120px",
    borderRadius: "4px",
    marginRight: theme.spacing(1),
  },
  searchField: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  searchBar: {
    width: "100%",
    position: "relative",
    top: 10,
    borderRadius: "50px",
    backgroundColor: "white",
    // padding: theme.spacing(2),
    padding: "10px 2px",
    boxShadow:
      // "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",

    [theme.breakpoints.down(600)]: {
      padding: "5px 2px",
    },
  },
  flexer: {
    display: "flex",

    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  flexerL: {
    display: "flex",
    alignItems: "center",
    width: "30%",
    padding: "0 10px",

    justifyContent: "space-between",
    [theme.breakpoints.down(900)]: {
      // flexDirection: "column",
      width: "40%",

      alignItems: "center",
    },
    [theme.breakpoints.down(600)]: {
      // flexDirection: "column",
      width: "100%",

      alignItems: "center",
    },
  },
  flexerR: {
    display: "flex",
    width: "70%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
    [theme.breakpoints.down(600)]: {
      // flexDirection: "column",
      marginTop: "10px",
      alignItems: "center",
      width: "100%",
    },
  },
}));

const SearchBox = () => {
  const classes = useStyles();
  const [searchBy, setSearchBy] = useState("product");
  const [searchText, setSearchText] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleSearch = () => {};

  return (
    <div className={classes.searchContainer}>
      <Card
        style={{ padding: "12px 5px", borderRadius: "30px" }}
        className={classes.searchBar}>
        <div className={classes.flexer}>
          <div className={classes.flexerL}>
            <div
              style={{
                marginRight: "4px",
                marginLeft: "4px",
                // borderRadius: "15px",
                width: "150px",
              }}>
              Search by:
            </div>
            {/* <div> */}
            <TextField
              select
              variant="outlined"
              color="success"
              // label="Search by"
              // focused
              // variant="standard"
              fullWidth
              value={searchBy}
              onChange={(e) => {
                setSearchBy(e.target.value);
              }}
              style={{ borderRadius: "4px" }}
              SelectProps={{
                style: {
                  borderRadius: "14px",

                  backgroundColor: "#F0F0F0", // Adjust the background color to your preference
                  height: "32px",
                  // padding: "0 8px 8px 8px",
                  alignItems: "center",
                },
              }}>
              <MenuItem value="hsCode">HS Code</MenuItem>
              <MenuItem value="product">Product Name</MenuItem>
              <MenuItem value="destination">Document Name</MenuItem>
              <MenuItem
                onClick={() => navigate("/business-matching")}
                value="#">
                Business Matching
              </MenuItem>
            </TextField>
            {/* </div> */}
          </div>
          <div className={classes.flexerR}>
            <TextField
              variant="outlined"
              placeholder="Search"
              color="success"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  window.location.href = `/repository/search/${searchBy}/${searchText}`;
                }
              }}
              InputProps={{
                endAdornment: (
                  <Link href={`/repository/search/${searchBy}/${searchText}`}>
                    {" "}
                    <SearchIcon
                      // onClick={handleSearch}
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                ),
                style: {
                  borderRadius: "14px",
                  backgroundColor: "#F0F0F0", // Adjust the background color to your preference
                  height: "32px",
                  padding: "0 8px",
                  // marginLeft: "30px",
                  // marginRight: "10px",
                },
              }}
              style={{ flex: 1 }} // Make the search box take remaining space
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SearchBox;
