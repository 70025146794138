import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import { Stack, Typography, Button } from "@mui/material";
import { colorTags, primaryColor } from "../../../constants/variants";
import { images } from "../../../assets/index";
import { Link, useNavigate } from "react-router-dom";
// top destinations and top products query
import {
  useGetTopProductsQuery,
  useGetTopDestinationsQuery,
  useGetDestinationsQuery,
  useGetProductsQuery,
} from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  recentEventsContainer: {
    background: "#ffff",
    padding: theme.spacing(4),
    textAlign: "center",
    marginTop: "5%",
  },
  title: {
    color: colorTags?.PRIMARY,
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontSize: "17",
    marginLeft: 100,
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    justifyContent: "center",
    gridGap: theme.spacing(5),
    marginTop: theme.spacing(4),
    width: "70%",
    margin: "0 auto",
    paddingBottom: "0px",

    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: theme.spacing(3),
    },
  },
  card: {
    width: "100%",
    minWidth: "100px",
    height: "70%",
    maxHeight: "100px",
    [theme.breakpoints.down(600)]: {
      maxHeight: "100px",
    },
    position: "relative",
    borderRadius: "10px",
    // boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 20px -12px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
  },
  cardContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  lineContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  line: {
    flexGrow: 1,
    height: 1,
    background: `linear-gradient(to right, ${primaryColor}, transparent)`,
    margin: `0 ${theme.spacing(2)}`,
  },
  line2: {
    flexGrow: 1,
    height: 1,
    background: `linear-gradient(to left, ${primaryColor}, transparent)`,
    margin: `0 ${theme.spacing(2)}`,
  },
  seeMoreButton: {
    // textColor: primaryColor,
    fontWeight: "bold",
    marginRight: theme.spacing(3),
    borderRadius: "20px",
  },
  glowingText: {
    position: "relative",
    display: "inline-block",
    fontWeight: "bold",
    fontSize: "24px",
    color: "#fff",
    // textShadow: `0 0 5px green`,
    animation: "$glowAnimation 2s ease-in-out infinite",
  },
  "@keyframes glowAnimation": {
    "0%": {
      textShadow: `0 0 2px yellow`,
    },
    "50%": {
      textShadow: `0 0 5px yellow`,
    },
    "100%": {
      textShadow: `0 0 2px yellow`,
    },
  },
  glowingText2: {
    position: "relative",
    display: "inline-block",
    fontWeight: "bold",
    fontSize: "24px",
    color: "#fff",
    // textShadow: `0 0 5px yellow`,
    animation: "$glowAnimation 2s ease-in-out infinite",
  },
  "@keyframes glowAnimation": {
    "0%": {
      textShadow: `0 0 5px green`,
    },
    "50%": {
      textShadow: `0 0 15px green`,
    },
    "100%": {
      textShadow: `0 0 5px green`,
    },
  },
}));

const TopTen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showAllCountries, setShowAllCountries] = useState(false);

  const events = [
    {
      title: "Coffee",
      image: images?.cf,
    },
    {
      title: "Flowers",
      image: images?.fl,
    },
    {
      title: "Textile",
      image: images?.tx,
    },
    {
      title: "Leather",
      image: images?.lt,
    },
    {
      title: "Gems",
      image: images?.mn,
    },
    {
      title: "Coffee",
      image: images?.cf,
    },
    {
      title: "Flowers",
      image: images?.fl,
    },
    {
      title: "Textile",
      image: images?.tx,
    },
    {
      title: "Leather",
      image: images?.lt,
    },
    {
      title: "Gems",
      image: images?.mn,
    },
  ];

  const countries = [
    {
      title: "China",
      image: images?.ch,
    },
    {
      title: "US",
      image: images?.us,
    },
    {
      title: "S.Arabia",
      image: images?.sa,
    },
    {
      title: "U.A.E.",
      image: images?.uae,
    },
    {
      title: "Europe",
      image: images?.eu,
    },
    {
      title: "China",
      image: images?.ch,
    },
    {
      title: "US",
      image: images?.us,
    },
    {
      title: "Saudi Arabia",
      image: images?.sa,
    },
    {
      title: "U.A.E.",
      image: images?.uae,
    },
    {
      title: "Europe",
      image: images?.eu,
    },
  ];

  const [latestTopDestinations, setLatestTopDestinations] = useState([]);
  const [latestTopProducts, setLatestTopProducts] = useState([]);

  const {
    data: getTopDestinationsData,
    isLoading,
    isError,
  } = useGetTopDestinationsQuery();
  const {
    data: destinationsDatasData,
    isLoading: destinationsDatasLoading,
    isError: destinationsDatasError,
  } = useGetDestinationsQuery();

  const {
    data: getTopProductsData,
    isLoading: getTopProductsLoading,
    isError: getTopProductsError,
  } = useGetTopProductsQuery();
  const {
    data: productsDatasData,
    isLoading: productsDatasLoading,
    isError: productsDatasError,
  } = useGetProductsQuery();

  useEffect(() => {
    if (getTopDestinationsData && destinationsDatasData) {
      const formattedDestinations = [];

      getTopDestinationsData.slice(-10).forEach((item) => {
        // Find a destination that matches either id or destinationId
        const matchedDestination = destinationsDatasData.find(
          (data) =>
            data.id === item.destinationId &&
            !formattedDestinations.some(
              (dest) => dest.title === data.destinationName
            )
        );

        if (matchedDestination) {
          formattedDestinations.push({
            title: matchedDestination.destinationName,
            image: matchedDestination.coverImage,
          });
        }
      });
      setLatestTopDestinations(formattedDestinations);
    }
  }, [getTopDestinationsData, destinationsDatasData]);
  useEffect(() => {
    if (getTopProductsData && productsDatasData) {
      // Sort the data by a property that represents its order or date, assuming there's such a property
      const sortedData = [...getTopProductsData].sort((a, b) => {
        // You might need to adjust this depending on the data structure and the property to sort by
        return a.timestamp - b.timestamp;
      });

      // Take the last 10 items (latest) and extract their IDs
      const latestIds = sortedData
        .slice(-10)
        .map((product) => product.productId);

      const formattedProducts = [];

      latestIds.forEach((productId) => {
        // Find a Product that matches either id or productId
        const matchedProduct = productsDatasData.find(
          (data) =>
            (data.id === productId || data.productId === productId) &&
            !formattedProducts.some((dest) => dest.title === data.productName)
        );

        if (matchedProduct) {
          formattedProducts.push({
            title: matchedProduct.productName,
            image: matchedProduct.coverImage,
          });
        }
      });

      setLatestTopProducts(formattedProducts);
    }
  }, [getTopProductsData, productsDatasData]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Intersection Observer callback function
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const visibleEvents = showAllEvents
    ? latestTopProducts.slice(-10).reverse()
    : isSmallScreen
    ? latestTopProducts.slice(-3).reverse()
    : latestTopProducts.slice(-5).reverse();
  const visibleCountries = showAllCountries
    ? latestTopDestinations.slice(-10).reverse()
    : isSmallScreen
    ? latestTopDestinations?.slice(-3).reverse()
    : latestTopDestinations?.slice(-5).reverse();

  return (
    <div className={classes.recentEventsContainer}>
      <Typography
        style={{ fontSize: 40, fontWeight: "bold" }}
        className={classes.title}
        variant="h5"
        component="h2">
        <span style={{ color: colorTags.SECONDARY }}> Top Products </span>
        {"  "}
        <span style={{ color: colorTags.ACCENT }}>And </span>
        {"  "} <span style={{ color: colorTags.PRIMARY }}>Destinations</span>
      </Typography>

      <div className={classes.cardContainer}>
        {visibleEvents.map((event, index) => (
          <Stack direction="column" spacing={1} key={index}>
            <Card className={classes.card}>
              <img
                src={BASE_FILE_URL + event?.image}
                alt={event.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <CardContent className={classes.cardContent}></CardContent>
            </Card>
            <div className={classes.glowingText}> {event.title}</div>
            {/* <Typography
              variant="h6"
              component="h3"
              style={{ textAlign: "center" }}>
              {event.title}
            </Typography> */}
          </Stack>
        ))}
      </div>

      <div className={classes.lineContainer}>
        <div className={classes.line}></div>
        <Button
          variant="outlined"
          color="success"
          onClick={() => setShowAllEvents(!showAllEvents)}
          className={classes.seeMoreButton}
          style={{ color: "#006738", borderRadius: "20px" }}>
          {showAllEvents ? "See Less" : "See More"}
        </Button>
        <div className={classes.line2}></div>
      </div>

      <div className={classes.cardContainer}>
        {visibleCountries?.map((event, index) => (
          <Stack
            direction="column"
            spacing={1}
            key={index}
            style={{ textAlign: "center" }}>
            <Card className={classes.card}>
              <img
                src={BASE_FILE_URL + event?.image}
                alt={event.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <CardContent className={classes.cardContent}></CardContent>
              {/* <Typography variant="h6" component="h3">
                  {event.title}
                </Typography> */}
              {/* <div className={classes.glowingText2}> {event.title}</div> */}
            </Card>
            <div className={classes.glowingText2}> {event.title}</div>
            {/* <Typography variant="h6" component="h3">
              {event.title}
            </Typography> */}
          </Stack>
        ))}
      </div>

      <div className={classes.lineContainer}>
        <div className={classes.line}></div>
        <Button
          variant="outlined"
          color="success"
          style={{ color: "#006738", borderRadius: "20px" }}
          onClick={() => setShowAllCountries(!showAllCountries)}
          className={classes.seeMoreButton}>
          {showAllCountries ? "See Less" : "See More"}
        </Button>
        <div className={classes.line2}></div>
      </div>
    </div>
  );
};

export default TopTen;
