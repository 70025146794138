import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import EmailOutlined from "@mui/icons-material/Mail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsOutlined from "@mui/icons-material/Notifications";
import // useGetNotificationsQuery,
// useGetSettingByKeyQuery,
"../../features/api/apiSlice";
import NotificationPopover from "../NotificationPopover";
import { ArrowBackIos } from "@mui/icons-material";
import { AuthContext } from "../../features/auth/authContext";
import { AuthProvider } from "../../features/auth/authContext";

import { images } from "../../assets/index";
import { colorTags } from "../../constants/variants";
import Notifications from "../../pages/NotificationPage/NotificationsPageDash";
import { useGetNotificationByIdQuery } from "../../features/api/apiSlice";
const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function NavBar({ openDrawer, handleDrawerOpen }) {
  // const { user } = useSelector((state) => state.auth);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const navigate = useNavigate();
  // const { token } = useContext(AuthContext);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [msgNotifications, setMsgNotifications] = useState({
    count: 0,
    notifications: [],
  });
  const [otherNotifications, setOtherNotifications] = useState({
    count: 0,
    notifications: [],
  });
  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isErrorNotifications,
    error: notificationsError,
  } = useGetNotificationByIdQuery({ id: user?.user_id });

  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);

  const handleNotificationsOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };
  const [openNotifications, setOpenNotifications] = useState(false);

  return (
    <AuthProvider>
      <AppBar
        position="fixed"
        open={openDrawer}
        sx={{
          bgcolor: "#006738",
          boxShadow: "none",
          paddingLeft: "1%",
          // borderBottomLeftRadius: "10px",
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: "none" }),
            }}>
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => navigate(-1)}
            edge="start">
            {/* <ArrowBackIos sx={{ width: "17px" }} /> */}
            <span style={{ fontSize: "13px" }}>go back</span>
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            fontSize={18}></Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <div>
              <IconButton
                size="large"
                aria-label="show notifications"
                onClick={handleNotificationsOpen}
                style={{ color: "#fff" }}>
                <Badge
                  badgeContent={notificationsData && notificationsData?.length}
                  color="error">
                  <NotificationsOutlined />
                </Badge>
              </IconButton>
              <Menu
                style={{ paddingTop: "0px" }}
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={handleNotificationsClose}>
                <Notifications />
              </Menu>
            </div>
            {/* <NotificationPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              notifications={otherNotifications?.notifications}
              notificationDataLoading={notificationDataLoading}
            /> */}

            <Link
              to={`/dashboard/profile-detail/${user?.user_id}`}
              style={{ color: "inherit", textDecoration: "none" }}>
              <IconButton size="large" edge="end" color="inherit">
                <AccountCircle />
              </IconButton>
              <Typography variant="button" ml={0.5}>
                {user?.username}
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </AuthProvider>
  );
}
