import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneAreaBase } from "material-ui-dropzone";

import { useStyles } from "../../../App";
import {
  useGetMembersQuery,
  useGetMemberByIdQuery,
  useCreateMemberRequestMutation,
  useUpdateMemberRequestMutation,
  useDeleteMemberRequestMutation,
  useUploadFileMutation,
  useGetProductsQuery,
  useGetDestinationsQuery,
} from "../../../features/api/apiSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language"; //website
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { toast } from "react-toastify";
import { FILE_DOWNLOAD_URL, FILE_LINK_URL } from "../../../constants/apiTags";
const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  dropzone: {
    minHeight: "50px !important",
    border: "1px dashed #006738",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(3),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "60%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    minWidth: "700px",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));
const AddMember = ({ open, onClose, title, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [sector, setSector] = useState("");
  const [subSector, setSubSector] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [status, setStatus] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [bio, setBio] = useState("");
  const [coverImage, setCoverImage] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [products, setProducts] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [socials, setSocials] = useState({
    whatsapp: "",
    linkedIn: "",
    website: "",
    twitter: "",
    facebook: "",
    instagram: "",
  });

  const { data: productsData, isLoading: productsLoading } =
    useGetProductsQuery();
  const { data: destinationsData, isLoading: destinationsLoading } =
    useGetDestinationsQuery();

  const [submitFileUpload] = useUploadFileMutation();
  const [createMemberRequest, { isLoading: createMemberRequestLoading }] =
    useCreateMemberRequestMutation();

  const [showSocialFields, setShowSocialFields] = useState({
    whatsapp: false,
    linkedin: false,
    website: false,
  });

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const validateImageFormat = (file) => {
    const allowedFormats = ["png", "jpeg", "jpg", "webp"];
    const fileFormat = file.name.split(".").pop();
    return allowedFormats.includes(fileFormat.toLowerCase());
  };
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleSave = async (e) => {
    e.preventDefault();

    // Validation
    if (companyName.length > 100) {
      toast.error("Company name cannot exceed 100 characters.");
      return;
    }

    if (contactPerson.length > 100) {
      toast.error("Contact person name cannot exceed 100 characters.");
      return;
    }

    if (sector.length > 50) {
      toast.error("Sector name cannot exceed 50 characters.");
      return;
    }

    if (subSector.length > 50) {
      toast.error("Sub-sector name cannot exceed 50 characters.");
      return;
    }

    if (!validateEmail(contactEmail)) {
      toast.error("Invalid email format.");
      return;
    }

    if (!validatePhone(contactPhone)) {
      toast.error("Invalid phone number format. It should be 10 digits.");
      return;
    }

    if (bio.length > 600) {
      toast.error("Bio cannot exceed 600 characters.");
      return;
    }

    if (
      showSocialFields.whatsapp &&
      socials.whatsapp &&
      !validateUrl(socials.whatsapp)
    ) {
      toast.error("Invalid WhatsApp URL format.");
      return;
    }

    if (
      showSocialFields.linkedIn &&
      socials.linkedIn &&
      !validateUrl(socials.linkedIn)
    ) {
      toast.error("Invalid LinkedIn URL format.");
      return;
    }

    if (
      showSocialFields.website &&
      socials.website &&
      !validateUrl(socials.website)
    ) {
      toast.error("Invalid website URL format.");
      return;
    }

    if (coverImage.length > 0 && !validateImageFormat(coverImage[0])) {
      toast.error(
        "Invalid cover image format. Allowed formats: png, jpeg, jpg, webp."
      );
      return;
    }

    if (avatar.length > 0 && !validateImageFormat(avatar[0])) {
      toast.error(
        "Invalid avatar image format. Allowed formats: png, jpeg, jpg, webp."
      );
      return;
    }

    const data = {
      companyName,
      sector,
      subSector,
      contactPerson,
      status,
      contactAddress,
      bio,
      email: contactEmail,
      contactPhone,
      products,
      destinations,
      socials,
      userId: user?.user_id,
    };

    if (coverImage.length > 0) {
      try {
        const imageResponse = await uploadImage(coverImage[0]);

        data.coverImage = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading cover image");
        data.coverImage = "";
        return;
      }
    } else {
      data.coverImage = "";
    }

    if (avatar.length > 0) {
      try {
        const imageResponse = await uploadImage(avatar[0]);

        data.avatar = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading avatar image");
        data.avatar = "";
        return;
      }
    } else {
      data.avatar = "";
    }

    await createMemberRequest(data)
      .then((res) => {
        if (res) {
          toast.success("Member created successfully");
        }
      })
      .catch((err) => {
        toast.error("Error creating member");
      }); // ... rest of the code ...

    onClose();
  };

  const handleCheckboxChange = (field) => {
    setShowSocialFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            {/* ... (existing code) */}
            <Stack direction="row" spacing={2}>
              <TextField
                label="Company Name"
                variant="outlined"
                className={classes.textField}
                required
                fullWidth
                style={{ marginTop: "15px" }}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={companyName.length > 100}
                helperText={
                  companyName.length > 100 &&
                  "Company name cannot exceed 100 characters"
                }
              />

              <TextField
                label="Contact Person"
                variant="outlined"
                className={classes.textField}
                fullWidth
                style={{ marginTop: "15px" }}
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                error={contactPerson.length > 100}
                helperText={
                  contactPerson.length > 100 &&
                  "Contact person name cannot exceed 100 characters"
                }
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Sector"
                variant="outlined"
                className={classes.textField}
                fullWidth
                style={{ marginTop: "15px" }}
                value={sector}
                onChange={(e) => setSector(e.target.value)}
                error={sector.length > 50}
                helperText={
                  sector.length > 50 &&
                  "Sector name cannot exceed 50 characters"
                }
              />

              <TextField
                label="Sub-Sector"
                variant="outlined"
                className={classes.textField}
                fullWidth
                style={{ marginTop: "15px" }}
                value={subSector}
                onChange={(e) => setSubSector(e.target.value)}
                error={subSector.length > 50}
                helperText={
                  subSector.length > 50 &&
                  "Sub-sector name cannot exceed 50 characters"
                }
              />
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <FormControl
                className={classes.formControl}
                style={{ width: "100%" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  color="success"
                  labelId="status"
                  id="status"
                  label="Status"
                  value={status}
                  fullWidth
                  onChange={(e) => setStatus(e.target.value)}>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>

                  <MenuItem value="Banned">Banned</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Main Address"
                variant="outlined"
                className={classes.textField}
                fullWidth
                value={contactAddress}
                onChange={(e) => setContactAddress(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Contact Email"
                variant="outlined"
                className={classes.textField}
                fullWidth
                type="email"
                required
                style={{ marginTop: "15px" }}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                error={!validateEmail(contactEmail)}
                helperText={
                  !validateEmail(contactEmail) && "Invalid email format"
                }
              />

              <TextField
                label="Contact Phone"
                variant="outlined"
                className={classes.textField}
                fullWidth
                style={{ marginTop: "15px" }}
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                error={!validatePhone(contactPhone)}
                helperText={
                  !validatePhone(contactPhone) &&
                  "Invalid phone number format. It should be 10 digits"
                }
              />
            </Stack>

            <TextField
              label="Bio"
              variant="outlined"
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px", marginBottom: "15px" }}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              error={bio.length > 600}
              helperText={
                bio.length > 600 && "Bio cannot exceed 600 characters"
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showSocialFields.whatsapp}
                  onChange={() => handleCheckboxChange("whatsapp")}
                />
              }
              label={
                <WhatsAppIcon
                  style={{
                    fontSize: "20px",
                    color: "#25D366",
                  }}
                />
              }
            />
            {showSocialFields.whatsapp && (
              <div>
                <TextField
                  label="WhatsApp"
                  variant="outlined"
                  placeholder="https://wa.me/923001234567"
                  fullWidth
                  value={socials.whatsapp}
                  onChange={(e) =>
                    setSocials((prevSocials) => ({
                      ...prevSocials,
                      whatsapp: e.target.value,
                    }))
                  }
                  error={
                    showSocialFields.whatsapp && !validateUrl(socials.whatsapp)
                  }
                  helperText={
                    showSocialFields.whatsapp &&
                    !validateUrl(socials.whatsapp) &&
                    "Invalid WhatsApp URL format"
                  }
                />
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSocialFields.linkedIn}
                  onChange={() => handleCheckboxChange("linkedIn")}
                />
              }
              label={
                <LinkedInIcon
                  style={{
                    fontSize: "20px",

                    color: "#0077B5",
                  }}
                />
              }
            />
            {showSocialFields.linkedIn && (
              <div>
                <TextField
                  label="LinkedIn"
                  variant="outlined"
                  placeholder="https://www.linkedin.com/company/aaexport"
                  fullWidth
                  value={socials.linkedIn}
                  onChange={(e) =>
                    setSocials((prevSocials) => ({
                      ...prevSocials,
                      linkedIn: e.target.value,
                    }))
                  }
                  error={
                    showSocialFields.linkedIn && !validateUrl(socials.linkedIn)
                  }
                  helperText={
                    showSocialFields.linkedIn &&
                    !validateUrl(socials.linkedIn) &&
                    "Invalid LinkedIn URL format"
                  }
                />
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSocialFields.website}
                  onChange={() => handleCheckboxChange("website")}
                />
              }
              label={
                <LanguageIcon
                  style={{
                    fontSize: "20px",

                    color: "blue",
                  }}
                />
              }
            />
            {showSocialFields.website && (
              <div>
                <TextField
                  label="Website"
                  variant="outlined"
                  placeholder="https://www.aaexport.com"
                  fullWidth
                  style={{ marginTop: "15px" }}
                  value={socials.website}
                  onChange={(e) =>
                    setSocials((prevSocials) => ({
                      ...prevSocials,
                      website: e.target.value,
                    }))
                  }
                  error={
                    showSocialFields.website && !validateUrl(socials.website)
                  }
                  helperText={
                    showSocialFields.website &&
                    !validateUrl(socials.website) &&
                    "Invalid website URL format"
                  }
                />
              </div>
            )}

            <DropzoneArea
              dropzoneClass={classez?.dropZone}
              style={{ height: "200px" }}
              showAlerts={false}
              useChipsForPreview={true}
              filesLimit={1}
              maxFiles={1}
              maxFileSize={5000000}
              dropzoneText={"Drop or Click to Upload Cover Image(Optional)"}
              onChange={(files) => setCoverImage(files)}
              error={
                coverImage.length > 0 && !validateImageFormat(coverImage[0])
              }
              helperText={
                coverImage.length > 0 &&
                !validateImageFormat(coverImage[0]) &&
                "Invalid cover image format. Allowed formats: png, jpeg, jpg, webp."
              }
            />
            <div style={{ height: "15px" }}></div>
            <DropzoneArea
              dropzoneClass={classez?.dropZone}
              style={{ height: "200px" }}
              showAlerts={false}
              useChipsForPreview={true}
              filesLimit={1}
              maxFiles={1}
              maxFileSize={5000000}
              dropzoneText={"Drop or Click to Upload Avatar(Optional)"}
              onChange={(files) => setAvatar(files)}
              error={avatar.length > 0 && !validateImageFormat(avatar[0])}
              helperText={
                avatar.length > 0 &&
                !validateImageFormat(avatar[0]) &&
                "Invalid avatar image format. Allowed formats: png, jpeg, jpg, webp."
              }
            />
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </Button>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddMember;
