import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationRequestMutation,
  useUpdateNotificationRequestMutation,
  useDeleteNotificationRequestMutation,
  useMarkNotificationAsReadRequestMutation,
} from "../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    height: "100vh",
  },
  card: {
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    overflow: "auto",
    padding: theme.spacing(2),
  },
}));

const NotificationsPage = () => {
  const classes = useStyles();
  const [groupedNotifications, setGroupedNotifications] = useState([]);
  // const { data: notificationsData, isLoading: notificationsLoading } =
  //   useGetNotificationsQuery();

  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isErrorNotifications,
    error: notificationsError,
  } = useGetNotificationByIdQuery({ id: user?.user_id });

  useEffect(() => {
    if (notificationsData) {
      setGroupedNotifications(
        notificationsData.reduce((acc, notification) => {
          const date = new Date(notification.notifDate).toLocaleDateString(
            "en-US"
          );
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(notification);
          return acc;
        }, {})
      );
    }
  }, [notificationsData]);

  return (
    <Container maxWidth="md" className={classes.content}>
      <Card className={classes.card}>
        <Typography variant="h6" align="left" gutterBottom>
          Notifications
        </Typography>
        <List>
          {Object.entries(groupedNotifications).map(([date, notifications]) => (
            <React.Fragment key={date}>
              <ListItem>
                <ListItemText primary={date} />
              </ListItem>
              {notifications.map((notification) => (
                <ListItem key={notification.id}>
                  <ListItemText
                    primary={notification.message}
                    secondary={new Date(
                      notification.notifDate
                    ).toLocaleDateString("en-US")}
                    secondaryTypographyProps={{ align: "right" }}
                  />
                </ListItem>
              ))}
            </React.Fragment>
          ))}
          {!groupedNotifications && (
            <ListItem>
              <ListItemText primary="No notifications" />
            </ListItem>
          )}
        </List>
      </Card>
    </Container>
  );
};

export default NotificationsPage;
