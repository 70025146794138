import axios from "axios";
import { BASE_URL } from "../../constants/apiTags";
import { mockUsers } from "../../app/Utils/mockData";






// const firstTimeUserLogin = async (userData) => {
//   const res = await axios.post(`${BASE_URL}/Auth/set-password`, userData);
//   return res.data;
// };

const userLogin = async (userData) => {
  const { username, password } = userData;

  const user = mockUsers.find(
    (user) => user.username === username && user.password === password
  );

  if (!user) {
    throw new Error("Invalid username or password");
  }
  // const res = await axios.post(`${BASE_URL}/Auth/login`, userData);
  // console.log(res);
  // const config = {
  //   headers: {
  //     ["authorization"]: `Bearer ${res?.data?.token}`,
  //   },
  // };

  // const res2 = await axios.get(`${BASE_URL}/Employee/me`, config);

  // return { ...res.data, ...res2.data };
  const userDataWithToken = {
    ...user,
    userAccount: {
      id: user.id, 
      email: `${user.username}@gmail.com`,
      userRole: "staff", 
      profile: {
        firstName: "John",
        lastName: "Doe", 
        avatar: "https://example.com/avatar.jpg", 
      },
    },
  };

  return userDataWithToken;
};

const editAccount = async (userData, token) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    `${BASE_URL}/Auth/edit-account`,
    userData,
    config
  );
  return res.data;
};

// const getUserById = async (id, token) => {
//   const config = {
//     headers: {
//       ["authorization"]: `Bearer ${token}`,
//     },
//   };

//   const res = await axios.get(`${BASE_URL}/User/${id}`, config);
//   return res;
// };

// const checkToken = async (token) => {
//   const config = {
//     headers: {
//       ["authorization"]: `Bearer ${token}`,
//     },
//   };

//   const res = await axios.get(`${BASE_URL}/Auth/check/token`, config);
//   return res;
// };

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  // firstTimeUserLogin,
  userLogin,
  editAccount,
  // getUserById,
  // checkToken,
  logout,
};

export default authService;
