import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddNews from "./AddNews";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditNews from "./EditNews";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useCreateNewsRequestMutation,
  useUpdateNewsRequestMutation,
  useDeleteNewsRequestMutation,
} from "../../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const newsDataq = [
  {
    id: 1,
    title: "Ethiopian Exports Soar to New Heights",
    intro: "A celebration of Ethiopia's record-breaking export achievements.",
    contentType: "Article",
    content: "This is the full article content...",
    coverImage: "ethiopian-exports.jpg",
    createdOn: "2023-06-01",
  },
  {
    id: 2,
    title: "New Trade Agreements Boost Ethiopia's Export Sector",
    intro:
      "Exploring the impact of recent trade agreements on Ethiopia's exports.",
    contentType: "Article",
    content: "This is the full article content...",
    coverImage: "trade-agreements.jpg",
    createdOn: "2023-06-02",
  },
];

export default function LawsRegulationsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: newsData,
    isLoading: isLoadingNewsData,
    isError: isErrorNewsData,
    error: errorNewsData,
  } = useGetNewsQuery();

  useEffect(() => {
    if (newsData) {
      console.log(newsData);
    }
  }, [newsData]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "intro",
      headerName: "Introduction",
      flex: 2,
    },
    {
      field: "contentType",
      headerName: "Content Type",
      flex: 1,
    },
    {
      field: "coverImage",
      headerName: "Cover Image",
      flex: 1,
      renderCell: (params) => (
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.coverImage}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => navigate("/posts/news/" + params?.row?.id)}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteNewsRequestMutation}
              dialogLabel="Delete News Article"
              id={params?.row?.id}
              name={`Article ${params?.row?.title}`}
            />
          </div>
        );
      },
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isLoadingNewsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      {/* <Link style={{ textDecoration: "none" }} href="/dashboard/add-news"> */}{" "}
      <div>
        {" "}
        {modalOpen ? (
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: colorTags.SECONDARY,
            }}
            onClick={handleModalClose}>
            Close
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              marginBottom: "1%",
              marginLeft: "88%",
              backgroundColor: primaryColor,
            }}
            onClick={handleModalOpen}>
            Add News
          </Button>
        )}
      </div>
      {/* </Link> */}
      {modalOpen ? (
        <AddNews
          // title="Add News"
          // content="This is the modal content."

          onClose={handleModalClose}
          actionText="Close"
        />
      ) : null}
      <SimpleCard
        title="News Articles List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={newsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
      <EditNews
        open={editModalOpen}
        onClose={handleEditModalClose}
        data={selectedRow}
      />
    </div>
  );
}
