import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddDestination from "./AddDestination";
import AddTopDestinations from "./AddTopDestinations";

import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import { images } from "../../../../assets/index";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetDestinationsQuery,
  useGetDestinationByIdQuery,
  useCreateDestinationRequestMutation,
  useUpdateDestinationRequestMutation,
  useDeleteDestinationRequestMutation,
} from "../../../../features/api/apiSlice";
import EditDestination from "./EditDestination";
import LoadingScreen from "../../../../components/LoadingMain";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { BASE_FILE_URL, FILE_LINK_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
const destinationsDataq = [
  {
    id: 1,
    destinationName: "Paris",
    country: "France",
    continent: "Europe",
    description: "The romantic city of lights",
    status: "Active",
    createdOn: new Date("2023-06-01").toLocaleDateString("en-us"),
    coverImage: "image_url_here_1",
    isoCode: "FR",
  },
  {
    id: 2,
    destinationName: "Rome",
    country: "Italy",
    continent: "Europe",
    description: "Ancient ruins and historical sites",
    status: "Active",
    createdOn: new Date("2023-06-02").toLocaleDateString("en-us"),
    coverImage: "image_url_here_2",
    isoCode: "IT",
  },
  // Add 8 more elements with isoCode property
  {
    id: 3,
    destinationName: "New York",
    country: "USA",
    continent: "North America",
    description: "The city that never sleeps",
    status: "Active",
    createdOn: new Date("2023-06-03").toLocaleDateString("en-us"),
    coverImage: images.us,
    isoCode: "US",
  },
  {
    id: 4,
    destinationName: "Tokyo",
    country: "Japan",
    continent: "Asia",
    description: "Modern and traditional culture",
    status: "Active",
    createdOn: new Date("2023-06-04").toLocaleDateString("en-us"),
    coverImage: "image_url_here_4",
    isoCode: "JP",
  },
  {
    id: 5,
    destinationName: "Cairo",
    country: "Egypt",
    continent: "Africa",
    description: "Home of ancient pyramids",
    status: "Active",
    createdOn: new Date("2023-06-05").toLocaleDateString("en-us"),
    coverImage: "image_url_here_5",
    isoCode: "EG",
  },
  {
    id: 6,
    destinationName: "Sydney",
    country: "Australia",
    continent: "Australia",
    description: "Beautiful beaches and wildlife",
    status: "Active",
    createdOn: new Date("2023-06-06").toLocaleDateString("en-us"),
    coverImage: "image_url_here_6",
    isoCode: "AU",
  },
  {
    id: 7,
    destinationName: "Rio de Janeiro",
    country: "Brazil",
    continent: "South America",
    description: "Vibrant culture and beaches",
    status: "Active",
    createdOn: new Date("2023-06-07").toLocaleDateString("en-us"),
    coverImage: "image_url_here_7",
    isoCode: "BR",
  },
  {
    id: 8,
    destinationName: "Machu Picchu",
    country: "Peru",
    continent: "South America",
    description: "Ancient Inca ruins",
    status: "Active",
    createdOn: new Date("2023-06-08").toLocaleDateString("en-us"),
    coverImage: "image_url_here_8",
    isoCode: "PE",
  },
  {
    id: 9,
    destinationName: "Copenhagen",
    country: "Denmark",
    continent: "Europe",
    description: "Scenic canals and palaces",
    status: "Active",
    createdOn: new Date("2023-06-09").toLocaleDateString("en-us"),
    coverImage: "image_url_here_9",
    isoCode: "DK",
  },
  {
    id: 10,
    destinationName: "Bali",
    country: "Indonesia",
    continent: "Asia",
    description: "Tropical paradise",
    status: "Active",
    createdOn: new Date("2023-06-10").toLocaleDateString("en-us"),
    coverImage: "image_url_here_10",
    isoCode: "ID",
  },
];

export default function DestinationsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: destinationData,
    isLoading,
    isError,
    error,
  } = useGetDestinationsQuery();
  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "destinationName",
      headerName: "Destination Name",
      flex: 1,
    },
    {
      field: "summary",
      headerName: "Summary",
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country Code",
      flex: 2,
    },
    {
      field: "continent",
      headerName: "Continent",
      flex: 1,
    },
    {
      field: "coverImage",
      headerName: "Cover Image",

      flex: 1,
      // valueFormatter: (params) => {
      //   return new Date(params?.value).toLocaleDateString("en-US");
      // },
      renderCell: (params) => (
        // <div>
        //   {" "}
        //   <IconButton
        //     href={BASE_FILE_URL + "/" + params?.row?.coverImage}
        //     target="_blank"
        //     download>
        //     <OpenInNew color="primary" />
        //   </IconButton>{" "}
        // </div>
        // <div
        //   style={{
        //     // backgroundImage: `url(${images?.MainBG})`,
        //     backgroundImage: `url(https://www.aaexport.somee.com/cs/${params?.row?.coverImage})`,
        //   }}></div>
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.coverImage}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            {/* <IconButton
              onClick={() =>
                navigate("/destination-detail/" + params?.row?.id)
              }>
              <Info color="primary" />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteDestinationRequestMutation}
              dialogLabel="Delete Destination"
              id={params?.row?.id}
              name={`${params?.row?.destinationName} as a destination`}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (destinationData) console.log(destinationData);
    if (isError) console.log(error);
  }, [destinationData]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const handleTopModalClose = () => setTopModalOpen(false);
  const handleTopModalOpen = () => setTopModalOpen(true);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "70%",

            backgroundColor: colorTags.PRIMARY,
          }}
          onClick={handleModalOpen}>
          Add Destination
        </Button>
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "20px",

            backgroundColor: colorTags.SECONDARY,
            color: "white",
          }}
          onClick={handleTopModalOpen}>
          Add TOP DESTINATIONS
        </Button>
      </div>
      <AddDestination
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Destination"
        content="This is the modal content."
        actionText="Close"
      />
      <AddTopDestinations
        open={topModalOpen}
        onClose={handleTopModalClose}
        title="Add Top Destinations"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Destinations List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={destinationData ?? []} columns={columns} />
        </Box>
      </SimpleCard>
      <EditDestination
        open={editModalOpen}
        onClose={handleEditModalClose}
        title="Edit Destination"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />
    </div>
  );
}
