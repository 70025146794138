import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Link, Card, CardContent } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { colorTags } from "../../../constants/variants";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { images } from "../../../assets/index";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
import BackToTop from "../../../components/BackToTop";
import ChatTwo from "../Chat/ChatTwo";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "#FAFAFA",
  },
  topCard: {
    position: "relative",
    width: "100%",
    height: 400, // Adjust the overall height as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(6),
    position: "relative", // Required for the overlay

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: `url('your-background-image-url.jpg')`, // Specify the URL of your background image
      backgroundSize: "cover", // Ensure the image covers the entire container
      backgroundPosition: "center", // Center the image
      opacity: 0.5, // Adjust the opacity as needed
      zIndex: 1, // Place the overlay above the background image
    },

    [theme.breakpoints.down("sm")]: {
      height: 200, // Adjust the height for smaller screens
    },
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    // color: colorTags.PRIMARY,
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },

    // paddingTop: theme.spacing(10),
  },
  section: {
    marginBottom: theme.spacing(5),
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // justifyContent: "left",
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
    color: colorTags.PRIMARY,
    // color: "#fff",
    textAlign: "left",
  },
  mainDescription: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(1),
    color: colorTags.PRIMARY,
    // textAlign: "center",
    textAlign: "left",
  },
  sectionDescription: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  links: {
    color: colorTags.PRIMARY,
  },
  contentBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  majorServices: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  majorServicesTitle: {
    fontSize: "2.5rem", // Adjust the size as needed
    fontWeight: "bold",
    color: colorTags.PRIMARY,
    textAlign: "center",
    marginTop: theme.spacing(4), // Add spacing as desired
  },
}));

const ServicesPage = () => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  const [image, setImage] = useState("");
  const handleSearch = () => {};
  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 7 });
  const services = [];
  const businessServicesData = [
    {
      title: "1. Business Development Services",
      type: "main",
      description:
        "The chamber promotes trade and investment by providing wide-ranging Business Development and Business Advocacy Services.",
    },
    {
      title: "1.1 Business Information and Advisory Service",
      type: "sub",

      description:
        "Chamber provides business information and advisory service to members. The advisory service dwells on trade and investment rules and regulations, taxation, access to finance etc.",
    },
    {
      title: "1.2 Weekly Business Opportunity Information",
      type: "sub",

      description:
        "Addresses of foreign suppliers and potential buyers of Ethiopian products, contact details of Partnership & Joint Venture, agents and Distributor Company’s information that sent to members through their emails on a weekly basis. The Information also includes announcements of up-to-date business information, annual trade fair programs taking place throughout the world, partnership opportunities and valuable business announcements.",
    },
    {
      title: "1.3 COMESA Information Center",
      type: "sub",

      description:
        "Addis Ababa Chamber of Commerce & Sectoral Associations with its previous project entitled 'Establishing COMESA Market Information Center' has various information sources on how to trade under COMESA and upgrade export service capacity of exporting firms in general and manufacturing sectors in particular.",
    },
    {
      title: "1.4 Export Coaching Service",
      type: "sub",

      description:
        "Export is one of the priority sectors to get support from the government areas. To this effect, the chamber provides supports to its members which mainly includes training and advisory on how to start export, product identification & development, incentives and market access.",
    },
    {
      title: "1.5 Manufacturing Advisory Unit",
      type: "sub",

      description:
        "AACCSA has a Manufacturing Advisory Unit (MAU) from its previous projects that provides manufacturing support service in the form of start-up consultation on investment requirement, incentives, market access, access to finance, and marketing information that help joint venture. The service is web-based and can be accessed on the mau.addischamber.com.",
    },
    {
      title: "1.6 Mentorship",
      type: "sub",

      description:
        "Mentoring is one of the most important tools and service for building a sustainable business. The service assists businesses to improve their career and develop good leadership or specific competence among the participating mentors and mentees. AACCSA’s mentorship (practice on one to one basis) is one of the capacity development programs available to members.",
    },
    {
      title: "2. Sub Sectors Platform",
      type: "main",

      description:
        "AACCSA organizes various sub-sector networking platforms, which help to promote specific sectors along the value chain and increase member’s engagement into networking events for future business development. The event is a very good opportunity, which helps to:",
    },
    {
      title: "3. Business matchmaking (Business-to-Business Meetings)",
      type: "main",

      description:
        "The Chamber organizes Business-to-Business Meetings (B2B) and matchmaking programs, which provide access to potential business and investment partners as well as marketing opportunities locally and abroad. The business matchmaking could be between buyers and sellers or investment partners/joint ventures or domestic business partners or with domestic and foreign business persons. Generally, AACCSA Organizes B2B in two ways:",
    },
    {
      title: "3.1 Inbound mission",
      type: "sub",

      description:
        "Network member companies with trade missions from around the world. Local business networks of buyers, sellers, etc.",
    },
    {
      title: "3.2 Outbound mission",
      type: "sub",

      description:
        "Create opportunities for member companies to share experience from abroad. Organize member business companies to travel to selected foreign countries to meet counterparts.",
    },
    {
      title: "4. Business Networking",
      type: "main",

      description:
        "Through AACCSA’s broad networking events, business companies meet and interact with plenty of business people, thereby sharing useful information and acquiring relevant contacts. The Networking programs include:",
    },
    {
      title: "5. Trainings",
      type: "main",

      description:
        "The chamber through its Training Institute provides different trainings to enable business companies to acquire the necessary knowledge and skills that lead to success in today’s challenging business environment. The trainings delivered by the Institute mainly focus on communication skills, executive and leadership development, financial management, accounting, management, human resource development, international trade, marketing and customer service, project and program management, logistics & procurement management, etc.",
    },
    {
      title: "6. Arbitration Service",
      type: "main",

      description:
        "AACCSA pioneered arbitration services by establishing an independent institute, which provides the following commercial dispute resolution services:",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Arbitration and Alternative Dispute Resolution (ADR) related services, commercial contract drafting, review and advice on dispute resolution mechanisms.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Referencing/advising on appropriate arbitration professionals, including arbitrators and expert witnesses.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Advisory service on alternative dispute resolution methods, such as mediation, adjudication, and conciliation.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Facilitating the settlement of commercial disputes in accurate accord with the arbitration rules of the Chamber.",
    },
    {
      title: " ",
      type: "sub",

      description: "- Adjudication services for construction disputes",
    },
    {
      title: " ",
      type: "sub",

      description: "- Mediation/conciliation",
    },
    {
      title: "7. International Trade Fairs",
      type: "main",

      description:
        "By organizing four international trade fairs (Agriculture and Food exhibition, Tour and Travel fair, Manufacturing and Technology exhibition and Addis Chamber International Trade fairs) annually, the chamber:",
    },
    {
      title: " ",
      type: "sub",

      description: "- Link local companies with international businesses",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Facilitates foreign partnership or agency agreements with local counterpart companies.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Disseminate business information and opportunities for trade fair participants and visitors as well as create forums for business negotiation and deals.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Create opportunity for technology transfer and identify new potential customers or strengthen relations with the existing ones.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Exhibit products or services and reach targeted audiences",
    },
    {
      title: "8. ISO Certification: Quality Management Service",
      type: "main",

      description:
        "AACCSA supports business companies to enhance competitiveness by implementing various types of ISO (International Organization for Standardization) management systems. These include: ISO – 9001, ISO – 22000, and ISO-14001. Moreover,",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Capacitates AACCSA to implement and maintain ISO 9001 system through continuous improvement.",
    },
    {
      title: " ",
      type: "sub",

      description:
        "- Conducts awareness creation programs on quality and quality management systems for member organizations.",
    },
    {
      title: "9. Business Promotion",
      type: "main",

      description:
        "Chamber members have an opportunity to be promoted on Addis Chamber Trade directories. The Business Directory is published every two years in hard copy, CD ROM, and uploaded on AACCSA website. The Directory contains member companies’ business profile & contact details as well as the country profile and business opportunities.",
    },
    {
      title: "10. Ensuring Credibility and Publicity of Companies",
      type: "main",

      description:
        "AACCSA membership boosts credibility by issuing testimonials for the trustworthiness of companies and authenticates business documents to boost their credibility. Moreover, AACCSA through its diverse electronic and print media (Television, Radio, Newspaper, etc...) provides promotion and publicity opportunities in a number of print and electronic media that AACCSA manages to promote its member companies free or with modest.",
    },
    {
      title:
        "11. Special Support Services for MSEs, and Women-led/Owned Businesses",
      type: "main",

      description:
        "The chamber provides dedicated business development services such as training, promotion, market linkage, and networking to target businesses such as MSEs, and women-led/owned businesses.",
    },
    {
      title: "12. Institute of Directors (IOD)",
      type: "main",

      description:
        "The Institute of Directors (IODs) operating under the Addis Ababa Chamber of Commerce & Sectoral Associations (AACCSA) aims to contribute its share towards the evolution of dynamic, ethical, and responsible Directors, that comply with and meet both local and international Corporate Governance standards and codes.",
    },
    {
      title: "13. Policy Advocacy",
      type: "main",

      description:
        "AACCSA is mandated to protect and promote the interest of its members and the business community. It advocates on behalf of the private sector to improve the doing business environment in the country. To effectively conduct its advocacy on business and related policy issues, the chamber has established 5 advocacy committees (containing more than 30 committee members). These are: Investment Advocacy committee, Trade and logistics Advocacy committee, Tax Advocacy committee, Land and finance provision Advocacy committee, and Multi sectorial affairs Advocacy committee.",
    },
    {
      title: "14. Addis Chamber Goodwill Ambassadors",
      type: "main",

      description:
        "The Chamber encourages members to participate in the Chamber activities to strengthen its services. In the year 2017, the chamber designated 18 volunteer members as Good Will Ambassadors to enhance the Chamber visibility and advocacy drive. These Good Will Ambassadors play significant roles in the development of the Chamber in close consultation with the wider business community.",
    },
    {
      title: "15. Research and Project Management",
      type: "main",

      description:
        "The chamber has also a dedicated unit for research and projects, which coordinates the research activities and communicates the results to the public and private sectors. The chamber’s evidence-based researches dealing with trade, investment, and sector-specific issues and their implications to medium- and long-run policy are aimed to reflect the interests and views of members and creating enabling business environment. The unit also coordinates specialized projects such as the Business Incubation Center and initiatives for improving the chamber’s services provided by various work units. The research results published in its widely circulating monthly newsletter, and the annual Journal of Trade and Business help to create awareness and influence actions by various stakeholders including the government.",
    },
    {
      title: "16. Media and Communication Service",
      type: "main",

      description:
        "The chamber utilizes diverse electronic and print media to air the views and interests of its members and the private sector in general. Relevant business and economic information, workshop outputs, results, and advertisements which help to promote business companies and chambers’ events are disseminated through the weekly Addis chamber TV program, weekly radio program, monthly newspapers, as well as social media. These initiatives helped to push forward the various agendas of the chamber as well as galvanize wide support from stakeholders.",
    },
  ];
  useEffect(() => {
    if (imageData) {
      setImage(imageData?.image);
    }
  }, [imageData]);

  const majorServicesData = [
    {
      title: "4.1 Overall Economic and Business Information on Ethiopia",
      type: "main",
    },
    {
      title: "4.1.1 Investment, Business, and Economic Indicators",
      type: "sub",
    },
    {
      title: "4.1.2 Macro-economic Situation",
      type: "sub",
    },
    {
      title: "4.1.3 Doing Business Environment",
      type: "sub",
    },
    {
      title: "4.1.4 Trade Facilitation Services",
      type: "sub",
    },
    {
      title: "4.2 Export Import Information",
      type: "main",
    },
    {
      title: "4.2.1 Export-Import Statistics",
      type: "sub",
    },
    {
      title: "4.2.2 Trade Balance with Selected Countries",
      type: "sub",
    },
    {
      title: "4.2.3 Major Exporter's Profile",
      type: "sub",
    },
    {
      title: "4.2.4 New Potential Trade Gateways for Ethiopia Exports",
      type: "sub",
    },
    {
      title: "4.2.5 Major Trade Destinations",
      type: "sub",
    },
    {
      title: "4.2.6 Information/List of Business Associations",
      type: "sub",
    },
    {
      title: "4.2.7 Regulatory Information, Trade Guides, and Procedures",
      type: "sub",
    },
    {
      title: "4.2.8 News",
      type: "sub",
    },
    {
      title: "4.2.9 Events",
      type: "sub",
    },
    {
      title: "4.3 Trade Related Publications and Services",
      type: "main",
    },
    {
      title: "4.3.1 How to Start Export",
      type: "sub",
    },
    {
      title: "4.3.2 Major Trade Agreements",
      type: "sub",
    },
    {
      title: "4.3.3 Trade Related Taxes Applicable in Ethiopia",
      type: "sub",
    },
    {
      title: "4.3.4 Export Support Service Providers",
      type: "sub",
    },
    {
      title: "4.4 Success Stories of Entrepreneurs",
      type: "main",
    },
    {
      title: "4.5 Developments in Global and Regional Trade",
      type: "main",
    },
    {
      title: "4.5.1 Global Trade Statistics",
      type: "sub",
    },
    {
      title: "4.5.2 Africa Trade Statistics",
      type: "sub",
    },
    {
      title: "4.5.3 Information Update on Regional Economic Communities",
      type: "sub",
    },
    {
      title: "4.5.4 Information on Free Trade Blocks",
      type: "sub",
    },
    {
      title: "4.5.5 ESG Issues for Trade",
      type: "sub",
    },
    {
      title: "4.5.6 International Trade Information Sources",
      type: "sub",
    },
    {
      title: "4.6 Useful Links and Information Sources",
      type: "main",
    },
  ];

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.2)), url(${images?.Logistics7})`,
        backgroundSize: "cover",
        position: "relative",
      }}>
      <Header />
      <div className={classes.container}>
        <div
          className={classes.topCard}
          style={{
            backgroundImage: image
              ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.4)), url(${
                  BASE_FILE_URL + image
                })`
              : `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${images?.MainImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <div
              style={{
                // backgroundColor: colorTags.SECONDARY,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}>
              <CardContent style={{ padding: 0 }}>
                <Typography
                  className={classes.majorServicesTitle}
                  variant="h2" // Adjust the variant as needed
                  style={{
                    color: "#fff", // Text color in the colored section
                    // paddingLeft: "5%",
                  }}
                  fontFamily="impact">
                  Major Services the Export Facility Portal Provides
                </Typography>
              </CardContent>
            </div>
            {/* <div
              style={{
                width: "40%",
                height: "100%",
                background: `url(${images?.AcMeet})`, // Set the image URL
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            /> */}
          </div>
        </div>

        <div className={classes.contentBox}>
          <div
            style={{
              margin: isSmallScreen ? "8px 15px 25px" : "15px 35px 45px",
            }}>
            <Typography
              variant="body1"
              textAlign="center"
              className={classes.mainDescription}
              style={{
                // color: "white",
                color: "#000",

                fontSize: isSmallScreen ? "1.2rem" : "1.4rem",
              }}>
              "Towards discharging its goal of promoting trade and investment,
              AACCSA provide various services including: advocacy for enabling
              business environment, dissemination of business information &
              business development advisory, promoting trade and investment
              through arranging business networking, exhibitions and trade fairs
              etc., engaging members on various economic and business issues by
              organizing workshops, supporting the business community in
              building up competent staff offering a year round courses through
              its training institute, conducting studies related to business,
              trade, and investment, and use of media and communication to air
              the interests and views of its members. The Chamber has also a
              good record of accomplishment in offering arbitration services to
              businesses as part of resolving possible disputes arising out of
              business transactions."
            </Typography>
          </div>
          {/* {businessServicesData.map((service, index) => (
            <Box key={index} className={classes.section}>
              <Typography
                variant="h5"
                className={classes.sectionTitle}
                ml={service.type === "main" ? "5px" : "35px"}
                style={{
                  marginBottom: service.type === "main" ? "10px" : "10px",
                  color:
                    service.type === "main"
                      ? colorTags.PRIMARY
                      : colorTags.SECONDARY,
                }}>
                {service.title}
              </Typography>
              <Typography
                variant="body1"
                ml={service.type === "main" ? "5px" : "35px"}
                className={classes.mainDescription}>
                {service.description}
              </Typography>
            </Box>
          ))} */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}>
            {/* <Typography
              variant={isSmallScreen ? "h6" : "h4"}
              className={classes.sectionTitle}
              textAlign="center"
              style={{
                marginBottom: "10px",
                color: colorTags.PRIMARY,
              }}> */}
            {/* 4.3.3 */}
            {/* Major Services the Export Facility Portal Provides
            </Typography> */}
            <Typography
              variant="body1"
              textAlign="center"
              className={classes.mainDescription}
              style={{
                // color: "white",
                color: "#000",
                fontWeight: "bold",

                fontSize: isSmallScreen ? "1.1rem" : "1.5rem",
              }}>
              The export portal is an accessible format designed to provide
              services for members, staff and partners of AACCSA, and the
              business community in general. Critical information the portal
              provides but not limited to include the following:
            </Typography>
          </div>
          <div
            style={
              {
                // backgroundColor: "#F5F5",
              }
            }
            className={classes.majorServices}>
            {majorServicesData.map((service, index) => (
              <div
                key={index}
                className={classes.section}
                style={{
                  backgroundColor:
                    service.type === "main" ? "#006738" : "#FAFAFA",
                  borderRadius: "10px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 14.5px 0px",
                  padding: "10px",
                  width: isSmallScreen ? "90%" : "50%",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "left",
                }}>
                {/* <Typography variant="h4" className={classes.sectionTitle}>
                {service.title}
              </Typography> */}
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    color:
                      service.type === "main"
                        ? // colorTags.PRIMARY
                          "#fff"
                        : colorTags.SECONDARY,
                  }}
                  className={classes.mainDescription}
                  ml={service.type === "main" ? "1%" : "10%"}>
                  {service.title}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
      {(user && user?.role_name === "Member") ||
      user?.role_name === "member" ? (
        <ChatTwo />
      ) : null}
      <BackToTop />
      <Footer />
    </div>
  );
};

export default ServicesPage;
