import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  TextField,
} from "@mui/material";
import {
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  useGetInstitutionsQuery,
  useGetInstitutionByIdQuery,
  useCreateInstitutionRequestMutation,
  useUpdateInstitutionRequestMutation,
  useDeleteInstitutionRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { FILE_LINK_URL } from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const AddInstitution = ({ open, onClose, title }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [institutionName, setInstitutionName] = useState("");
  const [cover, setCover] = useState([]);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [website, setWebsite] = useState("");
  const date = new Date();

  const [submitFileUpload] = useUploadFileMutation();

  const [formErrors, setFormErrors] = useState({
    institutionName: "",
    description: "",
    category: "",
    website: "",
  });

  const [
    createInstitutionRequest,
    {
      isLoading: isLoadingCreateInstitution,
      isError: isErrorCreateInstitution,
      error: errorCreateInstitution,
    },
  ] = useCreateInstitutionRequestMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleSave = async (e) => {
    e.preventDefault();
    const errors = {};

    // Validate institution name
    if (!institutionName) {
      errors.institutionName = "Institution name is required";
    } else if (institutionName.length > 50) {
      errors.institutionName = "Institution name cannot exceed 50 characters";
    }

    // Validate description
    if (description.length > 500) {
      errors.description = "Description cannot exceed 500 characters";
    }

    // Validate website
    const websiteRegex =
      /^(www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\/[a-zA-Z0-9-]+)$/;

    if (website && !website.match(websiteRegex)) {
      errors.website = "Invalid website format";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const updateData = {
      institutionName,
      description,
      category,
      website,
      userId: user?.user_id,
    };

    if (cover.length > 0) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/jpg",
      ];

      if (!allowedFileTypes.includes(cover[0].type)) {
        toast.error("Only JPEG and PNG images are allowed");
        return;
      }

      try {
        const imageResponse = await uploadImage(cover[0]);

        updateData.image = imageResponse?.dbPaths[0]?.documentName ?? "";
      } catch (error) {
        toast.error("Error uploading image");
        console.log(error);
        updateData.image = "";
        return;
      }
    } else {
      updateData.image = "";
    }
    await createInstitutionRequest(updateData)
      .then((res) => {
        if (res.data) {
          toast.success("Institution Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Updating Institution");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Institution Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={institutionName}
              onChange={(e) => setInstitutionName(e.target.value)}
              helperText={formErrors.institutionName}
              error={!!formErrors.institutionName}
            />
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              onChange={(e) => setDescription(e.target.value)}
              helperText={formErrors.description}
              error={!!formErrors.description}
            />
            <TextField
              label="Website"
              variant="outlined"
              placeholder="www.example.com / www.example.gov.et"
              value={website}
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              onChange={(e) => setWebsite(e.target.value)}
              helperText={formErrors.website}
              error={!!formErrors.website}
            />
            <TextField
              className={classes.textField}
              label="Category"
              variant="outlined"
              fullWidth
              style={{ marginTop: "15px" }}
              select
              required
              value={category}
              onChange={(e) => setCategory(e.target.value)}>
              <MenuItem value="GOV">Government</MenuItem>
              <MenuItem value="PRV">Private</MenuItem>
              <MenuItem value="NGO">NGO</MenuItem>
            </TextField>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={"Drop or Click to Upload Cover Image"}
                onChange={(files) => setCover(files)}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingCreateInstitution}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddInstitution;
