import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { Card, CardContent, Typography, Box, Stack, Grid } from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { colorTags } from "../../../constants/variants";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { images } from "../../../assets/index";
import BackToTop from "../../../components/BackToTop";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  aboutContainer: {
    backgroundImage: `
      linear-gradient(
        rgba(255, 255, 255, 0.95) 80%, /* Semi-transparent white color (top 80%) */
        rgba(${colorTags?.PRIMARY_RGBA}, 0.5) /* Solid white color (bottom 20%) */
      )`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundFilter: "blur(3px)",
    textAlign: "center",
  },
  aboutCard: {
    // width: "100%",
    // minHeight: "350px",
    // margin: "0 auto",
    // marginBottom: theme.spacing(4),
    // backgroundColor: "rgba(255, 255, 255, 0.6)",
    // padding: theme.spacing(4),
    // paddingTop: theme.spacing(6),
    // boxShadow: theme.shadows[4],
    // [theme.breakpoints.down("sm")]: {
    //   padding: theme.spacing(2),
    //   paddingTop: theme.spacing(3),
    //   minHeight: "300px",
    // },
    position: "relative",
    width: "100%",
    height: 400, // Adjust the overall height as needed
    // backgroundImage: `url(${images?.AcLogo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    // alignItems: "center",
    justifyContent: "flex-start",
    // overflow: "hidden",
    background: "rgba(255, 255, 255, 0.7)", // Add a background color with opacity

    [theme.breakpoints.down(600)]: {
      height: 250, // Adjust the height for smaller screens
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to top,  rgba(0,0,0, 0.2),rgba(255, 255, 255, 0))", // White gradient overlay
    },
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    // color: "#fff",

    textAlign: "center",
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: colorTags.PRIMARY,
  },
  cardListContainer: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
    },
  },
  card: {
    width: "95%",
    height: "400px",
    margin: "0 auto",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[4],
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "transform 0.4s ease",
    padding: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  cardContent: {
    padding: theme.spacing(8),
    borderRadius: theme.spacing(10),
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  textContent: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(4),
    // transform: "rotateY(0deg)",
    transition: "transform 1s ease",
  },
  timelineContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    marginLeft: "5%",
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    // background: `rgba(255, 232, 14, 0.6)`,
    background: `linear-gradient(to bottom, rgba(255, 232, 14, 0.6) 30%, transparent 30%)`,
    borderBottomLeftRadius: theme.spacing(2),
    // maxHeight: "300px",
    borderTopLeftRadius: theme.spacing(2),
    width: "90%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      padding: theme.spacing(0),

      width: "100%",
    },
  },
  timelineCard: {
    borderRadius: theme.spacing(2),
    backdropFilter: "blur(10px)",
    // boxShadow: theme.shadows[4],
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  timelineItem: {
    marginBottom: theme.spacing(4),
  },
  timelineOpItem: {
    width: "20%",
    maxWidth: "20%",
    color: colorTags.PRIMARY,
  },
  timelineDot: {
    backgroundColor: colorTags.PRIMARY,
    color: colorTags.PRIMARY,
  },
  timelineConnector: {
    backgroundColor: colorTags.PRIMARY,
    color: colorTags.PRIMARY,
  },
  timelineTitle: {
    fontWeight: "bold",
    fontSize: 20,
    color: colorTags.PRIMARY,
  },
  timelineContent: {
    marginLeft: theme.spacing(2),
    width: "130%",
  },
  imageContainer: {
    alignItems: "right",
    background: `url(${images?.AcMeet})`,
    backgroundSize: "100% 100%",
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),

    // position: "absolute",
    width: "30%",
    height: "40%",
    opacity: 1,
  },
  cardText: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  cardTitle: {
    fontWeight: "bold",
    color: colorTags.PRIMARY,
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [image, setImage] = useState("");

  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 8 });
  useEffect(() => {
    if (imageData) {
      setImage(imageData?.image);
    }
  }, [imageData]);
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const CardWithImageAndText = ({ title, image, text }) => {
    const handleMouseEnter = (e) => {
      e.currentTarget.querySelector(`.${classes.textContent}`).style.transform =
        "rotateY(360deg)";
    };

    const handleMouseLeave = (e) => {
      e.currentTarget.querySelector(`.${classes.textContent}`).style.transform =
        "rotateY(0)";
    };
    if (title === "Values")
      return (
        <div
          style={{
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          className={classes.card}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className={classes.cardContent} style={{ borderRadius: "10px" }}>
            <img src={image} alt={title} className={classes.image} />
            <div className={classes.textContent}>
              <Typography variant="h6" className={classes.cardTitle}>
                {title}
              </Typography>
              <Typography variant="body1" className={classes.cardText}>
                {text}
              </Typography>
              <br />
              <b>
                {" "}
                <ul style={{ color: colorTags.SECONDARY }}>
                  <li>Focus</li>
                  <li>INTEGRITY</li>
                  <li>INNOVATION</li>
                  <li>CREDIBILITY</li>
                  <li>PROFESSIONALISM</li>
                  <li>PARTNERSHIP</li>
                  <li>EXCELLENCE</li>
                </ul>
              </b>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div
          style={{
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          className={classes.card}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className={classes.cardContent}>
            <img src={image} alt={title} className={classes.image} />
            <div className={classes.textContent}>
              <Typography variant="h6" className={classes.cardTitle}>
                {title}
              </Typography>
              <Typography variant="body1" className={classes.cardText}>
                {text}
              </Typography>
            </div>
          </div>
        </div>
      );
  };

  return (
    <div
      className={classes.aboutContainer}
      // style={{
      //   backgroundImage: `
      // linear-gradient(
      //   rgba(255, 255, 255, 0.6), /* Semi-transparent white color */
      //   rgba(255, 255, 255, 0.6)
      // ),
      // url(${images?.Logistics6})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backdropFilter: "blur(10px)",
      //   textAlign: "center",
      // }}
    >
      <div
        style={{
          backgroundImage: `url(${images?.Logistics6})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backdropFilter: "blur(10px)",
          textAlign: "center",
          height: "100vh",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
        }}></div>
      <Header />
      <Card
        className={classes.aboutCard}
        style={{
          backgroundImage: image
            ? `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${
                BASE_FILE_URL + image
              })`
            : `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${images?.Chamber1})`,
        }}>
        <CardContent>
          <div>
            {" "}
            <Typography
              // className={classes.title}
              data-aos={isSmallScreen ? null : "fade-up"}
              data-aos-offset="700"
              sx={{
                fontSize: "95px",
                fontWeight: 600,
                // fontWeight: "bold",
                // background: "linear-gradient(to right, #f12711, #f5af19)",
                color: "#fff",
                textAlign: "left",
                paddingTop: "45%",
                "@media (max-width: 600px)": {
                  paddingTop: "40%",
                  fontSize: "70px",
                  zIndex: 1,
                },
                // paddingTop: theme.spacing(10),
              }}>
              About Us
            </Typography>
          </div>
        </CardContent>
      </Card>

      <div
        style={{
          width: "100%",
          border: "none",
          background: "none",
          // backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "32px",
          margin: "5% 0",
          marginBottom: isSmallScreen ? "16px" : "32px",
          borderRadius: "8px",
          fontFamily: "Arial, sans-serif",
        }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{
              fontSize: "18px",
              lineHeight: "1.6",
              color: "#333",
            }}>
            The Addis Ababa Chamber of Commerce and Sectoral Associations
            (AACCSA) stands out as the oldest and largest business membership
            organization in Ethiopia. Emperor Haile Selassie initiated the
            chamber in the mid-20th century.
          </Typography>
          {!isSmallScreen && (
            <Typography
              variant="body1"
              style={{
                fontSize: "18px",
                lineHeight: "1.6",
                color: "#333",
              }}>
              AACCSA's first establishment dates back to April 1947 through
              General Notice Number 90/1947. It was founded as an autonomous,
              non-governmental, non-political, and non-profit organization to
              act on behalf of its members. Over the years, it has experienced
              changes in various governments of the country. For instance, the
              Addis Ababa Chamber of Commerce changed its name to the Ethiopian
              Chamber of Commerce in 1962. In 1974, the Ethiopian Chamber of
              Commerce and the City Chambers were re-established by Proclamation
              Number 148/1978 in line with the then centrally planned economic
              policy where membership was mandatory.
            </Typography>
          )}
        </CardContent>
      </div>
      <Grid container className={classes.cardListContainer}>
        <Grid item xs={12} sm={4}>
          <CardWithImageAndText
            title="Mission"
            image={images?.Mission}
            text="“
            To support our members, build competitive, sustainable and prosperous businesses; to advocate for business-friendly environment; to contribute for the overall development of the private sector, and serve as a platform for business networking, through our superbly professional and dedicated staff, utilizing state of the art technology, and proactive partnerships; and maintaining high ethical standards.”"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardWithImageAndText
            title="Vision"
            image={images?.Vision}
            text="“To be instrumental in the flourishing of vibrant and prosperous businesses in Ethiopia.”"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardWithImageAndText
            title="Values"
            image={images?.Values}
            text="AACCSA has endorsed values that would underpin and guide its operations comprising of Focus, Integrity, Innovation, Credibility, Professionalism, Partnership, and Excellence which can be abbreviated as FI2CP2E for short. These are elaborated as below."
          />
        </Grid>
      </Grid>
      <div style={{ minHeight: "500px" }}>
        <div className={classes.timelineContainer}>
          {isSmallScreen ? null : (
            <img src={images.AcMeet} className={classes.imageContainer} />
          )}
          <Timeline align="center" style={{ marginTop: "2%" }}>
            <TimelineItem className={classes.timelineItem}>
              <TimelineOppositeContent
                className={classes.timelineOpItem}
                style={{ width: "20px" }}>
                <Typography variant="h6">1947</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator style={{ textColor: "blue" }}>
                <TimelineDot className={classes.timelineDot} />
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <div className={classes.timelineCard}>
                  <CardContent>
                    <Typography
                      className={classes.timelineTitle}
                      variant="body1">
                      Established
                      <br />
                    </Typography>
                    <Typography variant="body1">
                      The Addis Ababa Chamber of Commerce & Sectoral
                      Associations (AACCSA) has been established, by the General
                      Notice Number 90/ 1947, in April 1947 as an autonomous,
                      non-governmental, non-politi- cal and non-profit
                      organization to act on behalf of its members.
                    </Typography>
                  </CardContent>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.timelineItem}>
              <TimelineOppositeContent className={classes.timelineOpItem}>
                <Typography variant="h6">1991</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot} />
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <div className={classes.timelineCard}>
                  <CardContent>
                    <Typography
                      className={classes.timelineTitle}
                      variant="body1">
                      {" "}
                      Establishment of chambers of commerce and sectoral
                      associations
                    </Typography>{" "}
                    <Typography variant="body1">
                      A government change in 1991 introduced another new legal
                      framework for the establishment of chambers of commerce
                      and sectoral associations through Proclamation Number
                      341/2003, which made membership voluntary in line with the
                      new free market economic policy and the federal
                      arrangement. The existing mandate of AACCSA thus emanates
                      from this legal act.
                    </Typography>
                  </CardContent>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.timelineItem}>
              <TimelineOppositeContent
                style={{ width: "50px" }}
                className={classes.timelineOpItem}>
                <Typography variant="h6">2022</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot} />
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <div className={classes.timelineCard}>
                  <CardContent>
                    <Typography
                      className={classes.timelineTitle}
                      variant="body1">
                      75 years
                    </Typography>
                    <Typography variant="body1">
                      Celebrating 75 years annaversary.
                    </Typography>{" "}
                  </CardContent>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
      <BackToTop />
      <Footer />
    </div>
  );
};

export default AboutUs;
