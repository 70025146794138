import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Divider, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import "./Chat.css";
import { images } from "../../../assets/index";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/system";
import {
  useGetChatByIdQuery,
  useUpdateChatRequestMutation,
  useCreateChatRequestMutation,
} from "../../../features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingScreen from "../../../components/LoadingMain";
import { LoadingButton } from "@mui/lab";
import { BASE_URL } from "../../../constants/apiTags";

const ChatPage = () => {
  const initialMessages = [
    {
      id: 1,
      sender: "user1",
      message: "Hey there!",
      date: "2023-06-02",
      time: "14:30",
    },
    {
      id: 2,
      sender: "user2",
      message: "Hi! How are you?",
      date: "2023-06-02",
      time: "14:32",
    },
    {
      id: 3,
      sender: "user1",
      message: "I'm doing well, thanks!",
      date: "2023-06-02",
      time: "14:35",
    },
    {
      id: 4,
      sender: "user2",
      message: "That's great to hear!",
      date: "2023-06-02",
      time: "14:40",
    },
  ];
  // const user = useSelector((state) => state.auth.user);
  // const token = useSelector((state) => state.auth.accessToken);
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  // const user = localStorage.getItem("user");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [chatData2, setChatData2] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = useSelector((state) => state.auth);
  const [chatH, setChatH] = useState(null);
  const {
    data: chatData,
    isError: isErrorChatData,
    isLoading: isLoadingChatData,
  } = useGetChatByIdQuery({
    id: user && user?.role_name === "Member" ? user?.user_id : null,
  });

  useEffect(() => {
    const fetchData = () => {
      fetch(
        `${BASE_URL}/Chats/${
          (user && user?.role_name === "Member") || user?.role_name === "member"
            ? user?.user_id
            : null
        }`
      )
        .then((response) => response.json())
        .then((data) => {
          setChatData2(data[0]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    };

    // Fetch data initially and then set up an interval to fetch data every second
    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [updateChatRequest, { isLoading: isUpdatingChatRequest }] =
    useUpdateChatRequestMutation();
  const [createChatRequest, { isLoading: isCreatingChatRequest }] =
    useCreateChatRequestMutation();

  const MessageBubble = styled(Paper)(({ sent }) => ({
    padding: "8px",
    margin: "8px",
    borderRadius: "8px",
    alignSelf: sent ? "flex-end" : "flex-start",
    backgroundColor: sent ? "#1976D2" : "#FFC107",
    color: sent ? "white" : "black",
    whiteSpace: "pre-wrap", // Wrap text to the next line if needed
    maxWidth: "60%",
    wordWrap: "break-word", // Ensure long words break and wrap
    overflowWrap: "break-word", // Ensure overflow text wraps
    wordBreak: "break-word", // Handle word breaking for non-Latin languages
  }));

  const [messages, setMessages] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [set, setSet] = useState(false);
  useEffect(() => {
    if (chatData2) {
      setMessages(chatData2);
    }
  }, [chatData2]);

  // const handleSendMessage = async (e) => {
  //   if (newMessage?.trim() !== "") {
  //     const newMessageObj = {
  //       sender: "member",
  //       message: newMessage,
  //       time: new Date(),
  //     };
  //     setMessages({
  //       ...messages,
  //       conversation: [...messages.conversation, newMessageObj],
  //     });

  //     await updateChatRequest(messages)
  //       .then((res) => {
  //         if (res.data) {
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error("Error sending message");
  //       });

  //     setNewMessage("");
  //   }
  // };
  const handleSendMessage = async (e) => {
    if (newMessage?.trim() !== "") {
      const newMessageObj = {
        // id: messages?.conversation?.length + 1,
        sender: "member",
        message: newMessage,
        time: new Date(),
      };

      if (!messages || messages.conversation.length === 0) {
        // If the conversation array is empty, create a new chat request
        const newChatData = {
          id: user?.user_id,
          conversation: [newMessageObj],
          user: [
            {
              id: user?.user_id,
              name: user?.companyName,
            },
          ],
        };

        // Call the createChatRequest function to create a new chat
        await createChatRequest(newChatData)
          .then((res) => {
            if (res.data) {
              // Handle success if needed
              setSet(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error creating chat");
          });
      } else {
        // If the conversation array is not empty, update the existing chat
        const updatedChatData = {
          ...messages,
          conversation: [...messages.conversation, newMessageObj],
          user: [
            {
              id: user?.user_id,
              name: user?.companyName,
            },
          ],
        };

        // Call the updateChatRequest function to update the chat
        await updateChatRequest(updatedChatData)
          .then((res) => {
            if (res.data) {
              // Handle success if needed
              setSet(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error sending message");
          });
      }

      setNewMessage("");
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (!set && messages && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setSet(true);
    }
  }, [loading, messages]);
  if (!token) {
    return (
      toast.error(
        "Please login or register as an AACCSA Member to use our chat services"
      ),
      window.open("/login", "_self")
    );
  }

  if (
    (user && user?.role_name == "Admin") ||
    user?.role_name == "Staff" ||
    user?.role_name == "User"
  ) {
    return (
      toast.error("This page is for AACCSA Members only,"),
      window.open("/dashboard/chats", "_self")
    );
  }
  if (loading) {
    return <LoadingScreen />;
  } else if (isErrorChatData) {
    return toast.error("Error loading chat data"), window.open("/", "_self");
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: `url(${images.BlurBg3})`,
        backgroundSize: "cover",
      }}>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",

          // maxWidth: "500px",
          // margin: "50px auto",
          // backgroundImage: `url(${images.BlurBg2})`,
          backgroundSize: "cover",
          // backgroundColor: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          padding: isSmallScreen ? "5% 2%" : "5% 20%",
          flexGrow: 1,
          border: "1px solid #ccc",
          borderRadius: "10px",
          // boxShadow: "8px 8px 8px 8px #006738",

          boxShadow: "0 0 15px #006738",
          maxHeight: "600px",
          overflow: "hidden",
        }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "24px",
            // backgroundColor: "#fafa",
            color: "#fff",
            width: "100%",
            marginBottom: "20px",

            // margin: "40px",
          }}>
          <h2>Chat with Our Staff</h2>
        </div>
        <Divider />
        <div
          ref={chatContainerRef}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            flexGrow: 1,
            overflowY: "auto",
            scrollbarWidth: "thin",
            padding: "20px",

            scrollbarWidth: "thin",
            // backgroundColor: "#000",
            scrollbarColor: "#007bff #f5f5f5",
          }}>
          {messages &&
            messages?.conversation &&
            messages?.conversation?.map((msg, index) => (
              <div key={msg?.id}>
                {index === 0 || messages[index - 1]?.date !== msg?.time ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "5px",
                      fontSize: "12px",
                      color: "#888",
                    }}>
                    {new Date(msg?.time).toLocaleDateString("en-US")}
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent:
                      msg.sender === "member" ? "flex-end" : "flex-start",
                    marginBottom: "5px",
                  }}>
                  <MessageBubble
                    key={msg.id}
                    sent={msg.sender === "member"}
                    elevation={3}>
                    {msg.message}
                  </MessageBubble>
                </div>
              </div>
            ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            paddingTop: "15px",
          }}>
          {/* <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            style={{
              flexGrow: "1",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          /> */}
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            placeholder="Type your message..."
            style={{
              flexGrow: "1",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <LoadingButton
            loading={isUpdatingChatRequest}
            onClick={handleSendMessage}
            style={{
              marginLeft: "10px",
              // padding: "10px 10px",
              // backgroundColor: "#007bff",
              // color: "#fff",
              // border: "none",
              // borderRadius: "50%",
              // cursor: "pointer",
            }}>
            <SendIcon />
          </LoadingButton>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChatPage;
