import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
const NotFoundPage = () => {
  return (
    <>
        <Header />
    <div style={{height:"100vh", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>You can <a href="/" style={{color:"#006738"}}>return to the homepage</a>.</p>
    </div>
    <Footer />
    </>
  );
};

export default NotFoundPage;
