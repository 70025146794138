import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: `linear-gradient(45deg, #FFC107 30%, #E91E63 90%)`,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  },
  titleCard: {
    width: "100%",
    // backgroundColor: "#ff69b4",
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    opacity: "0.6",
  },
  title: {
    fontSize: "34px",
    fontWeight: "bold",
    color: "#ff69b4",
  },
  section: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    paddingLeft: "15%",
    paddingRight: "15%",
    textAlign: "justify",
    marginTop: "2%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
  },
  imageParagraphSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(2),
    paddingLeft: "10%",
    paddingRight: "10%",
    borderRadius: "8px",
    margin: "3% 0 3% 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  image: {
    width: "90%",
    borderRadius: "8px",

    borderRadiusTopLeft: "8px",
    borderRadiusBottomLeft: "8px",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      width: "100%",
      borderRadius: "8px",
    },
  },
  image2: {
    width: "90%",
    borderRadius: "8px",

    borderRadiusTopRight: "8px",
    borderRadiusBottomRight: "8px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
      borderRadius: "8px",
    },
  },
  paragraph: {
    width: "55%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  cardRow: {
    display: "flex",
    flexDirection: "row", // Ensure cards are laid out in a column on mobile
    width: "100%",
    marginBottom: "5%",
    padding: "0 7% 0 7%",
    marginTop: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Switch to row on desktop
      flexWrap: "wrap",
      gap: theme.spacing(2),
    },
  },
  card: {
    width: "100%", // Make cards full-width on mobile
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: "8px",
    // Adjust padding for mobile
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  cardMobile: {
    width: "100%", // Make cards full-width on mobile
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    // Adjust padding for mobile
  },
  avatar: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    margin: "0 auto",
    marginBottom: theme.spacing(1),
  },
  // New class to handle full-width images on mobile
  fullImageMobile: {
    width: "100%",
  },
}));

const WomenInExport = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.mainRoot}>
      <Header />

      <div className={classes.root}>
        <div
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${images?.MainBG})`,
            paddingBottom: "3%",
            position: "fixed",
            width: "100%",
            zIndex: "-1",
            height: "100%",
            // marginTop: "50px",
          }}></div>
        <Card className={classes.titleCard}>
          <Typography className={classes.title}>Women In Export</Typography>
        </Card>

        <div className={classes.section}>
          <Typography variant="h6">
            In Ethiopia, the role of women in export has been steadily evolving,
            contributing significantly to the country's economic growth and
            global trade relations. Over the years, Ethiopian women have emerged
            as key players in various export-oriented sectors, including
            agriculture, textiles, and handicrafts. Their participation in these
            industries has not only empowered them economically but has also
            enriched the nation's cultural tapestry. Ethiopian women engaged in
            export often play a crucial role in the production and marketing of
            high-quality products, such as coffee, flowers, and spices, which
            are highly sought after in international markets. Their dedication
            to preserving traditional farming practices while embracing modern
            techniques has resulted in the cultivation of premium export
            commodities that resonate with global consumers.
          </Typography>
        </div>

        <div className={classes.imageParagraphSection}>
          <div className={classes.paragraph}>
            <Typography style={{ marginRight: "10px" }}>
              Moreover, women-led cooperatives and entrepreneurial initiatives
              have thrived in Ethiopia's export sector, fostering collaboration
              and economic self-reliance among women in rural and urban areas
              alike. These women, with their entrepreneurial spirit and
              commitment to sustainability, have become ambassadors of Ethiopian
              products on the international stage. In addition to their economic
              contributions, Ethiopian women in export have also been
              instrumental in advocating for gender equality and women's rights.
              Their success stories inspire younger generations of women to
              pursue careers in export and challenge traditional gender norms.
              As they continue to break barriers and demonstrate their prowess
              on the global trade platform, Ethiopian women in export exemplify
              the resilience and determination that define their nation's rich
              heritage and promising future.
            </Typography>
          </div>
          {/* Apply the full-width image style for mobile */}
          <div>
            {" "}
            <img src={images.WIE2} alt="Image 2" className={classes.image2} />
          </div>
        </div>

        <div className={classes.imageParagraphSection}>
          <div>
            {" "}
            <img src={images.WIE1} alt="Image 1" className={classes.image} />
          </div>
          <div className={classes.paragraph}>
            <Typography>
              <b>
                {" "}
                Nigest Haile (Dr.) - Founder and Executive Director of the
                Center for Accelerated Women’s Economic Empowerment (CAWEE)
              </b>{" "}
              <br />
              Nigest is an activist in the promotion of women entrepreneurs,
              with a particular focus on women exporters. She has 23 years of
              experience working in the Ethiopian Ministry of Trade and Industry
              at different capacities. In 2004, she founded CAWEE, a pioneer
              trade promotion organization working in Ethiopia and beyond,
              providing promotional and capacity-building support services
              targeting women exporters.
            </Typography>
          </div>
        </div>

        <div className={classes.cardRow}>
          {/* Conditionally apply mobile styles to each card */}
          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <img
              src={images.FemaleAvatar}
              alt="Avatar 1"
              className={classes.avatar}
            />
            <Typography variant="h6">Sara Abera</Typography>
            <Typography variant="body2">Founder of Muya Ethiopia</Typography>
          </Card>

          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <img
              src={images.FemaleAvatar2}
              alt="Avatar 2"
              className={classes.avatar}
            />
            <Typography variant="h6">Birtukan Ayano</Typography>
            <Typography variant="body2">Founder and CEO of B’Ayoba</Typography>
          </Card>

          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <img
              src={images.FemaleAvatar}
              alt="Avatar 3"
              className={classes.avatar}
            />
            <Typography variant="h6">Dr. Eleni Zaude Gabre-Madhin</Typography>
            <Typography variant="body2">
              Economist and the founder of blueMoon
            </Typography>
          </Card>

          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <img
              src={images.FemaleAvatar2}
              alt="Avatar 4"
              className={classes.avatar}
            />
            <Typography variant="h6">Hiwot Amare</Typography>
            <Typography variant="body2">
              Description of card 4 goes here.
            </Typography>
          </Card>

          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <img
              src={images.FemaleAvatar}
              alt="Avatar 5"
              className={classes.avatar}
            />
            <Typography variant="h6">Saba Tewolde</Typography>
            <Typography variant="body2">
              Co-founder of the Zuria Collection
            </Typography>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WomenInExport;
