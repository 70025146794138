import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button, Link } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/index";
import { colorTags } from "../../constants/variants";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundImage: `linear-gradient(to left, rgba(255,255,255,0.5) 50%, transparent -100%), url(${images.BlurBg1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  card: {
    maxWidth: 300,
    padding: theme.spacing(0),
    marginTop: theme.spacing(-7),
    textAlign: "center",
    borderRadius: theme.spacing(2),
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",

    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(0),
      textAlign: "center",
      borderRadius: theme.spacing(2),
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 35px 20px -20px",
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colorTags.SECONDARY,
    "&:hover": {
      backgroundColor: colorTags.PRIMARY,
    },
  },
  title: {
    // fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  otherText: {
    color: colorTags.PRIMARY,
    fontSize: "12.5px",
  },
  leftText: {
    display: "none",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
      position: "absolute",
      right: "8%",
      top: "35%",

      textAlign: "right",
      color: "#006738",
      zIndex: 1,
    },
  },
}));

const Signup = ({ onLogin }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const token = "your-authentication-token";
    onLogin(token);
    window.location.reload();
    // navigate("/");
  };

  return (
    <>
      <Header />
      <div className={classes.root}>
        <div className={classes.leftText}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={images.AcLogo}
              alt="logo"
              style={{ width: "100px", height: "100px", marginBottom: "20px" }}
            />
          </div>
          {/* <Typography variant="h4">Welcome to AACCSA Export Portal</Typography> */}
          <Typography variant="body1">
            Sign up to be a member of AACCSA.
          </Typography>
        </div>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              variant="h5"
              className={classes.title}
              style={{ color: colorTags.PRIMARY }}>
              Signup
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
                type="email"
                label="Email"
                value={email}
                variant="outlined"
                color="success"
                onChange={handleEmailChange}
                fullWidth
                className={classes.inputField}
                sx={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  width: "100%",
                }}
              />
              <TextField
                type="password"
                label="Password"
                value={password}
                variant="outlined"
                color="success"
                onChange={handlePasswordChange}
                fullWidth
                className={classes.inputField}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}>
                Sign Up
              </Button>
            </form>

            <Typography variant="body2" color="textSecondary">
              Already have an account?{" "}
              <Button
                onClick={() => navigate("/login")}
                className={classes.otherText}>
                Sign In
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
