import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
import ProductsPage from "./Products/Products";
import ProceduresPage from "./Procedures/Products";
import LawsAndNormsPage from "./LawsAndNorms/Products";
import InstitutionsPage from "./Institutions/Products";
import FormsAndDocsPage from "./FormsAndDocs/Products";
import Header from "../Header/Header";
import Sponsors from "../Sponsors/Sponsors";
import { colorTags } from "../../../constants/variants";
import SearchIcon from "@material-ui/icons/Search";
import SearchBox from "../../../components/SearchBox";

// import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F1F1F1",
    padding: theme.spacing(12),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: theme.spacing(5),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    // margin: theme.spacing(2, 0),
    marginTop: theme.spacing(5),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF !important",
    color: `${colorTags.PRIMARY} !important`,

    borderRadius: theme.spacing(1),
  },

  // selectedTab: {
  //   color: `${colorTags.PRIMARY} !important`,
  // },
  tabsContainer: {
    overflowX: "auto",
    marginBottom: "20px",
    borderRadius: "10px",
    backgroundColor: "#D9D9D9",
  },
  searchBar: {
    width: "100%",
    position: "relative",
    // top: 80,
    borderRadius: "50px",
    backgroundColor: "none",
    padding: "20%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.down(600)]: {
    padding: "10%",
  },
}));

const RepositoryPage = () => {
  const classes = useStyles();
  const { id, searchBy, query } = useParams();

  useEffect(() => {
    console.log(id);
    if (id)
      if (id * 1 === 0) setSelectedTab(0);
      else if (id * 1 === 1) setSelectedTab(1);
      else if (id * 1 === 2) setSelectedTab(2);
      else if (id * 1 === 3) setSelectedTab(3);
      else if (id * 1 === 4) setSelectedTab(4);
      else setSelectedTab(0);
  }, [id]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div style={{ backgroundColor: "#F1F1F1" }}>
      <Header />
      <div style={{ marginTop: "25px" }}>
        <Sponsors />
      </div>

      <div className={classes.root}>
        {" "}
        <Typography
          variant="h5"
          style={{ textAlign: "left", marginBottom: "20px" }}>
          Repository
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor={colorTags?.PRIMARY}
          TabIndicatorProps={{
            style: { backgroundColor: colorTags.PRIMARY },
          }}
          centered={window.innerWidth >= 600}
          variant={window.innerWidth < 600 ? "scrollable" : "fullWidth"}>
          <Tab
            label="Products"
            className={selectedTab === 0 ? classes.selectedTab : ""}
          />
          <Tab
            label="Procedures"
            className={selectedTab === 1 ? classes.selectedTab : ""}
          />
          <Tab
            label="Institutions"
            className={selectedTab === 2 ? classes.selectedTab : ""}
          />
          <Tab
            label="Laws and Norms"
            className={selectedTab === 3 ? classes.selectedTab : ""}
          />
          <Tab
            label="Forms and Documents"
            className={selectedTab === 4 ? classes.selectedTab : ""}
          />
        </Tabs>
        <div className={classes.searchBar}>
          <SearchBox />
        </div>
        <div className={classes.content}>
          {selectedTab === 0 && <ProductsPage />}
          {selectedTab === 1 && <ProceduresPage />}

          {selectedTab === 2 && <InstitutionsPage />}
          {selectedTab === 3 && <LawsAndNormsPage />}
          {selectedTab === 4 && <FormsAndDocsPage />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RepositoryPage;
