import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Slide,
  Box,
  Typography,
  IconButton,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { colorTags } from "../../../../constants/variants";
import {
  useGetProceduresQuery,
  useGetInstitutionsQuery,
  useGetDocumentsQuery,
} from "../../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  slidingPanel: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "50%",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    overflowY: "auto",
    transition: "transform 0.3s ease",
    transform: "translateX(100%)",
    [theme.breakpoints.down(600)]: {
      width: "75%",
    },
  },
  slidingPanelVisible: {
    transform: "translateX(50%)",
    // [theme.breakpoints.down(600)]: {
    //   transform: "translateX(20%)",
    // },
  },
  slidingPanelContent: {
    padding: theme.spacing(2),
  },
  overlay: {
    content: "''",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
    opacity: 0,
    pointerEvents: "none",
    transition: "opacity 0.3s ease",
  },

  overlayVisible: {
    opacity: 1,
    pointerEvents: "auto",
  },
}));

const ProceduresPage = () => {
  const classes = useStyles();
  const [openIndex, setOpenIndex] = useState(null);
  const navigate = useNavigate();
  const handleItemClick = (index) => {
    setOpenIndex((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };
  const [procedureData, setProcedureData] = useState(null);
  const {
    data: procedureDataqq,
    isLoading: procedureLoading,
    error: procedureError,
  } = useGetProceduresQuery();

  const procedureDataq = [
    {
      title: "Product 1",
      summary: "Summary for Product 1...",
      mainSteps: [
        { description: "Step 1: Description for Step 1" },
        { description: "Step 2: Description for Step 2" },
      ],
      institutions: ["Institution 1", "Institution 2"],
      requiredDocuments: ["Document 1", "Document 2"],
    },
    {
      title: "Product 2",
      summary: "Summary for Product 2...",
      mainSteps: [
        { description: "Step 1: Description for Step 1" },
        { description: "Step 2: Description for Step 2" },
      ],
      institutions: ["Institution 3", "Institution 4"],
      requiredDocuments: ["Document 3", "Document 4"],
    },
  ];
  useEffect(() => {
    if (procedureDataqq) {
      console.log(procedureDataqq);
      setProcedureData(procedureDataqq);
    }
  }, [procedureDataqq]);
  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isErrorDocuments,
  } = useGetDocumentsQuery();

  const {
    data: institutionsData,
    isLoading: isInstitutionsLoading,
    isError: isErrorInstitutions,
  } = useGetInstitutionsQuery();
  const handleOverlayClick = () => {
    setOpenIndex(null);
  };

  if (procedureLoading || isDocumentsLoading || isInstitutionsLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <List className={classes.list}>
        {procedureData?.map((item, index) => (
          <>
            <ListItem button onClick={() => handleItemClick(index)} key={index}>
              <ListItemText primary={item.title + " " + item.productName} />
              {openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
      {procedureData?.map((item, index) => (
        <div>
          {" "}
          <div
            className={`${classes.overlay} ${
              openIndex === index ? classes.overlayVisible : ""
            }`}
            onClick={handleOverlayClick}></div>
          <Slide
            key={index}
            direction="left"
            in={openIndex === index}
            mountOnEnter
            unmountOnExit>
            <Box
              className={`${classes.slidingPanel} ${
                openIndex === index ? classes.slidingPanelVisible : ""
              }`}
              pt={window.innerWidth < 600 ? 6 : 3}>
              <div
                className={classes.slidingPanelContent}
                style={{ padding: "10%" }}>
                <IconButton
                  className={classes.hideButton}
                  style={{ marginLeft: "-10%", marginTop: "-5%" }}
                  onClick={() => setOpenIndex(null)}>
                  <ArrowForwardIosIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "2%",
                    fontSize: window.innerWidth < 600 ? "20px" : "24px",
                    color: colorTags.PRIMARY,
                  }}>
                  {item.title}
                </Typography>
                <Typography style={{ marginTop: "2%" }}>
                  {item.summary}
                </Typography>
                <Typography variant="h6" style={{ marginTop: "2%" }}>
                  Main Steps
                </Typography>
                <List>
                  {item?.mainSteps &&
                    JSON.parse(item.mainSteps).map((step, stepIndex) => (
                      <ListItem key={stepIndex} className={classes.nested}>
                        <ListItemText primary={step.description} />
                      </ListItem>
                    ))}
                </List>
                <Typography variant="h6">Institutions</Typography>
                <List>
                  {item?.institutionsInvolved &&
                    JSON.parse(item.institutionsInvolved).map(
                      (institution, index) => {
                        // Find the details for this institution from institutionsData
                        const institutionDetails = institutionsData?.find(
                          (item) => item.id === institution.institutionId
                        );

                        if (!institutionDetails) {
                          return null; // Skip if details are not found
                        }
                        return (
                          <ListItem key={index} className={classes.nested}>
                            <ListItemText
                              primary={institutionDetails?.institutionName}
                            />
                          </ListItem>
                        );
                      }
                    )}
                </List>
                <Typography variant="h6">Required Documents</Typography>
                <List>
                  {item?.requiredDocuments &&
                    JSON.parse(item.requiredDocuments).map(
                      (document, index) => {
                        // Find the details for this institution from institutionsData
                        const documentDetails = documentsData?.find(
                          (item) => item.id === document.documentId
                        );

                        if (!documentDetails) {
                          return null; // Skip if details are not found
                        }
                        return (
                          <ListItem key={index} className={classes.nested}>
                            <ListItemText primary={documentDetails?.title} />
                          </ListItem>
                        );
                      }
                    )}
                </List>
                <Box mt={2}>
                  {/* <a
                  href="http://localhost:3000/export/procedures"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  
                </a> */}
                  <Typography variant="body2">
                    <Link
                      href={`/export/procedures/` + item.id}
                      className={classes.otherText}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "blue",
                        fontSize: 16,
                      }}
                      target="_blank">
                      See Full Procedure
                    </Link>
                  </Typography>
                </Box>
              </div>
            </Box>
          </Slide>
        </div>
      ))}
    </>
  );
};

export default ProceduresPage;
