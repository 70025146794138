import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
// import ProductsPage from "./Products/Products";

import { colorTags } from "../../../../constants/variants";

import FormsAndDocsPage from "./FormsAndDocsPage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#F1F1F1",
    // padding: theme.spacing(12),
    // paddingTop: theme.spacing(2),
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    // marginTop: theme.spacing(2),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
}));

const ProductsPage = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <FormsAndDocsPage />
    </div>
  );
};

export default ProductsPage;
