import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, Typography, Box, Stack } from "@mui/material";
import { images } from "../../../assets/index";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import ExportType from "./ExportType";
import ExportTypeCost from "./ExportTypeCost";
import CountUp from "react-countup";
import { colorTags } from "../../../constants/variants";
import SponsorsCard from "../Sponsors/Sponsors";
// import { images } from "../../../assets/index";
import { Divider, useTheme } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useGetCustomImageByIdQuery } from "../../../features/api/apiSlice";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import BackToTop from "../../../components/BackToTop";
import ChatTwo from "../Chat/ChatTwo";

const useStyles = makeStyles((theme) => {
  const keyframeIncrement = () => {
    let keyframes = "";
    for (let i = 0; i <= 101; i++) {
      keyframes += `${i}% { content: '${i}'; }`;
    }
    return keyframes;
  };

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      backdropFilter: "blur(10px)",
      justifyContent: "flex-start",

      overflow: "hidden",
    },

    topCard: {
      position: "relative",
      width: "100%",
      height: 400, // Adjust the overall height as needed
      // backgroundImage: `url(${images?.Logistics4})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      // alignItems: "center",
      justifyContent: "flex-start",
      // overflow: "hidden",
      background: "rgba(255, 255, 255, 0.7)", // Add a background color with opacity

      [theme.breakpoints.down(600)]: {
        height: 250, // Adjust the height for smaller screens
      },

      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(to top,  rgba(255, 255, 255, 0),rgba(255,255,0, 0.3))", // White gradient overlay
      },
    },

    title: {
      fontSize: "40px",
      fontWeight: 600,
      // fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
      paddingTop: theme.spacing(10),
    },
    circularContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(2),
      marginTop: theme.spacing(-8),
      zIndex: 1,
      [theme.breakpoints.down(600)]: {
        justifyContent: "space-between",

        marginTop: theme.spacing(1),
        gap: theme.spacing(2),
      },
    },
    circular: {
      width: 150,
      height: 150,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(9),
      marginRight: theme.spacing(9),
      justifyContent: "center",
      cursor: "pointer",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.1)",
      },
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

      [theme.breakpoints.down(600)]: {
        width: 45,
        height: 45,
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
      },
    },
    circularTitle: {
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: theme.spacing(1),
      color: "#fff",
      [theme.breakpoints.down(600)]: {
        fontSize: 11,
      },
    },
    circularImage: {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    exportDescription: {
      width: "100%",
      backgroundColor: "#F0E68C",
      padding: theme.spacing(2),
    },
    exportDescriptionTitle: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    exportDescriptionText: {
      fontSize: 16,
      lineHeight: 1.6,
      textAlign: "justify",
      color: theme.palette.common.black,
    },
    descriptionContainer: {
      display: "flex",
      width: "100%",
      textAlign: "center",
      padding: theme.spacing(4),
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(5),
    },
    descriptionText: {
      fontSize: 18,
      lineHeight: 1.6,
      textAlign: "justify",
      color: theme.palette.common.black,
    },
    customCardContainer: {
      width: "100%",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(2),
      marginTop: theme.spacing(8),
      backgroundColor: "#2eee",
    },
    customCard: {
      width: "100%",
      height: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
      backgroundColor: "#2eee",
      opacity: 0.7,
    },
    customCardImage: {
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundPosition: "center",
    },
    customCardList: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    customCardListItem: {
      fontSize: 16,
      color: theme.palette.common.black,
    },
    incrementContainer: {
      fontSize: 32,
      position: "relative",
      "&:before": {
        content: "'0'",
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        animation: `$incrementAnimation 10s ease-in-out forwards`,
      },
    },
    "@keyframes incrementAnimation": {
      from: {
        content: "'0'",
      },
      to: {
        content: "'101'",
      },
      ...keyframeIncrement(),
    },
  };
});

const MiniDescription = ({ title, description }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const theme = useTheme();
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Box
      style={{
        marginTop: 20,
        textAlign: "center",
      }}>
      <Typography
        variant="h6"
        style={{
          fontSize: isSmallScreen ? 12 : 18,
          color: colorTags.PRIMARY,
          textAlign: "center",
        }}>
        {title}
      </Typography>
      <Typography
        style={{
          fontSize: isSmallScreen ? 11 : 18,
          // color: colorTags.PRIMARY,
          // textAlign: "center",
        }}>
        {description}
      </Typography>
    </Box>
  );
};

const LogisticsPage = () => {
  const classes = useStyles();
  const [selectedExport, setSelectedExport] = useState("plane");
  const [startCountUp, setStartCountUp] = useState(false);

  const location = useLocation();
  const sectionToScroll = useParams();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log(sectionToScroll);
    if (sectionToScroll)
      if (sectionToScroll.id) {
        const element = document.getElementById(sectionToScroll.id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
  }, [sectionToScroll]);

  const handleExportClick = (exportType) => {
    setSelectedExport(exportType);
  };

  useEffect(() => {
    AOS.init();

    const handleInitialVisibility = () => {
      const element = document.getElementById("scrollToElement");
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible =
          elementRect.top < window.innerHeight && elementRect.bottom >= 0;
        if (isVisible) {
          setStartCountUp(true);
        }
      }
    };

    handleInitialVisibility();

    const handleScrollVisibility = () => {
      const element = document.getElementById("scrollToElement");
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible =
          elementRect.top < window.innerHeight && elementRect.bottom >= 0;
        setStartCountUp(isVisible);
      }
    };

    window.addEventListener("scroll", handleScrollVisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollVisibility);
    };
  }, []);
  const [image, setImage] = useState("");
  const handleSearch = () => {};
  const {
    data: imageData,
    isLoading: isLoadingImageData,
    isError: isErrorImageData,
    error: errorImageData,
  } = useGetCustomImageByIdQuery({ id: 4 });
  useEffect(() => {
    if (imageData) {
      setImage(imageData?.image);
    }
  }, [imageData]);
  return (
    <div
      style={{
        backgroundImage: `
    linear-gradient(rgba(255, 255, 255, 0.93),rgba(0,0,0, 0.3)), 
    url(${images?.Logistics5})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
      }}>
      <Header />
      <div className={classes.container}>
        <Card
          className={classes.topCard}
          style={{
            backgroundImage: image
              ? `url(${BASE_FILE_URL + image})`
              : `url(${images?.Logistics4})`,
          }}>
          <CardContent>
            <Typography
              // className={classes.title}
              data-aos={isSmallScreen ? null : "fade-up"}
              data-aos-offset="700"
              sx={{
                fontSize: "95px",
                fontWeight: 600,
                // fontWeight: "bold",
                // background: "linear-gradient(to right, #f12711, #f5af19)",
                color: "#fff",
                textAlign: "left",
                paddingTop: "45%",
                "@media (max-width: 600px)": {
                  paddingTop: "40%",
                  fontSize: "70px",
                  zIndex: 1,
                },
                // paddingTop: theme.spacing(10),
              }}>
              Logistics
            </Typography>
          </CardContent>
        </Card>

        <Box className={classes.circularContainer}>
          <div>
            <div
              className={classes.circular}
              onClick={() => handleExportClick("plane")}
              style={{
                boxShadow:
                  selectedExport === "plane"
                    ? "rgba(19, 141, 0, 0.5) 0px 5px 15px"
                    : "none",
              }}>
              <div
                className={classes.circularImage}
                style={{
                  backgroundImage: `url(${images?.Plane})`,
                }}
                data-aos="fade-up">
                <Typography
                  variant={isSmallScreen ? "h6" : "h4"}
                  className={classes.circularTitle}>
                  Air
                </Typography>
              </div>
            </div>
            <MiniDescription
              title="Export by Plane"
              description="Shipping goods over air"
            />
          </div>

          <div>
            <div
              className={classes.circular}
              onClick={() => handleExportClick("truck")}
              style={{
                boxShadow:
                  selectedExport === "truck"
                    ? "rgba(19, 141, 0, 0.5) 0px 5px 15px"
                    : "none",
              }}>
              <div
                className={classes.circularImage}
                style={{
                  backgroundImage: `url(${images?.Truck})`,
                }}
                data-aos="fade-up">
                <Typography
                  variant={isSmallScreen ? "h6" : "h4"}
                  className={classes.circularTitle}>
                  Land
                </Typography>
              </div>
            </div>
            <MiniDescription
              title="Export by Truck/Train"
              description="Shipping goods overland"
            />
          </div>
          <div>
            <div
              className={classes.circular}
              onClick={() => handleExportClick("ship")}
              style={{
                boxShadow:
                  selectedExport === "ship"
                    ? "rgba(19, 141, 0, 0.5) 0px 5px 15px"
                    : "none",
              }}>
              <div
                className={classes.circularImage}
                style={{
                  backgroundImage: `url(${images?.Ship})`,
                }}
                data-aos="fade-up">
                <Typography
                  variant={isSmallScreen ? "h6" : "h4"}
                  className={classes.circularTitle}>
                  Sea
                </Typography>
              </div>
            </div>
            <MiniDescription
              title="Export by Ship"
              description="Shipping goods via ships"
            />
          </div>
        </Box>

        <ExportType type={selectedExport} />
        <div style={{ marginTop: "-10px" }}></div>
        {/* <ExportTypeCost /> */}

        <Box className={classes.descriptionContainer} id="scrollToElement">
          {/* <Typography variant="h4">Export Destinations</Typography> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyItems: "space-between",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div id="routes" style={{ marginTop: "10px" }}></div>

            <Stack
              spacing={2}
              direction={isSmallScreen ? "column" : "row"}
              style={{
                // marginRight: "10%",
                width: isSmallScreen ? "80%" : "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}>
              <div>
                {" "}
                <Card
                  data-aos="fade-left"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1%",
                    margin: "2%",
                    // width: "15%",
                    borderRadius: "10px",
                    minHeight: "90px",
                    minWidth: "200px",
                    backgroundColor: colorTags.SECONDARY,
                    opacity: 0.7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}>
                  <CountUp
                    start={startCountUp ? 0 : null}
                    end={160}
                    duration={2.75}
                    separator=" "
                    decimals={0}
                    decimal=","
                    suffix=" Routes"
                    style={{ color: "#fff", fontWeight: "bold", fontSize: 30 }}
                  />
                </Card>
                <Typography variant="h6">Since 2010</Typography>
              </div>
              <img
                src={images?.GlobeTrs}
                alt="Export Destinations"
                className={classes.exportImage}
                data-aos="fade-right"
                style={{
                  width: "30%",
                  // marginLeft: isSmallScreen ? "17%" : "10%",
                }}
              />
            </Stack>
          </div>
        </Box>
        {/* <Box className={classes.descriptionContainer} id="scrollToElement">
          <div
            style={{
              width: "100%",
              display: "flex",
              // justifyItems: "space-between",
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            {" "}
            <Stack
              spacing={isSmallScreen ? 0 : 2}
              direction="column"
              style={{
                // marginRight: "10%",
                width: isSmallScreen ? "100%" : "30%",
              }}>
              <div
                id="destinations"
                style={{ marginTop: isSmallScreen ? "0px" : "50px" }}></div>
              <Card
                data-aos="fade-left"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1%",
                  margin: isSmallScreen ? "0%" : "2%",
                  // width: "15%",
                  width: isSmallScreen ? "100%" : "30%",

                  borderRadius: "10px",
                  minHeight: "90px",

                  backgroundColor: colorTags.PRIMARY,
                  opacity: 0.8,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}>
                <CountUp
                  start={startCountUp ? 0 : null}
                  end={160}
                  duration={2.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  suffix=" Routes"
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: isSmallScreen ? 20 : 30,
                  }}
                />
              </Card>
              <Typography variant={isSmallScreen ? "div" : "h6"}>
                Exported to 160+ Destinations
              </Typography>
            </Stack>
            <img
              src={images?.GlobeTrs}
              alt="Export Destinations"
              className={classes.exportImage}
              data-aos="fade-right"
              style={{
                width: "40%",
                marginLeft: isSmallScreen ? "17%" : "10%",
              }}
            />
          </div>
        </Box> */}
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row", // Change direction for small screens
            alignItems: "space-around",
            backgroundColor: "rgba(255,255,255,0.8)",
            width: "100%",
            margin: "40px 0",
          }}>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.8)",
              minWidth: isSmallScreen ? "100%" : "60%", // Adjust width for large screens

              flex: 1,
              padding: "20px",
              fontFamily: "Arial, sans-serif", // Apply your desired font family
            }}>
            <Typography
              variant="h4"
              style={{
                fontSize: isSmallScreen ? "24px" : "29px",
                margin: "10px 0",
                color: "#F5F7F8",
              }}>
              The criteria for choosing the most suitable Mode of Transport
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: isSmallScreen ? "16px" : "18px",
                color: "#D0D4CA",
                lineHeight: "1.6",
                fontFamily: "Arial, sans-serif", // Apply your desired font family
              }}>
              The following criteria should be taken into consideration while
              determining the most suitable mode of international transport:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "20px",
                  paddingLeft: "0",
                }}>
                <li style={{ marginBottom: "10px" }} data-aos="fade-up">
                  The cost of the shipment
                </li>
                <li
                  style={{ marginBottom: "10px" }}
                  data-aos="fade-up"
                  data-aos-delay="100">
                  The exact destination of the goods
                </li>
                <li
                  style={{ marginBottom: "10px" }}
                  data-aos="fade-up"
                  data-aos-delay="200">
                  The delivery schedule
                </li>
                <li
                  style={{ marginBottom: "10px" }}
                  data-aos="fade-up"
                  data-aos-delay="300">
                  The foreign buyer’s access to the shipped product
                </li>
              </ul>
            </Typography>
          </div>

          {isSmallScreen ? (
            <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
          ) : (
            <Divider
              orientation="vertical"
              style={{ margin: "0 20px", backgroundColor: "#666" }}
            />
          )}

          <div
            style={{
              width: isSmallScreen ? "100%" : "40%", // Adjust width for large screens
              flex: 1,
              padding: "20px",
              fontFamily: "Arial, sans-serif", // Apply your desired font family
            }}>
            <Typography
              variant="h4"
              style={{
                fontSize: isSmallScreen ? "24px" : "29px",
                margin: "10px 0",
                color: "#333",
              }}>
              The Criteria of Quality Transport
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                marginLeft: "20px",
                paddingLeft: "0",
              }}>
              <li>Cost</li>
              <li>Speed</li>
              <li>Safety</li>
            </ul>
          </div>
        </div>
        <div
          style={{
            margin: "5%",
          }}>
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "20px",
              color: "#fff", // Your desired text color
            }}>
            The Importance of Transport Documents
          </h2>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              color: "#f9f9f9", // Your desired text color
            }}>
            Transport documents used in various modes of transportation share
            common features, making it convenient to cover their importance
            collectively with references as needed. The bill of lading or any
            other transport document should be completed meticulously and
            include the following information:
          </p>
          <ul
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              paddingLeft: "20px",
              listStyleType: "disc",
              color: "#f9f9f9", // Your desired text color
            }}>
            <li>The place and date of issuance</li>
            <li>The name and address of the recipient (consignee)</li>
            <li>The name and address of the sender/consigner</li>
            <li>A description of the nature of the goods</li>
            <li>The number of parcels</li>
            <li>Marks and numbers</li>
            <li>The type of packing used</li>
            <li>The gross weight of the product</li>
            <li>The HS coding</li>
            <li>The date of expedition</li>
            <li>Station/port/terminal of departure</li>
            <li>Station/port/terminal of arrival</li>
          </ul>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              color: "#f9f9f9", // Your desired text color
            }}>
            Along with the bill of lading or the consignment note, the shipper
            must provide the buyer with a copy of the invoice for customs
            clearance. The invoice should contain the following information:
          </p>
          <ul
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              paddingLeft: "20px",
              listStyleType: "disc",
              color: "#f9f9f9", // Your desired text color
            }}>
            <li>
              All the information from the consignment note or bill of lading
            </li>
            <li>A description of the goods shipped</li>
            <li>The cost of individual items and their total cost</li>
            <li>The materials used as input</li>
            <li>The conditions of the sale (FOB, CIF, etc.)</li>
            <li>The code of the commodity under the harmonized system</li>
          </ul>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              color: "#f9f9f9", // Your desired text color
            }}>
            It is crucial to make every effort to avoid:
          </p>
          <ul
            data-aos="fade-up"
            data-aos-duration="800"
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              paddingLeft: "20px",
              listStyleType: "disc",
              color: "#f9f9f9", // Your desired text color
            }}>
            <li>Delay in fulfilling customs formalities</li>
            <li>Confusion about the contents</li>
            <li>Problems that could delay or prevent shipment</li>
          </ul>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            {" "}
            <a href="" target="_blank" rel="noopener noreferrer">
              <span style={{ color: "lightblue" }}>Find out more here</span>
            </a>
          </div> */}
        </div>
      </div>
      <SponsorsCard />
      {(user && user?.role_name === "Member") ||
      user?.role_name === "member" ? (
        <ChatTwo />
      ) : null}
      <BackToTop />
      <Footer />
    </div>
  );
};

export default LogisticsPage;
