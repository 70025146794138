// authService.js
import axios from 'axios';
import { BASE_URL, API_TAGS } from "../../constants/apiTags";

const API_URL = BASE_URL;

export const authService = {
  login: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/Jwts/UserLogin`, userData);
      return response.data;
    } catch (error) {
      throw new Error('Login failed');
    }
  },

  register: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/signUp`, userData);
      return response.data;
    } catch (error) {
      throw new Error('Registration failed');
    }
  },

  resetPassword: async (resetLink, newPassword) => {
    try {
      const response = await axios.post(`${API_URL}/setPass`, {
        resetLink,
        newPassword,
      });
      return response.data;
    } catch (error) {
      throw new Error('Password reset failed');
    }
  },
};
