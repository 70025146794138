import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";

import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import {
  useGetTopProductsQuery,
  useCreateTopProductRequestMutation,
  useCreateTopDestinationRequestMutation,
  useUpdateTopProductRequestMutation,
  useUpdateTopDestinationRequestMutation,
  useDeleteTopProductRequestMutation,
  useDeleteTopDestinationRequestMutation,
  useGetProductsQuery,
} from "../../../../features/api/apiSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    width: "50%",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ProductsData = [
  {
    id: 1,
    destinationName: "Paris",
    country: "France",
    continent: "Europe",
    description: "The romantic city of lights",
    status: "Active",
    createdOn: new Date("2023-06-01").toLocaleDateString("en-us"),
    coverImage: "image_url_here_1",
    isoCode: "FR",
  },
  {
    id: 2,
    destinationName: "Rome",
    country: "Italy",
    continent: "Europe",
    description: "Ancient ruins and historical sites",
    status: "Active",
    createdOn: new Date("2023-06-02").toLocaleDateString("en-us"),
    coverImage: "image_url_here_2",
    isoCode: "IT",
  },
  // Add 8 more elements with isoCode property
  {
    id: 3,
    destinationName: "New York",
    country: "USA",
    continent: "North America",
    description: "The city that never sleeps",
    status: "Active",
    createdOn: new Date("2023-06-03").toLocaleDateString("en-us"),
    coverImage: "image_url_here_2",
    isoCode: "US",
  },
  {
    id: 4,
    destinationName: "Tokyo",
    country: "Japan",
    continent: "Asia",
    description: "Modern and traditional culture",
    status: "Active",
    createdOn: new Date("2023-06-04").toLocaleDateString("en-us"),
    coverImage: "image_url_here_4",
    isoCode: "JP",
  },
  {
    id: 5,
    destinationName: "Cairo",
    country: "Egypt",
    continent: "Africa",
    description: "Home of ancient pyramids",
    status: "Active",
    createdOn: new Date("2023-06-05").toLocaleDateString("en-us"),
    coverImage: "image_url_here_5",
    isoCode: "EG",
  },
  {
    id: 6,
    destinationName: "Sydney",
    country: "Australia",
    continent: "Australia",
    description: "Beautiful beaches and wildlife",
    status: "Active",
    createdOn: new Date("2023-06-06").toLocaleDateString("en-us"),
    coverImage: "image_url_here_6",
    isoCode: "AU",
  },
  {
    id: 7,
    destinationName: "Rio de Janeiro",
    country: "Brazil",
    continent: "South America",
    description: "Vibrant culture and beaches",
    status: "Active",
    createdOn: new Date("2023-06-07").toLocaleDateString("en-us"),
    coverImage: "image_url_here_7",
    isoCode: "BR",
  },
  {
    id: 8,
    destinationName: "Machu Picchu",
    country: "Peru",
    continent: "South America",
    description: "Ancient Inca ruins",
    status: "Active",
    createdOn: new Date("2023-06-08").toLocaleDateString("en-us"),
    coverImage: "image_url_here_8",
    isoCode: "PE",
  },
  {
    id: 9,
    destinationName: "Copenhagen",
    country: "Denmark",
    continent: "Europe",
    description: "Scenic canals and palaces",
    status: "Active",
    createdOn: new Date("2023-06-09").toLocaleDateString("en-us"),
    coverImage: "image_url_here_9",
    isoCode: "DK",
  },
  {
    id: 10,
    destinationName: "Bali",
    country: "Indonesia",
    continent: "Asia",
    description: "Tropical paradise",
    status: "Active",
    createdOn: new Date("2023-06-10").toLocaleDateString("en-us"),
    coverImage: "image_url_here_10",
    isoCode: "ID",
  },
];

const AddTopProducts = ({ open, onClose, title, onSave }) => {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);

  const {
    data: getProducts,
    isError: isGetProductsError,
    isLoading: isLoadingProductsError,
  } = useGetProductsQuery();

  const [
    createTopProductRequest,
    { data: updateData, error: updateError, isLoading: createLoading },
  ] = useCreateTopProductRequestMutation();

  const [latestTopProducts, setLatestTopProducts] = useState([]);
  const {
    data: getTopProductsData,
    isLoading,
    isError,
  } = useGetTopProductsQuery();

  useEffect(() => {
    if (getTopProductsData) {
      // Sort the data by a property that represents its order or date, assuming there's such a property
      const sortedData = [...getTopProductsData].sort((a, b) => {
        // You might need to adjust this depending on the data structure and the property to sort by
        return a.timestamp - b.timestamp;
      });

      // Take the last 10 items (latest) and extract their IDs
      const latestIds = sortedData.slice(-10).map((product) => product.id);

      setLatestTopProducts(latestIds);
      // setSelectedProducts(latestIds);
    }
  }, [getTopProductsData]);

  const handleSelectChange = (e) => {
    const selectedValues = e.target.value;

    if (selectedValues?.length <= 10) {
      // Allow selection if the number of selected values is 10 or less
      setSelectedProducts(selectedValues);
    }
    // You can optionally display a message to the user if they exceed the limit.
  };
  const handleSave = async () => {
    // Assuming createTopDestinationRequest creates a new destination
    console.log(selectedProducts);

    if (selectedProducts.length === 0) {
      // Handle the case where no Products are selected
      toast.success("NO PRODUCTS SELECTED");

      onClose();
      return;
    }

    // Create an array to store promises for individual API requests
    const saveRequests = selectedProducts.map(async (product) => {
      // Create an individual destination request
      const response = await createTopProductRequest({
        productId: product,
      });
      return response;
    });

    // Wait for all the requests to complete
    const responses = await Promise.all(saveRequests);

    if (responses.some((response) => response.error)) {
      // Handle the case where some requests failed
      toast.error("Some requests failed");
      setSelectedProducts([]);
      onClose();
      return;
    } else {
      toast.success("Data saved successfully");
      setSelectedProducts([]);
    }

    // Check the responses if needed

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <Typography
            variant="h5"
            className={classes.modalTitle}
            style={{ fontWeight: "medium" }}>
            {title}
          </Typography>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="selectedProducts" style={{ color: "#006738" }}>
                Select Up to 10 Products
              </InputLabel>
              <Select
                label="Select Up to 10 Products"
                variant="outlined"
                multiple
                color="success"
                value={selectedProducts}
                onChange={handleSelectChange} // Use the custom handler
                inputProps={{
                  name: "selectedProducts",
                  id: "selectedProducts",
                }}>
                {getProducts?.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.productName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <LoadingButton
                  loading={createLoading}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddTopProducts;
