import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Chip,
} from "@mui/material";
import { Edit, Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  useGetProductByIdQuery,
  useGetProcedureByIdQuery,
  useCreateProcedureRequestMutation,
  useUpdateProductRequestMutation,
} from "../../../../features/api/apiSlice";
import { images } from "../../../../assets/index";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { BASE_FILE_URL } from "../../../../constants/apiTags";

const useStyles = makeStyles((theme) => ({
  cardsHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5% 0px 20px 0px",
  },
  smallCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
    borderRadius: "10px",
  },
  smallCardTitles: {
    color: "#FBC74C",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  smallCardTitles2: {
    color: "#58E9CF",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  smallCardTitles3: {
    color: "#FF5050",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  infoCard: {
    marginTop: "3%",
    padding: "20px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
  },
  productImage: {
    width: "40%",
  },
  productDetails: {
    width: "60%",
  },
  chip: {
    marginRight: "5px",
    marginBottom: "5px",
  },

  productContainer: {
    width: "100%",
    display: "flex",
    marginTop: "7%",
    // backgroundColor: "#000",
  },
  productImage: {
    width: "40%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
  },
  productDetails: {
    width: "60%",
    // backgroundColor: "#fff",
    padding: "20px",
  },
  productName: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  createdAt: {
    color: "#777",
  },
  description: {
    textAlign: "justify",
    marginBottom: "15px",
    marginTop: "10px",
  },
  typeCard: {
    border: "1px solid #000",
    width: "fit-content",
    borderRadius: "8px",
    padding: "10px",
    marginBottom: "20px",
  },
  destinationsTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  destinationsChips: {
    display: "flex",
    flexWrap: "wrap",
  },
  routesTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "5px",
    marginTop: "10px",
  },
  routesChips: {
    display: "flex",
    flexWrap: "wrap",
  },
  borderedCardRed: {
    // border: "2px solid transparent",
    position: "relative",
    border: "1px solid transparent", // Adjust border width as needed
    borderRadius: "10px",
    marginTop: "20px",
    padding: "40px",
    backgroundImage:
      "linear-gradient(312.25deg, #FFC4BC 0%, rgba(255, 255, 255, 0) 66.19%)",
  },
  borderedCardGreen: {
    // border: "2px solid transparent",
    position: "relative",
    border: "1px solid transparent", // Adjust border width as needed
    borderRadius: "10px",
    marginTop: "40px",
    padding: "20px",
    backgroundImage:
      "linear-gradient(312.25deg, #A6FF96 0%, rgba(255, 255, 255, 0) 66.19%)",
  },
  // beautifulBorder: {
  //   backgroundImage: "linear-gradient(312.25deg, #FFC4BC 0%, rgba(255, 255, 255, 0) 66.19%)",
  //   borderRadius: "18px",
  //   position: "absolute",
  //   content: "",
  //   top: "-2px",
  //   bottom: "-2px",
  //   left: "-2px",
  //   right: "-2px",
  //   zIndex: "-1",
  // }
}));

const ProductDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [destinations, setDestinations] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [bgClr, setBgClr] = useState("#fff");
  const [clr, setClr] = useState("#000");

  const handleTheme = () => {
    if (bgClr === "#fff") {
      setBgClr("#000");
      setClr("#fff");
    } else {
      setBgClr("#fff");
      setClr("#000");
    }
  };
  const {
    data: productDetailData,
    isLoading: isProductDetailLoading,
    isError: isErrorProductDetail,
    error: productDetailError,
  } = useGetProductByIdQuery({ id: params.id });
  const [updateProductRequest, { isLoading: isUpdateProductLoading }] =
    useUpdateProductRequestMutation();
  const {
    data: pData,
    isLoading: isPDataLoading,
    isError: isPDataError,
    error: pDataError,
  } = useGetProcedureByIdQuery({
    id: productDetailData && productDetailData[0]?.procedureId,
  });
  const [
    createProcedureRequest,
    {
      isLoading: isCreatingProcedure,
      isError: isErrorCreatingProcedure,
      error: createProcedureError,
    },
  ] = useCreateProcedureRequestMutation();

  const procedureD = {
    title: "Full Procedure To Export",
    productName: "Product A",
    productId: null,

    mainSteps: [
      {
        description: "1.1	 Export Procedures and Documentation ",
        subSteps: [
          {
            description: "1.1.1	VAT Registration",
            detail:
              "According to Value Added Tax Proclamation, (285/2002) and the Regulation by the Council of Ministers on VAT (Regulation 79/2002), all export goods are liable to VAT at the zero rates. Literally, no VAT has to be charged. An exporter is entitled to reclaim the VAT on all the goods and services purchased to produce the exports. ",
          },
          {
            description: "1.1.2	Export Procedures and the required Documents ",
            detail:
              "An exporter must make sure that she/he is ready to export a commodity by availing/knowing how to avail export commodities .Enough finance, human resources and logistics such as warehouses and transport and packaging facilities, etc. Following this, it needs also to prepare the required documents.",
          },
          {
            description: "1.1.2.1	Document Preparations",
            detail:
              "Despite some developments, e commerce trade/electronic trade has not developed well in Ethiopia. Foreign trade is, therefore, mostly executed through the exchange of documents, which are guarantees for parties, the importer and exporter. These legal documents are usually called “bank documents /shipping documents” and are binding to both parties. Various documents produced by export supporting institutions need therefore be filled in an appropriate manner.",
          },
          {
            description: "1.1.2.2 Negotiation and Contract",
            detail:
              "Before starting to produce documents, both parties must negotiate on the terms and conditions of the sales/purchase. These negotiations must be held in a skillful manner to avoid suppression of the other party – usually the buyer.\nOnce the two parties reach an agreement with regard to the terms and conditions, the contract will have to be prepared. It is understood that the contract is a binding document. The following should be carefully noted before entering into a contract:\n\na. Bear in mind that international contracts contain potential points of dispute in the interpretation of terms of the contract.\n\nb. Be sure to specify the rights and obligations of each party.\n\nc. Do not rely too much on trust or on the buyer.\n\nd. Choose a written contract over an oral agreement.\n\ne. Consult a lawyer who is an expert in the preparation of contracts or who is well-versed in international law.\n\nf. Make sure that the contract has incorporated into it an arbitration clause.",
          },
          {
            description:
              "A. Knowing Essential Elements of the Export Sales Contract",
            detail:
              "The export sales contract contains various essential elements that should be carefully considered. These include:\n\n• The Names and addresses\n• The products, their pertinent standards, and specifications\n• Quantity\n• Inspection\n• Total value of the contract\n• The terms of delivery\n• Taxes, duties, and charges\n• Period of delivery shipment\n• Part shipment, trans-shipment, consolidation of cargo\n• Packaging, labeling, and marking\n• Terms of payment, amount, mode, and currency\n• Discounts and commissions\n• Licenses and permits\n• Insurance\n• Documentary requirements",
          },
          {
            description:
              "Where the exporter is required to buy marine insurance, it is important that he/she be asked to provide a certificate of insurance, rather than the policy so as to enable her/him to ship consignments under an open policy. This includes the following:",
            detail:
              "• Product guarantee\n• Delay in delivery\n• Force majeure or excuse for non-performance of the contract\n• Remedial action\n• The applicable law\n• Arbitration\n• Signature of the parties\n\nMoreover, it should be noted that the passing of ownership is still a matter of uncertainty in international trade. Even without a bill of lading, it is prudent to have a reservation of an ownership clause in the sales contract, to the effect that the exporter reserves ownership of the goods sold until she or he has received the purchase price.",
          },
          {
            description: "1.1.2.3 Documentary credit/Letter of Credit (L/C).",
            detail:
              "A letter of credit (L/C) is a bank document prepared by the opening bank (the buyer’s bank). This L/C is prepared in accordance with the Inco terms and ICC’s regulations of import and export. Its major contents are:\n• It protects the best interests of both the buyer and seller\n• It helps to enforce delivery of goods by the seller and payment of the value thereof by the buyer\n• The L/C –opening bank commits itself to settling the payment, whereas the seller’s bank, which is usually referred to as the negotiating bank, gives its assurance that the concerned commodity will be exported to the buyer.",
          },
          {
            description: "A. What to know in respect of L/C",
            detail:
              "Documentary credit (L/C) takes various types such as irrevocable, revocable, cash against document (CAD), advance payment, etc. The following are its major components:\n1. An undertaking letter of the buyer that the consignment will be settled within a maximum of 90 days effective from the day on which the foreign Exchange permit for the cash Against Document (CAD) mode of payment is secured is very important.\n2. It has the following information:\n- The L/C number\n- The opening date of the L/C\n- The name of the beneficiary - that is, the seller - and their full address\n- The name of the applicant, or the buyer, and their full address\n- The name of the L/C –opening bank and its full address\n- The amount of money in the credit\n- Whether or not partial shipment is allowed\n- Whether or not transshipment of the goods is allowed\n- The port of loading\n- The port of discharge\n- The latest date of shipment\n- The expiry date\n- The bag marking instruction\n- Description of the goods",
          },
          {
            description:
              "B. When receiving an L/C the following must be checked:",
            detail:
              "- Confirm the formality of the L/C (that is, whether or not it is irrevocable)\n- Confirm the credibility of the issuing bank\n- Check whether or not the L/C conforms with the sales contract\n- Study the content of each clause, which may affect performance\n- All pertinent bank documents, which are required by the L/C –opening bank for holding the negotiations, must be enumerated in the L/C including any additional conditions that must be met.\n- Bank documents must be prepared by the seller, in accordance with the L/C requirements, without any single mistake, including spelling errors:\n  - A commercial invoice\n  - A packing list\n  - The bill of lading\n  - The insurance certificate on the CIF term\n  - The certificate of weight and quality, and\n  - The certificate of origin, SPTA, phytosanitary certificate, etc",
          },
          {
            description: "1.1.2.4 Preparing cargo for Export",
            detail:
              "Once the L/C is received, the seller must be sure that she/he has to prepare the cargo and deliver it to the port of loading before the latest date of shipment. The seller should pay particular attention to the following:\n- Make sure that the required commodity is in stock for processing\n- Prepare packaging material as per the L/C marking instruction\n- Process the goods in accordance with the quality requirement stipulated in the L/C\n- Ascertain the arrival of the cargo at least one week before the latest date of shipment to make sure that the cargo will be loaded on the nominated vessel before the expiry of the shipping date.",
          },
          {
            description: "1.1.2.5 Banking Permit",
            detail:
              "A bank permit form is obtained from the negotiating bank and filled in carefully in accordance with the requirements of the L/C and of the form itself. The following documents (two copies each) are required for getting the approval of the Bank permit:\n- A contract duly signed by both the seller and the buyer in two copies\n- An authenticated message that the L/C is opened, together with the mode of payment\n- The seller’s invoice in two copies\n- The export license that is valid for the year\n- The tax registration certificate, i.e., the TIN certificate\n- The application form for the export permit duly filled in, signed, and stamped by the exporter\n- Confirmation that the exporter has cleared all outstanding items at the NBE and is not in the list of delinquent exporters.",
          },
          {
            description:
              "1.1.2.6 Getting Export Authorization and Quality Certificates for Shipment",
            detail:
              "The prepared cargo must be certified by the pertinent authority such as the Ministry of Agriculture, the Quality and Standards Authority, or by an independent Inspection Company, etc. To fulfill this, the following are required:\na) A letter of application\nb) Two copies of the contract\nc) The L/C\nd) The bank permit\ne) The sanitary certificate, and\nf) The invoice needs to be submitted.\nThe pertinent authorities give their assurances that the quality of the prepared cargo is in accordance with the requirement stipulated in the L/C. They approve and confirm that the cargo is in accordance with the country‘s regulation, which governs the exportation of the commodity.",
          },
        ],
      },
      {
        description: "1.1.3	Customs Clearance ",
        subSteps: [
          {
            description:
              "To avoid costly delays, the exporter has to submit to the customs clearing agent:",
            detail:
              "21Lorem ipsum dolor sit amet, consectetur adipiscing elit.\na) The shipping instruction given by the exporter\nb) The authorization form obtained from the shipping agent, signed, and stamped\nc) One copy of the bank permit\nd) A commercial invoice\ne) Copies of the contract and of the L/C; and\nf) The Tax Identification Number (TIN)\n\nAll of the above documents must be prepared by the shipping agent before shipping the cargo to the customs center. Simultaneously, the loaded trucks will be sent to the customs station for the purpose of checking. The quality and standards authority will then seal them for final release to the port. The officer from the quality and standards Authority will, however, need a notification of the truck and trailer number before coming to the customs office. The exporter should then communicate the details of the shipment to the nominated insurance company for inland coverage. Thus,\n\n- The exporter also needs to closely follow on every track of the goods that are to be exported up until they arrive at the port and are safely unloaded.\n- If the truck is delayed for unforeseen reasons beyond the latest shipment date, the exporter needs to inform the buyer and request an extension of the L/C shipping date and of the expiry date.",
          },
        ],
      },
      {
        description:
          "1.1.4	Preparation of the Bank Documents/Shipping Documents",
        subSteps: [
          {
            description: "",
            detail:
              "Close follow up is necessary to make sure that the cargo has safely arrived at the port before the scheduled vessel. As a matter of fact, it is advisable to deliver the cargo 72 hours before the arrival of the vessel.",
          },
          {
            description: "",
            detail:
              "The shipping agent arranges and avails containers for the cargo to be stuffed into containers.\nAs soon as the cargo is stuffed into containers, the shipping company issues details about contents of the stuffing, such as the number of the containers, the number of the seal, and the date of the stuffing. This copy must be sent to the buyer in order to notify that the cargo is at the port as per the schedule.",
          },
          {
            description: "",
            detail:
              "Sometimes the buyer could require knowing on additional inspection of the weight and quality of the goods, and fumigation at the port of loading. so it is necessary to do this before the arrival of the cargo at the port.",
          },
        ],
      },
      {
        description: "1.1.5	Final Steps requiring attention:",
        subSteps: [
          {
            description:
              "As soon as the cargo is on board the ship, the shipping company is expected to send a draft bill of lading to the shipper/exporter for verification before the original one.",
            detail:
              "It is important to carefully read and re-read so as to check whether or not there are any mistakes in the bill of lading, including errors in spelling.\nFollowing the correction, the original bill of lading must be issued by the shipping company and immediately forwarded to the shipper through clearing agent for fast preparation and delivery of the bank documents to the bank for the negotiations.",
          },
          {
            description: "The major bank documents are:",
            detail:
              "a) The bill of lading\nb) The commercial invoice\nc) The packing list\nd) The sanitary certificate\ne) The weight and quality certificate, and\nf) The preferential tariff certificates",
          },
        ],
      },
      {
        description: "1.1.6	Common discrepancies to be  learnt ",
        subSteps: [
          {
            description:
              "The following common discrepancies should also be understood for the necessary precautionary measures:",
            detail:
              "a) Unclean bills of lading\nb) Lack of evidence showing the goods have been shipped on Board\nc) The shipment made between ports other than those stated in the letter of credit\nd) Presentation of an insurance document other than the type mentioned in L/C\ne) Unit of measurement of the currency for insurance coverage not in accordance to the L/C\nf) Insurance not effective when the shipping documents arrive\ng) Conflicting documents\nh) Difference between description of the goods on the invoice from that given in the letter of credit\ni) Weights, marks, and numbers are not the same in all the documents\nj) Not timely presentation of the documents\nk) Not all documents in L/C have been presented\nl) The bill of lading and the insurance documents are not endorsed correctly\nm) Absence of signatures on the documents though they are required.",
          },
        ],
      },
      {
        description: "1.1.7	Additional Reminders",
        subSteps: [
          {
            description: "",
            detail:
              "a) The exporter must retain copies and submit all original documents to the negotiating bank for holding with it the negotiations.\nb) A separate letter, which accompanies the documents, will also have to be written including the details of the bank account and instruction to remit the proceedings should be given.\nc) The bank clerk who receives the bank documents must sign on the copy of the captioned letter as confirmation of receipt. Then, after examining carefully, the bank will send the documents to the L/C opening bank by courier services nominated by the buyer or by such as DHL, FEDEX, TNT, EMS, etc.\nD)Export Incentives",
          },
          {
            description:
              "The country’s significant incentive packages for exporters include the following:",
            detail:
              "⦁ Export Credit Guarantee Schemes\n⦁ Duty Exemption on Raw Materials used as inputs for export\n⦁ Investment Credit Support\n⦁ Tax Holidays for Priority Sectors\n⦁ Duty Free Import of Capital Goods\n⦁ Bonded Warehouse",
          },
        ],
      },
      {
        description: "1.1.8	Request for Payment of the Sales Value",
        subSteps: [
          {
            description: "",
            detail:
              "If the cargo is sold on fob basis, the bank will immediately (after holding the negotiations) credit the exporter’s account for the full amount of the sales value. Yet a risk of claim by the buyer is still there until the time that the cargo is safely delivered to her/his port and cleared. However, if the sale is on CIF basis, the payment can be effected but the risk of the sea freight and insurance will still be on the shoulder of the seller.",
          },
          {
            description: "",
            detail:
              "Risk on export is waived only when both parties are happy with the transaction made and willing to continue their business relation.",
          },
        ],
      },
    ],
    summary:
      "Export Procedures and Documentation : Once an export company is legally established, it should clearly know the various procedures it needs to regularly follow.",
    institutionsInvolved: [],
    requiredDocuments: [],
  };

  const [procedureData, setProcedureData] = useState(procedureD);

  useEffect(() => {
    if (productDetailData) {
      setProcedureData({
        ...procedureData,
        productName: productDetailData[0]?.productName ?? " ",
        productId: productDetailData[0]?.id ?? null,
      });
    }
  }, [productDetailData]);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleCreateProcedure = async (e) => {
    e.preventDefault();
    console.log(procedureData);
    const updateData = {
      id: productDetailData[0].id,
      description: productDetailData[0].description,
      type: productDetailData[0].type,
      // routes: JSON.parse(productDetailData[0].routes),
      routes: productDetailData[0].routes,

      productName: productDetailData[0].productName,
      hsCode: productDetailData[0].hsCode,
      // destinations: JSON.parse(productDetailData[0].destinations),
      destinations: productDetailData[0].destinations,

      coverImage: productDetailData[0].coverImage,
      // userId: user?.user_id,
    };
    await createProcedureRequest(procedureData)
      .then((res) => {
        if (res.data) {
          toast.success("Procedure Created Successfully");
          updateData.procedureId = res?.data[0]?.id;
          updateProductRequest(updateData)
            .then((res) => {
              console.log(res);
              if (res.data) {
                toast.success(
                  "Product Updated Successfully\n Redirecting to  Procedure Detail"
                );

                navigate(`/dashboard/procedures/${res?.data[0].procedureId}`);
              }
            })
            .catch((err) => {
              toast.error("Error Updating Product");
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Creating Procedure");
      });
  };

  const AnimatedCounter = ({ value }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      let start = 0;
      const interval = setInterval(() => {
        if (start >= value) {
          clearInterval(interval);
        }
        setCount(start);
        start += 10;
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }, [value]);

    return <span style={{ color: "#DE8F5F", fontSize: "25px" }}>{count}</span>;
  };

  useEffect(() => {
    if (productDetailData) {
      // setRoutes(JSON.parse(productDetail?.routes));
      // setDestinations(JSON.parse(productDetail?.destinations));
      setRoutes(productDetailData[0]?.routes);
      setDestinations(productDetailData[0]?.destinations);
    }
  }, [productDetailData]);

  const renderProductDetail = () => {
    if (isProductDetailLoading) {
      return <div>Loading...</div>;
    }
    if (isErrorProductDetail) {
      return <div>Error loading product details.</div>;
    }
    const productDetail = productDetailData[0];
    return (
      <div className={classes.productContainer}>
        <div
          className={classes.productImage}
          style={{
            // backgroundImage: `url(${images?.MainBG})`,
            backgroundImage: `url(${
              BASE_FILE_URL + "/" + productDetail?.coverImage
            })`,
          }}></div>

        <div className={classes.productDetails}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography
              className={classes.productName}
              // style={{ color: bgClr }}
              variant="h4">
              {productDetail?.productName}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.createdAt} variant="body2">
                Created:{" "}
                {new Date(productDetail?.createdAt).toLocaleDateString()}
              </Typography>
            </div>
          </Stack>
          <Typography className={classes.description} variant="body1">
            {productDetail?.description}
          </Typography>

          <Card className={classes.typeCard}>Type: {productDetail?.type}</Card>

          <Typography className={classes.destinationsTitle} variant="h5">
            Destinations
          </Typography>
          <div className={classes.destinationsChips}>
            {destinations?.map((destination, index) => (
              <Chip
                key={index}
                label={destination}
                color="primary"
                variant="outlined"
                style={{ margin: "5px" }}
              />
            ))}
          </div>

          <Typography className={classes.routesTitle} variant="h5">
            Routes
          </Typography>
          <div className={classes.routesChips}>
            {routes?.map((route, index) => (
              <Chip
                key={index}
                label={route}
                color="secondary"
                variant="outlined"
                style={{ margin: "5px" }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        marginLeft: 0,
        padding: "20px",
        paddingTop: "2%",
        // backgroundColor: bgClr,
      }}>
      <IconButton
        style={{
          position: "absolute",
          top: 100,
          right: 10,
          // backgroundColor: clr,
          // color: bgClr,
        }}
        // onClick={handleTheme}
        aria-label="Edit">
        <Edit />
      </IconButton>

      <div className={classes.cardsHolder}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Paper
              className={classes.smallCard}
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow:
                  "rgba(251, 199, 76, 0.1) -10px 20px 25px 10px, rgba(251, 199, 76, 0.04) 0px 10px 10px -5px",
              }}>
              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles}>Total Views</h3>
                <AnimatedCounter value={765} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              className={classes.smallCard}
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow:
                  "rgba(88, 233, 207, 0.1) -10px 20px 25px 10px, rgba(88, 233, 207, 0.04) 0px 10px 10px -5px",
              }}>
              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles2}>Searched</h3>
                <AnimatedCounter value={765} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              className={classes.smallCard}
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow:
                  "rgba(255, 80, 80, 0.1) -10px 20px 25px 10px, rgba(255, 80, 80, 0.04) 0px 10px 10px -5px",
              }}>
              <Stack
                p={2}
                alignItems="center"
                justifyContent="center"
                zIndex={2}>
                <h3 className={classes.smallCardTitles3}>Flags</h3>
                <AnimatedCounter value={765} />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>

      {renderProductDetail()}

      <div
        className={
          productDetailData && productDetailData[0]?.procedureId
            ? classes.borderedCardGreen
            : classes.borderedCardRed
        }>
        <div style={{ borderRadius: "15px" }}>
          {productDetailData &&
          pData &&
          pData[0] &&
          productDetailData[0]?.procedureId ? (
            <>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "#A8DF8E" }}>
                Procedure
              </Typography>
              <Link
                style={{ marginTop: "10px", textDecoration: "none" }}
                href={
                  "/dashboard/procedures/" + productDetailData[0]?.procedureId
                }
                target="_blank">
                <Button variant="contained" color="primary">
                  View Procedure
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "#FF6969" }}>
                No Procedure Available
              </Typography>

              {/* <IconButton
                style={{ marginTop: "10px" }}
                aria-label="Add Procedure"
                onClick={handleCreateProcedure}>
                <Add />
              </IconButton> */}

              <LoadingButton
                loading={isCreatingProcedure}
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                  backgroundColor: "#A8DF8E",
                  color: "#fff",
                }}
                onClick={handleCreateProcedure}>
                Create Procedure
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
