import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  MenuItem,
  Stack,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, LoadingButton } from "@mui/lab";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { images } from "../../../assets/index";
import { colorTags } from "../../../constants/variants";
import CustomButton from "../../../components/CustomButton";
import { styled } from "@mui/material/styles";
import { useCreatePromotionRequestMutation } from "../../../features/api/apiSlice";
import { emailRegex, phoneNumberRegex } from "../../../constants/variants";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "15px 30px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    // "linear-gradient(45deg, #068FFF 0%, #75C2F6  51%, #068FFF  100%)",
    "linear-gradient(45deg, #006737 0%, #9DC08B  51%, #006737  100%)",

  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyles = makeStyles((theme) => ({
  mainBG: {
    backgroundImage: `url(${images?.NodePattern})`,
  },
  container: {
    backgroundImage: `url(${images?.NodePattern})`,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: theme.spacing(14),
  },
  formContainer: {
    width: "80%",
    maxWidth: 600,
    padding: theme.spacing(4),
    boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(2),
    background: "transparent",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(8),
    color: colorTags.PRIMARY,
  },
}));

const SponsorshipRequestPage = () => {
  const classes = useStyles();

  const [
    createPromotionRequest,
    { data, isLoading, isError, isSuccess, error },
  ] = useCreatePromotionRequestMutation();
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [companyName, setCompanyName] = useState(user?.companyName ?? "");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [sponsorshipPackage, setSponsorshipPackage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const buttonRef = useRef(null);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setIsMouseEnter(false);
  };

  const isFormValid = () => {
    return (
      companyName !== "" &&
      phone !== "" &&
      email !== "" &&
      address !== "" &&
      sponsorshipPackage !== "" &&
      startDate !== null &&
      endDate !== null
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      companyName,
      requestDate: new Date(),
      startDate,
      endDate,
      coverImage: user?.coverImage ?? user?.avatar,
      level: sponsorshipPackage,
      memberID: user[0].user_id,
      status: "Requested",
    };

    await createPromotionRequest(data)
      .then((res) => {
        if (res.data) {
          // console.log(res.data);
          setTimeout(() => {
            toast.success("Promotion request submitted successfully");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setStartDate(new Date());
    setEndDate(new Date());
    setCompanyName("");
    setSponsorshipPackage("");
    window.open(`/profile/${user.user_id}`, "_self");
  };

  // await createPromotionRequest(data).then((res) => {
  // toast.success("Promotion request submitted successfully");
  // setCompanyName("");
  // setPhone("");
  // setEmail("");
  // setAddress("");
  // setSponsorshipPackage("");
  // setStartDate(null);
  // setEndDate(null);

  //     }).catch((err) => {
  //       toast.error("Something went wrong");
  //       setCompanyName("");
  // setPhone("");
  // setEmail("");
  // setAddress("");
  // setSponsorshipPackage("");
  // setStartDate(null);
  // setEndDate(null);

  //     });
  // };
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <div className={classes.mainBG}>
      <Header />
      <Container className={classes.container}>
        <Box
          style={{ backgroundColor: "rgba(255,255,255,0.9)", padding: "5%" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: "30px" }}
            align="center"
            className={classes.title}>
            Sponsorship Request
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company Name / Event Name"
                  placeholder="Company Name / Event Name"
                  plaxe
                  variant="outlined"
                  disabled
                  color="success"
                  fullWidth
                  className={classes.formField}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  color="success"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    const phoneValue = e.target.value;
                    const phoneIsValid = phoneNumberRegex.test(phoneValue);
                    setPhoneError(!phoneIsValid);
                  }}
                  required
                  error={phoneError}
                  helperText={phoneError ? "Invalid phone number" : ""}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  color="success"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    const emailValue = e.target.value;
                    const emailIsValid = emailRegex.test(emailValue);
                    setEmailError(!emailIsValid);
                  }}
                  required
                  error={emailError}
                  helperText={emailError ? "Invalid email address" : ""}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  color="success"
                  multiline
                  fullWidth
                  className={classes.formField}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  // style={{ marginTop: 20, marginBottom: 20 }}
                  select
                  label="Sponsorship Package"
                  variant="outlined"
                  color="success"
                  fullWidth
                  className={classes.formField}
                  value={sponsorshipPackage}
                  onChange={(e) => setSponsorshipPackage(e.target.value)}
                  required>
                  <MenuItem value="Platinum">Platinum</MenuItem>
                  <MenuItem value="Gold">Gold</MenuItem>
                  <MenuItem value="Silver">Silver</MenuItem>
                  <MenuItem value="Bronze">Bronze</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Stack
              direction="row"
              spacing={4}
              style={{ marginTop: 20, marginBottom: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">Start Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={startDate}
                      minDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">End Date</FormLabel>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      minDate={startDate}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>
            </Stack>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth>
              Submit
            </Button> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  width: "20%",
                  marginTop: 5,
                  //   marginBottom: 20,
                  transition: "transform 0.3s", 
                  "&:hover": {
                    transform: "translateX(-150px)", 
                  },
                }}>
                Submit
              </Button> */}
              {/* 
              <Button
                ref={buttonRef}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                Submit
              </Button> */}
              {/* <CustomButton text={"Submit"} /> */}
              <StyledButton onClick={handleSubmit}>
                <LoadingButton loading={isLoading} variant="text">
                  <span style={{ color: "white" }}>Submit</span>
                </LoadingButton>
              </StyledButton>
            </div>
          </form>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default SponsorshipRequestPage;
