import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneAreaBase } from "material-ui-dropzone";
import {
  useGetFAQsQuery,
  useGetFAQByIdQuery,
  useCreateFAQRequestMutation,
  useUpdateFAQRequestMutation,
  useDeleteFAQRequestMutation,
} from "../../../features/api/apiSlice";

import { useStyles } from "../../../App";

import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    minHeight: "50px !important",
    border: "1px dashed #006738",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
    zIndex: 10000,
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const EditFAQ = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [subject, setSubject] = useState("");
  const [customSubject, setCustomSubject] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [
    updateFAQRequest,
    {
      data: updateRequestData,
      isLoading: isLoadingUpdateRequest,
      isError: isErrorUpdateRequest,
      isSuccess,
    },
  ] = useUpdateFAQRequestMutation();

  useEffect(() => {
    if (data) {
      //   console.log(data);
      setQuestion(data.question);
      setAnswer(data.answer);
      setSubject(data.subject);
    }
  }, [data]);

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = {
      id: data.id,

      faqId: data.id,
      question,
      answer,
      subject: customSubject ?? subject,
      userId: user?.user_id,

      submittedBy: "",
    };
    console.log(formData);

    await updateFAQRequest(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) toast.success("FAQ Updated Successfully");
        setQuestion("");
        setAnswer("");
        setSubject([]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("FAQ Update Failed");
      });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Question"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              placeholder="Maximum of 500 characters"
              InputProps={{
                inputProps: {
                  maxLength: 500,
                },
              }}
              style={{ marginTop: "15px", zIndex: 1000 }}
              // inputProps={{
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              // ,
              // }}
            />

            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="Routes" style={{}}>
                Subject
              </InputLabel>
              <Select
                className={classes.textField}
                label="Subject"
                variant="outlined"
                value={subject}
                // placeholder="Maximum of 50 characters"

                // InputProps={{
                //   inputProps: {
                //     maxLength: 50,
                //   },
                // }}
                color="success"
                onChange={(e) => setSubject(e.target.value)}>
                {["Custom", "Coffee", "Textile", "Ship", "Plane", "Land"].map(
                  (option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  )
                )}
              </Select>
              {subject === "Custom" && (
                <TextField
                  label="Custom Subject"
                  variant="outlined"
                  color="success"
                  InputProps={{
                    inputProps: {
                      maxLength: 50,
                    },
                  }}
                  fullWidth
                  className={classes.textField}
                  value={customSubject}
                  onChange={(e) => setCustomSubject(e.target.value)}
                />
              )}
            </FormControl>
            <TextField
              label="Answer"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Maximum of 1000 characters"
              InputProps={{
                inputProps: {
                  maxLength: 1000,
                },
              }}
              className={classes.textField}
              fullWidth
              style={{ marginTop: "15px" }}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isLoadingUpdateRequest}
                  color="success"
                  variant="contained"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditFAQ;
