import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PartnersLaunch from "../pages/Home/Partners/PartnersLaunch";
import { images } from "../assets/index";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(-45deg, #4CCD99, #006738, white)", // Updated background gradient
    zIndex: 1,
  },
  logo: {
    position: "absolute",
    top: "75%",
    left: "85%",
    transform: "translate(-50%, -50%)",
    zIndex: 0, // Ensure logo is above background but below other content
  },
  countdown: {
    fontSize: "80vh",
    opacity: 0,
    fontWeight: "bold",
    color: "#fff",
    transform: "scale(1)",
    textShadow: "0 2px 5px rgba(0,0,0,0.5)", // Add glossy effect
    zIndex: 1,
  },
  countdownThree: {
    animation: "$fadeInOutThree 7.5s ease-in-out forwards",
  },
  countdownTwo: {
    animation: "$fadeInOutTwo 7.5s ease-in-out forwards",
  },
  countdownOne: {
    animation: "$fadeInOutOne 7.5s ease-in-out forwards",
  },
  "@keyframes fadeInOutThree": {
    "0%": {
      opacity: 0,
      transform: "scale(0.5)", // Initial scale
    },
    "30%, 70%": {
      opacity: 1,
      transform: "scale(1.2)", // Zoom in
    },
    "35%, 65%": {
      opacity: 1,
      transform: "scale(1)", // Normal scale
    },
    "40%, 100%": {
      opacity: 0,
      transform: "scale(1)", // Normal scale
    },
  },
  "@keyframes fadeInOutTwo": {
    "0%": {
      opacity: 0,
      transform: "scale(0.5)", // Initial scale
    },
    "30%, 70%": {
      opacity: 1,
      transform: "scale(1.2)", // Zoom in
    },
    "35%, 65%": {
      opacity: 1,
      transform: "scale(1)", // Normal scale
    },
    "40%, 100%": {
      opacity: 0,
      transform: "scale(1)", // Normal scale
    },
  },
  "@keyframes fadeInOutOne": {
    "0%": {
      opacity: 0,
      transform: "scale(0.5)", // Initial scale
    },
    "50%, 90%": {
      opacity: 1,
      transform: "scale(1.2)", // Zoom in
    },
    "55%, 85%": {
      opacity: 1,
      transform: "scale(1)", // Normal scale
    },
    "60%, 100%": {
      opacity: 0,
      transform: "scale(1)", // Normal scale
    },
  },
  button7: {
    zIndex: 1,

    touchAction: "manipulation",
    cursor: "pointer",
    position: "relative",
    padding: "1.5rem 4.5rem", // Adjusted padding for bigger button
    borderRadius: "1.87rem",
    lineHeight: "1.25rem",
    fontSize: "1.2rem", // Adjusted font size for bigger button
    fontWeight: 600,
    transition: "all 150ms ease-in-out",
    border: "1px solid #012880",
    backgroundImage: "linear-gradient(-180deg, #4CCD99 0%, #006738 100%)",
    boxShadow:
      "0 0.5rem 0.625rem 0 rgba(0,0,0,0.2), 0 -0.125rem 0.75rem rgb(65, 201, 226, 1) inset, 0 0.375rem 0.125rem rgba(255,255,255, 0.4) inset, 0 0.125rem 0.25rem 0 rgba(180, 70, 207, 1) inset",
    "&:hover": {
      filter: "brightness(1.1)",
    },
    "&:active": {
      transform: "scale(.96)",
    },
  },
  buttonText: {
    color: "transparent",
    backgroundImage: "linear-gradient(0deg, #4CCD99 -10%, #FEFAFD 100%)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    filter: "drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1))",
  },
  buttonLineBefore: {
    content: '""',
    display: "block",
    height: "0.125rem",
    position: "absolute",
    top: "0.25rem",
    left: "50%",
    transform: "translateX(-50%)",
    width: "calc(100% - 3.75rem)",
    background: "#fff",
    borderRadius: "100%",
    opacity: "0.7",
    backgroundImage:
      "linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%)",
  },
  buttonLineAfter: {
    content: '""',
    display: "block",
    height: "0.125rem",
    position: "absolute",
    bottom: "0.375rem",
    left: "50%",
    transform: "translateX(-50%)",
    width: "calc(100% - 3.75rem)",
    background: "#fff",
    borderRadius: "100%",
    filter: "blur(1px)",
    opacity: "0.05",
    backgroundImage:
      "linear-gradient(-270deg, rgba(255,255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%)",
  },
  welcomeText: {
    zIndex: 1,

    color: "#fff",
    fontSize: "3rem",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    textShadow: "0 2px 5px rgba(0,0,0,0.5)", // Add glossy effect
    transition: "color 0.5s ease-in-out",
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();
  const [count, setCount] = useState(-1);

  const startCountdown = () => {
    setCount(3);
  };

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount((prevCount) => prevCount - 1);
      }, 3000); // Adjust the duration between numbers

      return () => clearTimeout(timer);
    }
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      // Redirect to another page after the countdown finishes
      window.location.href = "/";
    }
  }, [count]);

  return (
    <div className={classes.loadingContainer}>
      {/* Logo */}
      <img src={images?.AcLogo} alt="Logo" className={classes.logo} />

      {count > 0 || count === -1 ? (
        <Typography
          variant="h1"
          className={`${classes.countdown} ${
            count === 3
              ? classes.countdownThree
              : count === 2
              ? classes.countdownTwo
              : classes.countdownOne
          }`}>
          {/* {count === 1 && "፩"}
          {count === 2 && "፪"}
          {count === 3 && "፫"} */}
          {count === 1 && "1"}
          {count === 2 && "2"}
          {count === 3 && "3"}
        </Typography>
      ) : (
        // <CircularProgress />
        <></>
      )}
      {count === -1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
          <PartnersLaunch />
          <Typography
            variant="h2"
            className={classes.welcomeText}
            style={{
              transition: "color 0.5s ease-in-out", // Transition color change
            }}>
            Welcome to the Official Launch of the <br />
            Addis Chamber Export Portal For Trade Facilitation!
          </Typography>
          <Button
            className={classes.button7}
            onClick={startCountdown}
            disableElevation>
            <span className={classes.buttonText}>Launch!</span>
            <span className={classes.buttonLineBefore}></span>
            <span className={classes.buttonLineAfter}></span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
