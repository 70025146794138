import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Grid,
  styled,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { colorTags } from "../../../constants/variants";
import { useStyles } from "../../../App";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useGetPromotionsQuery,
  useGetPromotionByIdQuery,
  useCreatePromotionRequestMutation,
  useUpdatePromotionRequestMutation,
  useDeletePromotionRequestMutation,
  useGetMembersQuery,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";

const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "#F4EAD5",
  borderRadius: "5px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #285430  71%, #006737  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyless = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: "70%",
  },
}));

const companyNames = ["Company A", "Company B", "Company C"];

const AddSponsors = ({ onClose }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [requestDate, setRequestDate] = useState(new Date());
  const [status, setStatus] = useState("Approved");
  const [memberId, setMemberId] = useState(0);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  // const [memberId, setMemberId] = useState(0);
  const [
    createPromotionRequest,
    {
      isLoading: isLoadingPromotionRequest,
      isError: isErrorPromotionRequest,
      error: errorPromotionRequest,
    },
  ] = useCreatePromotionRequestMutation();

  const {
    data: membersData,
    isLoading: isLoadingMembers,
    isError: isErrorMembers,
  } = useGetMembersQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (startDate < new Date()) {
      toast.error("Start date cannot be less than the current date.");
      return;
    }

    if (endDate < startDate) {
      toast.error("End date cannot be less than the start date.");
      return;
    }

    const data = {
      companyName: selectedCompany,
      level: selectedLevel,
      startDate: startDate,
      endDate: endDate,
      requestDate: requestDate,
      memberID: memberId,
      status: "Approved",
      userId: user?.user_id,
    };

    await createPromotionRequest(data)
      .then((res) => {
        if (res.data) {
          toast.success("Promotion Request Created Successfully");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });

    // Reset form fields
    setStartDate(new Date());
    setEndDate(new Date());
    setRequestDate(new Date());
    setSelectedCompany("");
    setSelectedLevel("");
    onClose();
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Typography
        variant="h4"
        style={{ color: colorTags.PRIMARY }}
        gutterBottom>
        Add New Promotion
      </Typography>
      <Stack
        direction="row"
        spacing={5}
        style={{ marginTop: 20, marginBottom: 20, width: "70%" }}>
        <Stack spacing={3} sx={{ width: "50%" }}>
          {/* <FormControl
            className={classes.formControl}
            style={{ marginBottom: "16px" }}>
            <InputLabel>Company Name</InputLabel>
            <Select
              label="Company Name"
              color="success"
              required
              value={selectedCompany}
              onChange={(e) => {
                const selectedCompanyId = membersData?.find(
                  (company) => company.companyName === e.target.value
                )?.id;
                setSelectedCompany(e.target.value);
                setMemberId(selectedCompanyId);
              }}>
              {membersData?.map((company) => (
                <MenuItem key={company.id} value={company.companyName}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Autocomplete
            options={membersData || []}
            getOptionLabel={(option) => option.companyName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Name"
                variant="outlined"
                color="success"
                required
              />
            )}
            value={selectedCompany}
            onChange={(event, newValue) => {
              const selectedCompanyId = newValue?.id || 0;
              setSelectedCompany(newValue?.companyName);
              setMemberId(selectedCompanyId);
            }}
          />
        </Stack>
        <Stack spacing={3} sx={{ width: "50%" }}>
          <FormControl
            className={classes.formControl}
            style={{ marginBottom: "16px" }}>
            <InputLabel>Level</InputLabel>
            <Select
              label="Level"
              color="success"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}>
              <MenuItem value="Platinum">Platinum</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
              <MenuItem value="Silver">Silver</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={5}
        style={{ marginTop: 20, marginBottom: 20, width: "70%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} color="success">
          <Stack spacing={3} sx={{ width: "50%" }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">start date</FormLabel>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                color="success"
                minDate={new Date()}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    color="success"
                    required
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3} sx={{ width: "50%" }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">end date</FormLabel>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                color="success"
                value={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <TextField
                    color="success"
                    required
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </LocalizationProvider>
      </Stack>
      <div
        style={{
          width: "70%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <StyledButton onClick={handleSubmit} color="primary">
          Submit Sponsor
        </StyledButton>
      </div>
    </Container>
  );
};

export default AddSponsors;
