import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Link,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { colorTags } from "../../constants/variants";

import {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationRequestMutation,
  useUpdateNotificationRequestMutation,
  useDeleteNotificationRequestMutation,
  useMarkNotificationAsReadRequestMutation,
} from "../../features/api/apiSlice";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    height: 550,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
}));

const Notifications = ({ open, onClose }) => {
  const classes = useStyles();

  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const notifications = [
    {
      message: "Notification 1",
      date: "2021-10-10",
      time: "09:30 AM",
      seen: false,
      subject: "Subject 1",
      id: 1,
    },
    {
      message: "Notification 2",
      date: "2021-10-10",
      time: "10:15 AM",
      seen: false,
      subject: "Subject 2",
      id: 2,
    },
    {
      message: "Notification 3",
      date: "2021-10-11",
      time: "11:45 AM",
      seen: false,
      subject: "Subject 3",
      id: 3,
    },
    {
      message: "Notification 4",
      date: "2021-10-11",
      time: "02:30 PM",
      seen: false,
      subject: "Subject 4",
      id: 4,
    },
  ];

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isErrorNotifications,
    error: notificationsError,
  } = useGetNotificationByIdQuery({ id: user?.user_id });
  // const {
  //   data: notificationsData,
  //   isLoading: notificationsLoading,
  //   isError: isErrorNotifications,
  //   error: notificationsError,
  // } = useGetNotificationsQuery();

  const [groupedNotifications, setGroupedNotifications] = useState([]);

  useEffect(() => {
    if (notificationsData) {
      setGroupedNotifications(
        notificationsData.reduce((acc, notification) => {
          const date = new Date(notification.notifDate).toLocaleDateString(
            "en-US"
          );
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(notification);
          return acc;
        }, {})
      );
    }
  }, [notificationsData]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [
    markAllAsRead,
    { isLoading: isMarkLoading, isError: isMarkError, error: markError },
  ] = useMarkNotificationAsReadRequestMutation();
  const [openRead, setOpenRead] = useState(false);

  const handleMarkAllAsRead = async (e) => {
    // setOpenRead(true)
    e.preventDefault();
    await markAllAsRead({ id: user?.user_id })
      .then((res) => {
        if (res.data) {
          setOpenRead(false);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  return (
    <Card
      style={{
        boxShadow:
          "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
      }}
      open={open}
      onClose={onClose}
      className={classes.card}>
      <CardHeader title="Notifications" />
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {groupedNotifications && groupedNotifications.length > 0 && (
          <div style={{ display: "flex" }}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="success"
                />
              }
              label="Mark all as read"
            />
            {isChecked ? (
              <Button onClick={handleMarkAllAsRead}>Save</Button>
            ) : null}
          </div>
        )}
        {groupedNotifications && groupedNotifications.length > 0 && (
          <Link href="/dashboard/notifications">View All</Link>
        )}
      </div>
      <List>
        {Object.entries(groupedNotifications).map(([date, notifications]) => (
          <React.Fragment key={date}>
            <ListItem>
              <ListItemText primary={date} />
            </ListItem>
            {notifications?.map((notification) => (
              <ListItem key={notification.id}>
                <ListItemText
                  primary={notification.message}
                  secondary={new Date(
                    notification.notifDate
                  ).toLocaleDateString("en-US")}
                  secondaryTypographyProps={{ align: "right" }}
                />
                {/* {groupedNotifications && groupedNotifications?.length < 1 && (
                  <ListItem>
                    <ListItemText primary="No notifications" />
                  </ListItem>
                )} */}
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
};
export default Notifications;
