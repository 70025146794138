import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  TextField,
  Paper,
  IconButton,
} from "@mui/material";
import {
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close"; // Add CloseIcon import
import { colorTags } from "../../../../constants/variants";
import {
  FILE_LINK_URL,
  BASE_FILE_URL,
  INTERNAL_LINK_URL,
} from "../../../../constants/apiTags";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(0),

    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%", // Adjust as needed
    maxHeight: "90%", // Adjust as needed
    overflow: "auto",
    outline: "none",
    position: "relative", // Add position relative
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: colorTags.PRIMARY,
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "medium",
    color: colorTags.PRIMARY,
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
}));

const InstitutionDetailModal = ({ open, onClose, institutionData }) => {
  const classes = useStyles();
  const [institutionName, setInstitutionName] = useState("");
  const [bio, setBio] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  useEffect(() => {
    if (institutionData) {
      setInstitutionName(institutionData.institutionName);
      setBio(institutionData.bio);
      setCategory(institutionData.category);
      setImage(institutionData.image);
      setDescription(institutionData.description);
      console.log(institutionData);
    }
  }, [institutionData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <div
        className={classes.modalContent}
        style={{
          backgroundImage: `url(${BASE_FILE_URL + image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.8)",
            padding: "16px",
          }}>
          {" "}
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" className={classes.modalTitle}>
            Institution Details
          </Typography>
          <div className={classes.modalBody}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h6">Institution Name</Typography>
                  <Typography>{institutionName}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h6">Description</Typography>
                  <Typography>{description}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h6">Category</Typography>
                  <Typography>{category}</Typography>
                </Paper>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <Paper elevation={3} style={{ padding: "1px" }}> */}
              {/* <div>
                  <img
                    src={BASE_FILE_URL + image}
                    alt="Cover Image"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div> */}
              {/* </Paper>
            </Grid> */}
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InstitutionDetailModal;
