import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import { ArrowBack, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { images } from "../../../assets/index";

const Sidebar = ({ open, handleSidebarClose }) => {
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const handleClick = () => {
    // Handle sub-menu item clicks or other actions as needed.
  };
  const navigate = useNavigate();
  const handleContentClick = () => {
    setIsContentOpen(!isContentOpen);
  };
  const handleMoreClick = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleSidebarClose}
      style={{ minWidth: "40%" }}>
      <div
        style={{
          minWidth: "220px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <img
          src={images?.AcLogo}
          alt="Logo"
          style={{ width: 30, height: 30, margin: "10px" }}
        />
        <IconButton onClick={handleSidebarClose}>
          <ArrowBack />
        </IconButton>
      </div>
      <div
        style={{
          minWidth: "220px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
        }}>
        <List>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemText primary="Home" />
          </ListItem>

          {/* Content menu */}
          <ListItem button onClick={handleContentClick}>
            <ListItemText primary="Content" />
            {isContentOpen ? <ExpandLess /> : <ExpandMore />}{" "}
            {/* Use ExpandLess or ExpandMore icons */}
          </ListItem>
          <Collapse in={isContentOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={{ paddingLeft: "20px" }}>
              <ListItem button onClick={() => navigate("/repository/0")}>
                <ListItemText primary="Products" style={{ fontSize: "22px" }} />
              </ListItem>
              <ListItem button onClick={() => navigate("/repository/1")}>
                <ListItemText
                  primary="Procedures"
                  style={{ fontSize: "22px" }}
                />
              </ListItem>
              <ListItem button onClick={() => navigate("/repository/2")}>
                <ListItemText
                  primary="Institutions"
                  style={{ fontSize: "22px" }}
                />
              </ListItem>
              <ListItem button onClick={() => navigate("/resources")}>
                <ListItemText
                  primary="Resources"
                  style={{ fontSize: "22px" }}
                />
              </ListItem>
              <ListItem button onClick={() => navigate("/logistics")}>
                <ListItemText
                  primary="Logistics"
                  style={{ fontSize: "22px" }}
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => navigate("/services")}>
            <ListItemText primary="Services" />
          </ListItem>
          <ListItem button onClick={() => navigate("/about-us")}>
            <ListItemText primary="About Us" />
          </ListItem>
          <ListItem button onClick={() => navigate("/contact-us")}>
            <ListItemText primary="Contact Us" />
          </ListItem>

          <ListItem button onClick={handleMoreClick}>
            <ListItemText primary="More" />
            {isMoreOpen ? <ExpandLess /> : <ExpandMore />}{" "}
          </ListItem>

          <Collapse in={isMoreOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={{ paddingLeft: "20px" }}>
              <ListItem button onClick={() => navigate("/faq")}>
                <ListItemText primary="FAQ" style={{ fontSize: "22px" }} />
              </ListItem>
              <ListItem button onClick={() => navigate("/posts")}>
                <ListItemText primary="Posts" style={{ fontSize: "22px" }} />
              </ListItem>
              {user ? (
                <ListItem button onClick={() => navigate("/business-matching")}>
                  <ListItemText
                    primary="Business Matching"
                    style={{ fontSize: "22px" }}
                  />
                </ListItem>
              ) : null}
              <ListItem button onClick={() => navigate("/export")}>
                <ListItemText primary="Export" style={{ fontSize: "22px" }} />
              </ListItem>
              {/* <ListItem button onClick={() => navigate("/women-in-export")}>
                <ListItemText
                  primary="Women in Export"
                  style={{ fontSize: "22px" }}
                />
              </ListItem> */}
              <ListItem button onClick={() => navigate("/resources")}>
                <ListItemText
                  primary="Resources"
                  style={{ fontSize: "22px" }}
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
