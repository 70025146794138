import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Divider, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import "./Chat.css";
import { images } from "../../../assets/index";
import { useSelector } from "react-redux";
import {
  useGetChatByIdQuery,
  useCreateChatRequestMutation,
  useUpdateChatRequestMutation,
  useDeleteChatRequestMutation,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { colorTags } from "../../../constants/variants";
import { BASE_URL } from "../../../constants/apiTags";
import { Bars } from "react-loader-spinner";
import { LoadingButton } from "@mui/lab";
const MessageBubble = styled(Paper)(({ sent }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "8px 20px 8px 10px",
  margin: "8px",
  borderRadius: "10px",
  alignSelf: sent ? "flex-end" : "flex-start",
  backgroundColor: sent ? "#f5f5f5" : "#006738",
  color: !sent ? "white" : "black",
  whiteSpace: "pre-wrap",
  maxWidth: "70%",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  wordBreak: "break-word",
  position: "relative",
}));
const ChatMini = () => {
  const initialMessages = [
    {
      id: 1,
      sender: "user1",
      message: "Hey there!",
      date: "2023-06-02",
      time: "14:30",
    },
    {
      id: 2,
      sender: "user2",
      message: "Hi! How are you?",
      date: "2023-06-02",
      time: "14:32",
    },
    {
      id: 3,
      sender: "user1",
      message: "I'm doing well, thanks!",
      date: "2023-06-02",
      time: "14:35",
    },
    {
      id: 4,
      sender: "user2",
      message: "That's great to hear!",
      date: "2023-06-02",
      time: "14:40",
    },
  ];

  const [messages, setMessages] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  // const storedUser = localStorage.getItem("user");
  // const user = storedUser ? JSON.parse(storedUser) : null;
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [chatData2, setChatData2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [set, setSet] = useState(false);
  const {
    data: conversationData,
    isLoading,
    isError,
  } = useGetChatByIdQuery({
    id: user?.user_id,
  });

  const [
    updateConversation,
    { isLoading: isUpdateLoading },
    // { isError: isUpdateError },
  ] = useUpdateChatRequestMutation();
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 500);
    setIsMidScreen(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  const [updateChatRequest, { isLoading: isUpdatingChatRequest }] =
    useUpdateChatRequestMutation();
  const [createChatRequest, { isLoading: isCreatingChatRequest }] =
    useCreateChatRequestMutation();

  useEffect(() => {
    const fetchData = () => {
      console.log(user?.user_id);
      fetch(
        `${BASE_URL}/Chats/${
          (user && user?.role_name === "Member") || user?.role_name === "member"
            ? user?.user_id
            : null
        }`
      )
        .then((response) => response.json())
        .then((data) => {
          setChatData2(data[0]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    };

    // Fetch data initially and then set up an interval to fetch data every second
    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (chatData2) {
      setMessages(chatData2);
    }
  }, [chatData2]);

  const handleSendMessage = async (e) => {
    if (newMessage?.trim() !== "") {
      const newMessageObj = {
        // id: messages?.conversation?.length + 1,
        sender: "member",
        message: newMessage,
        time: new Date(),
      };

      if (!messages || messages?.conversation?.length === 0) {
        // If the conversation array is empty, create a new chat request
        const newChatData = {
          id: user?.user_id,
          conversation: [newMessageObj],
          user: [
            {
              id: user?.user_id,
              name: user?.companyName,
            },
          ],
        };
        console.log(newChatData);
        // Call the createChatRequest function to create a new chat
        await createChatRequest(newChatData)
          .then((res) => {
            if (res.data) {
              // Handle success if needed
              setSet(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error creating chat");
          });
      } else {
        // If the conversation array is not empty, update the existing chat
        const updatedChatData = {
          ...messages,
          conversation: [...messages.conversation, newMessageObj],
          user: [
            {
              id: user?.user_id,
              name: user?.companyName,
            },
          ],
        };

        // Call the updateChatRequest function to update the chat
        await updateChatRequest(updatedChatData)
          .then((res) => {
            if (res.data) {
              // Handle success if needed
              setSet(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error sending message");
          });
      }

      setNewMessage("");
    }
  };
  const extractHourAndMinute = (time) => {
    const dateTimeParts = time.split("T");
    if (dateTimeParts.length === 2) {
      const timeParts = dateTimeParts[1].split(":");
      if (timeParts.length === 3) {
        const [hour, minute] = timeParts.slice(0, 2);

        // Convert to Ethiopian Time (ETC)
        const utcDateTime = new Date(
          `${dateTimeParts[0]}T${hour}:${minute}:00Z`
        );
        const etcDateTime = utcDateTime.toLocaleString("en-US", {
          timeZone: "Africa/Addis_Ababa", // ETC timezone
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        });

        return etcDateTime;
      }
    }
    return "";
  };

  useEffect(() => {
    if (!set && messages && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setSet(true);
    }
  }, [loading, messages]);

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "500px",
        // minWidth: "350px",
        margin: "50px auto",
        // backgroundImage: `url(${images.BlurBg2})`,
        backgroundSize: "cover",
        backgroundColor: "rgba(255,255,255,0.8)",
        flexGrow: 1,
        padding: isSmallScreen ? "10px" : "20px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        // boxShadow: "8px 8px 8px 8px #006738",

        boxShadow: "0 0 5px #006738",
        maxHeight: isSmallScreen ? "400px" : "600px", // Adjust the value for small screens
        overflow: "hidden",
      }}>
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          // backgroundColor: "#fafa",

          width: "100%",
          marginBottom: "20px",

          // margin: "40px",
        }}>
        <h2>Chat with Our Staff</h2>
      </div>
      <Divider />
      {messages && messages?.conversation && (
        <div
          ref={chatContainerRef}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            flexGrow: 1,
            overflowY: "auto",
            scrollbarWidth: "thin",
            padding: isSmallScreen ? "10px" : "20px",
            scrollbarWidth: "thin",
            // backgroundColor: "#000",
            scrollbarColor: "#007bff #f5f5f5",
          }}>
          {messages &&
            messages?.conversation &&
            messages?.conversation?.map((msg, index) => {
              const currentDate = new Date(msg.time).toLocaleDateString(
                "en-US"
              );
              const previousDate =
                index > 0
                  ? new Date(
                      messages.conversation[index - 1]?.time
                    ).toLocaleDateString("en-US")
                  : null;

              return (
                <div key={msg.id}>
                  {index === 0 || currentDate !== previousDate ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "5px",
                        fontSize: "12px",
                        color: "#888",
                      }}>
                      {currentDate}
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent:
                        msg.sender === "member" || msg.sender === "Member"
                          ? "flex-end"
                          : "flex-start",
                      marginBottom: "5px",
                    }}>
                    <MessageBubble
                      key={msg.id}
                      sent={msg.sender === "member" || msg.sender === "Member"}
                      elevation={3}
                      // time={msg.time}
                    >
                      {msg.message}
                      <div
                        style={{
                          fontSize: "12px",
                        }}>
                        {extractHourAndMinute(msg.time)}
                        {/* {msg.time} */}
                      </div>
                    </MessageBubble>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
          paddingTop: "15px",
        }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          placeholder="Type your message..."
          style={{
            flexGrow: "1",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <LoadingButton
          loading={isUpdatingChatRequest || isCreatingChatRequest}
          onClick={handleSendMessage}
          style={{
            // marginLeft: "10px",
            // padding: "10px 10px",
            // backgroundColor: "#007bff",
            color: colorTags.PRIMARY,
            // border: "none",
            // borderRadius: "50%",
            cursor: "pointer",
          }}>
          <SendIcon />
        </LoadingButton>
      </div>
    </div>
  );
};

export default ChatMini;
