import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../components";
import { primaryColor, colorTags } from "../../../constants/variants";
import GridData from "../../../components/GridData";
import { useNavigate } from "react-router-dom";
import { images } from "../../../assets/index";
import AddMember from "./AddMember";
import EditMember from "./EditMember";
import DeleteDialog from "../../../components/DeleteDialog";
import {
  useGetMembersQuery,
  useGetMemberByIdQuery,
  useCreateMemberRequestMutation,
  useUpdateMemberRequestMutation,
  useDeleteMemberRequestMutation,
} from "../../../features/api/apiSlice";

import EditIcon from "@mui/icons-material/Edit";
import ImageDisplay from "../../../components/ImageDisplay";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const membersDataq = [
  {
    id: 1,
    name: "Company A",
    industry: "Technology",
    products: ["Product A", "Product B"],
    destinations: ["Dest A", "Dest B"],
    avatar: images.Avatar,
    cover: images.Cover,
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    socials: {
      linkedIn: "linkedin.com/companyA",
      facebook: "facebook.com/companyA",
      instagram: "instagram.com/companyA",
    },
    website: "companyA.com",
  },
];

export default function MembersPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    data: membersData,
    isLoading: membersLoading,
    isError: membersError,
    error: errorMembers,
  } = useGetMembersQuery();

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "avatar",
      headerName: "Avatar",
      flex: 1,
      renderCell: (params) => (
        <div>
          <img
            src={BASE_FILE_URL + params?.row?.avatar}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 2,
    },
    {
      field: "sector",
      headerName: "Sector",
      flex: 1,
    },
    {
      field: "subSector",
      headerName: "Sub Sector",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                console.log(params);
                window.open("/profile/" + params?.row?.id, "_blank");
              }}>
              <Info color="primary" />
            </IconButton>

            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteMemberRequestMutation}
              dialogLabel="Delete Member"
              id={params?.row?.id}
              name={params?.row?.companyName + " as a Member?"}
            />
          </div>
        );
      },
    },
  ];

  if (membersLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: "5%", paddingTop: "5%", width: "90%" }}>
      {modalOpen ? (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: colorTags.SECONDARY,
          }}
          onClick={handleModalClose}>
          Close
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "88%",
            backgroundColor: primaryColor,
          }}
          onClick={handleModalOpen}>
          Add Member
        </Button>
      )}

      <AddMember
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Member"
        content="This is the modal content."
        actionText="Close"
      />

      <EditMember
        open={editModalOpen}
        onClose={handleEditModalClose}
        title="Edit Member"
        content="This is the modal content."
        actionText="Close"
        data={selectedRow}
      />

      <SimpleCard
        title="Members"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData
            rows={membersData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
          />
        </Box>
      </SimpleCard>
    </div>
  );
}
