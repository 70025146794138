import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { Stack, Divider } from "@mui/material";
import ShareIcon from "@material-ui/icons/Share";
import { Link as MuiLink, Link } from "@mui/material";
import { useGetEventsQuery } from "../../../features/api/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetEventByIdQuery,
  useGetCustomImageByIdQuery,
} from "../../../features/api/apiSlice";
import LoadingScreen from "../../../components/LoadingMain";
import ShareDialog from "../Posts/ShareEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(4),
    [theme.breakpoints.down(900)]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    alignSelf: "flex-start",
    marginBottom: theme.spacing(5),
    textAlign: "left",
    // marginLeft: theme.spacing(42),
    fontWeight: "medium",
    fontSize: "35px",
    color: colorTags.PRIMARY,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    maxWidth: "300px",
    [theme.breakpoints.down(900)]: {
      maxWidth: "250px",
    },
  },
  iconButton: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",
    bottom: "55px",
    color: colorTags.PRIMARY,

    borderRadius: "50%",
    left: "20%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.9)",
    },
  },
  iconButton2: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",
    color: colorTags.PRIMARY,
    bottom: "55px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.9)",
    },
    right: "10%",
    // transform: "translateX(-50%)",
  },
  dateContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    backgroundColor: `rgba(${colorTags.SECONDARY_RGBA}, 0.8)`,
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Arial",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
  },
  semiCircleTop: {
    content: "",
    position: "absolute",
    top: "-5px",
    left: "15%",
    width: "10px",
    height: "10px",
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    backgroundColor: "white",
  },
  semiCircleTop2: {
    content: "",
    position: "absolute",
    top: "-5px",
    left: "75%",
    width: "10px",
    height: "10px",
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    backgroundColor: "white",
  },
  day: {
    fontSize: "20px",
    lineHeight: "1.2",
    position: "absolute",
    top: "8px",
  },
  month: {
    fontSize: "12px",
    lineHeight: "1",
    position: "absolute",
    top: "36px",
  },
  year: {
    width: "100%",
    fontSize: "14px",
    lineHeight: "1",
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    padding: "10px",
    position: "absolute",
    textAlign: "center",
    bottom: "0",
    left: "0",
  },
  titleText: {
    marginTop: theme.spacing(1),
    // fontWeight: "bold",
    fontSize: "14px",
    cursor: "pointer",
    color: "#000",
    textAlign: "left",
  },
  titleDesc: {
    marginTop: theme.spacing(1),
    // fontWeight: "bold",
    fontSize: "20px",
    color: colorTags.SECONDARY,
    textAlign: "left",
    [theme.breakpoints.down(600)]: {
      fontSize: "15px",
      marginTop: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  description: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.7)",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(600)]: {
      fontSize: "14px",
      whiteSpace: "normal", // Allow text to wrap to multiple lines
      display: "-webkit-box",
      "-webkit-line-clamp": 2, // Number of lines to display (in this case, 2)
      "-webkit-box-orient": "vertical",
    },
  },
}));

const trimDescription = (description, maxLength) => {
  if (description.length <= maxLength) {
    return description;
  }
  return description.substring(0, maxLength) + " ...";
};

const monthAbbreviations = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const getFormattedMonth = (date) => {
  const month = date.getMonth();
  return monthAbbreviations[month];
};

const RecentEvents = () => {
  const classes = useStyles();
  const { data: eventsData, isLoading, isError } = useGetEventsQuery();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

  // const openShareDialog = async (event) => {
  //   await setSelectedEvent(event);
  //   console.log(event);
  //   console.log(selectedEvent);
  //   setShareDialogOpen(true);
  // };

  const openShareDialog = (event) => {
    setSelectedEvent(event);
    console.log(selectedEvent);
    // setShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setSelectedEvent(null);
    setShareDialogOpen(false);
  };
  const { id } = useParams();
  useEffect(() => {
    if (selectedEvent) setShareDialogOpen(true);
  }, [selectedEvent]);

  const convertToDateWithMonthName = (dateString) => {
    const dateParts = dateString.split("/");
    const monthNumber = parseInt(dateParts[0]);
    const day = dateParts[1];
    const year = dateParts[2];

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[monthNumber - 1];

    return `${monthName} ${day}, ${year}`;
  };
  function divideParagraph(paragraph) {
    if (!paragraph) return ["", "", ""];

    const introMaxLength = 534;
    const firstPartMaxLength = 900;

    if (paragraph.length <= introMaxLength) {
      return [paragraph, "", ""];
    }

    const intro = paragraph.slice(0, introMaxLength);
    const remaining = paragraph.slice(introMaxLength);

    if (remaining.length <= firstPartMaxLength) {
      return [intro, remaining, ""];
    }

    const firstPart = remaining.slice(0, firstPartMaxLength);
    const remaining2 = remaining.slice(firstPartMaxLength);

    return [intro, firstPart, remaining2];
  }
  // Intersection Observer callback function
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        target.style.animation = "fadeInUp 1s forwards";
        observer.unobserve(target);
      }
    });
  };

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  return (
    <div style={{ paddingTop: "5%", paddingBottom: "5%" }}>
      <div style={{ paddingLeft: "16.8%" }}>
        <Typography
          variant={isSmallScreen ? "h4" : "h3"}
          className={classes.title}>
          Events
        </Typography>
      </div>

      <div className={classes.root}>
        <Grid
          container
          spacing={5}
          style={{
            paddingLeft: isSmallScreen ? "0%" : "2%",
            paddingRight: isSmallScreen ? "0%" : "2%",
            width: "100%",
          }}>
          {eventsData?.map((event, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              style={{
                display: "flex",
                paddingLeft: "5px",
                paddingRight: "5px",
                justifyContent: "flex-start",
              }}>
              <Stack direction="row" spacing={2}>
                <div
                  className={classes.dateContainer}
                  onClick={() => navigate("/posts/events/" + event?.id)}>
                  <span className={classes.semiCircleTop} />
                  <span className={classes.semiCircleTop2} />
                  <span className={classes.day}>{`${event.startDate.slice(
                    8,
                    10
                  )} - ${event.endDate.slice(8, 10)}`}</span>
                  <span className={classes.month}>
                    {event.startDate.slice(5, 7) === event.endDate.slice(5, 7)
                      ? getFormattedMonth(new Date(event.startDate))
                      : `${getFormattedMonth(
                          new Date(event.startDate)
                        )} - ${getFormattedMonth(new Date(event.endDate))}`}
                  </span>
                  <span className={classes.year}>
                    {event.startDate.slice(0, 4) === event.endDate.slice(0, 4)
                      ? event.startDate.slice(0, 4)
                      : `${event.startDate.slice(0, 4)} - ${event.endDate.slice(
                          0,
                          4
                        )}`}
                  </span>
                </div>
                <Stack direction="column" spacing={isSmallScreen ? 0 : 2}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}>
                    {" "}
                    <Typography
                      variant="body1"
                      className={classes.titleText}
                      onClick={() => navigate("/posts/events/" + event?.id)}>
                      {event.title}{" "}
                    </Typography>
                    <IconButton
                      // component={MuiLink}
                      // target="_blank"
                      onClick={(e) => {
                        openShareDialog(event);
                      }}
                      // href={event.link}
                      style={{
                        color: colorTags.SECONDARY,
                        // width: "25px",
                        "&:hover": {
                          color: colorTags.PRIMARY,
                          background: "none",
                        },
                      }}>
                      <ShareIcon style={{ width: "15px" }} />
                    </IconButton>
                  </div>
                  <div style={{ width: "70%" }}>
                    {" "}
                    <Typography variant="body2" className={classes.description}>
                      {trimDescription(
                        event.description,
                        isSmallScreen ? 20 : 50
                      )}
                    </Typography>
                  </div>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </div>
      {isShareDialogOpen && (
        <ShareDialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          event={selectedEvent}
        />
      )}
    </div>
  );
};

export default RecentEvents;
