import React,{useState,useEffect} from "react";
import { Doughnut } from "react-chartjs-2";
import {
  useGetNewsQuery,
  useGetPublicationsQuery,
  useGetEventsQuery,
  useGetDocumentsQuery,
  useGetProductsQuery,
  useGetPromotionsQuery,
} from "../../../features/api/apiSlice";


const options = {
  // You can customize chart options here
};

const DoughnutChart = () => {

const {
  data: promotionsData,
  isLoading: promotionsLoading,
  isError: promotionsError,

} = useGetPromotionsQuery();

  const {
    data: newsData,
    isLoading: newsLoading,
    isError: newsError,
  } = useGetNewsQuery();
  const {
    data: publicationsData,
    isLoading: publicationsLoading,
    isError: publicationsError,
  } = useGetPublicationsQuery();
  const {
    data: eventsData,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetEventsQuery();
const {data: productsData, isLoading: productsLoading, isError: productsError} = useGetProductsQuery();
const {data: documentsData, isLoading: documentsLoading, isError: documentsError} = useGetDocumentsQuery();

const [data, setData] = useState({
  labels: ["Platinum", "Gold", "Silver"],
  datasets: [
    {
      data: [0, 0, 0],
      backgroundColor: ["#FFEBD8", "#FFD700", "#B0B0B0"], // Colors for Platinum, Gold, and Silver
    },
  ],
})

const [loading, setLoading] = useState(true);

useEffect(() => {
  if (!promotionsLoading && promotionsData) {
    const levels = {
      Platinum: 0,
      Gold: 0,
      Silver: 0,
    };

    promotionsData.forEach((promotion) => {
      switch (promotion.level) {
        case 'Platinum':
          levels.Platinum++;
          break;
        case 'Gold':
          levels.Gold++;
          break;
        case 'Silver':
          levels.Silver++;
          break;
        // Add more cases for other levels if needed
        default:
          break;
      }
    });

    data.datasets[0].data = [levels.Platinum,levels.Gold, levels.Silver ];
    setLoading(false);

  }
}, [promotionsData, promotionsLoading]);
  return (
    <div style={{ width: "300px", height: "300px" }}>
       {loading ? (
            <h1>Loading...</h1>
          ) : ( <Doughnut data={data} options={options} />)}
    </div>
  );
};

export default DoughnutChart;
