import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, API_TAGS } from "../../constants/apiTags";
const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
export const apiSlice = createApi({
  reducerpath: "apiSlice",

  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.user?.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
  }),

  tagTypes: Object.values(API_TAGS),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
// Dashboard stats
getDashboardStats: builder.query({
      query: () => ({
        url: `GetDashboardStats`,
        method: "GET",
      }),
      providesTags: [API_TAGS.DASHBOARD],
    }),

//Export - Products
getProducts: builder.query({
      query: () => ({
        url: `Products`, 
        method: "GET",
      }),
      providesTags: [API_TAGS.PRODUCT],
    }),
    
getProductById: builder.query({
  query: (data) => `Products/${data.id}`,

      providesTags: [API_TAGS.PRODUCT],
    }),

createProductRequest: builder.mutation({
      query: (data) => ({
        url: `Products`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PRODUCT],
    }),

updateProductRequest: builder.mutation({
      query: (data) => ({
        url: `Products/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PRODUCT],
    }),

    deleteProductRequest: builder.mutation({
      query: (data) => ({
        url: `Products/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.PRODUCT],
    }),

// Export - Procedures
getProcedures: builder.query({
      query: () => ({
        url: `Procedures`,
        method: "GET",
      }),
      providesTags: [API_TAGS.PROCEDURE],
    }),

getProcedureById: builder.query({
  query: (data) => `Procedures/${data.id}`,
      providesTags: [API_TAGS.PROCEDURE],
}),

createProcedureRequest: builder.mutation({
      query: (data) => ({
        url: `Procedures`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PROCEDURE],
    }),

updateProcedureRequest: builder.mutation({
      query: (data) => ({
        url: `Procedures/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PROCEDURE],
    }),

    deleteProcedureRequest: builder.mutation({
      query: (data) => ({
        url: `Procedures/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.PROCEDURE],
    }),






// Export - Destinations
getDestinations: builder.query({
      query: () =>`Destinations`,
      providesTags: [API_TAGS.DESTINATION],
     
      }),
  
getDestinationById: builder.query({
  query: (data) => `Destinations/${data.id}`,

      providesTags: [API_TAGS.DESTINATION],
    }),

createDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `Destinations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.DESTINATION],
    }),

updateDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `Destinations/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.DESTINATION],
    }),

    deleteDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `Destinations/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.DESTINATION],
    }),

// Export - Institutions
getInstitutions: builder.query({
      query: () => ({
        url: `Institutions`,
        method: "GET",
      }),
      providesTags: [API_TAGS.INSTITUTION],
    }),

getInstitutionById: builder.query({
  query: (data) => `Institutions/${data.id}`,

      providesTags: [API_TAGS.INSTITUTION],
}),

createInstitutionRequest: builder.mutation({
      query: (data) => ({
        url: `Institutions`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.INSTITUTION],
    }),

updateInstitutionRequest: builder.mutation({
      query: (data) => ({
        url: `Institutions/${data.inistitutionId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.INSTITUTION],
    }),

    deleteInstitutionRequest: builder.mutation({
      query: (data) => ({
        url: `Institutions/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.INSTITUTION],
    }),

// Export - Documents
getDocuments: builder.query({
      query: () => ({
        url: `Documentss`,
        method: "GET",
      }),
      providesTags: [API_TAGS.DOCUMENT],
    }),

  getDocumentById: builder.query({
    query: (data) => `Documentss/${data.id}`,

      providesTags: [API_TAGS.DOCUMENT],
    }),

createDocumentRequest: builder.mutation({
      query: (data) => ({
        url: `Documentss`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.DOCUMENT],
    }),

updateDocumentRequest: builder.mutation({
      query: (data) => ({
        url: `Documentss/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.DOCUMENT],
    }),

    deleteDocumentRequest: builder.mutation({
      query: (data) => ({
        url: `Documentss/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.DOCUMENT],
    }),

//  Posts - News
getNews: builder.query({
      query: () => ({
        url: `PostsNews`,
        method: "GET",
      }),
      providesTags: [API_TAGS.NEWS],
    }),

getNewsById: builder.query({
  query: (data) => `PostsNews/${data.id}`,

      providesTags: [API_TAGS.NEWS],
    }),

createNewsRequest: builder.mutation({
      query: (data) => ({
        url: `PostsNews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NEWS],
    }),

updateNewsRequest: builder.mutation({
      query: (data) => ({
        url: `PostsNews/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NEWS],
    }),

    deleteNewsRequest: builder.mutation({
      query: (data) => ({
        url: `PostsNews/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.NEWS],
    }),

// Posts - Publications
getPublications: builder.query({
      query: () => ({
        url: `PostsPublications`,
        method: "GET",
      }),
      providesTags: [API_TAGS.PUBLICATION],
    }),

getPublicationById: builder.query({
  query: (data) => `PostsPublications/${data.id}`,
      providesTags: [API_TAGS.PUBLICATION],
}),

createPublicationRequest: builder.mutation({
      query: (data) => ({
        url: `PostsPublications`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

updatePublicationRequest: builder.mutation({
      query: (data) => ({
        url: `PostsPublications/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

    deletePublicationRequest: builder.mutation({
      query: (data) => ({
        url: `PostsPublications/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.PUBLICATION],
    }),

// Posts - Events
getEvents: builder.query({
      query: () => ({
        url: `PostsEvents`,
        method: "GET",
      }),
      providesTags: [API_TAGS.EVENT],
    }),

getEventById: builder.query({
  query: (data) => `PostsEvents/${data.id}`,
      providesTags: [API_TAGS.EVENT],
}),

createEventRequest: builder.mutation({
      query: (data) => ({
        url: `PostsEvents`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    } ),

updateEventRequest: builder.mutation({
      query: (data) => ({
        url: `PostsEvents/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    }),

    deleteEventRequest: builder.mutation({
      query: (data) => ({
        url: `PostsEvents/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.EVENT],
    }),

// Chat
getChats: builder.query({
      query: () => ({
        url: `Chats`,
        method: "GET",
      }),
      providesTags: [API_TAGS.CHAT],
    }),

getChatById: builder.query({
  query: (data) => `Chats/${data.id}`,
      providesTags: [API_TAGS.CHAT],
}),

createChatRequest: builder.mutation({
      query: (data) => ({
        url: `Chats`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.CHAT],
    }),
  
updateChatRequest: builder.mutation({
      query: (data) => ({
        url: `Chats/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.CHAT],
    }),
  
    deleteChatRequest: builder.mutation({
      query: (data) => ({
        url: `Chats/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.CHAT],
    }),

// Promotion
getPromotions: builder.query({
      query: () => ({
        url: `Promotions`,
        method: "GET",
      }),
      providesTags: [API_TAGS.PROMOTION],
    }),

getPromotionById: builder.query({
  query: (data) => `Promotions/${data.id}`,
      providesTags: [API_TAGS.PROMOTION],
}),

createPromotionRequest: builder.mutation({
      query: (data) => ({
        url: `Promotions`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PROMOTION],
    }),

updatePromotionRequest: builder.mutation({
      query: (data) => ({
        url: `Promotions/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.PROMOTION],
    }),

    deletePromotionRequest: builder.mutation({
      query: (data) => ({
        url: `Promotions/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.PROMOTION],
    }),

// Members
getMembers: builder.query({
      query: () => ({
        url: `Members`,
        method: "GET",
      }),
      providesTags: [API_TAGS.MEMBER],
    }),

getMemberById: builder.query({
  query: (data) => `Members/${data.id}`,
      providesTags: [API_TAGS.MEMBER],
}),

createMemberRequest: builder.mutation({
      query: (data) => ({
        url: `Members`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.MEMBER],
    }),

updateMemberRequest: builder.mutation({
      query: (data) => ({
        url: `Members/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.MEMBER],
    }),

    deleteMemberRequest: builder.mutation({
      query: (data) => ({
        url: `Members/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.MEMBER],
    }),

// Settings Users
getUsers: builder.query({
      query: () => ({
        url: `Users`,
        method: "GET",
      }),
      providesTags: [API_TAGS.USER],
    }),

getUserById: builder.query({
  query: (data) => `Users/${data.id}`,
      providesTags: [API_TAGS.USER],
}),

createUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

updateUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

    deleteUserRequest: builder.mutation({
      query: (data) => ({
        url: `Users/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.USER],
    }),

// Settings User Roles
getUserRoles: builder.query({
      query: () => ({
        url: `Roles`,
        method: "GET",
      }),
      providesTags: [API_TAGS.USERROLE],
    }),

getUserRoleById: builder.query({
  query: (data) => `Roles/${data.id}`,
      providesTags: [API_TAGS.USERROLE],
}),

createUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),

updateUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),

    deleteUserRoleRequest: builder.mutation({
      query: (data) => ({
        url: `Roles/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.USERROLE],
    }),

// Settings Custom Images
getCustomImages: builder.query({
      query: () => ({
        url: `Pages`,
        method: "GET",
      }),
      providesTags: [API_TAGS.IMAGE],
    }),

getCustomImageById: builder.query({
  query: (data) => `Pages/${data.id}`,
      providesTags: [API_TAGS.IMAGE],
}),

createCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),

updateCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),

    deleteCustomImageRequest: builder.mutation({
      query: (data) => ({
        url: `Pages/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.IMAGE],
    }),

// Settings Logs
getLogs: builder.query({
      query: () => ({
        url: `Users/userLogs`,
        method: "GET",
      }),
      providesTags: [API_TAGS.LOG],
    }),

// getLogById: builder.query({
//   query: (data) => `Log/${data.id}`,
//       providesTags: [API_TAGS.LOG],
// }),

// createLogRequest: builder.mutation({
//       query: (data) => ({
//         url: `Log`,
//         method: "POST",
//         body: data,
//       }),
//       invalidatesTags: [API_TAGS.LOG],
//     }),

// updateLogRequest: builder.mutation({
//       query: (data) => ({
//         url: `Log`,
//         method: "PUT",
//         body: data,
//       }),
//       invalidatesTags: [API_TAGS.LOG],
//     }),

//     deleteLogRequest: builder.mutation({
//       query: (data) => ({
//         url: `Log`,
//         method: "DELETE",
//         params: data,
//       }),
//       invalidatesTags: [API_TAGS.LOG],
//     }),


// Settings FAQ
getFAQs: builder.query({
      query: () => ({
        url: `Faqs`,
        method: "GET",
      }),
      providesTags: [API_TAGS.FAQ],
    }),

getFAQById: builder.query({
  query: (data) => `Faqs/${data.id}`,
      providesTags: [API_TAGS.FAQ],
}),

createFAQRequest: builder.mutation({
      query: (data) => ({
        url: `Faqs`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FAQ],
    }),

updateFAQRequest: builder.mutation({
      query: (data) => ({
        url: `Faqs/${data.faqId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FAQ],
    }),

    deleteFAQRequest: builder.mutation({
      query: (data) => ({
        url: `Faqs/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.FAQ],
    }),


// Settings Flags
getFlags: builder.query({
      query: () => ({
        url: `Flags`,
        method: "GET",
      }),
      providesTags: [API_TAGS.FLAG],
    }),

getFlagById: builder.query({
  query: (data) => `Flags/${data.id}`,
      providesTags: [API_TAGS.FLAG],
}),

createFlagRequest: builder.mutation({
      query: (data) => ({
        url: `Flags`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FLAG],
    }),

updateFlagRequest: builder.mutation({
      query: (data) => ({
        url: `Flags/${data.flagId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FLAG],
    }),

    deleteFlagRequest: builder.mutation({
      query: (data) => ({
        url: `Flags/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.FLAG],
    }),


    //TOP PRODUCTS AND DESTINATIONS
    getTopProducts: builder.query({
      query: () => ({
        url: `TopProducts`,
        method: "GET",
      }),
      providesTags: [API_TAGS.TOPPRODUCT],
    }),

    getTopDestinations: builder.query({
      query: () => ({
        url: `TopDestinations`,
        method: "GET",
      }),
      providesTags: [API_TAGS.TOPDESTINATION],
    }),

   createTopProductRequest: builder.mutation({
      query: (data) => ({
        url: `TopProducts`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.TOPPRODUCT],
   }),

    createTopDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `TopDestinations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.TOPDESTINATION],
    }),

    updateTopProductRequest: builder.mutation({
      query: (data) => ({
        url: `TopProducts/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.TOPPRODUCT],
    }),

    updateTopDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `TopDestinations/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.TOPDESTINATION],
    }),

    deleteTopProductRequest: builder.mutation({
      query: (data) => ({
        url: `TopProducts/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.TOPPRODUCT],
    }),

    deleteTopDestinationRequest: builder.mutation({
      query: (data) => ({
        url: `TopDestinations/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.TOPDESTINATION],
    }),
  

    // NOTIFICATIONS
    getNotifications: builder.query({
      query: () => ({
        url: `Notifications`,
        method: "GET",
      }),
      providesTags: [API_TAGS.NOTIFICATION],
    }),

    getNotificationById: builder.query({
      query: (data) => `Notifications/${data.id}`,
      providesTags: [API_TAGS.NOTIFICATION],
    }),

    createNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    updateNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    deleteNotificationRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/${data.id}`,
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),

    markNotificationAsReadRequest: builder.mutation({
      query: (data) => ({
        url: `Notifications/deteleAllNotifications/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [API_TAGS.NOTIFICATION],
    }),



   
    // File
    //---------------------------------
    uploadFile: builder.mutation({
      query: (data) => ({
        url: "File",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.FILE],
    }),
    getFileLink: builder.query({
      query: (data) => `File/${data.fileName}`,
      // responseHandler: (response) => console.log(response.blob()),
    }),
    downloadFile: builder.query({
      query: (data) => ({
        url: `File/download`,
        method: "POST",
        body:{data}
      }),
      providesTags: [API_TAGS.FILE],
    }),



    // SET PASSWORD
    //---------------------------------
    setPassword: builder.mutation({
      query: (data) => ({
        url: "Jwts/SetPass",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.AUTH],
    }), 


    // FORGOT PASSWORD
    //---------------------------------
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `Jwts/SendPasswordResetLink/${data?.email}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [API_TAGS.AUTH],
    }),


// CONTACT US
//---------------------------------
contactUs: builder.mutation({
  query: (data) => ({
    url: "Contactus",
    method: "POST",
    body: data,
  }),
  invalidatesTags: [API_TAGS.CONTACT_US],
}),



// ANALYTICS
//---------------------------------
getAnalytics: builder.query({
  query: () => ({
    url: "Analytics",
    method: "GET",
  }),
  providesTags: [API_TAGS.ANALYTICS],
}),

postAnalytics: builder.mutation({
  query: (data) => ({
    url: "Analytics",
    method: "POST",
    body: data,
  }),
}),

  getAnalyticsById: builder.query({
    query: (data) => `Analytics/${data.id}`,
    providesTags: [API_TAGS.ANALYTICS],
  }),


  // code-verify
  VerifyCode: builder.mutation({
    query: (data) => ({
      url: "Jwts/ResetPassword",
      method: "POST",
      body: data,
    }),
    invalidatesTags: [API_TAGS.AUTH],

  }),

  // Links
getLinks: builder.query({
  query: (data)=> ({
    url: "/LinksLists",
    method:"GET",
  }),
  invalidatesTags: [API_TAGS.LINKS],
}),
createLinks:builder.mutation({
  query: (data)=> ({
    url:"/LinksLists",
    method:"POST",
    body:data
  }),
  invalidateTags: [API_TAGS.LINKS]
}),
updateLinks: builder.mutation({
  query:(data)=> ({
    url:`LinksLists/${data.id}`,
    method:"PUT",
    body:data
  }),
  invalidateTags: [API_TAGS.LINKS]
}),

deleteLinks: builder.mutation({
  query:(data)=>({
    url:`LinksLists/${data.id}`,
    method:"DELETE",
    body:data
  }),
  invalidateTags: [API_TAGS.LINKS]

})



})

  



});

export const {  

// set password
useSetPasswordMutation,
useForgotPasswordMutation,


// Contact Us


useContactUsMutation,

  // Dashboard stats
  useGetDashboardStatsQuery,

  // Export - Products
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductRequestMutation,
  useUpdateProductRequestMutation,
  useDeleteProductRequestMutation,

  // Export - Destinations
  useGetDestinationsQuery,
  useGetDestinationByIdQuery,
  useCreateDestinationRequestMutation,
  useUpdateDestinationRequestMutation,
  useDeleteDestinationRequestMutation,

  // Export - Institutions
  useGetInstitutionsQuery,
  useGetInstitutionByIdQuery,
  useCreateInstitutionRequestMutation,
  useUpdateInstitutionRequestMutation,
  useDeleteInstitutionRequestMutation,

  // Export - Documents
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useCreateDocumentRequestMutation,
  useUpdateDocumentRequestMutation,
  useDeleteDocumentRequestMutation,

  // Posts - News
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useCreateNewsRequestMutation,
  useUpdateNewsRequestMutation,
  useDeleteNewsRequestMutation,

  // Posts - Publications
  useGetPublicationsQuery,
  useGetPublicationByIdQuery,
  useCreatePublicationRequestMutation,
  useUpdatePublicationRequestMutation,
  useDeletePublicationRequestMutation,

  // Posts - Events
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventRequestMutation,
  useUpdateEventRequestMutation,
  useDeleteEventRequestMutation,

  // Chat
  useGetChatsQuery,
  useGetChatByIdQuery,
  useCreateChatRequestMutation,
  useUpdateChatRequestMutation,
  useDeleteChatRequestMutation,

  // Promotion
  useGetPromotionsQuery,
  useGetPromotionByIdQuery,
  useCreatePromotionRequestMutation,
  useUpdatePromotionRequestMutation,
  useDeletePromotionRequestMutation,

  // Members
  useGetMembersQuery,
  useGetMemberByIdQuery,
  useCreateMemberRequestMutation,
  useUpdateMemberRequestMutation,
  useDeleteMemberRequestMutation,

  // Settings Users
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserRequestMutation,
  useUpdateUserRequestMutation,
  useDeleteUserRequestMutation,

  // Settings User Roles
  useGetUserRolesQuery,
  useGetUserRoleByIdQuery,
  useCreateUserRoleRequestMutation,
  useUpdateUserRoleRequestMutation,
  useDeleteUserRoleRequestMutation,

  // Settings Custom Images
  useGetCustomImagesQuery,
  useGetCustomImageByIdQuery,
  useCreateCustomImageRequestMutation,
  useUpdateCustomImageRequestMutation,
  useDeleteCustomImageRequestMutation,

  // Settings Logs
  useGetLogsQuery,
  // useGetLogByIdQuery,
  // useCreateLogRequestMutation,
  // useUpdateLogRequestMutation,
  // useDeleteLogRequestMutation,

  // Settings FAQ
  useGetFAQsQuery,
  useGetFAQByIdQuery,
  useCreateFAQRequestMutation,
  useUpdateFAQRequestMutation,
  useDeleteFAQRequestMutation,

  // Settings Flags
  useGetFlagsQuery,
  useGetFlagByIdQuery,
  useCreateFlagRequestMutation,
  useUpdateFlagRequestMutation,
  useDeleteFlagRequestMutation,
  
// Notifications
useGetNotificationsQuery,
useGetNotificationByIdQuery,
useCreateNotificationRequestMutation,
useUpdateNotificationRequestMutation,
useDeleteNotificationRequestMutation,
useMarkNotificationAsReadRequestMutation,

// TOP PRODUCTS AND DESTINATIONS
useGetTopProductsQuery,
useGetTopDestinationsQuery,
useCreateTopProductRequestMutation,
useCreateTopDestinationRequestMutation,
useUpdateTopProductRequestMutation,
useUpdateTopDestinationRequestMutation,
useDeleteTopProductRequestMutation,
useDeleteTopDestinationRequestMutation,



// Procedures
useGetProceduresQuery,
useGetProcedureByIdQuery,
useCreateProcedureRequestMutation,
useUpdateProcedureRequestMutation,
useDeleteProcedureRequestMutation,



// File
useUploadFileMutation,
useGetFileLinkQuery,
useDownloadFileQuery,

// Analytics
useGetAnalyticsQuery,
usePostAnalyticsMutation,
useGetAnalyticsByIdQuery,

// LINKS
useGetLinksQuery,
useCreateLinksMutation,
useUpdateLinksMutation,
useDeleteLinksMutation,


// VERIFY CODE
useVerifyCodeMutation



} = apiSlice;
