import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  TextareaAutosize,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useGetEventsQuery,
  useGetEventByIdQuery,
  useCreateEventRequestMutation,
  useUpdateEventRequestMutation,
  useDeleteEventRequestMutation,
  useUploadFileMutation,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  FILE_DOWNLOAD_URL,
  FILE_LINK_URL,
} from "../../../../constants/apiTags";
const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
  textArea: {
    width: "100%",
    minHeight: "200px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    resize: "vertical",
  },
}));

const AddEvent = ({ open, onClose, title, onSave }) => {
  const classes = useStyless();
  const classez = useStyles();
  const [docTitle, setDocTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [cover, setCover] = useState([]);
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [titleError, setTitleError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [coverError, setCoverError] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const [
    createEventRequest,
    {
      isLoading: createEventLoading,
      isError: isErrorCreateEvent,
      error: createEventError,
    },
  ] = useCreateEventRequestMutation();
  const [submitFileUpload] = useUploadFileMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const validateLink = (url) => {
    // Regular expression for a valid URL
    const urlRegex =
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(url) || url === "";
    return urlRegex;
  };

  const validateEndDate = (endDate, startDate) => {
    return endDate >= startDate;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Reset errors
    setTitleError("");
    setLinkError("");
    setLocationError("");
    setEndDateError("");
    setCoverError("");

    const data = {
      title: docTitle,
      description: description,
      link: link,
      location: location,
      startDate: startDate,
      endDate: endDate,
      userId: user?.user_id,
    };

    // Validate event name
    if (docTitle.length === 0 || docTitle.length > 100) {
      setTitleError("Event name must be between 1 and 100 characters");
      return;
    }

    // Validate link
    if (!validateLink(link)) {
      setLinkError("Invalid URL");
      return;
    }

    // Validate location
    if (location.length > 100) {
      setLocationError("Location must be less than 100 characters");
      return;
    }

    // Validate end date
    if (!validateEndDate(endDate, startDate)) {
      setEndDateError("End date must be equal or later than start date");
      return;
    }

    // Validate cover image
    if (cover.length === 0) {
      setCoverError("Please upload a cover image");
      return;
    }

    try {
      const imageResponse = await uploadImage(cover[0]);

      data.coverImage = imageResponse?.dbPaths[0]?.documentName ?? "";
    } catch (error) {
      toast.error("Error uploading image");
      data.coverImage = "";
      return;
    }

    await createEventRequest(data)
      .then((res) => {
        toast.success("Event Created Successfully");
      })
      .catch((err) => {
        toast.error("Error in creating event");
      });

    setDocTitle("");
    setDescription("");
    setLink("");
    setCover([]);
    setLocation("");
    setStartDate(new Date());
    setEndDate(new Date());

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <TextField
              label="Event Name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              required
              style={{ marginTop: "15px" }}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
            />
            <TextareaAutosize
              className={classes.textArea}
              required
              placeholder="Write your article here..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              label="Article Link (optional)"
              variant="outlined"
              fullWidth
              margin="normal"
              className={classes.textField}
              value={link}
              onChange={(e) => setLink(e.target.value)}
              error={!!linkError}
              helperText={linkError}
            />
            <TextField
              label="Location"
              variant="outlined"
              value={location}
              fullWidth
              style={{ marginTop: "15px" }}
              className={classes.textField}
              onChange={(e) => setLocation(e.target.value)}
              error={!!locationError}
              helperText={locationError}
            />
            <Stack
              direction="row"
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">Start date</FormLabel>

                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3} sx={{ width: "50%" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <FormLabel component="legend">End date</FormLabel>

                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      color="success"
                      value={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField required variant="outlined" {...params} />
                      )}
                    />
                    <FormHelperText error={!!endDateError}>
                      {endDateError}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </LocalizationProvider>
            </Stack>
            <div className={classes.modalBody}>
              <DropzoneArea
                dropzoneClass={classez?.dropZone}
                style={{ marginTop: "15px" }}
                showAlerts={false}
                useChipsForPreview={true}
                acceptedFiles={[
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                  "image/webp",
                ]}
                filesLimit={1}
                maxFiles={1}
                maxFileSize={5000000}
                dropzoneText={
                  "Drop or Click to Upload Cover Image(Optional, JPEG, JPG, PNG, WEBP)"
                }
                onChange={(files) => setCover(files)}
                error={!!coverError}
                helperText={coverError}
              />
            </div>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={createEventLoading}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddEvent;
