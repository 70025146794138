import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Divider,
  TextField,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
// import ProductsPage from "./Products/Products";

import { colorTags } from "../../../../constants/variants";

import ExportPage from "./Exports";
import TemporaryExports from "./TemporaryExports";
import Perishables from "./Perishables";
import NonPerishables from "./NonPerishables";
import TopProducts from "./TopProducts";
import { useGetProductsQuery } from "../../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#F1F1F1",
    // padding: theme.spacing(12),
    // paddingTop: theme.spacing(2),
  },
  content: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    // padding: theme.spacing(2),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    // marginTop: theme.spacing(2),
  },
  searchBox: {
    width: "90%",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(3),
  },
  selectedTab: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
}));

const ProductsPage = ({ searchBy, query }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const {
    data: productData,
    isError: errorProductData,
    isLoading,
  } = useGetProductsQuery();

  const productDataq = [
    {
      id: 1,
      productName: "Electronics",
      description: "Description of Electronics",
      createdAt: "2023-08-01T10:00:00Z",
      routes: ["Road", "Train"],
      destinations: ["USA", "Canada"],
      type: "export",
      HsCode: "12345678",
    },
    {
      id: 2,
      productName: "Furniture",
      description: "Description of Furniture",
      createdAt: "2023-08-02T10:00:00Z",
      routes: ["Ship"],
      destinations: ["Germany"],
      type: "temporaryExport",
      HsCode: "23456789",
    },
    {
      id: 3,
      productName: "Food Products",
      description: "Description of Food Products",
      createdAt: "2023-08-03T10:00:00Z",
      routes: ["Road", "Train", "Ship"],
      destinations: ["France", "Italy", "Spain"],
      type: "perishable",
      HsCode: "34567890",
    },
    {
      id: 4,
      productName: "Clothing",
      description: "Description of Clothing",
      createdAt: "2023-08-04T10:00:00Z",
      routes: ["Plane"],
      destinations: ["Japan"],
      type: "nonPerishable",
      HsCode: "45678901",
    },
    {
      id: 5,
      productName: "Toys",
      description: "Description of Toys",
      createdAt: "2023-08-05T10:00:00Z",
      routes: ["Road", "Ship"],
      destinations: ["Brazil", "Argentina"],
      type: "export",
      HsCode: "56789012",
    },
    {
      id: 6,
      productName: "Automobile Parts",
      description: "Description of Automobile Parts",
      createdAt: "2023-08-06T10:00:00Z",
      routes: ["Road"],
      destinations: ["Australia"],
      type: "temporaryExport",
      HsCode: "67890123",
    },
    {
      id: 7,
      productName: "Perfumes",
      description: "Description of Perfumes",
      createdAt: "2023-08-07T10:00:00Z",
      routes: ["Train"],
      destinations: ["India"],
      type: "perishable",
      HsCode: "78901234",
    },
    {
      id: 8,
      productName: "Machinery",
      description: "Description of Machinery",
      createdAt: "2023-08-08T10:00:00Z",
      routes: ["Road", "Train", "Ship", "Plane"],
      destinations: ["China", "South Korea", "Singapore"],
      type: "nonPerishable",
      HsCode: "89012345",
    },
    {
      id: 9,
      productName: "Pharmaceuticals",
      description: "Description of Pharmaceuticals",
      createdAt: "2023-08-09T10:00:00Z",
      routes: ["Road", "Ship"],
      destinations: ["Mexico", "Canada"],
      type: "export",
      HsCode: "90123456",
    },
    {
      id: 10,
      productName: "Textiles",
      description: "Description of Textiles",
      createdAt: "2023-08-10T10:00:00Z",
      routes: ["Road", "Train"],
      destinations: ["United Kingdom", "Ireland"],
      type: "temporaryExport",
      HsCode: "01234567",
    },
  ];

  return (
    <div className={classes.root}>
      {" "}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          ".Mui-selected": {
            color: `${colorTags.SECONDARY} !important`,
          },
        }}
        TabIndicatorProps={{
          sx: { backgroundColor: colorTags.SECONDARY },
        }}
        centered
        variant="fullWidth"
        style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}>
        <Tab
          label="Export"
          className={selectedTab === 0 ? classes.selectedTab : ""}
        />
        <Tab
          label="Temporary Export"
          className={selectedTab === 1 ? classes.selectedTab : ""}
        />
        <Tab
          label="Perishable Goods"
          className={selectedTab === 2 ? classes.selectedTab : ""}
        />
        <Tab
          label="Non-Perishable Goods"
          className={selectedTab === 3 ? classes.selectedTab : ""}
        />
        {/* <Tab
          label="Top"
          className={selectedTab === 4 ? classes.selectedTab : ""}
        /> */}
      </Tabs>
      {/* <SearchBox /> */}
      <div className={classes.content}>
        {selectedTab === 0 && <ExportPage data={productData ?? []} />}
        {selectedTab === 1 && (
          <TemporaryExports
            data={productData?.filter(
              (product) => product.type === "Temporary"
            )}
          />
        )}
        {selectedTab === 2 && (
          <Perishables
            data={productData?.filter(
              (product) => product.type === "Perishable"
            )}
          />
        )}
        {selectedTab === 3 && (
          <NonPerishables
            data={productData?.filter(
              (product) => product.type === "Non-Perishable"
            )}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
