import React from "react";
import { Card, CardContent, Typography, Box, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../assets/index";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DescriptionIcon from "@mui/icons-material/Description";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
    marginTop: theme.spacing(15),
    padding: 0,
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    height: "auto",
    backgroundColor: "#ff3f",
  },
  coloredHalf: {
    flex: 1,
    backgroundColor: "rgba(240, 230, 140, 0.1)",
    borderRadius: "0 8px 8px ",
    // height: "400px",
    width: "100%",
    // marginLeft: theme.spacing(-3.9),

    padding: theme.spacing(3),
  },
  imageContainer: {
    flex: 1,
    height: "100%",
    borderRadius: "8px 0 0 8px",
    overflow: "hidden",
    backgroundColor: "#ff3f",
    // maxHeight: "400px",
    width: "100%",
  },
  image: {
    backgroundColor: "#ff3f",

    width: "100%",
    height: "50%",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  circlesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    // margin: `0 ${theme.spacing(1)}px`,

    marginLeft: theme.spacing(-3.9),
    padding: theme.spacing(3),
    borderRadius: "8px",
    zIndex: 1,
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: colorTags.PRIMARY,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    // marginBottom: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  icon: {
    color: "#fff",
  },
  contentContainer: {
    backgroundColor: "#ff3f",

    flex: 1,
    padding: theme.spacing(3),
  },
}));

const ExportType = ({ type }) => {
  const classes = useStyles();
  if (type === "plane")
    return (
      <Card
        className={classes.mainContainer}
        style={{
          background: "none",
        }}>
        {/* Image Half */}
        <div className={classes.imageContainer}>
          <img src={images?.Plane} alt="Airplane" className={classes.image} />
        </div>
        {/* Circles
        <div className={classes.circlesContainer}>
          <div className={classes.circle}>
            <LocalShippingIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <MonetizationOnIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <DescriptionIcon className={classes.icon} />
          </div>
        </div> */}
        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <MonetizationOnIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <DescriptionIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
  else if (type === "ship")
    return (
      <Card className={classes.mainContainer}>
        {/* Image Half */}
        <div className={classes.imageContainer}>
          <img src={images?.Ship} alt="Ship" className={classes.image} />
        </div>

        {/* Circles */}
        {/* <div className={classes.circlesContainer}>
          <div className={classes.circle}>
            <LocalShippingIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <MonetizationOnIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <DescriptionIcon className={classes.icon} />
          </div>
        </div> */}
        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <MonetizationOnIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <DescriptionIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
  else if (type === "truck")
    return (
      <Card className={classes.mainContainer}>
        {/* Image Half */}
        <div className={classes.imageContainer}>
          <img src={images?.Truck} alt="Truck" className={classes.image} />
        </div>

        {/* Circles */}
        {/* <div className={classes.circlesContainer}>
          <div className={classes.circle}>
            <LocalShippingIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <MonetizationOnIcon className={classes.icon} />
          </div>
          <div className={classes.circle}>
            <DescriptionIcon className={classes.icon} />
          </div>
        </div> */}

        <div className={classes.coloredHalf}>
          <Stack direction="column" spacing={3}>
            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <LocalShippingIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <MonetizationOnIcon className={classes.icon} />
              </div>

              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>

            {/* Colored Half */}
            <div className={classes.coloredHalf}>
              <div className={classes.circle}>
                <DescriptionIcon className={classes.icon} />
              </div>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Content Title
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vitae consectetur erat. Duis bibendum scelerisque mauris nec
                faucibus. Sed posuere arcu eu risus scelerisque, id varius orci
                finibus. Donec pharetra ac ante eu rutrum. Nam mollis lectus eu
                lectus dignissim, at tristique justo vestibulum. Vivamus cursus
                orci at ex pulvinar, id lacinia tellus elementum. Suspendisse
                eget arcu quis tellus fermentum ultrices. Integer ac libero sem.
                Donec eu enim in mi ultrices euismod eu nec neque. Quisque ac
                erat augue. Aenean mollis varius quam ut tincidunt. Curabitur
                tristique, libero vel rhoncus hendrerit, quam purus cursus nisl,
                in gravida mi lorem eu urna.
              </Typography>
            </div>
          </Stack>
        </div>
      </Card>
    );
};

export default ExportType;
