import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Pagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { makeStyles } from "@material-ui/core/styles";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { images } from "../../../assets/index";
import { Info } from "@mui/icons-material";
import ChatResponse from "./ChatResponse";
import {
  useGetChatsQuery,
  useGetChatByIdQuery,
  useCreateChatRequestMutation,
  useUpdateChatRequestMutation,
  useDeleteChatRequestMutation,
} from "../../../features/api/apiSlice";
import { BASE_URL } from "../../../constants/apiTags";
import { Bars } from "react-loader-spinner";

const chatData = [
  {
    user: {
      id: 1,
      name: "John",
    },
    conversation: [
      {
        id: 1,
        sender: "John",
        message: "Hello, how are you?",
        time: "2023-06-01 10:00 AM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hi John, I'm doing well. How can I assist you today?",
        time: "2023-06-01 10:05 AM",
      },
      {
        id: 3,
        sender: "John",
        message: "I have a question about my account balance.",
        time: "2023-06-01 10:10 AM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "Sure, please provide me with your account details.",
        time: "2023-06-01 10:15 AM",
      },
    ],
  },
  {
    user: {
      id: 2,
      name: "Emily",
    },
    conversation: [
      {
        id: 1,
        sender: "Emily",
        message: "Hi there!",
        time: "2023-06-01 09:30 AM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hello Emily, how can I help you?",
        time: "2023-06-01 09:35 AM",
      },
      {
        id: 3,
        sender: "Emily",
        message: "I'm having trouble logging into my account.",
        time: "2023-06-01 09:40 AM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "I can help you with that. Please provide your username.",
        time: "2023-06-01 09:45 AM",
      },
    ],
  },
  {
    user: {
      id: 3,
      name: "Michael",
    },
    conversation: [
      {
        id: 1,
        sender: "Michael",
        message: "Hey, how's it going?",
        time: "2023-06-01 11:30 AM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hi Michael, I'm here to help. What can I do for you?",
        time: "2023-06-01 11:35 AM",
      },
      {
        id: 3,
        sender: "Michael",
        message: "I need assistance with a recent order.",
        time: "2023-06-01 11:40 AM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "Sure thing! Please provide your order number.",
        time: "2023-06-01 11:45 AM",
      },
      // ... other messages
    ],
  },
  {
    user: {
      id: 4,
      name: "Sarah",
    },
    conversation: [
      {
        id: 1,
        sender: "Sarah",
        message: "Hello!",
        time: "2023-06-01 02:00 PM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hi Sarah, how may I assist you today?",
        time: "2023-06-01 02:05 PM",
      },
      {
        id: 3,
        sender: "Sarah",
        message: "I have a question about your services.",
        time: "2023-06-01 02:10 PM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "Of course! Feel free to ask anything.",
        time: "2023-06-01 02:15 PM",
      },
      // ... other messages
    ],
  },
  {
    user: {
      id: 5,
      name: "Alex",
    },
    conversation: [
      {
        id: 1,
        sender: "Alex",
        message: "Hey there!",
        time: "2023-06-02 09:30 AM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hello Alex, how can I assist you?",
        time: "2023-06-02 09:35 AM",
      },
      {
        id: 3,
        sender: "Alex",
        message: "I'm having trouble accessing my account.",
        time: "2023-06-02 09:40 AM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "I'm here to help. Please provide your email.",
        time: "2023-06-02 09:45 AM",
      },
      // ... other messages
    ],
  },
  {
    user: {
      id: 6,
      name: "Olivia",
    },
    conversation: [
      {
        id: 1,
        sender: "Olivia",
        message: "Hi!",
        time: "2023-06-02 01:00 PM",
      },
      {
        id: 2,
        sender: "Admin",
        message: "Hi Olivia, how can I assist you today?",
        time: "2023-06-02 01:05 PM",
      },
      {
        id: 3,
        sender: "Olivia",
        message: "I need information about your products.",
        time: "2023-06-02 01:10 PM",
      },
      {
        id: 4,
        sender: "Admin",
        message: "Certainly! What specific products are you interested in?",
        time: "2023-06-02 01:15 PM",
      },
      // ... other messages
    ],
  },
  // ... other conversations
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const useStyles = makeStyles((theme) => ({
  cardsHolder: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    // backgroundImage: `linear-gradient(to left, #ffffff 50%, transparent 50%), url(${images.BlurBg1})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundColor: "#f1f1f1",
    margin: "5% 0px 20px 0px",
    padding: "0px 10px 0px 10px",
    backgroundSize: "cover",
  },
  smallCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
    backgroundColor: "#0DC3FC",
    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    boxShadow:
      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  },
  smallCardTitles: {
    color: "#fff",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  mainContainer: {
    height: "100%",
  },
}));

export default function ChatPage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [chatsPage, setChatsPage] = useState(1);
  const [showAllChats, setShowAllChats] = useState(false);
  const usersPerChatsPage = 10;
  const totalPagesChats = Math.ceil(chatData.length / usersPerChatsPage);
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    data: chatsData,
    isLoading: isLoadingChatsData,
    isError: isErrorChatsData,
    error: errorChatsData,
  } = useGetChatsQuery();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${BASE_URL}/Chats`);
      const data = await response.json();
      setChat(data);
      setLoading(false);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Creating a new array to store the most recent message of each conversation
  const recentMessages = chat?.map((chat) => {
    const user = JSON.parse(chat?.user);
    const conversation = JSON.parse(chat?.conversation);

    const lastMessage = conversation[conversation.length - 1];

    return {
      id: chat.id,
      user,
      message: lastMessage.message,
      time: lastMessage.time,
    };
  });

  console.log(recentMessages);
  // Sorting the recentMessages array based on dates
  const sortedRecentMessages =
    recentMessages &&
    [...recentMessages].sort((a, b) => {
      const dateA = new Date(a.time);
      const dateB = new Date(b.time);
      return dateB - dateA;
    });

  // Calculating the start and end index of the chats to display
  const startIndexC = (chatsPage - 1) * usersPerChatsPage;
  let endIndexC = startIndexC + usersPerChatsPage;

  if (endIndexC > sortedRecentMessages?.length) {
    endIndexC = sortedRecentMessages?.length;
  }

  const chatsPaginated = sortedRecentMessages?.slice(startIndexC, endIndexC);

  const handleChatsPageChange = (event, newPage) => {
    setChatsPage(newPage);
  };

  const handleSeeMore = () => {
    setShowAllChats(true);
    setChatsPage(1);
  };

  const handleSeeLess = () => {
    setShowAllChats(false);
    setChatsPage(1);
  };

  const extractHourAndMinute = (time) => {
    const dateTimeParts = time.split("T");
    if (dateTimeParts.length === 2) {
      const timeParts = dateTimeParts[1].split(":");
      if (timeParts.length === 3) {
        const [hour, minute] = timeParts.slice(0, 2);

        // Convert to Ethiopian Time (ETC)
        const utcDateTime = new Date(
          `${dateTimeParts[0]}T${hour}:${minute}:00Z`
        );
        const etcDateTime = utcDateTime.toLocaleString("en-US", {
          timeZone: "Africa/Addis_Ababa", // ETC timezone
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        });

        return etcDateTime;
      }
    }
    return "";
  };

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#1967FF"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );

  return (
    <>
      <div className={classes.mainContainer}>
        <div
          elevation={3}
          style={{
            padding: "10%",

            background: "none",
            height: "90%",
            // backgroundColor: "#ff3",
          }}>
          <Paper
            elevation={3}
            style={{
              height: "50px",
              borderRadius: "5px",
              marginBottom: "16px",
              backgroundColor: "#1967FF",
              // backgroundColor: "white",
              padding: "16px",
              marginBottom: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            {/* User Avatar */}
            <h3 className={classes.smallCardTitles}>Chats</h3>

            {/* User Name */}
            <div style={{ marginLeft: "20px", marginRight: "auto" }}>
              {/* <h3 style={{ color: "#fff" }}>Member</h3> */}
            </div>

            {/* Recent Message */}
            <div
              style={{
                marginRight: "10%",
                display: "flex",
                alignItems: "center",
              }}>
              <p style={{ marginRight: "190px", color: "#fff " }}>Message</p>
              <p style={{ marginRight: "20%", color: "#fff" }}>Time</p>

              {/* Info Icon */}
              <IconButton
              // onClick={() =>
              //   // navigate("/product-detail/")
              >
                {/* <Info color="primary" /> */}
              </IconButton>
            </div>
          </Paper>

          {/* List of Chat Cards */}
          <div
            style={{
              height: "90%",
              overflow: "auto",
            }}>
            {chatsPaginated?.length > 0 ? (
              chatsPaginated?.map((chat) => (
                <Paper
                  elevation={3}
                  key={chat.user.id}
                  style={{
                    backgroundColor: "white",
                    padding: "16px",
                    marginBottom: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxHeight: "60px",
                  }}>
                  {/* User Avatar */}
                  {/* <img
                  src={chat.user.avatar}
                  alt={chat.user.name}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                /> */}

                  {/* User Name */}
                  <div style={{ marginLeft: "20px", marginRight: "auto" }}>
                    <h3>{chat.user[0].name}</h3>
                  </div>

                  {/* Recent Message */}
                  <div
                    style={{
                      marginRight: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <p
                      style={{
                        marginLeft: "auto",
                        marginRight: "15%",
                        textAlign: "left",
                      }}>
                      {chat.message}
                    </p>
                    <p style={{ marginRight: "25px" }}>
                      {new Date(chat.time).toLocaleDateString("en-US")}
                      <span style={{ marginLeft: "7px" }}>
                        {" "}
                        {extractHourAndMinute(chat.time)}
                      </span>
                    </p>

                    {/* Info Icon */}
                    <Tooltip title="Open Conversation" placement="bottom">
                      <Button
                        onClick={() => {
                          setUser(chat?.id);
                          setModalOpen(true);
                        }}>
                        {" "}
                        <img
                          src={images.ChatIcon}
                          alt={"Open Chat"}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                </Paper>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "24px",
                  color: "#333",
                  backgroundColor: "#f0f0f0",
                  padding: "20px",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}>
                <h3>No Chats Open</h3>
              </div>
            )}
          </div>
        </div>
        <ChatResponse
          chat={user && user}
          open={modalOpen}
          onSave={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
        />
        {/* {showAllChats ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}>
            <Button variant="outlined" color="error" onClick={handleSeeLess}>
              see less
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}>
            {chatsPage < totalPagesChats && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSeeMore}>
                see more
              </Button>
            )}
          </div>
        )} */}
      </div>
    </>
  );
}
