import react, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";
import { SimpleCard } from "../../../../components";
import {
  colorTags,
  primaryColor,
  secondaryColor,
  accentColor,
} from "../../../../constants/variants";
import AddProduct from "./AddProduct";
import AddTopProducts from "./AddTopProducts";

import GridData from "../../../../components/GridData";
import DeleteDialog from "../../../../components/DeleteDialog";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductRequestMutation,
  useUpdateProductRequestMutation,
  useDeleteProductRequestMutation,
} from "../../../../features/api/apiSlice";
import EditProduct from "./EditProduct";
import { Bars } from "react-loader-spinner";

import DownloadAttachment from "../../../../components/DownloadAttachment";
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const productDataq = [
  {
    id: 1,
    productName: "Electronics",
    description: "Description of Electronics",
    createdAt: "2023-08-01T10:00:00Z",
    routes: ["Road", "Train"],
    destinations: ["USA", "Canada"],
    type: "export",
    HsCode: "12345678",
  },
  {
    id: 2,
    productName: "Furniture",
    description: "Description of Furniture",
    createdAt: "2023-08-02T10:00:00Z",
    routes: ["Ship"],
    destinations: ["Germany"],
    type: "temporaryExport",
    HsCode: "23456789",
  },
  {
    id: 3,
    productName: "Food Products",
    description: "Description of Food Products",
    createdAt: "2023-08-03T10:00:00Z",
    routes: ["Road", "Train", "Ship"],
    destinations: ["France", "Italy", "Spain"],
    type: "perishable",
    HsCode: "34567890",
  },
  {
    id: 4,
    productName: "Clothing",
    description: "Description of Clothing",
    createdAt: "2023-08-04T10:00:00Z",
    routes: ["Plane"],
    destinations: ["Japan"],
    type: "nonPerishable",
    HsCode: "45678901",
  },
  {
    id: 5,
    productName: "Toys",
    description: "Description of Toys",
    createdAt: "2023-08-05T10:00:00Z",
    routes: ["Road", "Ship"],
    destinations: ["Brazil", "Argentina"],
    type: "export",
    HsCode: "56789012",
  },
  {
    id: 6,
    productName: "Automobile Parts",
    description: "Description of Automobile Parts",
    createdAt: "2023-08-06T10:00:00Z",
    routes: ["Road"],
    destinations: ["Australia"],
    type: "temporaryExport",
    HsCode: "67890123",
  },
  {
    id: 7,
    productName: "Perfumes",
    description: "Description of Perfumes",
    createdAt: "2023-08-07T10:00:00Z",
    routes: ["Train"],
    destinations: ["India"],
    type: "perishable",
    HsCode: "78901234",
  },
  {
    id: 8,
    productName: "Machinery",
    description: "Description of Machinery",
    createdAt: "2023-08-08T10:00:00Z",
    routes: ["Road", "Train", "Ship", "Plane"],
    destinations: ["China", "South Korea", "Singapore"],
    type: "nonPerishable",
    HsCode: "89012345",
  },
  {
    id: 9,
    productName: "Pharmaceuticals",
    description: "Description of Pharmaceuticals",
    createdAt: "2023-08-09T10:00:00Z",
    routes: ["Road", "Ship"],
    destinations: ["Mexico", "Canada"],
    type: "export",
    HsCode: "90123456",
  },
  {
    id: 10,
    productName: "Textiles",
    description: "Description of Textiles",
    createdAt: "2023-08-10T10:00:00Z",
    routes: ["Road", "Train"],
    destinations: ["United Kingdom", "Ireland"],
    type: "temporaryExport",
    HsCode: "01234567",
  },
];

export default function ProductsPage() {
  const { user } = useSelector((state) => state.auth);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const [topModalOpen, setTopModalOpen] = useState(false);
  const handleTopModalClose = () => setTopModalOpen(false);
  const handleTopModalOpen = () => setTopModalOpen(true);

  // Product API
  const {
    data: productData,
    isLoading: isLoadingProductData,
    isError: isErrorProductData,
    error: errorProductData,
  } = useGetProductsQuery();

  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "hsCode",
      headerName: "HS Code",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,

      valueFormatter: (params) => {
        return new Date(params?.value).toLocaleString("en-US");
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() =>
                navigate(`/dashboard/product-detail/${params?.row?.id}`)
              } // Update the route accordingly
            >
              <Info color="primary" />
            </IconButton>
            {/* {user?.userAccount?.userRole?.productPermissions
              ?.canUpdateProducts && (
              <UpdateProductDialog productData={params?.row} /> // Update this component
            )}
            {user?.userAccount?.userRole?.productPermissions
              ?.canDeleteProducts && (
              <DeleteDialog
                api={useDeleteProductMutation} // Update the API mutation
                dialogLabel="Delete Product"
                id={params?.row?.id}
                name={params?.row?.productName}
              />
            )} */}

            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteProductRequestMutation}
              dialogLabel="Delete Product"
              id={params?.row?.id}
              name={`${params?.row?.productName}`}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isLoadingProductData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <div
      style={{
        // marginRight: "10%",
        marginLeft: "5%",
        marginTop: "5%",
        width: "90%  ",
      }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "70%",

            backgroundColor: colorTags.PRIMARY,
          }}
          onClick={handleModalOpen}>
          Add Product
        </Button>
        <Button
          variant="contained"
          style={{
            marginBottom: "1%",
            marginLeft: "20px",

            backgroundColor: colorTags.SECONDARY,
            color: "white",
          }}
          onClick={handleTopModalOpen}>
          Add TOP PRODUCTS
        </Button>

        {/* <DownloadAttachment name="apply.jpg" /> */}
      </div>
      <AddProduct
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Product"
        content="This is the modal content."
        actionText="Close"
      />

      <AddTopProducts
        open={topModalOpen}
        onClose={handleTopModalClose}
        title="Add Top Products"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Export Products"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={productData ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditProduct
        open={editModalOpen}
        onClose={handleEditModalClose}
        title="Edit Product"
        onSave={handleEditModalClose}
        data={selectedRow}
      />
    </div>
  );
}
