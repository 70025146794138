import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Link,
  Card,
  Stack,
} from "@mui/material";
import Header from "../pages/Home/Header/Header";
import Footer from "../pages/Home/Footer/Footer";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)({
  margin: "10px",
  //   marginLeft: "40px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #006737 0%, #9DC08B  51%, #006737  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const TermsOfAgreementPage = () => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const handleAgreeButtonClick = () => {
    // Add your logic here to handle the user's agreement
    if (isAgreed) {
      // Perform actions for when the user agrees to the terms
      console.log("User agreed to the terms.");
    } else {
      // Show a warning or error message that agreement is required
      console.log("Please agree to the terms before proceeding.");
    }
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
      }}>
      <Header />
      <div style={{ marginTop: "100px", mineight: "100vh" }}>
        <div maxWidth="md" style={{ marginTop: "40px" }}>
          <Card
            style={{
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              padding: "5%",
              borderRadius: "10px",
            }}>
            <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
              Terms of Agreement
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to our website. By accessing and using this website, you
              accept and agree to be bound by the following terms and
              conditions. If you do not agree to these terms, you must not use
              the website.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>1. Ownership of Website Content:</strong> All materials on
              this website, including text, images, logos, and design, are the
              property of our company and are protected by copyright laws.
            </Typography>
            {/* Add more sections as needed for your specific Terms of Agreement */}
            {/* ... */}

            <Typography variant="body1" gutterBottom>
              By using this website, you agree to these terms and conditions in
              full. For the full terms of agreement, please{" "}
              <Link
                href="/full-terms"
                target="_blank"
                rel="noopener noreferrer">
                click here
              </Link>
              .
            </Typography>
            <Stack
              direction={isSmallScreen ? "column" : "row"}
              spacing={isSmallScreen ? 2 : 25}
              mt={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAgreed}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="I agree to the Terms of Agreement"
              />
              {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAgreeButtonClick}
              disabled={!isAgreed}>
             
            </Button> */}

              <StyledButton
                color="primary"
                onClick={handleAgreeButtonClick}
                disabled={!isAgreed}>
                {" "}
                Agree and Proceed
              </StyledButton>
            </Stack>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfAgreementPage;
