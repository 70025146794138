import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Link, Grid } from "@material-ui/core";
import {
  Facebook,
  YouTube,
  Telegram,
  Twitter,
  Language,
  Lock,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { images } from "../../../assets/index";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#D0D1CF",
    padding: theme.spacing(3),
    color: "#000",
  },
  logo: {
    width: "50px",
  },
  company: {
    color: "#E7B035",
    paddingTop: theme.spacing(1),
  },
  aboutUs: {
    marginTop: theme.spacing(3),
  },
  aboutUsText: {
    width: "100%",
    margin: "0 auto",
  },
  contentLinks: {
    marginTop: theme.spacing(3),
  },
  contentLinksList: {
    listStyle: "none",
    padding: 0,
    "& li": {
      marginBottom: theme.spacing(1),
    },
  },
  socialIcons: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  socialIcon: {
    backgroundColor: "#0D8523",
    color: "#fff",
    padding: "4px",
    borderRadius: "4px",
    border: "1px solid #E1A411",
    marginRight: theme.spacing(1),
  },
  miniFooter: {
    backgroundColor: "#616161",
    padding: theme.spacing(1),
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative", // Change to relative positioning
    zIndex: 1, // Ensure it's above other elements
  },
}));

const MiniFooter = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.miniFooter}>
        <div>
          <Typography variant="body2">
            Addis Ababa Chamber of Commerce and Sectoral Associations
          </Typography>
        </div>
        <div>
          <Typography variant="body2">© AR C ICT Technologies</Typography>
        </div>
      </div>
    </>
  );
};

export default MiniFooter;
