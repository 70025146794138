import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SimpleCard } from "../../../../components";
import { primaryColor, colorTags } from "../../../../constants/variants";
import GridData from "../../../../components/GridData";
import AddInstitution from "./AddInstitution";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetInstitutionsQuery,
  useGetInstitutionByIdQuery,
  useCreateInstitutionRequestMutation,
  useUpdateInstitutionRequestMutation,
  useDeleteInstitutionRequestMutation,
} from "../../../../features/api/apiSlice";
import EditInstitution from "./EditInstitution";
import InstitutionDetailModal from "./InstitutionDetail";
import { images } from "../../../../assets/index";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { OpenInNew } from "@mui/icons-material";
import { Bars } from "react-loader-spinner";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const institutionsDataq = [
  {
    id: 1,
    institutionName: "Example University",
    description: "A prestigious institution",
    image: images.Corp,
    date: new Date("2023-06-01").toLocaleDateString("en-us"),
    category: "GOV",
  },
  {
    id: 2,
    institutionName: "Another College",
    description: "Leading in education",
    image: "image_url_here",
    date: new Date("2023-06-02").toLocaleDateString("en-us"),
    category: "NGO",
  },
  {
    id: 3,
    institutionName: "University of Excellence",
    description: "Fostering innovation",
    image: "image_url_here",
    date: new Date("2023-06-03").toLocaleDateString("en-us"),
    category: "PRV",
  },
  {
    id: 4,
    institutionName: "Knowledge Institute",
    description: "Promoting research",
    image: "image_url_here",
    date: new Date("2023-06-04").toLocaleDateString("en-us"),
    category: "GOV",
  },
  {
    id: 5,
    institutionName: "Global Foundation",
    description: "Empowering communities",
    image: "image_url_here",
    date: new Date("2023-06-05").toLocaleDateString("en-us"),
    category: "NGO",
  },
  {
    id: 6,
    institutionName: "Educational Trust",
    description: "Supporting education",
    image: "image_url_here",
    date: new Date("2023-06-06").toLocaleDateString("en-us"),
    category: "PRV",
  },
  {
    id: 7,
    institutionName: "City College",
    description: "Quality education",
    image: "image_url_here",
    date: new Date("2023-06-07").toLocaleDateString("en-us"),
    category: "GOV",
  },
  {
    id: 8,
    institutionName: "Foundation for Learning",
    description: "Educational initiatives",
    image: "image_url_here",
    date: new Date("2023-06-08").toLocaleDateString("en-us"),
    category: "NGO",
  },
  {
    id: 9,
    institutionName: "Innovation Hub",
    description: "Driving innovation",
    image: "image_url_here",
    date: new Date("2023-06-09").toLocaleDateString("en-us"),
    category: "PRV",
  },
  {
    id: 10,
    institutionName: "Community College",
    description: "Community-focused education",
    image: "image_url_here",
    date: new Date("2023-06-10").toLocaleDateString("en-us"),
    category: "GOV",
  },
];

export default function InstitutionsPage() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const handleDetailModalClose = () => setDetailModalOpen(false);
  const handleDetailModalOpen = () => setDetailModalOpen(true);

  const {
    data: institutionsData,
    isLoading: isInstitutionDataLoading,
    isError: isErrorInstitutionData,
    error: errorInstitutionData,
  } = useGetInstitutionsQuery();

  useEffect(() => {
    if (institutionsData) {
      console.log(institutionsData);
    }
  }, [institutionsData]);

  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      field: "id",
      headerName: "No.",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "institutionName",
      headerName: "Institution Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params?.value).toLocaleDateString("en-US");
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex">
            <IconButton
              onClick={() => {
                setSelectedRow(params?.row);
                console.log(params?.row);
                setDetailModalOpen(true);
              }}>
              <Info color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelectedRow(params?.row);
              }}>
              <EditIcon color="primary" />
            </IconButton>
            <DeleteDialog
              api={useDeleteInstitutionRequestMutation}
              dialogLabel="Delete Institution"
              id={params?.row?.id}
              name={`${params?.row?.institutionName}`}
            />
          </div>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  if (isInstitutionDataLoading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );

  return (
    <div style={{ marginLeft: "5%", marginTop: "5%", width: "90%" }}>
      <Button
        variant="contained"
        style={{
          marginBottom: "1%",
          marginLeft: "88%",
          backgroundColor: primaryColor,
        }}
        onClick={handleModalOpen}>
        Add Institution
      </Button>
      <AddInstitution
        open={modalOpen}
        onClose={handleModalClose}
        title="Add Institution"
        content="This is the modal content."
        actionText="Close"
      />
      <SimpleCard
        title="Institutions List"
        style={{
          marginTop: "2%",
        }}>
        <Box width="100%" overflow="auto">
          <GridData rows={institutionsData ?? []} columns={columns} />
        </Box>
      </SimpleCard>

      <EditInstitution
        open={editModalOpen}
        data={selectedRow}
        onClose={handleEditModalClose}
        onSave={handleEditModalClose}
        title="Edit Institution"
        content="This is the modal content."
        actionText="Close"
      />
      <InstitutionDetailModal
        open={detailModalOpen}
        institutionData={selectedRow}
        onClose={handleDetailModalClose}
        title="Institution Details"
        content="This is the modal content."
        actionText="Close"
      />
    </div>
  );
}
