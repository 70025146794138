import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Typography,
  TextField,
  Grid,
  Avatar,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import {
  Button,
  Select,
  Stack,
  styled,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FlagContentModal from "../../../components/FlagContent";
import { useSelector } from "react-redux";
import {
  useUpdateMemberRequestMutation,
  useGetMemberByIdQuery,
  useGetDestinationsQuery,
  useGetProductsQuery,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../constants/apiTags";
import LoadingScreen from "../../../components/LoadingMain";
import ChangePasswordModal from "../../../components/changePasswordModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const StyledButton = styled(Button)({
  margin: "10px",
  padding: "10px 25px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "white",
  borderRadius: "10px",
  display: "block",
  border: "0px",
  fontWeight: 700,
  boxShadow: "0px 0px 14px -7px #006737",
  backgroundImage:
    "linear-gradient(45deg, #068FFF 0%, #75C2F6  51%, #068FFF  100%)",
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "manipulation",
  "&:hover": {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    backgroundColor: "#f2f2f2",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
  },
  formContainer: {
    width: "100%",
    padding: theme.spacing(4),
    boxShadow:
      " rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: theme.spacing(1),
    backgroundColor: "#fff",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  mainTitle: {
    textAlign: "center",
    color: colorTags.PRIMARY,
    marginTop: theme.spacing(8),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    cursor: "pointer",
    marginBottom: theme.spacing(5),
  },
  cover: {
    // width: theme.spacing(40),
    width: "100%",
    height: theme.spacing(15),
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(5),
  },
  imageInput: {
    display: "none",
  },
  socialsContainer: {
    [theme.breakpoints.down("600")]: {
      display: "none",
    },
  },
}));

const EditProfilePage = () => {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [sector, setSector] = useState("");
  const [subSector, setSubSector] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [status, setStatus] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [bio, setBio] = useState("");
  const [coverImage, setCoverImage] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [coverImageS, setCoverImageS] = useState("");
  const [avatarS, setAvatarS] = useState("");
  const [products, setProducts] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [socials, setSocials] = useState({
    whatsapp: "",
    linkedIn: "",
    website: "",
    // twitter: "",
    //   facebook: "",
    //   instagram: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const [showSocialFields, setShowSocialFields] = useState({
    whatsapp: false,
    linkedIn: false,
    website: false,
  });
  const [avatarImagePreview, setAvatarImagePreview] = useState([]);
  const [coverImagePreview, setCoverImagePreview] = useState([]);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const params = useParams(); // Get the id parameter from the URL
  const { data: productsData, isLoading: productsLoading } =
    useGetProductsQuery();
  const { data: destinationsData, isLoading: destinationsLoading } =
    useGetDestinationsQuery();

  const [
    updateMemberRequest,
    {
      isLoading: isLoadingUpdateRequest,
      isError: isErrorUpdateRequest,
      error: errorUpdateRequest,
    },
  ] = useUpdateMemberRequestMutation();

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Access the uploaded image data, e.g., data.documentName
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    // Prepare the updateData
    const updateData = {
      id: user?.user_id,
      companyName,
      sector,
      subSector,
      contactPerson,
      status,
      contactAddress,
      bio,
      email: contactEmail,
      contactPhone,
      products,
      destinations,
      socials,
      avatar: coverImageS,
      cover: avatarS,
    };

    if (coverImage.length > 0) {
      try {
        const imageResponse = await uploadImage(coverImage[0]);

        updateData.coverImage =
          imageResponse?.dbPaths[0]?.documentName ?? coverImageS;
      } catch (error) {
        toast.error("Error uploading cover image");
        updateData.coverImage = coverImageS;
        return;
      }
    } else {
      updateData.coverImage = coverImageS;
    }

    if (avatar.length > 0) {
      try {
        const imageResponse = await uploadImage(avatar[0]);

        updateData.avatar = imageResponse?.dbPaths[0]?.documentName ?? avatarS;
      } catch (error) {
        toast.error("Error uploading avatar image");
        updateData.avatar = avatarS;
        return;
      }
    } else {
      updateData.avatar = avatarS;
    }
    await updateMemberRequest(updateData)
      .then((res) => {
        if (res.data) toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Updating Profile");
      });
  };
  const {
    data: memberData,
    isLoading: isLoadingMemberData,
    isError: isErrorMemberData,
  } = useGetMemberByIdQuery({ id: user?.user_id });
  const handleCheckboxChange = (field) => {
    setShowSocialFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  useEffect(() => {
    // Fetch member data using the id parameter from the URL

    if (memberData) {
      setCompanyName(memberData[0].companyName);
      setSector(memberData[0].sector);
      setSubSector(memberData[0].subSector);
      setContactPerson(memberData[0].contactPerson);
      setStatus(memberData[0].status);
      setContactAddress(memberData[0].contactAddress);
      setContactEmail(memberData[0].email);
      setContactPhone(memberData[0].contactPhone);
      setBio(memberData[0].bio);
      setCoverImageS(memberData[0].coverImage);
      setAvatarS(memberData[0].avatar);
      setProducts(JSON.parse(memberData[0].products));
      setDestinations(JSON.parse(memberData[0].destinations));
      setSocials(JSON.parse(memberData[0].socials));
      // console.log(memberData);
    }
  }, [memberData]);

  useEffect(() => {
    if (
      // !user ||
      // user?.user_id * 1 !== params?.id ||
      // user?.role_name !== "Member"
      (memberData && user && user?.user_id * 1 !== memberData[0].id) ||
      user?.role_name !== "Member"
    )
      window.location.href = "/";
  }, [user, memberData]);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [coverPicturePreview, setCoverPicturePreview] = useState(null);

  const handleImageChangeAv = (event) => {
    const file = event.target.files[0];

    setAvatarImagePreview(event.target.files);

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageChangeCv = (event) => {
    const file = event.target.files[0];

    setCoverImagePreview(event.target.files);

    const reader = new FileReader();
    reader.onloadend = () => {
      setCoverPicturePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  if (isLoadingMemberData) {
    return <LoadingScreen />;
  }
  // else if (isErrorMemberData) {
  //   return <div>Error</div>;
  // }
  else
    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Header />
        <Stack
          direction="row"
          justifyContent={"space-around"}
          spacing={2}
          style={{ marginTop: "35px" }}>
          {" "}
          <Typography variant="h4" gutterBottom className={classes.mainTitle}>
            Edit Your Profile
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            style={{
              //  marginLeft: "30px",
              borderRadius: "10px",
            }}
            onClick={() => setChangePasswordOpen(true)}>
            Change Password
          </Button>
        </Stack>
        <div className={classes.root}>
          <Container maxWidth="md" className={classes.formContainer}>
            <form onSubmit={handleSave}>
              <Grid container spacing={2}>
                {/* Left Section */}
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.avatarContainer}>
                    <label htmlFor="image-upload">
                      {profilePicturePreview ? (
                        <Avatar
                          alt={companyName}
                          src={profilePicturePreview}
                          className={classes.avatar}
                        />
                      ) : (
                        <Avatar
                          alt={companyName}
                          src={BASE_FILE_URL + avatarS}
                          className={classes.avatar}
                        />
                      )}
                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        className={classes.imageInput}
                        onChange={(e) => {
                          handleImageChangeAv(e);
                          setAvatar(e.target.files);
                        }}
                      />
                    </label>
                    <div className={classes?.socialsContainer}>
                      <TextField
                        className={classes.formField}
                        label="Website"
                        name="website"
                        value={socials.website}
                        onChange={(e) =>
                          setSocials({ ...socials, website: e.target.value })
                        }
                        fullWidth
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <i className="fab fa-facebook"></i>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        InputProps={{
                          maxLength: 50,
                        }}
                      />
                      <TextField
                        className={classes.formField}
                        label="Whatsapp"
                        name="whatsapp"
                        value={socials.whatsapp}
                        onChange={(e) =>
                          setSocials({ ...socials, whatsapp: e.target.value })
                        }
                        fullWidth
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <i className="fab fa-twitter"></i>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        InputProps={{
                          maxLength: 50,
                        }}
                      />
                      <TextField
                        className={classes.formField}
                        label="LinkedIn"
                        name="linkedin"
                        value={socials.linkedIn}
                        onChange={(e) =>
                          setSocials({ ...socials, linkedIn: e.target.value })
                        }
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="fab fa-linkedin"></i>
                            </InputAdornment>
                          ),
                          maxLength: 50,
                        }}
                      />
                    </div>
                  </div>
                </Grid>

                {/* Right Section */}
                <Grid item xs={12} sm={6} md={8}>
                  <div>
                    {" "}
                    <label htmlFor="image-upload2">
                      {coverPicturePreview ? (
                        <img
                          alt={companyName}
                          src={coverPicturePreview}
                          className={classes.cover}
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          alt={companyName}
                          src={BASE_FILE_URL + coverImageS}
                          className={classes.cover}
                          style={{ objectFit: "cover" }}
                        />
                      )}
                      <input
                        type="file"
                        id="image-upload2"
                        accept=".jpeg, .jpg, .png, .webp"
                        className={classes.imageInput}
                        onChange={(e) => {
                          handleImageChangeCv(e);
                          setCoverImage(e.target.files);
                        }}
                      />
                    </label>
                  </div>
                  <TextField
                    className={classes.formField}
                    label="Company Name"
                    name="companyName"
                    value={companyName}
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    InputProps={{
                      maxLength: 50,
                    }}
                    onChange={(e) => setCompanyName(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    className={classes.formField}
                    label="Sector"
                    name="sector"
                    value={sector}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    className={classes.formField}
                    label="Sub Sector"
                    name="subSector"
                    value={subSector}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    className={classes.formField}
                    label="Contact Person"
                    name="contactPerson"
                    value={contactPerson}
                    fullWidth
                    InputProps={{
                      maxLength: 50,
                    }}
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                  <TextField
                    className={classes.formField}
                    label="Contact Address"
                    name="contactAddress"
                    InputProps={{
                      maxLength: 50,
                    }}
                    value={contactAddress}
                    fullWidth
                    onChange={(e) => setContactAddress(e.target.value)}
                  />
                  <TextField
                    className={classes.formField}
                    label="Bio"
                    name="bio"
                    value={bio}
                    InputProps={{
                      maxLength: 600,
                    }}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => setBio(e.target.value)}
                  />
                  <TextField
                    className={classes.formField}
                    label="Contact Email"
                    name="contactEmail"
                    InputProps={{
                      maxLength: 100,
                    }}
                    value={contactEmail}
                    fullWidth
                    onChange={(e) => setContactEmail(e.target.value)}
                    error={!validateEmail(contactEmail)}
                    helperText={
                      !validateEmail(contactEmail) && "Invalid email format"
                    }
                  />
                  <TextField
                    className={classes.formField}
                    label="Contact Phone"
                    name="contactPhone"
                    InputProps={{
                      maxLength: 50,
                    }}
                    value={contactPhone}
                    fullWidth
                    onChange={(e) => setContactPhone(e.target.value)}
                    error={!validatePhone(contactPhone)}
                    helperText={
                      !validatePhone(contactPhone) &&
                      "Invalid Phone number format"
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showSocialFields.whatsapp}
                        onChange={() => handleCheckboxChange("whatsapp")}
                      />
                    }
                    label={
                      <WhatsAppIcon
                        style={{
                          fontSize: "20px",
                          color: "#25D366",
                        }}
                      />
                    }
                  />
                  {showSocialFields.whatsapp && (
                    <div>
                      <TextField
                        label="WhatsApp"
                        variant="outlined"
                        fullWidth
                        value={socials.whatsapp}
                        onChange={(e) =>
                          setSocials((prevSocials) => ({
                            ...prevSocials,
                            whatsapp: e.target.value,
                          }))
                        }
                        InputProps={{
                          maxLength: 50,
                        }}
                      />
                    </div>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showSocialFields.linkedin}
                        onChange={() => handleCheckboxChange("linkedIn")}
                      />
                    }
                    label={
                      <LinkedInIcon
                        style={{
                          fontSize: "20px",

                          color: "#0077B5",
                        }}
                      />
                    }
                  />
                  {showSocialFields.linkedIn && (
                    <div>
                      {" "}
                      <TextField
                        label="LinkedIn"
                        variant="outlined"
                        fullWidth
                        value={socials.linkedIn}
                        InputProps={{
                          maxLength: 50,
                        }}
                        onChange={(e) =>
                          setSocials((prevSocials) => ({
                            ...prevSocials,
                            linkedIn: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showSocialFields.website}
                        onChange={() => handleCheckboxChange("website")}
                      />
                    }
                    label={
                      <LanguageIcon
                        style={{
                          fontSize: "20px",

                          color: "blue",
                        }}
                      />
                    }
                  />
                  {showSocialFields.website && (
                    <div>
                      <TextField
                        label="Website"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          maxLength: 50,
                        }}
                        style={{ marginTop: "15px" }}
                        value={socials.website}
                        onChange={(e) =>
                          setSocials((prevSocials) => ({
                            ...prevSocials,
                            website: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                  <FormControl fullWidth style={{ marginTop: "5px" }}>
                    <InputLabel id="products">Products</InputLabel>
                    <Select
                      labelId="products"
                      label="Products"
                      id="products"
                      multiple
                      value={products}
                      onChange={(e) => setProducts(e.target.value)}
                      variant="outlined"
                      className={classes.textField}
                      fullWidth
                      inputProps={{
                        max: 5, // Set the maximum number of selections to 5
                      }}
                      // renderValue={(selected) => (
                      //   <div>
                      //     {selected?.map((value) => (
                      //       <Chip key={value} label={value} />
                      //     ))}
                      //   </div>
                      // )}
                    >
                      {productsData?.map((product) => (
                        <MenuItem
                          key={product?.id}
                          value={product?.productName}>
                          {product?.productName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "15px" }}>
                    <InputLabel id="destinations">Destinations</InputLabel>
                    <Select
                      labelId="destinations"
                      label="Destinations"
                      id="destinations"
                      multiple
                      value={destinations}
                      onChange={(e) => setDestinations(e.target.value)}
                      variant="outlined"
                      className={classes.textField}
                      fullWidth
                      inputProps={{
                        max: 5, // Set the maximum number of selections to 5
                      }}
                      // renderValue={(selected) => (
                      //   <div>
                      //     {selected.map((value) => (
                      //       <Chip key={value} label={value} />
                      //     ))}
                      //   </div>
                      // )}
                    >
                      {destinationsData?.map((destination) => (
                        <MenuItem
                          key={destination?.id}
                          value={destination?.destinationName}>
                          {destination?.destinationName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                spacing={2}
                style={{ marginTop: "35px" }}>
                <Button
                  color="error"
                  variant="outlined"
                  style={{
                    //  marginLeft: "30px",
                    borderRadius: "10px",
                  }}
                  onClick={() => window.history.back()}>
                  Discard Changes and Return
                </Button>
                <StyledButton color="primary" type="submit">
                  Save Edit
                </StyledButton>
              </Stack>
            </form>
          </Container>
          <ChangePasswordModal
            open={changePasswordOpen}
            onClose={() => setChangePasswordOpen(false)}
            data={
              memberData && {
                id: memberData[0].id,
                email: memberData[0].contactEmail,
              }
            }
          />
        </div>
        <Footer />
      </div>
    );
};

export default EditProfilePage;
