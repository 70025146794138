import React, { useState, useEffect } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
} from "@material-ui/core";

import { colorTags } from "../../../constants/variants";
import {
  useGetFlagsQuery,
  useGetFlagByIdQuery,
  useCreateFlagRequestMutation,
  useUpdateFlagRequestMutation,
  useDeleteFlagRequestMutation,
} from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#006738",
      },
      "&:hover fieldset": {
        borderColor: "#006738",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006738",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#006738",
    },
  },
}));

const EditFlag = ({ open, onClose, title, onSave, data }) => {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const currentUrl = window.location.href;
  const [content, setContent] = useState("");
  const [
    updateFlagRequest,
    { isLoading: isUpdating, isError, error, isSuccess },
  ] = useUpdateFlagRequestMutation();
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = {
      id: data.id,
      flagId: data.id,
      reason,
      description,
      content,
      comment,
      userId: user?.user_id,
    };
    await updateFlagRequest(formData)
      .then((res) => {
        if (res.data) {
          toast.success("Flag Resolved Successfully");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });

    onClose();
  };

  useEffect(() => {
    if (data) {
      setReason(data.reason);
      setDescription(data.description);
      setComment(data.comment);
      setContent(data.content);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h5"
              className={classes.modalTitle}
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              {title}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: "#00FF00" }} />
          <div className={classes.modalBody}>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold" }}
              onClick={() => window.open(content, "_blank")}>
              <span
                style={{
                  color: "#000",
                  fontSize: "17px",
                  cursor: "pointer",
                }}>
                {" "}
                Content:
              </span>
              &nbsp;&nbsp;{" "}
              <span
                style={{
                  color: "#00A9FF",
                  fontSize: "17px",
                  cursor: "pointer",
                }}>
                <a
                  style={{ textDecoration: "none" }}
                  href={content}
                  target="_blank"></a>
              </span>
              {content}
            </Typography>
            <Typography
              variant="h5"
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              <span style={{ color: "#000", fontSize: "17px" }}> Reason:</span>
              &nbsp;&nbsp; {reason}
            </Typography>

            <Typography
              variant="h6"
              style={{ fontWeight: "medium", color: colorTags.PRIMARY }}>
              <span style={{ color: "#000", fontSize: "17px" }}>
                {" "}
                Description:
              </span>
              &nbsp;&nbsp; {description}
            </Typography>

            <TextField
              label="Comment"
              variant="outlined"
              placeholder="Comment / Resolution of the issue"
              multiline
              rows={4}
              className={classes.textField}
              fullWidth
              maxLength={1000}
              style={{ marginTop: "15px" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions} style={{}}>
                <LoadingButton
                  loading={isUpdating}
                  variant="contained"
                  color="success"
                  style={{ fontWeight: "bold", color: "#fff" }}
                  onClick={handleSave}>
                  Update
                </LoadingButton>

                <Button
                  variant="outlined"
                  style={{ color: "#FF0000", marginLeft: "10px" }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditFlag;
