import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const institutionsData = [
  {
    id: 1,
    institutionName: "Example University 1",
    description: "A prestigious institution",
    image: "image_url_here",
    date: new Date("2023-06-01").toLocaleDateString("en-us"),
  },
  {
    id: 2,
    institutionName: "Another College 2",
    description: "Leading in education",
    image: "image_url_here",
    date: new Date("2023-06-02").toLocaleDateString("en-us"),
  },
  {
    id: 3,
    institutionName: "University XYZ 3",
    description: "Innovative learning",
    image: "image_url_here",
    date: new Date("2023-06-03").toLocaleDateString("en-us"),
  },
  {
    id: 4,
    institutionName: "Global Institute 4",
    description: "World-class education",
    image: "image_url_here",
    date: new Date("2023-06-04").toLocaleDateString("en-us"),
  },
  {
    id: 5,
    institutionName: "Tech College 5",
    description: "Cutting-edge technology",
    image: "image_url_here",
    date: new Date("2023-06-05").toLocaleDateString("en-us"),
  },
  {
    id: 6,
    institutionName: "Science Academy 6",
    description: "Advancing in science",
    image: "image_url_here",
    date: new Date("2023-06-06").toLocaleDateString("en-us"),
  },
  {
    id: 7,
    institutionName: "Art School 7",
    description: "Fostering creativity",
    image: "image_url_here",
    date: new Date("2023-06-07").toLocaleDateString("en-us"),
  },
  {
    id: 8,
    institutionName: "Medical College 8",
    description: "Healthcare excellence",
    image: "image_url_here",
    date: new Date("2023-06-08").toLocaleDateString("en-us"),
  },
  {
    id: 9,
    institutionName: "Business Institute 9",
    description: "Leaders in business education",
    image: "image_url_here",
    date: new Date("2023-06-09").toLocaleDateString("en-us"),
  },
  {
    id: 10,
    institutionName: "Language School 10",
    description: "Mastering languages",
    image: "image_url_here",
    date: new Date("2023-06-10").toLocaleDateString("en-us"),
  },
];
const AddInstitutions = ({ open, onClose }) => {
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedInstitutions(event.target.value);
  };

  const handleSave = () => {
    // You can do something with the selected institutions here
    console.log("Selected Institutions:", selectedInstitutions);
    onClose();
  };

  const handleClose = () => {
    setSelectedInstitutions([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Institutions</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Select Institutions:
        </Typography>
        <Select
          multiple
          value={selectedInstitutions}
          onChange={handleSelectChange}
          fullWidth
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            getContentAnchorEl: null,
            style: { marginTop: "1%", maxHeight: 350 },
          }}>
          {institutionsData.map((institution) => (
            <MenuItem key={institution.id} value={institution.id}>
              {institution.institutionName}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          style={{ marginRight: 16 }}
          color="success">
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInstitutions;
