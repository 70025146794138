import React, { useEffect } from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { Navigate, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: "0 8% 0 8%",
    alignItems: "center",
    width: "100%",

    height: 500,
    background: "none",
    [theme.breakpoints.down(900)]: {
      // flexDirection: "column",
      marginTop: theme.spacing(5),
      height: "auto",
      width: "95%",
    },

    [theme.breakpoints.down(600)]: {
      marginTop: theme.spacing(5),

      flexDirection: "column",
      height: "auto",
    },
  },

  card: {
    cursor: "pointer",
    minWidth: 250,
    minHeight: 300,
    width: 280,
    height: 300,
    margin: theme.spacing(3),
    borderRadius: 15,
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA}, 0.9)`,
    backdropFilter: "blur(10px)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    position: "relative",
    [theme.breakpoints.down(900)]: {
      minWidth: 235,
      minHeight: 300,
      margin: theme.spacing(1.5),
    },

    // boxShadow: `rgba(${colorTags.PRIMARY}, 0.5) 10px 10px 50px`,
    // boxShadow: `rgba(${colorTags.PRIMARY}, 1) 20px 25px 50px -12px`,
    // boxShadow: "rgba(11, 91, 7, 1) 10px 10px 10px",
    // boxShadow:
    //   "rgba(11, 91, 7, 0.2) 0px 0px 24px, rgba(11, 91, 7, 0.2) 0px 0px 56px, rgba(11, 91, 7, 0.2) 0px 0px 80px",
    // boxShadow:
    //   "rgba(17, 17, 26, 0.3) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.3) 0px 24px 80px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 30px 20px -20px",
    "&:hover": {
      transform: "scale(1.01)",
      transition: "all 0.2s ease-in-out",
      // boxShadow: "rgba(0, 0, 0, 0.25) 30px 30px 30px 30px",
      boxShadow: "rgba(0, 0, 0, 0.4) -5px -5px -5px -5px",
    },
  },
  title: {
    fontSize: 27,
    color: "#FEBE24",
    //  colorTags.SECONDARY,
    fontWeight: 400,
    textAlign: "left",
  },
  subtitle: {
    fontSize: 16,
    textAlign: "justify",
    marginTop: theme.spacing(3),
  },
  divider: {
    position: "absolute",
    bottom: theme.spacing(6),
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    height: 2,
    backgroundColor: "lightgrey",
  },
}));

const ServiceCard = ({ title, content, nav }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const contentArray = content.split(", ");
  useEffect(() => {
    // Initialize AOS for each card
    AOS.init({ duration: 3000 });
  }, []);
  return (
    <Card className={classes.card} onClick={() => navigate(nav)}>
      <Typography variant="h6" component="h2" className={classes.title}>
        {title}
      </Typography>
      {contentArray.map((item, index) => (
        <Typography
          style={{
            textAlign: "justify",
          }}
          variant="body2"
          component="p"
          className={classes.subtitle}
          key={index}>
          {item}
        </Typography>
      ))}
      <div className={classes.divider} />
    </Card>
  );
};

const Services = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}>
      <div className={classes.cardContainer}>
        <Card className={classes.card} data-aos="fade-up" data-aos-delay={1000}>
          <Typography
            onClick={() => navigate("/export")}
            variant="h5"
            component="h2"
            className={classes.title}>
            EXPORT
          </Typography>

          <Typography
            variant="body2"
            component="p"
            style={{
              textAlign: "justify",
            }}
            className={classes.subtitle}
            onClick={() => navigate("/export/howTo")}>
            How to start
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            style={{ fontWeight: "bold" }}
            onClick={() => navigate("/business-matching")}>
            Business Matching
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("/export/howTop")}>
            Export Products
          </Typography>

          <div className={classes.divider} />
        </Card>
        <Card data-aos="fade-up" data-aos-delay={1100} className={classes.card}>
          <Typography
            variant="h5"
            component="h2"
            onClick={() => navigate("/logistics")}
            className={classes.title}>
            LOGISTICS
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("/logistics")}>
            Transportation Options
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("logistics/routes")}>
            Routes
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("logistics/destinations")}>
            Destinations
          </Typography>

          <div className={classes.divider} />
        </Card>
        <Card data-aos="fade-up" data-aos-delay={1200} className={classes.card}>
          <Typography
            variant="h5"
            component="h2"
            onClick={() => navigate("/resources")}
            className={classes.title}>
            RESOURCES
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("/repository/3")}>
            Legal Documents
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("/women-in-export")}>
            Women In Export
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.subtitle}
            onClick={() => navigate("/resources")}>
            Other Links
          </Typography>

          <div className={classes.divider} />
        </Card>
      </div>
    </div>
  );
};
export default Services;
