import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Grid,
  IconButton,
  Link,
  TextField,
  DragHandle,
  Divider,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { colorTags } from "../../../../constants/variants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { images } from "../../../../assets/index";
import ChatIcon from "@mui/icons-material/Chat";
import FlagIcon from "@mui/icons-material/Flag";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import * as Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  Remove,
  Edit,
  Delete,
  AddCircleOutline,
  FiberManualRecord,
} from "@mui/icons-material";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import FlagContentModal from "../../../../components/FlagContent";
// import AddMainStep from "./AddMainStep";
// import EditMainStep from "./EditMainStep";
// import AddInstitutions from "./AddInstitutions";
// import AddDocuments from "./AddDocuments";
import { set } from "date-fns";

import DeleteDialog from "../../../../components/DeleteDialog";
import { LoadingButton } from "@mui/lab";
import {
  useGetProceduresQuery,
  useGetProcedureByIdQuery,
  useCreateProcedureRequestMutation,
  useUpdateProcedureRequestMutation,
  useDeleteProcedureRequestMutation,
  useGetInstitutionsQuery,
  useGetDocumentsQuery,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import _ from "lodash";
import { FILE_LINK_URL, BASE_FILE_URL } from "../../../../constants/apiTags";
import LanguageIcon from "@mui/icons-material/Language";
import { Bars } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#F7F7F7",
  },
  pageContainer: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(0),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      // display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  leftColumn: {
    width: "35%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  rightColumn: {
    width: "55%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(-7),
  },
  header: {
    backgroundColor: colorTags.PRIMARY,
    color: "#fff",
    textAlign: "center",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
  },
  title: {
    fontSize: 20,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  mainTitle: {
    fontSize: 30,
    fontWeight: "bold",
    marginTop: theme.spacing(6),
    alignSelf: "left",
  },
  stepsContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: "#F7F7F7",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "transparent",
    // marginBottom: theme.spacing(2),
  },
  summaryTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#006738",
  },
  imageContainer: {
    position: "relative",
    // width: "100%",
    // height: "100%",
    width: "100px",
    height: "100px",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: 1,
    [theme.breakpoints.down(600)]: {
      width: "60px",
      height: "60px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  iconButton: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    backgroundColor: "red",
    boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
    color: "white",
    zIndex: 1000,
  },
  mainStepsContainer: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
}));
const styles2 = StyleSheet.create({
  container: {
    padding: "7%",
    fontFamily: "Helvetica",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
    color: "black",
  },
  summaryContainer: {
    padding: "7%",
  },
  summaryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "darkgreen",
  },
  summaryText: {
    fontSize: 14,
    marginBottom: 10,
  },
  stepsContainer: {
    paddingTop: "7%",
  },
  stepContainer: {
    marginBottom: 15,
  },
  stepDescription: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subStepContainer: {
    marginLeft: 20,
    marginBottom: 15,
  },
  subStepDescription: {
    fontSize: 16,
    marginTop: 10,

    fontWeight: "medium",
  },
  subStepDetail: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 10,
  },
  institutionsContainer: {
    marginTop: "7%",
  },
  institutionsTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  institutionName: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 20,
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    right: 30,
    fontSize: 10,
    flexDirection: "row",
    alignItems: "center",
    color: "#888",
  },
  footerIcon: {
    marginRight: 4,
  },
  waterMark: {
    position: "absolute",
    bottom: 100,
    right: 30,
    fontSize: 10,
    color: "#aaa",
  },

  // waterMark: {
  //   position: "absolute",
  //   fontSize: 50,
  //   opacity: 0.1,
  //   zIndex: -1,
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
});
const procedureD = {
  title: "Full Procedure To Export Product One",
  productName: "Product A",

  mainSteps: [
    {
      description: "1.1	 Export Procedures and Documentation ",
      subSteps: [
        {
          description: "1.1.1	VAT Registration ",
          detail:
            "According to Value Added Tax Proclamation, (285/2002) and the Regulation by the Council of Ministers on VAT (Regulation 79/2002), all export goods are liable to VAT at the zero rates. Literally, no VAT has to be charged. An exporter is entitled to reclaim the VAT on all the goods and services purchased to produce the exports. ",
        },
        {
          description: "1.1.2	Export Procedures and the required Documents ",
          detail:
            "An exporter must make sure that she/he is ready to export a commodity by availing/knowing how to avail export commodities .Enough finance, human resources and logistics such as warehouses and transport and packaging facilities, etc. Following this, it needs also to prepare the required documents.",
        },
        {
          description: "1.1.2.1	Document Preparations",
          detail:
            "Despite some developments, e commerce trade/electronic trade has not developed well in Ethiopia. Foreign trade is, therefore, mostly executed through the exchange of documents, which are guarantees for parties, the importer and exporter. These legal documents are usually called “bank documents /shipping documents” and are binding to both parties. Various documents produced by export supporting institutions need therefore be filled in an appropriate manner.",
        },
        {
          description: "1.1.2.2 Negotiation and Contract",
          detail:
            "Before starting to produce documents, both parties must negotiate on the terms and conditions of the sales/purchase. These negotiations must be held in a skillful manner to avoid suppression of the other party – usually the buyer.\nOnce the two parties reach an agreement with regard to the terms and conditions, the contract will have to be prepared. It is understood that the contract is a binding document. The following should be carefully noted before entering into a contract:\n\na. Bear in mind that international contracts contain potential points of dispute in the interpretation of terms of the contract.\n\nb. Be sure to specify the rights and obligations of each party.\n\nc. Do not rely too much on trust or on the buyer.\n\nd. Choose a written contract over an oral agreement.\n\ne. Consult a lawyer who is an expert in the preparation of contracts or who is well-versed in international law.\n\nf. Make sure that the contract has incorporated into it an arbitration clause.",
        },
        {
          description:
            "A. Knowing Essential Elements of the Export Sales Contract",
          detail:
            "The export sales contract contains various essential elements that should be carefully considered. These include:\n\n• The Names and addresses\n• The products, their pertinent standards, and specifications\n• Quantity\n• Inspection\n• Total value of the contract\n• The terms of delivery\n• Taxes, duties, and charges\n• Period of delivery shipment\n• Part shipment, trans-shipment, consolidation of cargo\n• Packaging, labeling, and marking\n• Terms of payment, amount, mode, and currency\n• Discounts and commissions\n• Licenses and permits\n• Insurance\n• Documentary requirements",
        },
        {
          description:
            "Where the exporter is required to buy marine insurance, it is important that he/she be asked to provide a certificate of insurance, rather than the policy so as to enable her/him to ship consignments under an open policy. This includes the following:",
          detail:
            "• Product guarantee\n• Delay in delivery\n• Force majeure or excuse for non-performance of the contract\n• Remedial action\n• The applicable law\n• Arbitration\n• Signature of the parties\n\nMoreover, it should be noted that the passing of ownership is still a matter of uncertainty in international trade. Even without a bill of lading, it is prudent to have a reservation of an ownership clause in the sales contract, to the effect that the exporter reserves ownership of the goods sold until she or he has received the purchase price.",
        },
        {
          description: "1.1.2.3 Documentary credit/Letter of Credit (L/C).",
          detail:
            "A letter of credit (L/C) is a bank document prepared by the opening bank (the buyer’s bank). This L/C is prepared in accordance with the Inco terms and ICC’s regulations of import and export. Its major contents are:\n• It protects the best interests of both the buyer and seller\n• It helps to enforce delivery of goods by the seller and payment of the value thereof by the buyer\n• The L/C –opening bank commits itself to settling the payment, whereas the seller’s bank, which is usually referred to as the negotiating bank, gives its assurance that the concerned commodity will be exported to the buyer.",
        },
        {
          description: "A. What to know in respect of L/C",
          detail:
            "Documentary credit (L/C) takes various types such as irrevocable, revocable, cash against document (CAD), advance payment, etc. The following are its major components:\n1. An undertaking letter of the buyer that the consignment will be settled within a maximum of 90 days effective from the day on which the foreign Exchange permit for the cash Against Document (CAD) mode of payment is secured is very important.\n2. It has the following information:\n- The L/C number\n- The opening date of the L/C\n- The name of the beneficiary - that is, the seller - and their full address\n- The name of the applicant, or the buyer, and their full address\n- The name of the L/C –opening bank and its full address\n- The amount of money in the credit\n- Whether or not partial shipment is allowed\n- Whether or not transshipment of the goods is allowed\n- The port of loading\n- The port of discharge\n- The latest date of shipment\n- The expiry date\n- The bag marking instruction\n- Description of the goods",
        },
        {
          description:
            "B. When receiving an L/C the following must be checked:",
          detail:
            "- Confirm the formality of the L/C (that is, whether or not it is irrevocable)\n- Confirm the credibility of the issuing bank\n- Check whether or not the L/C conforms with the sales contract\n- Study the content of each clause, which may affect performance\n- All pertinent bank documents, which are required by the L/C –opening bank for holding the negotiations, must be enumerated in the L/C including any additional conditions that must be met.\n- Bank documents must be prepared by the seller, in accordance with the L/C requirements, without any single mistake, including spelling errors:\n  - A commercial invoice\n  - A packing list\n  - The bill of lading\n  - The insurance certificate on the CIF term\n  - The certificate of weight and quality, and\n  - The certificate of origin, SPTA, phytosanitary certificate, etc",
        },
        {
          description: "1.1.2.4 Preparing cargo for Export",
          detail:
            "Once the L/C is received, the seller must be sure that she/he has to prepare the cargo and deliver it to the port of loading before the latest date of shipment. The seller should pay particular attention to the following:\n- Make sure that the required commodity is in stock for processing\n- Prepare packaging material as per the L/C marking instruction\n- Process the goods in accordance with the quality requirement stipulated in the L/C\n- Ascertain the arrival of the cargo at least one week before the latest date of shipment to make sure that the cargo will be loaded on the nominated vessel before the expiry of the shipping date.",
        },
        {
          description: "1.1.2.5 Banking Permit",
          detail:
            "A bank permit form is obtained from the negotiating bank and filled in carefully in accordance with the requirements of the L/C and of the form itself. The following documents (two copies each) are required for getting the approval of the Bank permit:\n- A contract duly signed by both the seller and the buyer in two copies\n- An authenticated message that the L/C is opened, together with the mode of payment\n- The seller’s invoice in two copies\n- The export license that is valid for the year\n- The tax registration certificate, i.e., the TIN certificate\n- The application form for the export permit duly filled in, signed, and stamped by the exporter\n- Confirmation that the exporter has cleared all outstanding items at the NBE and is not in the list of delinquent exporters.",
        },
        {
          description:
            "1.1.2.6 Getting Export Authorization and Quality Certificates for Shipment",
          detail:
            "The prepared cargo must be certified by the pertinent authority such as the Ministry of Agriculture, the Quality and Standards Authority, or by an independent Inspection Company, etc. To fulfill this, the following are required:\na) A letter of application\nb) Two copies of the contract\nc) The L/C\nd) The bank permit\ne) The sanitary certificate, and\nf) The invoice needs to be submitted.\nThe pertinent authorities give their assurances that the quality of the prepared cargo is in accordance with the requirement stipulated in the L/C. They approve and confirm that the cargo is in accordance with the country‘s regulation, which governs the exportation of the commodity.",
        },
      ],
    },
    {
      description: "1.1.3	Customs Clearance ",
      subSteps: [
        {
          description:
            "To avoid costly delays, the exporter has to submit to the customs clearing agent:",
          detail:
            "21Lorem ipsum dolor sit amet, consectetur adipiscing elit.\na) The shipping instruction given by the exporter\nb) The authorization form obtained from the shipping agent, signed, and stamped\nc) One copy of the bank permit\nd) A commercial invoice\ne) Copies of the contract and of the L/C; and\nf) The Tax Identification Number (TIN)\n\nAll of the above documents must be prepared by the shipping agent before shipping the cargo to the customs center. Simultaneously, the loaded trucks will be sent to the customs station for the purpose of checking. The quality and standards authority will then seal them for final release to the port. The officer from the quality and standards Authority will, however, need a notification of the truck and trailer number before coming to the customs office. The exporter should then communicate the details of the shipment to the nominated insurance company for inland coverage. Thus,\n\n- The exporter also needs to closely follow on every track of the goods that are to be exported up until they arrive at the port and are safely unloaded.\n- If the truck is delayed for unforeseen reasons beyond the latest shipment date, the exporter needs to inform the buyer and request an extension of the L/C shipping date and of the expiry date.",
        },
      ],
    },
    {
      description: "1.1.4	Preparation of the Bank Documents/Shipping Documents",
      subSteps: [
        {
          description: "",
          detail:
            "Close follow up is necessary to make sure that the cargo has safely arrived at the port before the scheduled vessel. As a matter of fact, it is advisable to deliver the cargo 72 hours before the arrival of the vessel.",
        },
        {
          description: "",
          detail:
            "The shipping agent arranges and avails containers for the cargo to be stuffed into containers.\nAs soon as the cargo is stuffed into containers, the shipping company issues details about contents of the stuffing, such as the number of the containers, the number of the seal, and the date of the stuffing. This copy must be sent to the buyer in order to notify that the cargo is at the port as per the schedule.",
        },
        {
          description: "",
          detail:
            "Sometimes the buyer could require knowing on additional inspection of the weight and quality of the goods, and fumigation at the port of loading. so it is necessary to do this before the arrival of the cargo at the port.",
        },
      ],
    },
    {
      description: "1.1.5	Final Steps requiring attention:",
      subSteps: [
        {
          description:
            "As soon as the cargo is on board the ship, the shipping company is expected to send a draft bill of lading to the shipper/exporter for verification before the original one.",
          detail:
            "It is important to carefully read and re-read so as to check whether or not there are any mistakes in the bill of lading, including errors in spelling.\nFollowing the correction, the original bill of lading must be issued by the shipping company and immediately forwarded to the shipper through clearing agent for fast preparation and delivery of the bank documents to the bank for the negotiations.",
        },
        {
          description: "The major bank documents are:",
          detail:
            "a) The bill of lading\nb) The commercial invoice\nc) The packing list\nd) The sanitary certificate\ne) The weight and quality certificate, and\nf) The preferential tariff certificates",
        },
      ],
    },
    {
      description: "1.1.6	Common discrepancies to be  learnt ",
      subSteps: [
        {
          description:
            "The following common discrepancies should also be understood for the necessary precautionary measures:",
          detail:
            "a) Unclean bills of lading\nb) Lack of evidence showing the goods have been shipped on Board\nc) The shipment made between ports other than those stated in the letter of credit\nd) Presentation of an insurance document other than the type mentioned in L/C\ne) Unit of measurement of the currency for insurance coverage not in accordance to the L/C\nf) Insurance not effective when the shipping documents arrive\ng) Conflicting documents\nh) Difference between description of the goods on the invoice from that given in the letter of credit\ni) Weights, marks, and numbers are not the same in all the documents\nj) Not timely presentation of the documents\nk) Not all documents in L/C have been presented\nl) The bill of lading and the insurance documents are not endorsed correctly\nm) Absence of signatures on the documents though they are required.",
        },
      ],
    },
    {
      description: "1.1.7	Additional Reminders",
      subSteps: [
        {
          description: "",
          detail:
            "a) The exporter must retain copies and submit all original documents to the negotiating bank for holding with it the negotiations.\nb) A separate letter, which accompanies the documents, will also have to be written including the details of the bank account and instruction to remit the proceedings should be given.\nc) The bank clerk who receives the bank documents must sign on the copy of the captioned letter as confirmation of receipt. Then, after examining carefully, the bank will send the documents to the L/C opening bank by courier services nominated by the buyer or by such as DHL, FEDEX, TNT, EMS, etc.\nD)Export Incentives",
        },
        {
          description:
            "The country’s significant incentive packages for exporters include the following:",
          detail:
            "⦁ Export Credit Guarantee Schemes\n⦁ Duty Exemption on Raw Materials used as inputs for export\n⦁ Investment Credit Support\n⦁ Tax Holidays for Priority Sectors\n⦁ Duty Free Import of Capital Goods\n⦁ Bonded Warehouse",
        },
      ],
    },
    {
      description: "1.1.8	Request for Payment of the Sales Value",
      subSteps: [
        {
          description: "",
          detail:
            "If the cargo is sold on fob basis, the bank will immediately (after holding the negotiations) credit the exporter’s account for the full amount of the sales value. Yet a risk of claim by the buyer is still there until the time that the cargo is safely delivered to her/his port and cleared. However, if the sale is on CIF basis, the payment can be effected but the risk of the sea freight and insurance will still be on the shoulder of the seller.",
        },
        {
          description: "",
          detail:
            "Risk on export is waived only when both parties are happy with the transaction made and willing to continue their business relation.",
        },
      ],
    },
  ],
  summary:
    "1.1	 Export Procedures and Documentation : Once an export company is legally established, it should clearly know the various procedures it needs to regularly follow.",
  institutionsInvolved: [],
  requiredDocuments: [],
};

const ProceduresPageDash = () => {
  const classes = useStyles();
  const classes2 = useStyles();
  const [procedureData, setProcedureData] = useState(null);
  const params = useParams();
  const [stepIndex, setStepIndex] = useState(0);
  const [mainIndex, setMainIndex] = useState(0);
  const pdfContainerRef = useRef();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepDetailEdit, setStepDetailEdit] = useState(false);
  const [stepDetail, setStepDetail] = useState("");
  const [editedSummary, setEditedSummary] = useState(procedureData?.summary);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    data: procedureDataq,
    isLoading: isLoadingProcedure,
    isError: isErrorProcedure,
    error,
  } = useGetProcedureByIdQuery({ id: params?.id });

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isErrorDocuments,
  } = useGetDocumentsQuery();

  const {
    data: institutionsData,
    isLoading: isInstitutionsLoading,
    isError: isErrorInstitutions,
  } = useGetInstitutionsQuery();

  const [
    updateProcedureRequest,
    { isLoading: isLoadingUpdateProcedure, isError: isErrorUpdateRequest },
  ] = useUpdateProcedureRequestMutation();

  useEffect(() => {
    if (isErrorProcedure) {
      toast.error("Error loading procedure");
      window.location.href = "/dashboard/procedures";
    }
  }, [isErrorProcedure]);

  const handleStepDetailEdit = (text) => {
    setStepDetail(text);

    setStepDetailEdit(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isEditing, setIsEditing] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const handleSaveSummaryClick = async (e) => {
    e.preventDefault();

    // Update the local state first
    setProcedureData((prevState) => ({
      ...prevState,
      summary: editedSummary,
    }));

    // Create a copy of the updated state to pass to the API request
    const updatedProcedureData = {
      ...procedureData,
      summary: editedSummary,
    };

    setIsEditingSummary(false);

    await updateProcedureRequest(updatedProcedureData)
      .then((res) => {
        if (res.data) {
          toast.success("Summary Updated Successfully");
        }
      })
      .catch((error) => {
        console.error("Error saving procedure data:", error);
        toast.error("Error saving procedure data");
      });
  };

  const handleEditSummaryClick = async (e) => {
    setIsEditingSummary(true);
    setEditedSummary(procedureData?.summary);
  };

  const [editedDetail, setEditedDetail] = useState(null);

  useEffect(() => {
    if (procedureDataq) {
      setProcedureData(procedureDataq[0]);

      if (
        procedureDataq[0].mainSteps &&
        procedureDataq[0].mainSteps[mainIndex] &&
        procedureDataq[0].mainSteps[mainIndex].subSteps[stepIndex]
      ) {
        setEditedDetail(
          procedureDataq[0].mainSteps[mainIndex].subSteps[stepIndex].detail ??
            ""
        );
      }
    }
  }, [procedureDataq, mainIndex, stepIndex]);

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedDetail(
      procedureData?.mainSteps[mainIndex].subSteps[stepIndex].detail
    );
  };
  const handleCancelSummaryClick = () => {
    setIsEditingSummary(false);
    setEditedSummary(procedureData?.summary);
  };

  const [isEditSubStep, setIsEditSubStep] = useState(false);
  // const [editSubStepDesc, setEditSubStepDesc] = useState("");
  // const [editSubStepDetail, setEditSubStepDetail] = useState("");
  // const [editSubStepModes, setEditSubStepModes] = useState(false);
  // // const initialEditSubStepModes = procedureDataq?.mainSteps?.map((mainStep) =>
  // //   mainStep?.subSteps?.map(() => false)
  // // );
  // // setEditSubStepModes(initialEditSubStepModes);

  const initialEditSubStepModes =
    procedureDataq &&
    procedureDataq[0]?.mainSteps?.map((mainStep) =>
      mainStep?.subSteps?.map(() => false)
    );

  // Initialize editSubStepModes with the initial values
  const [editSubStepModes, setEditSubStepModes] = useState(
    initialEditSubStepModes
  );

  const [addMainStepOpen, setAddMainStepOpen] = useState(false);
  const [editMainStepOpen, setEditMainStepOpen] = useState(false);
  const [editMainStep, setEditMainStep] = useState(null);

  const [AddInstitutionsOpen, setAddInstitutionsOpen] = useState(false);
  const [AddDocumentsOpen, setAddDocumentsOpen] = useState(false);

  const [editSubStepDesc, setEditSubStepDesc] = useState("");
  const [editSubStepDetail, setEditSubStepDetail] = useState("");

  useEffect(() => {
    if (procedureDataq) {
      const initialDesc =
        procedureDataq &&
        procedureDataq[0]?.mainSteps?.map((mainStep) =>
          mainStep.subSteps.map(() => "")
        );
      const initialDetail =
        procedureDataq &&
        procedureDataq[0]?.mainSteps?.map((mainStep) =>
          mainStep.subSteps.map(() => "")
        );

      setEditSubStepDesc(initialDesc);
      setEditSubStepDetail(initialDetail);
    }
  }, [procedureDataq]);

  // const handleEditStep = (mainStepIndex, subStepIndex, description, detail) => {
  //   // Set the editing modes to true for the specific substep
  //   const updatedModes = editSubStepModes.map((mainStepModes, mIndex) =>
  //     mainStepModes.map((mode, sIndex) =>
  //       mIndex === mainStepIndex && sIndex === subStepIndex ? true : mode
  //     )
  //   );
  //   setEditSubStepModes(updatedModes);

  //   // Set the description and detail for editing
  //   const updatedDesc = [...editSubStepDesc];
  //   updatedDesc[subStepIndex] = description;
  //   setEditSubStepDesc(updatedDesc);

  //   const updatedDetail = [...editSubStepDetail];
  //   updatedDetail[subStepIndex] = detail;
  //   setEditSubStepDetail(updatedDetail);
  // };
  const handleEditStep = (mainStepIndex, subStepIndex, description, detail) => {
    // Set the corresponding value in editSubStepModes to true for the substep to enter edit mode
    // const updatedModes = editSubStepModes?.map((mainStepModes, mIndex) =>
    //   mainStepModes?.map((mode, sIndex) =>
    //     mIndex === mainStepIndex && sIndex === subStepIndex ? true : mode
    //   )
    // );
    console.log(mainStepIndex, subStepIndex, description, detail);
    setIsEditSubStep(true);
    setMainIndex(mainStepIndex);
    setStepIndex(subStepIndex);

    // setEditSubStepModes(updatedModes);

    // Populate the description and detail fields with the current values
    // const updatedEditSubStepDesc = [...editSubStepDesc];
    // updatedEditSubStepDesc[mainStepIndex][subStepIndex] = description || "";

    // const updatedEditSubStepDetail = [...editSubStepDetail];
    // updatedEditSubStepDetail[mainStepIndex][subStepIndex] = detail || "";

    setEditSubStepDesc(description);
    setEditSubStepDetail(detail);
  };

  const handleEditSubStepDescChange = (mainStepIndex, subStepIndex, e) => {
    const updatedEditSubStepDesc = [...editSubStepDesc];
    updatedEditSubStepDesc[mainStepIndex][subStepIndex] = e.target.value;
    setEditSubStepDesc(updatedEditSubStepDesc);
  };

  // Handle changes for substep detail
  const handleEditSubStepDetailChange = (mainStepIndex, subStepIndex, e) => {
    const updatedEditSubStepDetail = [...editSubStepDetail];
    updatedEditSubStepDetail[mainStepIndex][subStepIndex] = e.target.value;
    setEditSubStepDetail(updatedEditSubStepDetail);
  };

  const handleSaveStep = async (mainStepIndex, subStepIndex) => {
    // console.log(mainStepIndex, subStepIndex);
    try {
      // Find the mainStep using mainIndex
      const mainStep = procedureData?.mainSteps[mainStepIndex];

      // Find the subStep using subStepIndex
      const subStep = mainStep?.subSteps[subStepIndex];

      // console.log(mainStep, subStep, editSubStepDesc, editSubStepDetail);
      // Create a copy of the procedure data
      const updatedProcedureData = _.cloneDeep(procedureData);

      updatedProcedureData.mainSteps[mainStepIndex].subSteps[
        subStepIndex
      ].description = editSubStepDesc;
      updatedProcedureData.mainSteps[mainStepIndex].subSteps[
        subStepIndex
      ].detail = editSubStepDetail;

      // Call the API to save the updated procedureData
      const response = await updateProcedureRequest(updatedProcedureData);

      // Check the response and handle success/failure accordingly
      if (response.data) {
        // Data successfully saved
        toast.success("Procedure Updated Successfully");
      } else {
        // Error in saving data
        toast.error("Error Updating Procedure");
      }
    } catch (error) {
      // Handle API request error
      console.log(error);
      toast.error("Error Updating Procedure");
    }

    handleCancelEditStep();
  };

  // const handleCancelEditStep = (mainStepIndex, subStepIndex) => {
  //   const updatedModes = editSubStepModes?.map((mainStepModes, mIndex) =>
  //     mainStepModes?.map((mode, sIndex) =>
  //       mIndex === mainStepIndex && sIndex === subStepIndex ? false : mode
  //     )
  //   );
  //   setEditSubStepModes(updatedModes);
  // };
  const handleCancelEditStep = () => {
    setMainIndex(null);
    setStepIndex(null);
    setIsEditSubStep(false);
    setEditSubStepDesc("");
    setEditSubStepDetail("");
  };

  const isAnySubStepEditing = () => {
    for (let i = 0; i < editSubStepModes?.length; i++) {
      for (let j = 0; j < editSubStepModes[i]?.length; j++) {
        if (editSubStepModes[i][j]) {
          return true;
        }
      }
    }
    return false;
  };

  const handleRemoveSubStep = (mainStepIndex, subStepIndex) => {
    // Create a deep copy of procedureData
    const updatedProcedureData = { ...procedureData };

    // Get the mainStep that needs to be updated
    const mainStepToUpdate = updatedProcedureData.mainSteps[mainStepIndex];

    // Check if the mainStep and subSteps exist before proceeding
    if (mainStepToUpdate && mainStepToUpdate.subSteps) {
      // Create a deep copy of the subSteps array
      const updatedSubSteps = [...mainStepToUpdate.subSteps];

      // Remove the subStep at the specified subStepIndex
      updatedSubSteps.splice(subStepIndex, 1);

      // Update the mainStep with the modified subSteps
      mainStepToUpdate.subSteps = updatedSubSteps;
    }

    // Update the procedureData state with the changes
    updatedProcedureData.mainSteps[mainStepIndex] = mainStepToUpdate;
    setProcedureData(updatedProcedureData);

    // Close the editing mode
    handleCancelEditStep(mainStepIndex, subStepIndex);
  };

  const handleCancelEdit = () => {};

  const PdfDocument = () => (
    <Document>
      <Page size="A4">
        <View style={styles2.container}>
          <Text style={styles2.title}>
            {procedureData?.title + " " + procedureData?.productName}{" "}
          </Text>
          <View style={styles2.summaryContainer}>
            <Text style={styles2.summaryTitle}>Summary</Text>
            <Text style={styles2.summaryText}>{procedureData?.summary}</Text>
          </View>
          <View style={styles2.stepsContainer}>
            {procedureData?.mainSteps?.map((step, index) => (
              <View key={index} style={styles2.stepContainer}>
                <Text style={styles2.stepDescription}>{step.description}</Text>
                {step?.subSteps?.map((subStep, index) => (
                  <View key={index} style={styles2.subStepContainer}>
                    <Text style={styles2.subStepDescription}>
                      {subStep.description}
                    </Text>
                    <Text style={styles2.subStepDetail}>{subStep.detail}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
      <Page size="A4">
        <View style={styles2.container}>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Institutions Involved</Text>
            <View>
              {procedureData?.institutionsInvolved?.map(
                (institution, index) => (
                  <View key={index} style={styles2.institutionName}>
                    <Text>{institution.name}</Text>
                  </View>
                )
              )}
              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
          <View style={styles2.institutionsContainer}>
            <Text style={styles2.institutionsTitle}>Required Documents</Text>
            <View>
              {procedureData?.requiredDocuments?.map((document, index) => (
                <View key={index} style={styles2.institutionName}>
                  <Text>{document.name}</Text>
                </View>
              ))}
              <Text style={styles2.waterMark}>AACCSA</Text>
            </View>
          </View>
        </View>
        <Text style={styles2.footer}>
          <InfoCircleOutlined style={styles2.footerIcon} />
          Addis Ababa Chamber of Commerce and Sectoral Associations/AACCSA/ 2023
        </Text>
        <Text style={styles2.waterMark}>AACCSA</Text>
      </Page>
    </Document>
  );

  const [deleteInstitutions, setDeleteInstitutions] = useState([]);
  const [deleteDocuments, setDeleteDocuments] = useState([]);

  const toggleDeleteDocument = (documentId) => {
    if (deleteDocuments.includes(documentId)) {
      setDeleteDocuments(deleteDocuments.filter((id) => id !== documentId));
    } else {
      setDeleteDocuments([...deleteDocuments, documentId]);
    }
  };

  const [isEditingRequiredDocuments, setIsEditingRequiredDocuments] =
    useState(false);

  const [isEditingInstitutions, setIsEditingInstitutions] = useState(false);
  const [deletedInstitutions, setDeletedInstitutions] = useState([]);

  // REMOVE INSTITUTION
  const handleRemoveInstitution = (index) => {
    setDeletedInstitutions([...deletedInstitutions, index]);
    setIsEditingInstitutions(true);
  };

  const handleCancelRemoveInstitution = (index) => {
    // Remove the index from the deletedInstitutions array
    const updatedDeletedInstitutions = deletedInstitutions.filter(
      (item) => item !== index
    );
    setDeletedInstitutions(updatedDeletedInstitutions);
    if (updatedDeletedInstitutions.length === 0) {
      setIsEditingInstitutions(false);
    }
  };

  const handleSaveInstitutionsClick = async () => {
    // Create a copy of the procedure data
    const updatedProcedureData = _.cloneDeep(procedureData);

    // Filter out the institutions that are marked for deletion
    updatedProcedureData.institutionsInvolved =
      updatedProcedureData.institutionsInvolved.filter(
        (institution, index) => !deletedInstitutions.includes(index)
      );

    // Call the API to save the updated procedureData
    try {
      const response = await updateProcedureRequest(updatedProcedureData);

      // Check the response and handle success/failure accordingly
      if (response.data) {
        toast.success("Procedure Updated Successfully");
      } else {
        toast.error("Error Saving Procedure Data");
      }
    } catch (error) {
      console.error("Error saving procedure data:", error);
      toast.error("Error Saving Procedure Data");
    }

    // Clear the deletedInstitutions array and exit edit mode
    setDeletedInstitutions([]);
    setIsEditingInstitutions(false);
  };
  const handleEditInstitutionsClick = () => {
    // Enable editing mode for institutions
    setIsEditingInstitutions(true);
  };

  // UPDATE DOCUMENTS
  const AddDocuments = ({ open, onClose }) => {
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const handleSelectChangeDocs = (event) => {
      setSelectedDocuments(event.target.value);
    };

    const handleSave = async (e) => {
      e.preventDefault();
      const updatedProcedureData = _.cloneDeep(procedureData);

      // Create a Set to store unique documentIds
      const uniqueDocumentIds = new Set(
        // updatedProcedureData?.requiredDocuments?.map((item) => item.documentId)
        updatedProcedureData?.requiredDocuments?.map((item) => item.id)
      );

      // Filter out selectedDocuments that are not already in documents
      const newDocuments = selectedDocuments.filter(
        (id) => !uniqueDocumentIds.has(id)
      );

      // Add the new unique documents to the procedure's documents
      updatedProcedureData.requiredDocuments = [
        ...updatedProcedureData.requiredDocuments,
        ...newDocuments.map((id) => ({ documentId: id })),
      ];

      await updateProcedureRequest(updatedProcedureData)
        .then((res) => {
          if (res.data) {
            toast.success("Procedure Updated Successfully");
          }
        })
        .catch((error) => {
          console.error("Error saving procedure data:", error);
          toast.error("Error saving procedure data");
        });

      handleClose();
    };

    const handleClose = () => {
      setSelectedDocuments([]);
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Documents</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Select Documents:
          </Typography>
          <Select
            multiple
            value={selectedDocuments}
            onChange={handleSelectChangeDocs}
            fullWidth
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              getContentAnchorEl: null,
              style: { marginTop: "1%", maxHeight: 350 },
            }}>
            {documentsData?.map((document) => (
              <MenuItem key={document.id} value={document.id}>
                {document.title}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoadingUpdateProcedure}
            onClick={handleSave}
            style={{ marginRight: 16 }}
            color="success">
            Save
          </LoadingButton>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // UPDATE  PROCEDURES
  const AddInstitutions = ({ open, onClose }) => {
    const [selectedInstitutions, setSelectedInstitutions] = useState([]);

    const handleSelectChange = (event) => {
      setSelectedInstitutions(event.target.value);
    };

    const handleSave = async (e) => {
      e.preventDefault();
      const updatedProcedureData = _.cloneDeep(procedureData);

      // Create a Set to store unique institutionIds
      const uniqueInstitutionIds = new Set(
        updatedProcedureData.institutionsInvolved.map(
          (item) => item.institutionId
        )
      );

      // Filter out selectedInstitutions that are not already in institutionsInvolved
      const newInstitutions = selectedInstitutions.filter(
        (id) => !uniqueInstitutionIds.has(id)
      );

      // Add the new unique institutions to institutionsInvolved
      updatedProcedureData.institutionsInvolved = [
        ...updatedProcedureData.institutionsInvolved,
        ...newInstitutions.map((id) => ({ institutionId: id })),
      ];

      await updateProcedureRequest(updatedProcedureData)
        .then((res) => {
          if (res.data) {
            toast.success("Procedure Updated Successfully");
          }
        })
        .catch((error) => {
          console.error("Error saving procedure data:", error);
          toast.error("Error saving procedure data");
        });

      handleClose();
    };

    const handleClose = () => {
      setSelectedInstitutions([]);
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Institutions</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Select Institutions:
          </Typography>
          <Select
            multiple
            value={selectedInstitutions}
            onChange={handleSelectChange}
            fullWidth
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              getContentAnchorEl: null,
              style: { marginTop: "1%", maxHeight: 350 },
            }}>
            {institutionsData?.map((institution) => (
              <MenuItem key={institution.id} value={institution.id}>
                {institution.institutionName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoadingUpdateProcedure}
            onClick={handleSave}
            style={{ marginRight: 16 }}
            color="success">
            Save
          </LoadingButton>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // DELETE DOCUMENTS
  const [toBeDeletedDocuments, setToBeDeletedDocuments] = useState([]);

  const handleRemoveDocument = (documentIndex) => {
    setToBeDeletedDocuments([...toBeDeletedDocuments, documentIndex]);
    setIsEditingRequiredDocuments(true);
  };

  const handleCancelRemoveDocument = (documentIndex) => {
    // Remove the documentIndex from the toBeDeletedDocuments array
    const updatedToBeDeletedDocuments = toBeDeletedDocuments.filter(
      (index) => index !== documentIndex
    );
    setToBeDeletedDocuments(updatedToBeDeletedDocuments);
    if (updatedToBeDeletedDocuments.length === 0) {
      setIsEditingRequiredDocuments(false);
    }
  };

  // Function to check if there are documents to be deleted
  const hasDocumentsToBeDeleted = toBeDeletedDocuments.length > 0;

  // Function to handle saving the changes
  const handleSaveDeletedDocumentsClick = async (e) => {
    e.preventDefault();
    // Create a copy of the procedure data
    const updatedProcedureData = _.cloneDeep(procedureData);

    // Filter out the documents that are marked for deletion
    updatedProcedureData.requiredDocuments =
      updatedProcedureData.requiredDocuments.filter(
        (document, index) => !toBeDeletedDocuments.includes(index)
      );

    // Call the API to save the updated procedureData
    try {
      const response = await updateProcedureRequest(updatedProcedureData);

      // Check the response and handle success/failure accordingly
      if (response.data) {
        toast.success("Procedure Updated Successfully");
      } else {
        toast.error("Error Saving Procedure Data");
      }
    } catch (error) {
      console.error("Error saving procedure data:", error);
      toast.error("Error Saving Procedure Data");
    }

    // Clear the deletedDocuments array and exit edit mode
    setToBeDeletedDocuments([]);
    setIsEditingRequiredDocuments(false);
  };

  // ADD MAIN STEPS
  const AddMainStep = ({ open, onClose }) => {
    const [mainStepDescription, setMainStepDescription] = useState("");
    const [substeps, setSubsteps] = useState([]);
    const [substepDescription, setSubstepDescription] = useState("");
    const [substepDetail, setSubstepDetail] = useState("");

    const handleAddSubstep = (e) => {
      e.preventDefault();
      console.log(substepDescription, substepDetail);

      if (substepDescription.trim() !== "" && substepDetail.trim() !== "") {
        const newSubstep = {
          description: substepDescription,
          detail: substepDetail,
        };

        setSubsteps((prevSubsteps) => {
          const updatedSubsteps = [...prevSubsteps, newSubstep];
          console.log(updatedSubsteps); // Log the updated state
          return updatedSubsteps;
        });

        setSubstepDescription("");
        setSubstepDetail("");
      }
    };

    const handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = [...substeps];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSubsteps(items);
      console.log(substeps);
    };

    const handleSave = async (e) => {
      e.preventDefault();

      if (mainStepDescription.trim() === "") {
        toast.error("Please enter a main step description");
        return;
      }

      const updatedProcedureData = {
        ...procedureData,
        mainSteps: [
          ...procedureData.mainSteps,
          {
            description: mainStepDescription,
            subSteps: substeps,
          },
        ],
      };
      console.log(updatedProcedureData);
      try {
        setProcedureData(updatedProcedureData);

        await updateProcedureRequest(updatedProcedureData);

        toast.success("Procedure Updated Successfully");

        onClose();
      } catch (error) {
        console.log(error);
        toast.error("Error Updating Procedure");
      }
    };

    const handleClose = () => {
      setMainStepDescription("");
      setSubsteps([]);
      setSubstepDescription("");
      setSubstepDetail("");
      onClose();
    };
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Add Main Step</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Main Step Description"
            variant="outlined"
            color="success"
            value={mainStepDescription}
            onChange={(e) => setMainStepDescription(e.target.value)}
            margin="normal"
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="substeps">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    maxHeight: "350px", // Set a maximum height for scrolling
                    overflowY: "auto", // Enable vertical scrolling
                  }}>
                  {substeps?.map((substep, index) => (
                    <Draggable
                      key={index}
                      draggableId={`substep-${index}`}
                      index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Card
                            key={index}
                            variant="outlined"
                            style={{ marginTop: 16 }}>
                            <CardContent>
                              <Typography
                                variant="subtitle1"
                                style={{ color: colorTags.SECONDARY }}
                                gutterBottom>
                                Substep {index + 1}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Description: {substep.description}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Detail: {substep.detail}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                color="success"
                label="Substep Description"
                variant="outlined"
                value={substepDescription}
                onChange={(e) => setSubstepDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                color="success"
                label="Substep Detail"
                variant="outlined"
                value={substepDetail}
                onChange={(e) => setSubstepDetail(e.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddSubstep}
              style={{ marginTop: 16 }}>
              Add Substep
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoadingUpdateProcedure}
            onClick={handleSave}
            style={{ marginRight: 16 }}
            color="success">
            Save
          </LoadingButton>
          <LoadingButton onClick={handleClose} color="error">
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  // UPDATE MAIN STEPS   //DELETE MAINSTEP
  const EditMainStep = ({ open, onClose, data, mainStepIndex }) => {
    const [mainStepDescription, setMainStepDescription] = useState("");
    const [substeps, setSubsteps] = useState([]);
    const [substepDescription, setSubstepDescription] = useState("");
    const [substepDetail, setSubstepDetail] = useState("");
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
      if (data) {
        setMainStepDescription(data.description);
        setSubsteps(data.subSteps);
      }
    }, [data]);

    const handleAddSubstep = () => {
      if (substepDescription.trim() !== "" && substepDetail.trim() !== "") {
        const newSubstep = {
          description: substepDescription,
          detail: substepDetail,
        };
        setSubsteps([...substeps, newSubstep]);
        setSubstepDescription("");
        setSubstepDetail("");
      }
    };

    const handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = [...substeps];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSubsteps(items);
    };

    const handleSave = async (e) => {
      e.preventDefault();
      // Check if the mainStepDescription is not empty
      if (mainStepDescription.trim() === "") {
        toast.error("Please enter a main step description");
        return;
      }

      const updatedProcedureData = _.cloneDeep(procedureData);
      console.log(updatedProcedureData);
      console.log(mainStepIndex);
      // Check if the mainStepIndex is within bounds
      if (
        mainStepIndex !== undefined &&
        mainStepIndex >= 0 &&
        mainStepIndex < updatedProcedureData.mainSteps.length
      ) {
        updatedProcedureData.mainSteps[mainStepIndex].description =
          mainStepDescription;
        updatedProcedureData.mainSteps[mainStepIndex].subSteps = substeps;
      } else {
        toast.error("Invalid main step index");
        return;
      }

      // Assuming updateProcedureRequest is an asynchronous function, call it to save the data
      try {
        // Update the procedure data in your backend
        await updateProcedureRequest(updatedProcedureData).then((res) => {
          if (res.data) {
            // toast.success("Procedure Updated Successfully");
            toast.success("Main Step Updated Successfully");
            handleClose();
          }
        });
      } catch (error) {
        console.log(error);
        toast.error("Error Updating Main Step");
      }
    };
    const handleDeleteMainStep = () => {
      setDeleteDialogOpen(true);
    };

    const confirmDeleteMainStep = async (e) => {
      e.preventDefault();
      // Ensure that the mainStepIndex is within bounds
      const updatedProcedureData = _.cloneDeep(procedureData);

      if (
        mainStepIndex !== undefined &&
        mainStepIndex >= 0 &&
        mainStepIndex < updatedProcedureData.mainSteps.length
      ) {
        updatedProcedureData.mainSteps.splice(mainStepIndex, 1);
        try {
          // Update the procedure data in your backend
          await updateProcedureRequest(updatedProcedureData).then((res) => {
            if (res.data) {
              // toast.success("Procedure Updated Successfully");
              toast.success("Main Step Updated Successfully");
              handleClose();
            }
          });
        } catch (error) {
          console.log(error);
          toast.error("Error Updating Main Step");
        }
        setDeleteDialogOpen(false); // Close the delete dialog
      }
    };

    const handleCancelDelete = () => {
      setDeleteDialogOpen(false); // Close the delete dialog
    };

    const handleClose = () => {
      setMainStepDescription("");
      setSubsteps([]);
      setSubstepDescription("");
      setSubstepDetail("");
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Edit Main Step</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Main Step Description"
            variant="outlined"
            color="success"
            value={mainStepDescription}
            onChange={(e) => setMainStepDescription(e.target.value)}
            margin="normal"
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="substeps">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    maxHeight: "350px", // Set a maximum height for scrolling
                    overflowY: "auto", // Enable vertical scrolling
                  }}>
                  {substeps?.map((substep, index) => (
                    <Draggable
                      key={index}
                      draggableId={`substep-${index}`}
                      index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Card
                            key={index}
                            variant="outlined"
                            style={{ marginTop: 16 }}>
                            <CardContent>
                              <Typography
                                variant="subtitle1"
                                style={{ color: colorTags.SECONDARY }}
                                gutterBottom>
                                {substep.description}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Detail: {substep?.detail}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                color="success"
                label="Substep Description"
                variant="outlined"
                value={substepDescription}
                onChange={(e) => setSubstepDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                color="success"
                label="Substep Detail"
                variant="outlined"
                value={substepDetail}
                onChange={(e) => setSubstepDetail(e.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddSubstep}
              style={{ marginTop: 16 }}>
              Add Substep
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteMainStep}
            style={{ marginRight: "5%" }}>
            Delete Main Step
          </Button>
          <LoadingButton
            loading={isLoadingUpdateProcedure}
            onClick={handleSave}
            style={{ marginRight: 16 }}
            color="success">
            Save
          </LoadingButton>

          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>

        <Dialog open={isDeleteDialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Delete Main Step</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this main step?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="success">
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingUpdateProcedure}
              onClick={confirmDeleteMainStep}
              color="error">
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  };

  const DeleteSubStep = ({ open, onClose, mainStepIndex, subStepIndex }) => {
    const handleDeleteSubStep = async (mainStepIndex, subStepIndex) => {
      try {
        // Create a copy of the procedure data
        const updatedProcedureData = _.cloneDeep(procedureData);

        // Remove the specified substep from the mainStep
        updatedProcedureData?.mainSteps[mainStepIndex].subSteps.splice(
          subStepIndex,
          1
        );

        // Call the API to save the updated procedureData
        const response = await updateProcedureRequest(updatedProcedureData);

        // Check the response and handle success/failure accordingly
        if (response.data) {
          toast.success("Substep Deleted Successfully");
          onClose();
        } else {
          toast.error("Error Deleting Substep");
          onClose();
        }
      } catch (error) {
        // Handle API request error
        console.log(error);
        onClose();

        toast.error("Error Deleting Substep");
      }
    };
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography gutterBottom variant="body2">
                  Are you sure you want to delete substep?
                </Typography>
                <div className="flex gap-2 justify-end pt-3">
                  <LoadingButton
                    loading={isLoadingUpdateProcedure}
                    variant="contained"
                    color="error"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() =>
                      handleDeleteSubStep(mainStepIndex, subStepIndex)
                    }>
                    Delete
                  </LoadingButton>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<CloseIcon />}
                    onClick={() => setDeleteDialogOpen(false)}>
                    Close
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };
  const [deletingSubStepIndex, setDeletingSubStepIndex] = useState(null);

  // DELETE SUBSTEP
  // const handleDeleteSubStep = async (mainStepIndex, subStepIndex) => {
  //   // // Create a copy of the mainStep to avoid mutating state directly
  //   // const updatedProcedureData = JSON.parse(JSON.stringify(procedureData));

  //   // updatedProcedureData.mainSteps[mainStepIndex].subSteps.splice(
  //   //   subStepIndex,
  //   //   1
  //   // );

  //   // Update procedureData with the modified mainStep
  //   const updatedProcedure = {
  //     ...procedureData,
  //     mainSteps: [
  //       ...procedureData.mainSteps.slice(0, mainStepIndex),
  //       {
  //         ...procedureData.mainSteps[mainStepIndex],
  //         subSteps: [
  //           ...procedureData.mainSteps[mainStepIndex].subSteps?.slice(
  //             0,
  //             subStepIndex
  //           ),
  //           ...procedureData.mainSteps[mainStepIndex].subSteps?.slice(
  //             subStepIndex + 1
  //           ),
  //         ],
  //       },
  //       ...procedureData.mainSteps.slice(mainStepIndex + 1),
  //     ],
  //   };

  //   await updateProcedureRequest(updatedProcedure)
  //     .then((res) => {
  //       if (res.data) {
  //         toast.success("Procedure Updated Successfully");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error Updating Procedure");
  //     });

  //   // Clear the deletingSubStepIndex
  //   setDeletingSubStepIndex(null);
  // };

  if (isLoadingProcedure) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  } else if (isErrorProcedure) {
    return <div>{error}</div>;
  } else {
    return (
      <div className={classes.page}>
        <div style={{ padding: "40px" }}>
          <Typography
            variant="h5"
            style={{ marginTop: "10px", paddingLeft: "20px" }}
            className={classes.mainTitle}>
            {procedureData?.title + " " + procedureData?.productName}{" "}
          </Typography>
        </div>

        <div className={classes.pageContainer}>
          <div className={classes.leftColumn}>
            <Stack direction="column" spacing={2}>
              <Card className={classes.card}>
                <div className={classes.header}>
                  <Typography className={classes.title}>Steps</Typography>
                  <div
                    style={{
                      position: "relative",
                      // width: "70%",
                    }}>
                    {" "}
                    <IconButton
                      className={classes.addButton}
                      color="primary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: 0,
                        boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                      }}
                      onClick={() => setAddMainStepOpen(true)}>
                      <AddCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                  </div>
                </div>
                <CardContent className={classes.mainStepsContainer}>
                  {procedureData?.mainSteps?.map((mainStep, mainStepIndex) => (
                    <div key={mainStep.id} variant="outlined">
                      <CardContent>
                        <Typography variant="h6">
                          {mainStep.description}
                          <IconButton
                            onClick={() => {
                              setEditMainStepOpen(true);
                              setEditMainStep(mainStep);
                              setMainIndex(mainStepIndex);
                            }}
                            className={classes.addButton}
                            color="primary"
                            style={{
                              backgroundColor: "white",
                              boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                            }}>
                            <Edit fontSize="medium" />
                          </IconButton>
                        </Typography>
                        <Divider />
                        {mainStep?.subSteps?.map((subStep, subStepIndex) => (
                          <div
                            key={subStepIndex}
                            variant="outlined"
                            style={{ marginTop: "15px" }}>
                            {isEditSubStep && subStepIndex === stepIndex ? (
                              <>
                                {" "}
                                <TextareaAutosize
                                  rowsMin={3} // You can adjust this to set the minimum number of rows to display
                                  rowsMax={6} // You can adjust this to set the maximum number of rows to display
                                  fullWidth
                                  value={editSubStepDesc}
                                  onChange={(e) =>
                                    setEditSubStepDesc(e.target.value)
                                  }
                                />
                                <TextareaAutosize
                                  rowsMin={3}
                                  rowsMax={6}
                                  fullWidth
                                  value={editSubStepDetail}
                                  onChange={(e) =>
                                    setEditSubStepDetail(e.target.value)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <Typography style={{ listStyleType: "circle" }}>
                                  <FiberManualRecord
                                    fontSize="small"
                                    style={{
                                      color: `#${mainStepIndex * 30}6738`,
                                    }}
                                  />
                                  {subStep?.description}
                                </Typography>
                                <Typography>{subStep?.detail}</Typography>
                              </>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "-10px",
                              }}>
                              {isEditSubStep && subStepIndex === stepIndex ? (
                                <div style={{ width: "fit-content" }}>
                                  <LoadingButton
                                    loading={isLoadingUpdateProcedure}
                                    onClick={() =>
                                      handleSaveStep(
                                        mainStepIndex,
                                        subStepIndex
                                      )
                                    }
                                    color="success">
                                    Save
                                  </LoadingButton>
                                  <LoadingButton
                                    onClick={() =>
                                      handleCancelEditStep(
                                        mainStepIndex,
                                        subStepIndex
                                      )
                                    }
                                    color="secondary">
                                    Cancel
                                  </LoadingButton>
                                </div>
                              ) : (
                                <>
                                  {!isEditSubStep && (
                                    <>
                                      <IconButton
                                        onClick={() =>
                                          handleEditStep(
                                            mainStepIndex,
                                            subStepIndex,
                                            subStep.description,
                                            subStep.detail
                                          )
                                        }>
                                        <Edit />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          // handleRemoveSubStep(
                                          //   mainStepIndex,
                                          //   subStepIndex
                                          // )
                                          {
                                            setMainIndex(mainStepIndex);
                                            setStepIndex(subStepIndex);
                                            setDeleteDialogOpen(true);
                                          }
                                        }>
                                        <Delete />
                                      </IconButton>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ))}

                        <Button
                          onClick={() => {
                            setEditMainStepOpen(true);
                            setEditMainStep(mainStep);
                          }}
                          style={{ color: colorTags.SECONDARY }}
                          startIcon={<AddCircleOutline />}>
                          Add Sub Step
                        </Button>
                      </CardContent>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Stack>
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.iconContainer}>
              <div style={{ display: "flex" }}>
                <DeleteDialog
                  api={useDeleteProcedureRequestMutation}
                  dialogLabel="Delete Procedure"
                  id={procedureData?.id}
                  name={procedureData?.title}
                />
                <PDFDownloadLink
                  document={<PdfDocument />}
                  fileName="ProcedureData.pdf"
                  style={{ textDecoration: "none" }}>
                  <IconButton style={{ color: "#006738" }}>
                    <DownloadIcon />
                  </IconButton>
                </PDFDownloadLink>
              </div>
            </div>
            <Card className={classes.card}>
              <div className={classes.header}>
                <Typography className={classes.title}>Summary</Typography>
                <div
                  style={{
                    position: "relative",
                    // width: "60%",
                  }}>
                  {" "}
                  {isEditingSummary ? (
                    <div style={{ width: "fit-content" }}>
                      <LoadingButton
                        loading={isLoadingUpdateProcedure}
                        onClick={handleSaveSummaryClick}
                        color="success"
                        style={{
                          backgroundColor: "white",
                          color: colorTags.PRIMARY,
                          position: "absolute",
                          right: "100px",
                        }}>
                        Save
                      </LoadingButton>
                      <Button
                        onClick={handleCancelSummaryClick}
                        color="secondary"
                        style={{
                          backgroundColor: "white",
                          position: "absolute",
                          right: "0",
                        }}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <IconButton
                      onClick={handleEditSummaryClick}
                      className={classes.addButton}
                      color="primary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: 0,
                        boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                      }}>
                      <Edit fontSize="medium" />
                    </IconButton>
                  )}
                </div>
              </div>
              <CardContent>
                {/* <Typography variant="body1">{procedureData.summary}</Typography> */}
                {isEditingSummary ? (
                  <TextField
                    multiline
                    fullWidth
                    value={editedSummary}
                    onChange={(e) => setEditedSummary(e.target.value)}
                  />
                ) : (
                  // <Typography variant="body1">{editedummary}</Typography>
                  <Typography variant="body1">
                    {procedureData?.summary}
                  </Typography>
                )}
              </CardContent>
            </Card>
            {/* <Card className={classes.card}>
              <div className={classes.header}>
                <Typography className={classes.title}>
                  Step Detail
                  <span style={{ fontSize: "13px" }}>
                    {procedureData?.mainSteps &&
                      procedureData?.mainSteps[mainIndex]?.subSteps[stepIndex]
                        ?.description}
                  </span>
                </Typography>
                <div style={{ position: "relative" }}> */}
            {/* {isEditing ? (
                  <div style={{ width: "fit-content" }}>
                    <Button
                      onClick={handleSaveClick}
                      color="success"
                      style={{
                        backgroundColor: "white",
                        color: colorTags.PRIMARY,

                        position: "absolute",
                        right: "100px",
                      }}>
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelClick}
                      color="secondary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: "0",
                      }}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <IconButton
                    onClick={handleEditClick}
                    className={classes.addButton}
                    color="primary"
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      right: 0,
                      boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                    }}>
                    <Edit fontSize="medium" />
                  </IconButton>
                )} */}
            {/* </div>
              </div>
              <CardContent> */}
            {/* {isEditing ? (
                <TextField
                  multiline
                  fullWidth
                  value={editedDetail}
                  onChange={(e) => setEditedDetail(e.target.value)}
                />
              ) : ( */}
            {/* <Typography variant="body1">{editedDetail}</Typography> */}
            {/* )} */}
            {/* </CardContent>
            </Card> */}

            <Card className={classes.card}>
              <div className={classes.header}>
                <Typography className={classes.title}>
                  Institutions Involved
                </Typography>
                <div style={{ position: "relative" }}>
                  {isEditingInstitutions ? (
                    <LoadingButton
                      loading={isLoadingUpdateProcedure}
                      onClick={handleSaveInstitutionsClick}
                      variant="outlined"
                      style={{ color: "#fff" }}>
                      Save
                    </LoadingButton>
                  ) : (
                    <IconButton
                      className={classes.addButton}
                      onClick={() => setAddInstitutionsOpen(true)}
                      color="primary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: 0,
                        boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                      }}>
                      <AddCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                  )}
                </div>
              </div>
              <CardContent>
                <Grid container spacing={3}>
                  {procedureData?.institutionsInvolved?.map(
                    (institution, index) => {
                      // Find the details for this institution from institutionsData
                      const institutionDetails = institutionsData?.find(
                        (item) => item.id === institution.institutionId
                      );

                      if (!institutionDetails) {
                        return null; // Skip if details are not found
                      }

                      return (
                        <Grid item xs={3} mb={2} key={index}>
                          <div className={classes.imageContainer}>
                            <img
                              src={BASE_FILE_URL + institutionDetails?.image}
                              alt={institutionDetails?.institutionName}
                              className={classes.image}
                              title={institutionDetails?.institutionName}
                            />
                            {deletedInstitutions?.includes(index) ? (
                              <IconButton
                                style={{
                                  backgroundColor: "white",
                                  position: "absolute",
                                  right: 0,
                                  boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                                }}
                                onClick={() =>
                                  handleCancelRemoveInstitution(index)
                                }>
                                <CancelIcon
                                  style={{
                                    position: "absolute",
                                    bottom: "18px",
                                    right: "5px",
                                    backgroundColor: "white",
                                    boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                    color: "red",
                                    borderRadius: "50%",
                                    zIndex: 1000,
                                  }}
                                  color="error"
                                  fontSize="small"
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                style={{
                                  backgroundColor: "white",
                                  position: "absolute",
                                  right: 0,
                                  boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                                }}
                                onClick={() => handleRemoveInstitution(index)}>
                                <Delete
                                  style={{
                                    position: "absolute",
                                    bottom: "18px",
                                    right: "5px",
                                    backgroundColor: "white",
                                    boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                    color: "red",
                                    borderRadius: "50%",
                                    zIndex: 1000,
                                  }}
                                  color="error"
                                  fontSize="small"
                                />
                              </IconButton>
                            )}
                          </div>
                          <Typography
                            mt={1}
                            pl={1}
                            variant="body2"
                            textAlign="justify">
                            {institutionDetails?.institutionName}
                            {institutionDetails?.website && (
                              <Link
                                href={institutionDetails?.website}
                                target="_blank"
                                style={{ textDecoration: "none" }}>
                                <LanguageIcon
                                  style={{
                                    // color: colorTags.PRIMARY,
                                    color: "#00A9FF",
                                    marginLeft: "5px",
                                    width: "15px",
                                  }}
                                />
                              </Link>
                            )}
                          </Typography>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <div className={classes.header}>
                <Typography className={classes.title}>
                  Required Documents
                </Typography>
                <div style={{ position: "relative" }}>
                  {isEditingRequiredDocuments ? (
                    <LoadingButton
                      loading={isLoadingUpdateProcedure}
                      onClick={handleSaveDeletedDocumentsClick}
                      variant="outlined"
                      style={{ color: "#fff" }}>
                      Save
                    </LoadingButton>
                  ) : (
                    <IconButton
                      className={classes.addButton}
                      onClick={() => setAddDocumentsOpen(true)}
                      color="primary"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        right: 0,
                        boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                      }}>
                      <AddCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                  )}
                </div>
              </div>
              <CardContent>
                <Grid container spacing={3}>
                  {procedureData?.requiredDocuments?.map((document, index) => {
                    // Find the details for this institution from institutionsData
                    const documentDetails = documentsData?.find(
                      (item) => item.id === document.documentId
                    );

                    if (!documentDetails) {
                      return null; // Skip if details are not found
                    }

                    return (
                      <Grid item xs={3} mb={2} key={index}>
                        <div className={classes.imageContainer}>
                          <img
                            src={
                              BASE_FILE_URL + documentDetails?.coverImage ?? ""
                            }
                            alt={documentDetails?.documentName}
                            className={classes.image}
                            title={documentDetails?.documentName}
                          />
                          {toBeDeletedDocuments?.includes(index) ? (
                            <IconButton
                              style={{
                                backgroundColor: "white",
                                position: "absolute",
                                right: 0,
                                boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                              }}
                              onClick={() => handleCancelRemoveDocument(index)}>
                              <CancelIcon
                                style={{
                                  position: "absolute",
                                  bottom: "18px",
                                  right: "5px",
                                  backgroundColor: "white",
                                  boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                  color: "red",
                                  borderRadius: "50%",
                                  zIndex: 1000,
                                }}
                                color="error"
                                fontSize="small"
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{
                                backgroundColor: "white",
                                position: "absolute",
                                right: 0,
                                boxShadow: `rgba(${colorTags.PRIMARY_RGBA} , 0.1) 0px 10px 15px -3px, rgba(${colorTags.PRIMARY_RGBA} ,0.05) 0px 4px 6px -2px`,
                              }}
                              onClick={() => handleRemoveDocument(index)}>
                              <Delete
                                style={{
                                  position: "absolute",
                                  bottom: "18px",
                                  right: "5px",
                                  backgroundColor: "white",
                                  boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 12px`,
                                  color: "red",
                                  borderRadius: "50%",
                                  zIndex: 1000,
                                }}
                                color="error"
                                fontSize="small"
                              />
                            </IconButton>
                          )}
                        </div>
                        <Typography
                          mt={1}
                          pl={1}
                          variant="body2"
                          textAlign="justify">
                          {documentDetails?.title}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
            <AddMainStep
              open={addMainStepOpen}
              onClose={() => setAddMainStepOpen(false)}
            />

            <EditMainStep
              open={editMainStepOpen}
              onClose={() => {
                setEditMainStepOpen(false);
                setEditMainStep(null);
              }}
              data={editMainStep}
              mainStepIndex={mainIndex}
            />

            <AddInstitutions
              open={AddInstitutionsOpen}
              onClose={() => setAddInstitutionsOpen(false)}
            />

            <AddDocuments
              open={AddDocumentsOpen}
              onClose={() => setAddDocumentsOpen(false)}
            />

            <DeleteSubStep
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              mainStepIndex={mainIndex}
              subStepIndex={stepIndex}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default ProceduresPageDash;
