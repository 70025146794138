import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./input.css";
import { AuthProvider } from "./features/auth/authContext";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "aos/dist/aos.css";
import AOS from "aos";
import { UserProvider } from "./features/auth2/UserContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store} style={{ width: "100%" }}>

   <UserProvider>
      <App />
    </UserProvider>
    </Provider>
  // </React.StrictMode>
);
AOS.init(); 


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
