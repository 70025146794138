import { useLocation } from "react-router-dom";
import ListItemLink from "../ListItemLink";

export default function SideBarMenuSingle({ menu }) {
  const { pathname } = useLocation();

  if (!menu.permission) return <></>;

  return (
    <ListItemLink
      to={menu.to}
      primary={menu.label}
      bgColor={menu.selected?.includes(pathname) ? "#EEEEEE" : "#006738"}
      textColor={menu.selected?.includes(pathname) ? "#006738" : "#EEEEEE"}
      icon={
        <div
          style={{
            color: menu.selected?.includes(pathname) ? "#006738" : "#EEEEEE",
          }}>
          {menu.icon}
        </div>
      }
    />
  );
}
